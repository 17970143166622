import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import InfoRounded from "@mui/icons-material/InfoRounded";
import { IconButton } from "@mui/material";
import ActivityTable from "./components/ActivityTable/ActivityTable.js";
import withAuthorization from "../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const UserActivity = (props) => {
  const classes = useStyles();

  const { history, ...rest } = props;

  useEffect(() => {
    const fetchData = async () => {};
    fetchData();
  });

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <h4>User Activity Search</h4>
        <Tooltip title="Enter user id to view user's activity">
          <IconButton aria-label="?">
            <InfoRounded />
          </IconButton>
        </Tooltip>
      </div>

      <div className={classes.content}>
        <ActivityTable />
      </div>
    </div>
  );
};

UserActivity.propTypes = {
  history: PropTypes.object,
};

export default withAuthorization(UserActivity);
