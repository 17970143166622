import React, {useState, useEffect} from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  }
}));

const PaypalWebhookDetailsHeader = props => {
  const { history, payoutItemId, dateCreated, ...rest } = props;
  const classes = useStyles();
  
  return(
    <div className={classes.root}>
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
    >
      <Grid
        item
      >
        <span><b>Paypal Payout Id:</b> {payoutItemId}</span>
      </Grid>
      
      <Grid 
        item
      >
        <span><b>Date Created:</b> {dateCreated}</span>
      </Grid>
    </Grid>
  </div>
  );
  
}

PaypalWebhookDetailsHeader.propTypes = {
  
}

export default withMasterAuthorization(PaypalWebhookDetailsHeader);