export function GetInstitutionName(bankAccount){
  if(bankAccount.InstitutionName){
    return bankAccount.InstitutionName
  } else{
    return ""
  }
}

export function GetInstitutionLogo(bankAccount){
  if(bankAccount.InstitutionLogo){
    return bankAccount.InstitutionLogo
  } else{
    return ""
  }
}

export function GetBankGroupId(bankAccount){
  if(bankAccount.BankGroupId){
    return bankAccount.BankGroupId
  } else{
    return 0
  }
}

export function GetRefreshRequired(bankAccount){
  if(bankAccount.RefreshRequired){
    return bankAccount.RefreshRequired
  } else{
    return false
  }
}

export function GetStatus(bankAccount){
  if(bankAccount.Status){
    return bankAccount.Status
  } else{
    return 0
  }
}

export function GetBetfullyBankAccountId(bankAccount){
  if(bankAccount.BetfullyBankAccountId){
    return bankAccount.BetfullyBankAccountId
  } else{
    return 0
  }
}

export function GetStoredTransactionCount(bankAccount){
  if(bankAccount.StoredTransactionCount){
    return bankAccount.StoredTransactionCount
  } else{
    return 0
  }
}

export function GetMatchedStoredTransactionCount(bankAccount){
  if(bankAccount.MatchedStoredTransactionCount){
    return bankAccount.MatchedStoredTransactionCount
  } else{
    return 0
  }
}

export function GetPlaidBankAccountId(bankAccount){
  if(bankAccount.PlaidBankAccountId){
    return bankAccount.PlaidBankAccountId
  } else{
    return ""
  }
}

export function GetAccountName(bankAccount){
  if(bankAccount.AccountName){
    return bankAccount.AccountName
  } else{
    return ""
  }
}

export function GetAccountMask(bankAccount){
  if(bankAccount.AccountMask){
    return bankAccount.AccountMask
  } else{
    return ""
  }
}

export function GetAccountSubtype(bankAccount){
  if(bankAccount.AccountSubtype){
    return bankAccount.AccountSubtype
  } else{
    return ""
  }
}










