import React, { useState, useEffect } from "react";
import clsx from "clsx";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import {
  GetOfferId,
  GetTitle,
  GetDescription,
  GetActivationCount,
  GetReward,
  GetDateCreated,
  GetStatus,
  GetStartDate,
  GetExpirationDate,
  GetCashBackPercentage,
  GetCompanyIcon,
  GetCompanyId as GetOfferCompanyId
} from "helpers/OfferHelper";

import mockData from "./data";
import withAuthorization from "../../../../helpers/Authorization";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: "flex",
    alignItems: "center"
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

const statusColors = {
  delivered: "success",
  pending: "info",
  refunded: "danger"
};



const LatestOrders = props => {
  const { className, recentOffers, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        // action={
        //   <Button color="primary" size="small" variant="outlined">
        //     New entry
        //   </Button>
        // }
        title="Latest Offers"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Offer Id</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell sortDirection="desc">
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel active direction="desc">
                        Date Starting
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell>Users</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recentOffers.map(order => (
                  <TableRow hover key={GetOfferId(order)}>
                    <TableCell>{GetOfferId(order)}</TableCell>
                    <TableCell>
                      {GetDateCreated(order)}
                    </TableCell>
                    <TableCell>{GetStartDate(order)}</TableCell>
                    <TableCell>{GetActivationCount(order)}</TableCell>
                    <TableCell>
                      <div className={classes.statusContainer}>
                        {GetStatus(order)}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        {/* <Button color="primary" size="small" variant="text">
          View all <ArrowRightIcon />
        </Button> */}
      </CardActions>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string
};

export default withAuthorization(LatestOrders);
