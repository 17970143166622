import React, { useState, useEffect } from "react";
import WebService from "../../../../api/WebServices";
import SessionHelper from "../../../../helpers/SessionHelper";
import clsx from "clsx";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import Spinner from 'react-bootstrap/Spinner'
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import DateRangeIcon from '@mui/icons-material/DateRange';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  TablePagination,
  Grid,
  colors
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { Form, Row, Col } from "react-bootstrap";
import FormLabel from '@mui/material/FormLabel';

import { 
  GetBetfullyTransactionId, 
  GetPlaidName, 
  GetPlaidAmount, 
  GetPlaidAmountFormatted,
  GetPlaidDateString,
  GetPlaidDate,
  GetStatus,
  GetPlaidTransactionId,
  GetPlaidAccountId,
  GetPlaidCategoryId,
  GetPlaidTransactionType,
  GetPlaidIsoCurrencyCode,
  GetPlaidUnofficialCurrencyCode,
  GetPlaidPending,
  GetPlaidPendingTransactionId,
  GetPlaidAccountOwner,
  GetDateCreated,
  GetLastPlaidUpdate,
  GetProcessed,
  GetClientId,
  GetClientName,
  GetMerchantName,
  GetWord
 } from "../../../../helpers/WebUserTransactionHelper";

 import {
  GetCompanyNameWithId
} from "helpers/CompanyHelper";

import withAuthorization from "../../../../helpers/Authorization";
import { GetName } from "helpers/UserHelper";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 800,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
  formControlWidth: {
    width: "162px",
    marginRight: theme.spacing(2)
  },
  customLoader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100"
  }
}));

const statusColors = {
  delivered: "success",
  pending: "info",
  refunded: "danger",
};



const UnmatchedSection = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const { history } = props;
  const [transactions, setTransactions] = useState([]);

  const TIME_RANGE = {
    DEFAULT: {Name:'NOTHING', Val:-1},
    SAME_DAY: {Name:'Last Day', Val:0},
    MONTH: {Name:'Last Month', Val:1},
    MONTH_6: {Name:'Last 6 Months', Val:2},
    YEAR: {Name:'Last Year', Val:3},
    ALL_TIME: {Name:'All Time', Val:4},
    CUSTOM: {Name:'Custom', Val:5}
  }

  const [isLoading, setIsLoading] = useState(true);

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [selectedTransactionFilter, setSelectedTransactionFilter] = useState(-1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateRange, setDateRange] = useState(TIME_RANGE.ALL_TIME.Val);

  const dateSelected = (date) => {
    setStartDate(date);
  };

  const endDateSelected = (date) => {
    setEndDate(date);
  };

  const [matchChecked, setMatchChecked] = useState(true);

  const handleMatchCheckChange = (event) => {
    setMatchChecked(event.target.checked);
  };

  const rangeSelected = (range) => {
    if(range == TIME_RANGE.CUSTOM.Val || dateRange == TIME_RANGE.CUSTOM.Val){
      setStartDate(new Date());
      setEndDate(new Date());
    }
    setDateRange(range);
  };

  const COLUMNS_SORT = {
    DEFAULT: -1,
    NAME_DESC: 0,
    NAME_ASC: 1,
    AMOUNT_DESC: 2,
    AMOUNT_ASC: 3,
    DATE_DESC: 4,
    DATE_ASC: 5,
    STATUS_DESC: 6,
    STATUS_ASC: 7,
    COMP_NAME_DESC: 8,
    COMP_NAME_ASC: 9
  }

  const [selectedSort, setSelectedSort] = useState(COLUMNS_SORT.DEFAULT);
  
  const toggleSort = (columnType) => {
    var nextColumn = columnType;
    switch(columnType){
      case COLUMNS_SORT.NAME_ASC:
      case COLUMNS_SORT.NAME_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.NAME_DESC)?COLUMNS_SORT.NAME_ASC:COLUMNS_SORT.NAME_DESC;
        sortTransactions(nextColumn);
        setSelectedSort(nextColumn);
        break;
     
      case COLUMNS_SORT.AMOUNT_ASC:
      case COLUMNS_SORT.AMOUNT_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.AMOUNT_DESC)?COLUMNS_SORT.AMOUNT_ASC:COLUMNS_SORT.AMOUNT_DESC;
        sortTransactions(nextColumn);
        setSelectedSort(nextColumn);
        break;
      case COLUMNS_SORT.DATE_ASC:
      case COLUMNS_SORT.DATE_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.DATE_DESC)?COLUMNS_SORT.DATE_ASC:COLUMNS_SORT.DATE_DESC;
        sortTransactions(nextColumn);
        setSelectedSort(nextColumn);
        break;
      case COLUMNS_SORT.STATUS_ASC:
      case COLUMNS_SORT.STATUS_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.STATUS_DESC)?COLUMNS_SORT.STATUS_ASC:COLUMNS_SORT.STATUS_DESC;
        sortTransactions(nextColumn);
        setSelectedSort(nextColumn);
        break;
      case COLUMNS_SORT.COMP_NAME_ASC:
      case COLUMNS_SORT.COMP_NAME_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.COMP_NAME_DESC)?COLUMNS_SORT.COMP_NAME_ASC:COLUMNS_SORT.COMP_NAME_DESC;
        sortTransactions(nextColumn);
        setSelectedSort(nextColumn);
        break;
        
      default:
        nextColumn = COLUMNS_SORT.DEFAULT;
        sortTransactions(nextColumn);
        setSelectedSort(COLUMNS_SORT.DEFAULT);
        break;
    }
   
  };

  const sortTransactions = (columnType) =>{
    transactions.sort(function(userA, userB) {
      var compareResult = 0;
      switch(columnType){
        case COLUMNS_SORT.NAME_ASC:
          compareResult = GetPlaidName(userB).localeCompare(GetPlaidName(userA));
          break;
        case COLUMNS_SORT.NAME_DESC:
          compareResult = GetPlaidName(userA).localeCompare(GetPlaidName(userB));
          break;
        case COLUMNS_SORT.AMOUNT_ASC:
          if (GetPlaidAmount(userA)<GetPlaidAmount(userB)){
            compareResult = -1;
          }
          if (GetPlaidAmount(userA)>GetPlaidAmount(userB)) {
            compareResult = 1;
          }
          break;
        case COLUMNS_SORT.AMOUNT_DESC:
          if (GetPlaidAmount(userA)>GetPlaidAmount(userB)){
            compareResult = -1;
          }
          if (GetPlaidAmount(userA)<GetPlaidAmount(userB)) {
            compareResult = 1;
          }
          break;
        case COLUMNS_SORT.STATUS_ASC:
          compareResult = GetMerchantName(userB).localeCompare(GetMerchantName(userA));
          break;
        case COLUMNS_SORT.STATUS_DESC:
          compareResult = GetMerchantName(userA).localeCompare(GetMerchantName(userB));
          break;
        case COLUMNS_SORT.COMP_NAME_ASC:
          compareResult = GetCompanyNameWithId(GetClientId(userB)).localeCompare(GetCompanyNameWithId(GetClientId(userA)));
          break;
        case COLUMNS_SORT.COMP_NAME_DESC:
          compareResult = GetMerchantName(userA).localeCompare(GetMerchantName(userB));
          break;
          
        case COLUMNS_SORT.DATE_ASC:
          compareResult = new Date(GetPlaidDate(userA)) - new Date(GetPlaidDate(userB));
          break;
        case COLUMNS_SORT.DATE_DESC:
          compareResult = new Date(GetPlaidDate(userB)) - new Date(GetPlaidDate(userA))
          break;
        
        default:
          compareResult = GetPlaidDateString(userB).localeCompare(GetPlaidDateString(userA));
      }
      //Compare unique ids in case of ties
      if(compareResult == 0){
        if (GetBetfullyTransactionId(userA)<GetBetfullyTransactionId(userB)){
          compareResult = -1;
        }
        if (GetBetfullyTransactionId(userA)>GetBetfullyTransactionId(userB)) {
          compareResult = 1;
        }
      }
      return compareResult;
    });
    applyAllFilters();
  };
  
  const isSortActive = (columnType) => {
    return selectedSort == columnType;
  };


  useEffect(() => {
    const fetchData = async () => {
      const result = await WebService.GetUmatchedTransactions(history, 0);
      setIsLoading(false)
      console.log("Get Transactions:" + result);
      if (result != null) {
        const myData = [].concat(result)
        .sort((a, b) => new Date(a.PlaidDate).getTime() < new Date(b.PlaidDate).getTime() ? 1 : -1)
        setTransactions(myData);
      } else {
        history.push({ pathname: "./sign-in" });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("Use effect called")
    applyAllFilters();
    setPage(0)
  },[transactions, selectedTransactionFilter, dateRange,startDate,endDate, matchChecked]); //[selectedLogFilter, logs, startDate, endDate, dateRange]);

  const handleFilterSelected = (value) => {
      setSelectedTransactionFilter(value)
  };

  

  const applyAllFilters = () => {
    var allTransactionsVar = transactions;

    if(selectedTransactionFilter > 0){
      allTransactionsVar = allTransactionsVar.filter(aLog => GetClientId(aLog) == selectedTransactionFilter)
    }

    if(matchChecked){
      //allTransactionsVar = allTransactionsVar.filter(aLog => GetClientId(aLog) > 0)
    }
  
    //0 is showing all times
    if(dateRange != TIME_RANGE.ALL_TIME.Val){
      var startFormatted = moment(startDate);
      var endFormatted = moment(startDate).add(-6, 'days');
      var compareFunc = (aDate) => {
        return true
      }
      console.log("Date Range Selected: "+dateRange)
      console.log("Date Range VAL: "+TIME_RANGE.MONTH.Val)
      console.log(TIME_RANGE.MONTH.Val == dateRange)
      if(dateRange == TIME_RANGE.SAME_DAY.Val){
        console.log("Date Range Option: "+TIME_RANGE.SAME_DAY.Name)
        compareFunc = (aDate) => {
          return moment(aDate).isSame(startDate, 'day')
        }
      }else if(dateRange == TIME_RANGE.MONTH.Val){
        console.log("Date Range Option: "+TIME_RANGE.MONTH.Name)
        endFormatted = moment(startDate).add(-1, 'months');
        compareFunc = (aDate) => {
          return moment(aDate).isBetween(endFormatted, startFormatted, 'days', [])
        }
      }else if(dateRange == TIME_RANGE.MONTH_6.Val){
        console.log("Date Range Option: "+TIME_RANGE.MONTH_6.Name)
        endFormatted = moment(startDate).add(-6, 'months');
        compareFunc = (aDate) => {
          return moment(aDate).isBetween(endFormatted, startFormatted, 'days', [])
        }
      }else if(dateRange == TIME_RANGE.YEAR.Val){
        console.log("Date Range Option: "+TIME_RANGE.YEAR.Name)
        endFormatted = moment(startDate).add(-1, 'years');
        compareFunc = (aDate) => {
          return moment(aDate).isBetween(endFormatted, startFormatted, 'days', [])
        }
      }else if(dateRange == TIME_RANGE.ALL_TIME.Val){
        console.log("Date Range Option: "+TIME_RANGE.ALL_TIME.Name)
      }else if(dateRange == TIME_RANGE.CUSTOM.Val){
        console.log("Date Range Option: "+TIME_RANGE.CUSTOM.Name)
        compareFunc = (aDate) => {
          endFormatted = moment(endDate);
          if(moment(endDate).isBefore(moment(startDate))){
            return moment(aDate).isBetween(endFormatted, startFormatted, 'days', [])
          }else{
            return moment(aDate).isBetween(startFormatted, endFormatted, 'days', [])
          }
        }
      }
        

      console.log("Start Date: "+startFormatted);
      console.log("End Date: "+endFormatted);
      
      allTransactionsVar = allTransactionsVar.filter(
        aLog => compareFunc(GetPlaidDateString(aLog))
      )
    }

    setFilteredTransactions(allTransactionsVar)
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0)
    setRowsPerPage(event.target.value);
  };

  const useRowStyles = makeStyles((theme) => ({
    '& > *': {
      borderBottom: 'unset',
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      backgroundColor: colors.blueGrey[100]
    },
  }));
  
  function TransactionRow(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {GetPlaidName(row)}
          </TableCell>
          <TableCell align="left">{GetPlaidAmountFormatted(row)}</TableCell>
          <TableCell align="left">{GetPlaidDate(row)}</TableCell>
          <TableCell align="left">{GetMerchantName(row)}</TableCell>
          <TableCell align="left">{GetCompanyNameWithId(GetClientId(row))}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={5}>
                <Typography variant="h4" gutterBottom component="div">
                  Plaid Details
                </Typography>
                <Paper className={classes.paper}>
                <Grid container spacing={4}>
                  <Grid align="left" item xs={4}>
                      <div><b>Plaid Name:</b> {GetPlaidName(row)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                      <div><b>Plaid Amount:</b> {GetPlaidAmountFormatted(row)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                     <div> <b>Plaid Date:</b> {GetPlaidDate(row)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                      <div><b>Transaction Id:</b> {GetPlaidTransactionId(row)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                      <div><b>Account Id:</b> {GetPlaidAccountId(row)}  </div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                      <div><b>Category Id:</b> {GetPlaidCategoryId(row)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                      <div><b>Transaction Type:</b> {GetPlaidTransactionType(row)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                      <div><b>Iso Currency Code:</b> {GetPlaidIsoCurrencyCode(row)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                      <div><b>Unofficial Currency Code:</b> {GetPlaidUnofficialCurrencyCode(row)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                      <div><b>Pending:</b> {(GetPlaidPending(row))?"yes":"no"}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                      <div><b>Pending Transaction Id:</b> {GetPlaidPendingTransactionId(row)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                     <div><b>Account Owner:</b> {GetPlaidAccountOwner(row)}</div> 
                  </Grid>
                  <Grid align="left" item xs={4}>
                      <div><b>Last Plaid Update:</b> {GetLastPlaidUpdate(row)}</div>
                  </Grid>
                </Grid>
                </Paper>
              </Box>
              <Box margin={5}>
                <Typography variant="h4" gutterBottom component="div">
                  BetFully Details
                </Typography>
                <Paper className={classes.paper}>
                <Grid container spacing={4}>
                  <Grid align="left" item xs={4}>
                      <div><b>Transaction Id:</b> {GetBetfullyTransactionId(row)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                    <div><b>Status:</b> {GetStatus(row)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                      <div><b>Date Archived:</b> {GetDateCreated(row)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                      <div><b>Processed:</b> {(GetProcessed(row))?"yes":"no"}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                    
                      <div><b>Matching Keyword:</b> {GetWord(row)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                    
                      <div><b>Client:</b> {GetClientName(row)}</div>
                  </Grid>
                </Grid>
                </Paper>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        // action={
        //   <Button color="primary" size="small" variant="text">
        //     View all <ArrowRightIcon />
        //   </Button>
        // }
        title="Unmatched User Transactions"
      />
      <Form inline>
          <Form.Label className="my-1 mr-2 ml-2" htmlFor="companyFilter">
            Company
          </Form.Label>
          <Form.Control
            as="select"
            className="my-1 mr-sm-2"
            id="companyFilter"
            onChange = {(event) => handleFilterSelected(event.target.value)}
          >
            <option value={-1}>All</option>
            {SessionHelper.getCompanyList().map((aNum) => (
              <option value={aNum.Id} key={aNum.Id}>{aNum.Name}</option>
            ))}
          </Form.Control>
        <DateRangeIcon className="mr-sm-2"/>
        <Form.Control
            as="select"
            className={classes.formControlWidth}
            id="inlineFormCustomSelectPref"
            onChange = {(event) => rangeSelected(event.target.value)}
          >
            <option value={TIME_RANGE.ALL_TIME.Val} key={TIME_RANGE.ALL_TIME.Val}>{TIME_RANGE.ALL_TIME.Name}</option>
            <option value={TIME_RANGE.MONTH_6.Val} key={TIME_RANGE.MONTH_6.Val}>{TIME_RANGE.MONTH_6.Name}</option>
            <option value={TIME_RANGE.MONTH.Val} key={TIME_RANGE.MONTH.Val}>{TIME_RANGE.MONTH.Name}</option>
            <option value={TIME_RANGE.YEAR.Val} key={TIME_RANGE.YEAR.Val}>{TIME_RANGE.YEAR.Name}</option>
            <option value={TIME_RANGE.CUSTOM.Val} key={TIME_RANGE.CUSTOM.Val}>{TIME_RANGE.CUSTOM.Name}</option>
        </Form.Control>

        {dateRange == TIME_RANGE.CUSTOM.Val && <DatePicker selected={startDate} onChange={date => dateSelected(date)}/>}
        {dateRange == TIME_RANGE.CUSTOM.Val && <FormLabel className="mr-sm-2 ml-sm-2"> to </FormLabel>}
        {dateRange == TIME_RANGE.CUSTOM.Val && <DatePicker selected={endDate} onChange={date => endDateSelected(date)}/>}
        </Form>
        
      {/* <FormControlLabel
      className="mr-sm-2 ml-sm-2"
        control={<Checkbox
          checked={matchChecked}
          onChange={handleMatchCheckChange}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />}
        label="Show Matched Transactions Only"
      /> */}
        <Divider />
      
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.NAME_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.NAME_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.NAME_ASC) || isSortActive(COLUMNS_SORT.NAME_DESC)}>
                        PlaidName
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.AMOUNT_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.AMOUNT_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.AMOUNT_ASC) || isSortActive(COLUMNS_SORT.AMOUNT_DESC)}>
                        PlaidAmount
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.DATE_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.DATE_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.DATE_ASC) || isSortActive(COLUMNS_SORT.DATE_DESC)}>
                        PlaidDate
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.STATUS_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.STATUS_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.STATUS_ASC) || isSortActive(COLUMNS_SORT.STATUS_DESC)}>
                        Merchant Name
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.COMP_NAME_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.COMP_NAME_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.COMP_NAME_ASC) || isSortActive(COLUMNS_SORT.COMP_NAME_DESC)}>
                        Betfully Company
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { isLoading && <TableRow
                        className={classes.tableRow}
                        hover
                        key={-1}
                      >
                      <TableCell></TableCell>
                   
                      <TableCell md="auto"><Spinner animation="border" variant="primary" /></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>}
                  { !isLoading && (filteredTransactions == null || filteredTransactions.length == 0) && <TableRow
                        className={classes.tableRow}
                        hover
                        key={-1}
                      >
                    <TableCell></TableCell>
                    <TableCell>{"No Data Available"}</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>}
                  {filteredTransactions.slice(page * rowsPerPage, (rowsPerPage)+(page)*rowsPerPage).map((log) => (
                    <TransactionRow key={GetBetfullyTransactionId(log)} row={log} />
                    // <TableRow
                    //   className={classes.tableRow}
                    //   hover
                    //   key={GetBetfullyTransactionId(log)}
                    // >
                    //   <TableCell>{GetPlaidName(log)}</TableCell>
                    //   <TableCell>{GetPlaidAmount(log)}</TableCell>
                    //   <TableCell>{GetPlaidDate(log)}</TableCell>
                    //   <TableCell>{GetStatus(log)}</TableCell>
                    // </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={filteredTransactions.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </CardActions>
    </Card>
  );
};

UnmatchedSection.propTypes = {
  className: PropTypes.string,
};

export default withAuthorization(UnmatchedSection);
