import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, IconButton } from "@mui/material";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { Form, Row, Col, Spinner } from "react-bootstrap";
import {
  GetCompanyId,
  GetCompanyName,
  GetCompanyNameWithId
} from "helpers/CompanyHelper";
import SessionHelper from "helpers/SessionHelper";
import { 
  CreateUserTransactionEntriesMap,
  CreateUserTransactionEntriesMapDate,
  GetUserTransactionEntries,
  GetTotalDeposits,
  GetDepositAmountSum,
  GenerateDataForChart,
  DateUnits,
  TransactionEntryField,
  GetTEMField,
  GetTransactionEntryFieldString
 } from "helpers/UserDetailsHelper";

import {
  UserProfile,
  TotalDeposits,
  OfferActivty,
  ClaimRate,
  DepositsBySite,
  DepositHistory,
  UserInfo,
  CompetitorsDeposits,
  CompetitorsWithdrawals,
  TotalWithdrawals,
  TransactionTable,
} from "./components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import PropTypes from "prop-types";
import withAuthorization from "../../../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const TransactionsView = (props) => {
  const classes = useStyles();
  const { history, userDetails, isLoading } = props;
  // const [userDetails, setUserDetails] = useState([]);

  const TIME_RANGE = {
    SAME_DAY: {Name:'Last Day', Val:0, DateUnits:DateUnits.DAY, Count:1},
    WEEK: {Name:'Last 7 Days', Val:5, DateUnits:DateUnits.DAY, Count:7},
    WEEK_2: {Name:'Last 14 Days', Val:6,DateUnits:DateUnits.DAY, Count:14},
    MONTH: {Name:'Last Month', Val:1, DateUnits:DateUnits.DAY, Count:30},
    MONTH_3: {Name:'Last 3 Months', Val:7, DateUnits: DateUnits.MONTH, Count:3},
    MONTH_6: {Name:'Last 6 Months', Val:2, DateUnits: DateUnits.MONTH, Count:6},
    YEAR: {Name:'Last Year', Val:3, DateUnits:DateUnits.MONTH, Count:12},
    ALL_TIME: {Name:'All Time', Val:4, DateUnits:DateUnits.YEAR, Count:5},
  }

  const [compareCompanies, setCompareCompanies] = useState(false);
  const [transactionMap, setTransactionMap] = useState(CreateUserTransactionEntriesMap(GetUserTransactionEntries(userDetails)));
  const [transactionEntryField, setTransactionEntryField] = useState(TransactionEntryField.DEPOSIT);
  const [timeRangeBar, setTimeRangeBar] = useState(TIME_RANGE.ALL_TIME);
  const [filteredCompanyIdA, setFilteredCompanyIdA] = useState(
    (SessionHelper.getCompanyList() != null && SessionHelper.getCompanyList().length > 0)?
    GetCompanyId((SessionHelper.getCompanyList())[0]):0);
    const [filteredCompanyIdB, setFilteredCompanyIdB] = useState(0);
    
    const [barChartDataSet, setBarChartDataSet] = useState(
      GenerateDataForChart(
        !compareCompanies,
        GetUserTransactionEntries(userDetails), 
        (filteredCompanyIdA == 0)?SessionHelper.getCompanyList():SessionHelper.getCompanyList().filter(x => GetCompanyId(x) ==  filteredCompanyIdA || GetCompanyId(x) == filteredCompanyIdB), 
        TIME_RANGE.ALL_TIME.Count,
        TIME_RANGE.ALL_TIME, transactionEntryField));

  const handleCompareCheckChange = (event) => {
    if(!event.target.checked){
      setCompareCompanies(false)
      setFilteredCompanyIdB(0)
    }else{
      setCompareCompanies(true)
    }
  }
  // const handleMatchCheckChange = (event) => {
  //   if(!event.target.checked){
  //     setFilteredCompanyId(filteredCompanyId.filter(x => x != +event.target.value))
  //   }else{
  //     setFilteredCompanyId([...filteredCompanyId, +event.target.value])
  //   }
  // };

  const handleSetTransactionEntryField = (event) => {
    setTransactionEntryField(+event.target.value)
  }

  const handleChangeA = (event) => {
    setFilteredCompanyIdA(+event.target.value)
  };

  const handleChangeB = (event) => {
    setFilteredCompanyIdB(+event.target.value)
  };

  const handleDateChangeDate = (event) => {
    var amountTime = TIME_RANGE.ALL_TIME;
    switch(+event.target.value){
      case TIME_RANGE.SAME_DAY.Val:
        amountTime = TIME_RANGE.SAME_DAY;
        break;
      case TIME_RANGE.WEEK.Val:
        amountTime = TIME_RANGE.WEEK;
        break;
      case TIME_RANGE.WEEK_2.Val:
        amountTime = TIME_RANGE.WEEK_2;
        break;
      case TIME_RANGE.MONTH.Val:
        amountTime = TIME_RANGE.MONTH;
        break;
      case TIME_RANGE.MONTH_3.Val:
        amountTime = TIME_RANGE.MONTH_3;
        break;
      case TIME_RANGE.MONTH_6.Val:
        amountTime = TIME_RANGE.MONTH_6;
        break;
      case TIME_RANGE.ALL_TIME.Val:
        amountTime = TIME_RANGE.ALL_TIME;
        break;
      case TIME_RANGE.YEAR.Val:
        amountTime = TIME_RANGE.YEAR;
        break;
    }
    setTimeRangeBar(amountTime);
};

  const handleDateChangeBar = (timeRangeVal) => {
      if(timeRangeVal == TIME_RANGE.SAME_DAY.Val)
        setTimeRangeBar(TIME_RANGE.SAME_DAY);
      if(timeRangeVal ==  TIME_RANGE.WEEK.Val)
        setTimeRangeBar(TIME_RANGE.WEEK);
      if(timeRangeVal ==  TIME_RANGE.WEEK_2.Val)
        setTimeRangeBar(TIME_RANGE.WEEK_2);
      if(timeRangeVal ==  TIME_RANGE.MONTH.Val)
        setTimeRangeBar(TIME_RANGE.MONTH);
      if(timeRangeVal ==  TIME_RANGE.MONTH_3.Val)
        setTimeRangeBar(TIME_RANGE.MONTH_3);
      if(timeRangeVal ==  TIME_RANGE.MONTH_6.Val)
        setTimeRangeBar(TIME_RANGE.MONTH_6);
      if(timeRangeVal ==  TIME_RANGE.ALL_TIME.Val)
        setTimeRangeBar(TIME_RANGE.ALL_TIME);
      if(timeRangeVal ==  TIME_RANGE.YEAR.Val)
        setTimeRangeBar(TIME_RANGE.YEAR);
    //setTimeRangeBar(timeRange);
  };

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    console.log("CHANGE")
    var filterList = (filteredCompanyIdA == 0)?SessionHelper.getCompanyList():SessionHelper.getCompanyList().filter(x => GetCompanyId(x) ==  filteredCompanyIdA || GetCompanyId(x) == filteredCompanyIdB);
    setTransactionMap((timeRangeBar ==TIME_RANGE.ALL_TIME)?CreateUserTransactionEntriesMap(GetUserTransactionEntries(userDetails))
      :CreateUserTransactionEntriesMapDate(GetUserTransactionEntries(userDetails), timeRangeBar.Count,timeRangeBar.DateUnits))

    setBarChartDataSet(GenerateDataForChart(!compareCompanies,GetUserTransactionEntries(userDetails), filterList, timeRangeBar.Count,timeRangeBar.DateUnits, transactionEntryField))
  }, [userDetails, isLoading, timeRangeBar, filteredCompanyIdA, filteredCompanyIdB, transactionEntryField, compareCompanies]);
  
  return (
    <div className={classes.root}>
      {/* {SessionHelper.getCompanyList().map((comp)=> <FormControlLabel
      className="mr-sm-2 ml-sm-2"
        control={<Checkbox
          checked={filteredCompanyId.includes(GetCompanyId(comp))}
          value={GetCompanyId(comp)}
          onChange={handleMatchCheckChange}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />}
        label={GetCompanyName(comp)}
      />)} */}
       {/* <FormControl className="mr-sm-2 ml-sm-2">
        <InputLabel htmlFor="age-native-simple">Time Span</InputLabel>
        <Select
          native
          value={timeRangeBar.Val}
          onChange={handleDateChangeDate}
          inputProps={{
            name: 'name',
            id: 'name-native-simple',
          }}
        >
          <option value={TIME_RANGE.ALL_TIME.Val} key={TIME_RANGE.ALL_TIME.Val}>{TIME_RANGE.ALL_TIME.Name}</option>
          <option value={TIME_RANGE.MONTH_6.Val} key={TIME_RANGE.MONTH_6.Val}>{TIME_RANGE.MONTH_6.Name}</option>
          <option value={TIME_RANGE.MONTH.Val} key={TIME_RANGE.MONTH.Val}>{TIME_RANGE.MONTH.Name}</option>
          <option value={TIME_RANGE.YEAR.Val} key={TIME_RANGE.YEAR.Val}>{TIME_RANGE.YEAR.Name}</option>
        </Select>
      </FormControl> */}
      {false && compareCompanies &&
      <FormControl className="mr-sm-2 ml-sm-2">
        <InputLabel htmlFor="age-native-simple">Transaction Type</InputLabel>
        <Select
          native
          value={transactionEntryField}
          onChange={handleSetTransactionEntryField}
          inputProps={{
            name: 'name',
            id: 'name-native-simple',
          }}
        >
          <option value={TransactionEntryField.DEPOSIT}>{GetTransactionEntryFieldString(TransactionEntryField.DEPOSIT)}</option> 
          <option value={TransactionEntryField.WITHDRAWAL}>{GetTransactionEntryFieldString(TransactionEntryField.WITHDRAWAL)}</option> 
          <option value={TransactionEntryField.POINTS}>{GetTransactionEntryFieldString(TransactionEntryField.POINTS)}</option> 
         
      
        </Select>
      </FormControl>}
      {/* <FormControl className="mr-sm-2 ml-sm-2">
        <InputLabel htmlFor="age-native-simple">Company</InputLabel>
        <Select
          native
          value={filteredCompanyIdA}
          onChange={handleChangeA}
          inputProps={{
            name: 'name',
            id: 'name-native-simple',
          }}
        >
      {SessionHelper.getCompanyList().filter(x => GetCompanyId(x) != filteredCompanyIdB).map((comp)=> 
          <option key={GetCompanyId(comp)} value={GetCompanyId(comp)}>{GetCompanyName(comp)}</option> 
         
       )}
        </Select>
      </FormControl> */}

      {false && compareCompanies && <FormControl className="mr-sm-2 ml-sm-2">
        <InputLabel htmlFor="age-native-simple">Company 2</InputLabel>
        <Select
          native
          value={filteredCompanyIdB}
          onChange={handleChangeB}
          inputProps={{
            name: 'name',
            id: 'name-native-simple',
          }}
        >
          <option value={0}>{"Select"}</option> 
      {SessionHelper.getCompanyList().filter(x => GetCompanyId(x) != filteredCompanyIdA).map((comp)=> 
          <option key={GetCompanyId(comp)} value={GetCompanyId(comp)}>{GetCompanyName(comp)}</option> 
         
       )}
        </Select>
      </FormControl>
      }
      <div>
      {/* <FormControlLabel
      className="mr-sm-2 ml-sm-2"
        control={<Checkbox
          checked={compareCompanies}
          onChange={handleCompareCheckChange}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />}
        label={"Compare Data"}
      /> */}
      </div>
      {isLoading &&<Spinner animation="border" variant="primary" />}
      {!isLoading && <Grid container spacing={4}>
        {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalDeposits companyName={GetCompanyNameWithId(filteredCompanyIdA)} amount={GetTEMField(filteredCompanyIdA, transactionMap, TransactionEntryField.DEPOSIT)}/>
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalWithdrawals companyName={GetCompanyNameWithId(filteredCompanyIdA)} amount={GetTEMField(filteredCompanyIdA, transactionMap, TransactionEntryField.WITHDRAWAL)}/>
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12} style={{opacity: (compareCompanies && filteredCompanyIdB > 0)?100:0}}>
          <CompetitorsDeposits companyName={GetCompanyNameWithId(filteredCompanyIdB)} amount={GetTEMField(filteredCompanyIdB, transactionMap, TransactionEntryField.DEPOSIT)}/>
        </Grid>

        <Grid item lg={3} sm={6} xl={3} xs={12} style={{opacity: (compareCompanies&& filteredCompanyIdB > 0)?100:0}}>
          <CompetitorsWithdrawals companyName={GetCompanyNameWithId(filteredCompanyIdB)} amount={GetTEMField(filteredCompanyIdB, transactionMap, TransactionEntryField.WITHDRAWAL)}/>
        </Grid>

        <Grid item lg={4} md={6} xl={3} xs={12}>
          <DepositsBySite showAllStats={!compareCompanies} transactionMap={transactionMap} barChartData={barChartDataSet} transactionEntryField={transactionEntryField} />
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <DepositHistory  
            showAllStats={!compareCompanies}
            barChartDataSet={barChartDataSet}
            transactionEntryField={transactionEntryField}
           />
        </Grid> */}
        
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <TransactionTable isLoading={isLoading} userdetails={userDetails} />
        </Grid>
      </Grid>}
    </div>
  );
};

TransactionsView.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
};
export default withAuthorization(TransactionsView);
