import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import InfoRounded from "@mui/icons-material/InfoRounded";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Spinner from 'react-bootstrap/Spinner'
import {
  ButtonGroup,
  Button,
  Card,
  CardBody,
  CardText,
  CardGroup,
  CardTitle,
} from "reactstrap";
import Form from 'react-bootstrap/Form'

import { 
  KeywordStatusToInt, 
  GetPopupTitle, 
  GetPopupPos,
  GetPopupNeg,
  GetPopupBodyOperator } from '../../helpers/KeywordHelper'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import WebService from "../../api/WebServices";
import Modal from 'react-bootstrap/Modal';

import {
  Grid,
  IconButton,
  TextField,
  Link,
  Typography,
} from "@mui/material";
import { InputGroup } from "reactstrap";

import withAuthorization from "../../helpers/Authorization";
import Keywords from "./components/Keywords";
import AdminAccounts from "./components/AdminAccounts";
import webService from "../../api/WebServices";
import Navigation from "./components/Navigation";
import Profile from "./components/Profile";
import ProfileDetails from "./components/ProfileDetails";
import SessionHelper from 'helpers/SessionHelper'
import { ToastProvider, useToasts } from "react-toast-notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.white,
    height: "100%",
  },
  cardPreview: {
    width: "25%",
  },
  grid: {
    height: "100%",
    width: "50%",
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginLeft: "25%",
    marginRight: "75%",
    marginTop: "50px",
    marginBottom: "50px",
    maxWidth: '750px'
  },
  tableCellA: {
    paddingLeft: "30px",
    paddingTop: "33px",
    paddingBottom: "32px",
    // marginTop: "5px",
    // marginBottom: "5px",
  },
  buttonConfirm: {
    marginLeft: "10px",
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/auth.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(3),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  contentParent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    paddingLeft: 0,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  suggestion: {
    marginTop: theme.spacing(2),
    paddingBototm: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  spinnerCache:{
    textAlign:"center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const Settings = (props) => {
  const { history } = props;
  const { addToast } = useToasts();
  const classes = useStyles();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [DaysValid, setDaysValid] = useState(30);
  const [cashBackValue, setCashBackValue] = useState(2);
  const [rewardValue, setRewardValue] = useState(10000);
  const [daysAvailable, setDaysAvailable] = useState(7);
  const [sendRequest, setSendRequest] = useState(false);

  


  const [ActiveFilterList, setActiveFilterList] = useState([]);
  const [showCompletion, setShowCompletion] = useState(false);
  const handleCloseCompletion = () => setShowCompletion(false);
  const handleShowCompletion = () => setShowCompletion(true);

  const [KeywordInput, setKeywordInput] = useState("");
  const [SettingsKeyInput, setSettingsKeyInput] = useState("");
  const [SettingsValueInput, setSettingsValueInput] = useState("");
  const [EditRowId, setEditRowId] = useState(0);
  const [ShowAddSetting, setShowAddSetting] = useState(false);

  const keywordIdString = 'keywordIdInput';
  const settingsKeyString = 'settingsKeyInput';
  const settingsValueString = 'settingsValueInput';

  const [MyKeywords, setMyKeywords] = useState([]);
  const [rows, setRows] = useState([])
    
  const [showAddKeyword, setShowAddKeyword] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const handleCloseAddKeyword = () => setShowAddKeyword(false);
  const handleShowAddKeyword = () => setShowAddKeyword(true);

  const [showClearCache, setShowClearCache] = useState(false);
  const handleCloseClearCache = () => setShowClearCache(false);
  const handleShowClearCache = () => setShowClearCache(true);

  const [showCacheSpinner, setShowCacheSpinner] = useState(false);
  const handleCloseCacheSpinner = () => setShowCacheSpinner(false);
  const handleShowCacheSpinner = () => setShowCacheSpinner(true);

  const [showResume, setShowResume] = useState(false);
  const handleHideResume = () => setShowResume(false);
  const handleShowResume = () => setShowResume(true);

  const increment = useRef(null);

  const handleShowEdit = () => setShowEdit(true);
  const handleCloseEdit = () => setShowEdit(false);
  const handleToggleEdit = () => setShowEdit(!showEdit);

  const handleCreateKeyword = () => {
    console.log("Send Keyword")
    console.log("Keyword request:");
    console.log(KeywordInput);
    if(KeywordInput != null) {
      WebService.CreateKeyword(history, KeywordInput)
        .then(data => {
          console.log("ABC DONE");
          console.log("Data: "+data);
          if(data != null) {
            handleCloseAddKeyword();
          }else{
            handleCloseAddKeyword()
          }
        });
    }
  }

  const handleCreateSettingItem = () => {
    if(SettingsKeyInput != null && SettingsValueInput != null) {
      WebService.CreateAppSettingItem(history, SettingsKeyInput, SettingsValueInput)
        .then(data => {
          if(data != null) {
            if(data.length > 0){
              setRows(data);
              setShowAddSetting(false);
              setEditRowId(0)
              setSettingsValueInput("")
              setSettingsKeyInput("")
              addToast("Setting Added", {
                appearance: "success",
                autoDismiss: true,
              });
            }else{
              addToast("Unable to add setting", {
                appearance: "error",
                autoDismiss: true,
              });
            }
          }
          else{
            addToast("Something went wrong", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    }
  }

  const handleEditSettingItem = () => {
    if(SettingsValueInput != null && EditRowId > 0) {
      WebService.UpdateAppSettingItem(history, EditRowId, SettingsValueInput)
        .then(data => {
          if(data != null) {
            if(data.length > 0){
              setRows(data);
              setShowAddSetting(false);
              setEditRowId(0)
              setSettingsValueInput("")
              setSettingsKeyInput("")
              addToast("Setting Updated", {
                appearance: "success",
                autoDismiss: true,
              });
            }else{
              addToast("Unable to update setting", {
                appearance: "error",
                autoDismiss: true,
              });
            }
          }
          else{
            addToast("Something went wrong", {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    }
  }

  const checkIfResetCompleted = () => {
    try {
      increment.current = setInterval(async () => {
        WebService.CheckSaveAndClearBothCachesComplete(history).then(data => {
          console.log("result:"+data)
          if(data == true){
            handleCloseCacheSpinner();
            handleShowResume();
            clearInterval(increment.current);
          }
        });
      }, 5000);
    } catch(e) {
      console.log(e);
    }
  };

  const handleCreateDemoData = () =>{
    handleShowCacheSpinner();
    
    WebService.CreateDemoData(history)
      .then(data => {
        if(data != null) {
          checkIfResetCompleted()
        }else{
          handleCloseCacheSpinner();
          handleShowResume();
          handleCloseClearCache()
        }
      });
  }

  const handleClearAndSaveCache = () => {
    handleShowCacheSpinner();
    
    WebService.SaveAndClearBothCaches(history)
      .then(data => {
        if(data != null) {
          checkIfResetCompleted()
        }else{
          handleCloseCacheSpinner();
          handleShowResume();
          handleCloseClearCache()
        }
      });
  }

  const handleResumeServer = () => {
    WebService.ResumeServer(history)
      .then(data => {
        if(data == true) {
          handleHideResume()
        }else{
        }
      });
  }

  

  const [popupData, setPopupdata] = useState({
    title: "Approve Keyword?", 
    body: "Approve or reject the proposed keyword. If rejected, the operator will have a chance to revise their request. You may leave a note to provide feedback on what they must correct.",
    keyword: 0,
    companyName: "Company",
    dateSubmitted:"Date: -",
    status: KeywordStatusToInt.None,
    adminNote: ""
  });

  const handleSetKeywordPopup = (companyName, dateCreated, theKeyword, keywordString, status, adminNote) => setPopupdata({
    title: "Approve Keyword?",
    body: "Approve or reject the proposed keyword. If rejected, the operator will have a chance to revise their request. You may leave a note to provide feedback on what they must correct.",
    keyword: theKeyword,
    keywordString: keywordString,
    companyName: companyName,
    dateSubmitted: dateCreated,
    status: status,
    adminNote: adminNote
  });

  const goToKeywordDetails = (keyword, companyName, dateCreated, keywordString, status, adminNote) => {
    console.log("Today is the day"+keyword);
    handleSetKeywordPopup(companyName, dateCreated, keyword, keywordString, status, adminNote);
    setKeywordInput(keywordString);
    handleCloseEdit();
    //handleSelectKeyword(keyword);
    handleShowCompletion();
  }

  const handleModifyKeyword = () => {
    console.log("Send Keyword")
    console.log("Keyword request:");
    console.log(KeywordInput);
    if(KeywordInput != null && popupData.keyword > 0) {
      WebService.ModifyKeyword(history, KeywordInput, popupData.keyword)
        .then(data => {
          console.log("ABC DONE");
          console.log("Data: "+data);
          if(data != null) {
            setMyKeywords(data);
          }else{
          }
        });
    }
    handleCloseCompletion();
  }


  const [formState, setFormState] = useState({
    isValid: true,
    values: {},
    touched: {},
    errors: {},
    productObj: {
      Title: "Title",
      Description: "Subtitle",
    },
  });

  const [RequestObject, setRequestObject] = useState({
    Title: "",
    Description: "",
    Reward: 0,
    CashbackPercent: 0,
    AmountOfDaysValid: 0,
    Add: [],
  });

  const handleBack = () => {
    history.goBack();
  };

  const handleEditSelected = (row) => {
    setShowAddSetting(false);
    if(row.Id == EditRowId){
      setEditRowId(0)
    }else{
      setEditRowId(row.Id)
      setSettingsValueInput(row.ItemValue)
    }
  }

  const handleAddSettingSelected = () => {
    if(!ShowAddSetting){
      setShowAddSetting(true)
      setEditRowId(0)
      setSettingsValueInput("")
      setSettingsKeyInput("")
    }else{
      setShowAddSetting(false)
    }
  }

  const myChangeHandler = (event) => {
    console.log("Name:"+event.target.name);
    console.log("Value:"+event.target.value);
    let nam = event.target.name;
    let val = event.target.value;
    event.persist();
    if(nam === keywordIdString){
      setKeywordInput(val)
    }else if(nam === settingsKeyString){
      setSettingsKeyInput(val);
    }else if(nam === settingsValueString){
      setSettingsValueInput(val);
    }
  }

  
  
  const GetBottomPortionOfPage = () => {
    if(SessionHelper.isMasterAdmin()){
      return (<AdminAccounts/>);
    }else if(webService.getUserAuthorization() === "Admin"){
      return (<Keywords 
              keywords={MyKeywords} 
              openAddKeywordPopup={handleShowAddKeyword} 
              DetailsButton={goToKeywordDetails}/>);

    }
    return (<div/>)
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await WebService.GetMyEvents(history, undefined);
      if(result != null){
        console.log("GetMyEvents results:");
        console.log(result.AllKeywordModels);
        setMyKeywords(result.AllKeywordModels);
        setRows(result.AppSettings);
      }else{
      }
    };
    fetchData();
  }, []);

  const hasError = (field) => formState.touched[field] && formState.errors[field] ? true : false;

  function createData(Id, ItemKey, ItemValue) {
    return { Id, ItemKey, ItemValue };
  }
  
  const fakerows = [
    createData(1, 'Frozen yoghurt', "156"),
    createData(2, 'Ice cream sandwich', "237"),
    createData(3, 'Eclair', "262"),
    createData(4, 'Cupcake', "305"),
    createData(5, 'Gingerbread', "356"),
  ];
  
  

  return (
    <div className={classes.root}>
      <Modal
        className={classes.content}
        show={showCompletion}
        onHide={() => handleCloseCompletion()}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{GetPopupTitle(popupData.status)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>{GetPopupBodyOperator(popupData.status)}</div>
          <br />
          <div className="d-flex justify-content-between"><div>Keyword: '{popupData.keywordString}'</div><Button onClick={() => handleToggleEdit()} color="link">Edit</Button></div>
          {showEdit && 
            <Form >
              <Form.Group controlId="formBasicEmail" >
                <Form.Label>{"Modified Keyword:"}</Form.Label>
                <Form.Control required placeholder="Enter Here" name={keywordIdString} value={KeywordInput} onChange={myChangeHandler}/>
                <Form.Text className="text-muted">
                  
                </Form.Text>
              </Form.Group>
            </Form>
          }
          <div>Date Created: {popupData.dateSubmitted}</div>
          <br />
            <div>Admin Note: {popupData.adminNote}</div>
        </Modal.Body>
        
        <Modal.Footer>
          <Button variant="primary" onClick={() => handleModifyKeyword()}>
            Confirm
          </Button>
          <Button variant="secondary" onClick={() => handleCloseCompletion()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className={classes.content}
        show={showAddKeyword}
        onHide={handleCloseAddKeyword}
        centered>
        <Modal.Header closeButton>
          <Modal.Title>{"Edit Keyword"}</Modal.Title>
        </Modal.Header>
        <Modal.Body><div>{"Keyword You Want to Add"}</div>
          <Form>
              <Form.Group controlId="formBasicEmail" >
                <Form.Label>Input the keyword you would like us to scan for</Form.Label>
                <Form.Control required placeholder="Enter keyword" name={keywordIdString} onChange={myChangeHandler}/>
                <Form.Text className="text-muted">
                  
                </Form.Text>
              </Form.Group>
          </Form>
          </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCreateKeyword}>
            Yes
          </Button>
          <Button variant="secondary" onClick={handleCloseAddKeyword}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        className={classes.content}
        show={showClearCache}
        onHide={handleCloseClearCache}
        backdrop="static"
        centered>
        <Modal.Header closeButton={!showCacheSpinner}>
          <Modal.Title>{"Save and Clear Caches"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{!showCacheSpinner && <div >{"Admin and User Caches will be saved then cleared"}</div>}
        {showCacheSpinner && <div className={classes.spinnerCache}><Spinner  animation="border" variant="primary" /></div>}
        {showCacheSpinner && <div className={classes.spinnerCache}>{"Saving Cache... (Can take up to 30 sec)"}</div>}
          </Modal.Body>
        <Modal.Footer>
        
          <Button variant="primary" disabled={showCacheSpinner} onClick={handleCreateDemoData}>
            Create Demo Data (Deletes current DB!)
          </Button>
          <Button variant="primary" disabled={showCacheSpinner} onClick={handleClearAndSaveCache}>
            Clear and Save
          </Button>
          <Button variant="primary" disabled={!showResume || showCacheSpinner} onClick={handleResumeServer}>
            Resume Server
          </Button>
          <Button variant="secondary" disabled={showCacheSpinner} onClick={handleCloseClearCache}>
            Cancel
          </Button>

        </Modal.Footer>
      </Modal>
      <ul className="list-group list-group-horizontal">
        
        <li className={`list-group-item border-0 w-25 ${classes.contentParent}`}>
          <Navigation/>
             <Button
             onClick={handleShowClearCache}
             className={classes.signInButton}
             color="secondary"
             fullwidth="true"
             size="small"
             variant="contained"
           >
             Reset Cache
           </Button>
        </li>

        <li className="list-group-item border-0 w-50">
          <Profile/> 
          <ProfileDetails/>

        </li>
        
        
        
        <li>
          {/*<ul className="list-group list-group-vertical">*/}
          {/*  <Button*/}
          {/*    className={classes.signInButton}*/}
          {/*    color="secondary"*/}
          {/*    disabled={!formState.isValid}*/}
          {/*    fullwidth="true"*/}
          {/*    size="small"*/}
          {/*    variant="contained"*/}
          {/*  >*/}
          {/*    Contact Us*/}
          {/*  </Button>*/}
          {/*  <Button*/}
          {/*    className={classes.signInButton}*/}
          {/*    color="secondary"*/}
          {/*    disabled={!formState.isValid}*/}
          {/*    fullwidth="true"*/}
          {/*    size="small"*/}
          {/*    variant="contained"*/}
          {/*  >*/}
          {/*    FAQ*/}
          {/*  </Button>*/}
          {/*  <Button*/}
          {/*    className={classes.signInButton}*/}
          {/*    color="secondary"*/}
          {/*    disabled={!formState.isValid}*/}
          {/*    fullwidth="true"*/}
          {/*    size="small"*/}
          {/*    variant="contained"*/}
          {/*  >*/}
          {/*    Credits*/}
          {/*  </Button>*/}
          {/*  <Button*/}
          {/*    className={classes.signInButton}*/}
          {/*    color="secondary"*/}
          {/*    disabled={!formState.isValid}*/}
          {/*    fullwidth="true"*/}
          {/*    size="small"*/}
          {/*    variant="contained"*/}
          {/*  >*/}
          {/*    Memberships*/}
          {/*  </Button>*/}
          {/*</ul>*/}
        </li>
        
      </ul>
      {/* <BasicTable/>   */}
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="40%">Key</TableCell>
              <TableCell width="40%" align="left">Value</TableCell>
              <TableCell width="20%" align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.Id}>
                <TableCell component="th" scope="row">
                  {row.ItemKey}
                </TableCell>
                {row.Id != EditRowId && <TableCell align="left"  className={classes.tableCellA} >{row.ItemValue}</TableCell>}
                {row.Id == EditRowId && <TableCell align="left">
                  <TextField
                  id="outlined-secondary"
                  variant="outlined"
                  value={SettingsValueInput}
                  name={settingsValueString} 
                  onChange={myChangeHandler}
                  color="secondary"
                />
                </TableCell>}
                <TableCell align="left">
                  <Button onClick={() => handleEditSelected(row)}>
                    {row.Id == EditRowId && <CancelOutlinedIcon/>}
                    {row.Id != EditRowId && <EditOutlinedIcon/>}
                  </Button>
                  {row.Id == EditRowId && <Button  className={classes.buttonConfirm} onClick={() => handleEditSettingItem()}>
                    <CheckOutlinedIcon/>
                  </Button>}
                  
                </TableCell>
              </TableRow>
            ))}
            {ShowAddSetting && <TableRow key={-1}>
                <TableCell align="left">
                  <TextField
                  id="outlined-secondary"
                  variant="outlined"
                  value={SettingsKeyInput}
                  name={settingsKeyString} 
                  onChange={myChangeHandler}
                  color="secondary"
                /></TableCell>
                <TableCell align="left">
                 <TextField
                  id="outlined-secondary"
                  variant="outlined"
                  value={SettingsValueInput}
                  name={settingsValueString} 
                  onChange={myChangeHandler}
                  color="secondary"
                  />
                </TableCell>
                <TableCell align="left">
                  <Button onClick={() => handleCreateSettingItem()}>
                    {"Confirm"}
                  </Button>
                </TableCell>
              </TableRow>}
          </TableBody>
        </Table>
        <Button onClick={() => handleAddSettingSelected()}>
          {ShowAddSetting && <CancelOutlinedIcon/>}
          {!ShowAddSetting && <AddCircleOutlineOutlinedIcon/>}
                  </Button>
      </TableContainer>
      <Keywords
         keywords={MyKeywords}
         openAddKeywordPopup={handleShowAddKeyword}
         DetailsButton={goToKeywordDetails}/>
         
    </div>
  );
};

Settings.propTypes = {
  history: PropTypes.object,
};

export default withAuthorization(Settings);
