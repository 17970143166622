import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Bar, Line } from "react-chartjs-2";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  FormLabel
} from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import {
  options
} from "helpers/ChartHelper"

import {
  GetOfferEntryFieldString
} from "helpers/UserDetailsHelper";

import {
  GetCompanyNameWithId
} from "helpers/CompanyHelper";

import withAuthorization from "../../../../../../helpers/Authorization";

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    position: "relative",
    height: "300px",
  },
  actions: {
    justifyContent: "flex-end"
  },
  formControlWidth: {
    width: "162px",

  },
}));

const LatestSales = props => {
  const { className, barChartDataSet, offerEntryField, showAllStats, ...rest } = props;
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        
        title={(showAllStats)?"Recent Data for "+((barChartDataSet.datasets != null && barChartDataSet.datasets[0] != null)?GetCompanyNameWithId(barChartDataSet.datasets[0].companyId):""):"Recent "+GetOfferEntryFieldString(offerEntryField) }
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Line data={barChartDataSet} options={options} />
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button color="primary" size="small" variant="text">
          Overview <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string,
  offerEntryField: PropTypes.any,
  showAllStats: PropTypes.bool
};

export default withAuthorization(LatestSales);
