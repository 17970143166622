import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Divider,
  colors
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GetAppIcon from "@mui/icons-material/GetApp";
import withAuthorization from "../../../../helpers/Authorization";

class ProductCardPreview extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { className, product, showImageSelect, displayImage, ...rest } = this.props;
    const classes = makeStyles((theme) => ({
      root: {},
      imageContainer: {
        height: 64,
        width: 64,
        backgroundColor: colors.orange[700],
        margin: "0 auto",
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: "5px",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      image: {
        width: "100%",
      },
      statsItem: {
        display: "flex",
        alignItems: "center",
      },
      statsIcon: {
        color: theme.palette.icon,
        marginRight: theme.spacing(1),
      },
    }));

    return (
      <Card {...rest}>
        <CardContent>
          <div>
            <img
              onClick={() => showImageSelect()}
              className="img-fluid pt-5 pl-5 pr-5 pb-5"
              style={{
                backgroundColor: colors.grey[300]
              }}
              alt="Offer Image"
              src={displayImage}
            />
          </div>
        
        </CardContent>
        <Divider />
        <CardActions>
          <Grid container justify="space-between">
          
            <Grid item>
              <Typography display="inline" variant="body2">
                Click to Change Image
              </Typography>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    );
  }
}

ProductCardPreview.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
};

export default withAuthorization(ProductCardPreview);
