import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import WebService from "../../api/WebServices.js";
import PropTypes from "prop-types";
import AdminsTable from './Components/AdminsTable'
import AdminsToolbar from './Components/AdminsToolbar'
import { EXPIRED_TOKEN } from 'constants/constants'
import withMasterAuthorization from "../../helpers/MasterAuthorization";
import { 
    GetCompanyId,
    GetCompanyName
  } from '../../helpers/CompanyHelper'
import SessionHelper from '../../helpers/SessionHelper'

  import { 
    GetEmail,
    GetUserName,
    GetAdminUserId, 
    GetDateCreated,
    GetUserRole, 
    GetUserCompany,
    GetUserCompanyId
  } from '../../helpers/AdminUserHelper'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    }
}));

const AdminList = props => {
    const { history, ...rest } = props;
    const classes = useStyles();
    const [admins, setAdmins] = useState([]);
    const [filteredAdmins, setFilteredAdmins] = useState([]);
    
    const [selectedFilter, setSelectedFilter] = useState(0);

    useEffect(() => {
        filterAdmins();

    }, [selectedFilter,admins]);

    const handleFilterSelected = (value) => {
        console.log("Filter selected: "+value)
        setSelectedFilter(value)
      };

    const filterAdmins = (value) =>{
        console.log(selectedFilter)
        if(selectedFilter != 0){
            console.log("filter")
            console.log(admins[0])
            const filteredRes = admins.filter(aUser => (aUser.CompanyId) == selectedFilter)
            setFilteredAdmins(filteredRes)
         //   setFilteredAdmins(admins)
        }else{
            console.log("No filter")
            setFilteredAdmins(admins)
        }
    }

    useEffect(() => {
        console.log("calling useeffect api call")
        if(SessionHelper.isMasterAdmin()) {
            const fetchData = async () => {
                const result = await WebService.GetAdminUsers(history, undefined);
                if (result != null) {
                    // Temporary sorting solution:
                    result.sort((firstAdminUser, secondAdminUser) =>
                       (firstAdminUser.Company != null ? GetCompanyId(firstAdminUser.Company) : 0) -
                       (secondAdminUser.Company != null ? GetCompanyId(secondAdminUser.Company) : 0));
                    console.log("Transfer")

                    for (var i = 0; i < result.length; i++) {
                        if(result[i].Company != null){
                            console.log("ID: "+result[i].Company)
                            result[i].CompanyId = GetCompanyId(result[i].Company);
                            result[i].CompanyName = GetCompanyName(result[i].Company);
                        }else{
                            result[i].CompanyId = 0;
                            result[i].CompanyName = "N/A";
                        }
                    } 
                    setAdmins(result)
                    filterAdmins()
                    
                } else {
                    history.push({pathname: "./sign-in"});
                }
            };
            fetchData();
        }
    }, []);

    return (
      <div className={classes.root}>
          <AdminsToolbar history={history} onFilterSelected={handleFilterSelected}/>
          <div className={classes.content}>
              <AdminsTable Admins={filteredAdmins} />
          </div>
      </div> 
    );
};

AdminList.propTypes = {
    history: PropTypes.object
};

export default withMasterAuthorization(AdminList);
