import React, { useState, useEffect } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";
import {
  Button
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WebService from "../../api/WebServices";
import SessionHelper from 'helpers/SessionHelper'

import {
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import withMasterAuthorization from "../../helpers/MasterAuthorization";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    width: "100%"
  },
  grid: {
    height: "100%"
  },
  centerWrapper: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundPosition: "center"
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center"
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  Roles: {
    marginTop: theme.spacing(2)
  },
  Companies: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  CreateButton: {
    margin: theme.spacing(2, 0)
  }
}));

const CreateAdminUser = props => {
  const { history } = props;

  const classes = useStyles();
  const [availableUserRoles, setUserAvailableRoles] = useState([]);
  const [availableCompanies, setAvailableCompanies] = useState([])
  const [loadInitialData, setLoadInitialData] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState({ Name: "Select One" })
  const [selectedRole, setSelectedRole] = useState({ Name: "Select One" });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successPopupData, setSuccessPopupData] = useState({
    title:"Success",
    body:"Successfully created the admin"
  });
  
  const [errorPopupData, setErrorPopupData] = useState({
    title:"Error creating admin",
    body:"There was an error creating and admin please contact support"
  }) 
  
  const [formState, setFormState] = useState({
    isValid: true,
    values: {},
    touched: {},
    errors: {}
  });

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleRoleDropDownSelected = roleSelected => {
    setSelectedRole(roleSelected);
  }
  
  const handleCompanyDropDownSelected = companySelected => { 
    setSelectedCompany(companySelected);
  }
  
  const handleCreateAdminUser = event => {
    event.preventDefault();
    
    const requestObject = {
      UserName: formState.values.UserName,
      Email: formState.values.Email,
      Password: " ",
      UserRoleId: selectedRole.Id,
      CompanyId: selectedCompany.Id
    };
    
    setIsLoading(true);
    
    WebService.CreateAdminUser(history, requestObject).then(data => {
      console.log("Data returned after user created:")
      console.log(data);
      if (data != null) {
        // admin created successfully:
        setShowSuccessModal(true);
      } else {
        // Error createing admin:
        setShowErrorModal(true);
      }
      setIsLoading(false);
    });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    if(SessionHelper.isMasterAdmin()) {
      if (loadInitialData) {
        WebService.GetAdminRolesAndAdminCompanies(history, undefined).then(data => {
          if (data != null) {
            setUserAvailableRoles(data.Roles);
            setAvailableCompanies(data.Companies);
            setLoadInitialData(false);
          }
        });
      }
    }
  }, [loadInitialData]);

  const handleCloseSuccessModal = () =>{
    if(!isLoading){
      setShowSuccessModal(false);
      history.push("./Admins");
    }
  }
  
  const handleCloseErrorModal = () =>{
    if(!isLoading){
      setShowErrorModal(false);
    }
  }
  
  return (
    <div className={classes.root}>
      {(isLoading) && <Spinner animation="border" variant="primary" />}

      {(!isLoading) && 
      <div>
        <Modal
              className={classes.content}
              size="lg"
              show={showSuccessModal}
              onHide={handleCloseSuccessModal}
              centered>
            <Modal.Header closeButton>
              <Modal.Title>{successPopupData.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{successPopupData.body}</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseSuccessModal}>
                Ok
              </Button>
            </Modal.Footer>
        </Modal>

        <Modal
            className={classes.content}
            size="lg"
            show={showErrorModal}
            onHide={handleCloseErrorModal}
            centered>
          <Modal.Header closeButton>
            <Modal.Title>{errorPopupData.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{errorPopupData.body}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleCloseErrorModal}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        
        
        <Grid className={classes.grid} container>
          <Grid className={classes.content} item lg={12} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton onClick={handleBack}>
                  <ArrowBackIcon/>
                </IconButton>
              </div>
              <div className={classes.contentBody}>
                <div className={classes.centerWrapper}>
                  <form className={classes.form} onSubmit={handleCreateAdminUser}>
                    <Typography className={classes.title} variant="h2">
                      Create New Admin User
                    </Typography>
  
                    <TextField
                        className={classes.textField}
                        error={hasError("UserName")}
                        fullWidth
                        helperText={
                          hasError("UserName") ? formState.errors.UserName[0] : null
                        }
                        label="User Name"
                        name="UserName"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.UserName || ""}
                        variant="outlined"
                    />
  
                    <TextField
                        className={classes.textField}
                        error={hasError("Email")}
                        fullWidth
                        helperText={
                          hasError("Email") ? formState.errors.Email[0] : null
                        }
                        label="Email"
                        name="Email"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.Email || ""}
                        variant="outlined"
                    />
  
                    <Typography
                        className={classes.Roles}
                        color="textSecondary"
                        variant="body1"
                    >
                      User Role
                    </Typography>
  
                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {selectedRole.Name}
                      </Dropdown.Toggle>
  
                      <Dropdown.Menu>
                        {
                          availableUserRoles.map(role =>
                              role === selectedRole ? (
                                  <Dropdown.Item
                                      onClick={event => handleRoleDropDownSelected(role)}
                                      active
                                  >
                                    {role.Name}
                                  </Dropdown.Item>
                              ) : (
                                  <Dropdown.Item
                                      onClick={event => handleRoleDropDownSelected(role)}
                                  >
                                    {role.Name}
                                  </Dropdown.Item>
                              )
                          )}
                      </Dropdown.Menu>
                    </Dropdown>
  
  
                    <Typography
                        className={classes.Companies}
                        color="textSecondary"
                        variant="body1"
                    >
                      Company user belongs to
                    </Typography>
  
                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {selectedCompany.Name}
                      </Dropdown.Toggle>
  
                      <Dropdown.Menu>
                        {
                          availableCompanies.map(company =>
                              company === selectedCompany ? (
                                  <Dropdown.Item
                                      onClick={event => handleCompanyDropDownSelected(company)}
                                      active
                                  >
                                    {company.Name}
                                  </Dropdown.Item>
                              ) : (
                                  <Dropdown.Item
                                      onClick={event => handleCompanyDropDownSelected(company)}
                                  >
                                    {company.Name}
                                  </Dropdown.Item>
                              )
                          )}
                      </Dropdown.Menu>
                    </Dropdown>
  
                    <Button
                        className={classes.CreateButton}
                        color="primary"
                        disabled={!formState.isValid}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                    >
                      Create Admin
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        
        </div>
      }
      
    </div>
  );
};

CreateAdminUser.propTypes = {
  history: PropTypes.object
};

export default withMasterAuthorization(CreateAdminUser);
