import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";

//import { SearchInput } from '../KeywordToolbar/node_modules/components';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  authorizePayoutButton: {
    marginRight: theme.spacing(1)
  },
  rejectPayoutButton: {
    marginRight: theme.spacing(1)
  }
}));

const KeywordFooterButtons = props => {
  const { className, HandleSendPayments, HandleRejectPayouts, history, ...rest } = props;
  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          className={classes.authorizePayoutButton}
          color="primary"
          variant="contained"
          onClick={HandleSendPayments}
        >
          Authorize Payments 
        </Button>
        <Button
          className={classes.rejectPayoutButton}
          color="secondary"
          variant="outlined"
          onClick={HandleRejectPayouts}
        >
          Reject Payouts
        </Button>
      </div>
    </div>
  );
};

KeywordFooterButtons.propTypes = {
  className: PropTypes.string
};

export default withMasterAuthorization(KeywordFooterButtons);
