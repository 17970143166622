import React, { useState } from "react";
import clsx from "clsx";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
   Card,
   CardActions,
   CardHeader,
   CardContent,
   Button,
   Divider,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   Tooltip,
   TableSortLabel,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import mockData from "./data";
import withAuthorization from "../../../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
   root: {},
   content: {
      padding: 0,
   },
   inner: {
      minWidth: 800,
   },
   statusContainer: {
      display: "flex",
      alignItems: "center",
   },
   status: {
      marginRight: theme.spacing(1),
   },
   actions: {
      justifyContent: "flex-end",
   },
}));


const AdminAccounts = (props) => {
   const { className, ...rest } = props;

   const classes = useStyles();
   const [orders] = useState(mockData);

   return (
      <Card {...rest} className={clsx(classes.root, className)}>
         <CardHeader
            action={
               <Button color="primary" size="small" variant="outlined">
                  Add Account
               </Button>
            }
            title="Admin Accounts"
         />
         <Divider />
         <CardContent className={classes.content}>
            <PerfectScrollbar>
               <div className={classes.inner}>
                  <Table>
                     <TableHead>
                        <TableRow>
                           <TableCell>AccountId</TableCell>
                           <TableCell>Email</TableCell>
                           <TableCell sortDirection="desc">
                              <Tooltip enterDelay={300} title="Sort">
                                 <TableSortLabel active direction="desc">
                                    Last Accessed
                                 </TableSortLabel>
                              </Tooltip>
                           </TableCell>

                           <TableCell>Status</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {orders.map((order) => (
                           <TableRow hover key={order.id}>
                              <TableCell>{order.ref}</TableCell>
                              <TableCell>{order.customer.name}</TableCell>
                              <TableCell>
                                 {moment(order.createdAt).format("DD/MM/YYYY")}
                              </TableCell>

                              <TableCell>
                                 <div className={classes.statusContainer}>
                                    {order.status}
                                 </div>
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </Table>
               </div>
            </PerfectScrollbar>
         </CardContent>
         <Divider />
         <CardActions className={classes.actions}>
            <Button color="primary" size="small" variant="text">
               View all <ArrowRightIcon />
            </Button>
         </CardActions>
      </Card>
   );
};

AdminAccounts.propTypes = {
   className: PropTypes.string,
};

export default withAuthorization(AdminAccounts);
