import React, { useState, useEffect } from "react";

import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";

import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Tooltip,
  TableSortLabel
} from "@mui/material";

import {
  GetName,
  GetEmail,
  GetInitials,
  GetPhone,
  GetCreatedDate,
  GetUserLocation,
  GetUserId,
  GetRecentlyOnline,
  GetPendingTransactions,
  GetProfileURL,
  GetUserStandingStatus,
  GetUserPoints,
} from "helpers/UserHelper";

import SessionHelper from "helpers/SessionHelper";
import {
  GetSelectedPage, 
  SetSelectedPage, 
  GetRowsPerPage, 
  SetRowsPerPage, 
  GetCurrentSort,
  SetCurrentSort
} from "helpers/ConfigHelper";


import {COLUMNS_TYPE, COLUMNS_SORT, toggleSortFunc, isSortActive} from "helpers/TableHelper"

import { GetMyOfferDetails } from "api/WebServices";
import withAuthorization from "../../../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const UsersTable = (props) => {
  const { className, users, history, pageConfig, user, ...rest } = props;

  const classes = useStyles();

  // const pageConfig = SessionHelper.getUserListConfig();

  const [selectedSort, setSelectedSort] = useState(GetCurrentSort(pageConfig));
  const sortTypeList = [
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.NUM,
    COLUMNS_TYPE.REV_NUM,
    COLUMNS_TYPE.DATE,
    COLUMNS_TYPE.REV_DATE,
    COLUMNS_TYPE.NUM,
    COLUMNS_TYPE.REV_NUM

  ];
  const dataRetrieveFunctionList = [
    GetName, 
    GetName, 
    GetEmail, 
    GetEmail, 
    GetUserPoints, 
    GetUserPoints, 
    GetCreatedDate,
    GetCreatedDate,
    GetUserId,
    GetUserId
  ];

  const toggleSort = (columnType) => {
    toggleSortFunc(columnType, selectedSort, users, sortTypeList, dataRetrieveFunctionList, GetUserId, undefined, setSelectedSort) 
    SetCurrentSort(selectedSort)
  };
  
  const selectedUser = useState();
  
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(GetRowsPerPage(pageConfig));
  const [page, setPage] = useState(GetSelectedPage(pageConfig));
  

  const handleSelectAll = (event) => {
    const { users } = props;

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = users.map((user) => user.id);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };

  const handleIsSortActive = (columnType) => {
    return isSortActive(columnType, selectedSort)
  };

  const handleSectedUser = (event, usera) => {
    SessionHelper.setUserListConfig();
    history.push({ pathname: "./userdetails", state: { user: usera } });
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    console.log("Page "+page);
    SetSelectedPage(page)
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0)
    SetSelectedPage(page)
    SetRowsPerPage(event.target.value)
    setRowsPerPage(event.target.value);
  };

  useEffect(() => {

    if(users !== undefined && users != null){
      //if num of users is less than current page is supposed to display
      if(page > 0 && users.length < rowsPerPage * (page) + 1){
        var pageToChangeTo = 0;
        if(users.length > 0){
          pageToChangeTo = ((users.length-1)/rowsPerPage)
        }
        pageToChangeTo = Math.floor(pageToChangeTo)
        setPage(pageToChangeTo)
        SetSelectedPage(pageToChangeTo)
      }
    }

  }, [users]);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL1_DESC)}>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL1_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL1_ASC) || handleIsSortActive(COLUMNS_SORT.COL1_DESC)}>
                      Name
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell >
                  </TableCell>
                  <TableCell >
                  </TableCell>
                  <TableCell  onClick={()=>toggleSort(COLUMNS_SORT.COL2_DESC)}>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL2_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL2_ASC) || handleIsSortActive(COLUMNS_SORT.COL2_DESC)}>
                      Email
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL3_ASC)}>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL3_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL3_ASC) || handleIsSortActive(COLUMNS_SORT.COL3_DESC)}>
                      Points
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL4_ASC)}>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL4_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL4_ASC) || handleIsSortActive(COLUMNS_SORT.COL4_DESC)}>
                      Registration date
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL5_ASC)}>
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL5_ASC)?"asc":"desc"} 
                      active={handleIsSortActive(COLUMNS_SORT.COL5_ASC) || handleIsSortActive(COLUMNS_SORT.COL5_DESC)}>
                      BetFullyUserId
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                  <TableCell >
                    {/* <Tooltip enterDelay={300} title="Sort"> */}
                      {/* <TableSortLabel direction={isSortActive(COLUMNS_SORT.LOCATION_ASC)?"asc":"desc"}  */}
                      {/* active={isSortActive(COLUMNS_SORT.LOCATION_ASC) || isSortActive(COLUMNS_SORT.LOCATION_DESC)}> */}
                      Standing
                      {/* </TableSortLabel> */}
                    {/* </Tooltip> */}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.slice((page)*rowsPerPage, (rowsPerPage)+(page)*rowsPerPage).map((user) => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={GetUserId(user)}
                    onClick={(event) => handleSectedUser(event, user)}
                    selected={selectedUsers.indexOf(GetUserId(user)) !== -1}
                  >
                    <TableCell style={{ width: '15%' }}>
                      <div className={classes.nameContainer}>
                        <Avatar
                          className={classes.avatar}
                          src={GetProfileURL(user)}
                        >
                          {GetInitials(user)}
                        </Avatar>
                        <Typography variant="body1">{GetName(user)}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{GetRecentlyOnline(user) && <div>{"🟢"}</div>}</TableCell>
                    <TableCell>{GetPendingTransactions(user) && <div>{"⚠️"}</div>}</TableCell>
                    <TableCell style={{ width: '30%' }}>{GetEmail(user)}</TableCell>
                    <TableCell style={{ width: '12%' }}>{GetUserPoints(user)}</TableCell>
                    <TableCell style={{ width: '17%' }}>{GetCreatedDate(user)}</TableCell>
                    <TableCell style={{ width: '12%' }}>{GetUserId(user)}</TableCell>
                    <TableCell style={{ width: '12%' }}>{GetUserStandingStatus(user)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={users.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
  history: PropTypes.object,
  user: PropTypes.object,
};

export default withAuthorization(UsersTable);
