import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Grid, Typography, Avatar } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MoneyIcon from "@mui/icons-material/Money";

import {
  GetCancelledCount,
  GetActiveCount,
  GetExpiredCount,
  GetInactiveCount,
  GetCompletedCount,
  CreateUserOfferEntriesMap,
  CreateUserOfferEntriesMapDate,
  GenerateDataForOfferChart,
  GetEntryClients,
  GetOfferEntryFieldString,
  OfferEntryField,
  GetOEMFieldAll,
  GetOEMField,
  DateUnits,
} from "helpers/UserDetailsHelper";


import {
  GetCompanyId,
  GetCompanyName,
  GetCompanyNameWithId
} from "helpers/CompanyHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
  contactSection: {
    marginBottom: theme.spacing(1),
  },
  
}));

const OfferActivty = (props) => {
  const { className, offerMap, companyId, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="h4"
            >
              Offer Activity
            </Typography>
            <Typography variant="h5" className={classes.contactSection}>
              <b>Total Offers w/{GetCompanyNameWithId(companyId)}: </b>
              {GetOEMField(companyId, offerMap, OfferEntryField.COUNT)}
            </Typography>
            <Typography variant="h5" className={classes.contactSection}>
              <b>Total Offers w/Competitors: </b>
              {GetOEMFieldAll(companyId, offerMap, OfferEntryField.COUNT)}
            </Typography>
            <Typography variant="h5" className={classes.contactSection}>
              <b>Total Points Earned: </b>
              {GetOEMField(companyId, offerMap, OfferEntryField.POINTS)}
            </Typography>
            <Typography variant="h5" className={classes.contactSection}>
              <b>Competitor Total Points Earned: </b>
              {GetOEMFieldAll(companyId, offerMap, OfferEntryField.POINTS)}
            </Typography>
            {/* <Typography variant="h5">
              <b>Cashback Threshold: </b>
              {"4%"}
            </Typography> */}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <MoneyIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

OfferActivty.propTypes = {
  className: PropTypes.string,
  offerMap: PropTypes.any,
  companyId: PropTypes.number
};

export default OfferActivty;
