import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import withAuthorization from "../../../../helpers/Authorization";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {MenuList} from "@mui/material";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
   }
}))

const Navigation = (props) => {
   const classes = useStyles();
   const handleClose = () =>{
      console.log("Closing window")
   }
   
   return (
      <MenuList
         className={classes.root}
         open={true}
         onClose={handleClose}
         
      >
         <MenuItem onClick={handleClose}>General</MenuItem>
         {/*<MenuItem onClick={handleClose}>Subscription</MenuItem>*/}
         {/*<MenuItem onClick={handleClose}>Password</MenuItem>*/}
         <MenuItem>Contact us</MenuItem> 
      </MenuList>
   );
};

Navigation.propTypes = {
   history: PropTypes.object,
};

export default withAuthorization(Navigation);