import {
  GetPlaidDepositTotal,
  GetPlaidWithdrawTotal,
  GetCompanyId,
  GetAmountProcessed,
  GetPointsAwarded,
  GetPlaidDateString } from "helpers/UserTransactionEntryHelper";

import {
  GetOfferItemCreatedDate,
  GetPointsEarned,
  GetClient,
  GetUserOfferItemId,
  GetOfferItemId,
  GetAmountProcessed as GetOfferAmountProcessed,
  GetOfferItemStartDate} from "helpers/UserEntryClientHelper";

import {
  GetCompanyName,
  GetCompanyId as GetCompanyHelperId
} from "helpers/CompanyHelper";

import {
  colors
} from "@mui/material";

import moment from "moment";


/****************************** User Details  *************************************/
export function GetTotalDeposits(userDetails) {
  if (userDetails.TotalDepositAmount) {
    return userDetails.TotalDepositAmount;
  } else {
    return "-";
  }
}

export function GetInactiveCount(userDetails) {
  if (userDetails.InactiveCount) {
    return userDetails.InactiveCount;
  } else {
    return "-";
  }
}

export function GetActiveCount(userDetails) {
  if (userDetails.ActiveCount) {
    return userDetails.ActiveCount;
  } else {
    return "-";
  }
}

export function GetExpiredCount(userDetails) {
  if (userDetails.ExpiredCount) {
    return userDetails.ExpiredCount;
  } else {
    return "-";
  }
}

export function GetCompletedCount(userDetails) {
  if (userDetails.CompletedCount) {
    return userDetails.CompletedCount;
  } else {
    return "-";
  }
}

export function GetCancelledCount(userDetails) {
  if (userDetails.CancelledCount) {
    return userDetails.CancelledCount;
  } else {
    return "-";
  }
}

export function GetClaimRate(userDetails) {
  if (userDetails.ClaimRate) {
    return userDetails.ClaimRate;
  } else {
    return "-";
  }
}

export function GetEntryClientsDeposits(userDetails) {
  if (userDetails.EntryClients) {
    var deposits = [];
    for (const [index, value] of userDetails.EntryClients.entries()) {
      deposits.push(userDetails.EntryClients[index].TotalDepositAmount);
    }

    return deposits;
  } else {
    return [];
  }
}

export function GetEntryClientsLabels(userDetails) {
  if (userDetails.EntryClients) {
    var labels = [];
    for (const [index, value] of userDetails.EntryClients.entries()) {
      labels.push(userDetails.EntryClients[index].ClientName);
    }

    return labels;
  } else {
    return [];
  }
}

export function GetEntryClients(userDetails) {
  //console.log("ENTRY CLIENTS: "+userDetails.EntryClients)
  if (userDetails.EntryClients) {
    return userDetails.EntryClients;
  } else {
    return [];
  }
}

export function GetUserTransactionEntries(userDetails) {
  if (userDetails.UserTransactionEntries) {
    return userDetails.UserTransactionEntries;
  } else {
    return [];
  }
}

export function GetEntryDates(userDetails) {
  if (userDetails.EntryDates) {
    return userDetails.EntryDates;
  } else {
    return [];
  }
}

export function GetDataObjects(userDetails, data) {
  if (data && data.labels) {
    var dataObjects = [];
    //console.log(data);
    for (const [index, value] of data.labels.entries()) {
      //console.log(value + " " + index);
      var dataObject = {
        title: value,
        value: GetPercentage(userDetails, data.datasets[0].data[index]),

        color: data.datasets[0].backgroundColor[index],
      };

      //console.log(dataObject);
      dataObjects.push(dataObject);
    }

    return dataObjects;
  } else {
    return [];
  }
}

export function GetPercentage(userDetails, amount) {
  var totalDeposits = GetTotalDeposits(userDetails);
  if (totalDeposits > 0) {
    return Math.round((amount / totalDeposits) * 100);
  }

  return 0;
}

/****************************** Entry Clients  *************************************/

export function GetClientDepositAmount(clientEntry) {
  if (clientEntry.TotalDepositAmount) {
    return clientEntry.TotalDepositAmount;
  } else {
    return "N/A";
  }
}

export function GetClientName(clientEntry) {
  if (clientEntry.ClientName) {
    return clientEntry.ClientName;
  } else {
    return "N/A";
  }
}

export function GetClientId(clientEntry) {
  if (clientEntry.ClientId) {
    return clientEntry.ClientId;
  } else {
    return "N/A";
  }
}

/****************************** Entry  Dates  *************************************/

export function GetClientNameFrom(clientId) {
  if (clientId != null) {
    switch (clientId) {
      case 0:
        return "N/A";
      case 1:
        return "FanDuel";
      case 2:
        return "DraftKings";
      default:
        return "N/A";
    }
  } else {
    return "N/A";
  }
}

export function GetOfferStatus(clientId) {
  if (clientId != null) {
    switch (clientId) {
      case 0:
        return "Inactive";
      case 1:
        return "Active";
      case 2:
        return "Expired";
      case 3:
        return "Cancelled";
      case 4:
        return "Completed";
      default:
        return "N/A";
    }
  } else {
    return "N/A";
  }
}

/****************************** UserTransactionEntry  *************************************/

export function GetDepositAmountSum(transactionList) {
  var sum = 0;
  if (transactionList) {
    transactionList.forEach(function (item, index) {
      sum += GetPlaidDepositTotal(item)
    });
  }

  return sum;
}

export function GetWithdrawAmountSum(transactionList) {
  var sum = 0;
  if (transactionList) {
    transactionList.forEach(function (item, index) {
      sum += GetPlaidWithdrawTotal(item)
    });
  }
  
  return sum;
}

export function CreateUserTransactionEntriesMap(transactionList){
  
  let transactionEntryMap = new Map();
  // Totals amounts of deposits, withdrawals and processed amount across all dates, grouped by company
  const map1 = transactionList.map(entry => (transactionEntryMap.has(GetCompanyId(entry)))?
  transactionEntryMap.set(GetCompanyId(entry), {deposit: transactionEntryMap.get(GetCompanyId(entry)).deposit + GetPlaidDepositTotal(entry), 
    withdraw: transactionEntryMap.get(GetCompanyId(entry)).withdraw + GetPlaidWithdrawTotal(entry), 
    points: transactionEntryMap.get(GetCompanyId(entry)).points + GetPointsAwarded(entry), 
    processed: transactionEntryMap.get(GetCompanyId(entry)).processed + GetAmountProcessed(entry)})
  :transactionEntryMap.set(GetCompanyId(entry), {deposit: GetPlaidDepositTotal(entry), withdraw: GetPlaidWithdrawTotal(entry), points: GetPointsAwarded(entry), processed: GetAmountProcessed(entry)}));

  
    return transactionEntryMap;
}

export function CreateUserTransactionEntriesMapDate(transactionList, dateRangeLength, dateUnit ){
  var boundaryDate = moment().add(1-dateRangeLength, GetMomentKey(dateUnit))
  //console.log("From:" + boundaryDate.format("MMM DD, YYYY"))
  //console.log("To:" + moment().format("MMM DD, YYYY"))
  
  // Totals amounts of deposits, withdrawals and processed amount across all dates, grouped by company
  const filteredTransactionList = transactionList.filter(x => DateCompareFunction(GetPlaidDateString(x), boundaryDate, dateUnit))
  return CreateUserTransactionEntriesMap(filteredTransactionList)
}

export const TransactionEntryField = {
  DEPOSIT: 0,
  WITHDRAWAL: 1,
  PROCESSED: 2,
  POINTS: 3
}

export function GetTransactionEntryFieldString(field){
  switch(field){
    case TransactionEntryField.DEPOSIT:
      return "Deposits";
    case TransactionEntryField.WITHDRAWAL:
      return "Withdrawals";
    case TransactionEntryField.PROCESSED:
      return "Processed";
    case TransactionEntryField.POINTS:
      return "Points";
  }
  return "n/a"
}

export function GetTransactionEntryFieldPrefixString(field){
  switch(field){
    case TransactionEntryField.DEPOSIT:
      return "";
    case TransactionEntryField.WITHDRAWAL:
      return "";
    case TransactionEntryField.PROCESSED:
      return "";
    case TransactionEntryField.POINTS:
      return "";
  }
  return "";
}

export function GetTransactionEntryFieldSuffixString(field){
  switch(field){
    case TransactionEntryField.DEPOSIT:
      return "";
    case TransactionEntryField.WITHDRAWAL:
      return "";
    case TransactionEntryField.PROCESSED:
      return "";
    case TransactionEntryField.POINTS:
      return "pts";
  }
  return "";
}

export function GetTransactionEntryFieldIsCurrency(field){
  switch(field){
    case TransactionEntryField.DEPOSIT:
      return true;
    case TransactionEntryField.WITHDRAWAL:
      return true;
    case TransactionEntryField.PROCESSED:
      return true;
    case TransactionEntryField.POINTS:
      return false;
  }
  return false;
}

export function GetTEMField(companyId, transactionEntryMap, transactionEntryField){
  var res = 0;
  // console.log(typeof companyId);
  // console.log("GetTEMField")
  // console.log("Keys: "+transactionEntryMap.keys().next().value)
  // console.log("compId: "+companyId)
  // console.log("transactionEntryMap: "+transactionEntryMap.size)
  // console.log("transactionEntryField: "+transactionEntryField)
  if(transactionEntryMap != null){
    const item = transactionEntryMap.get(companyId);
    // console.log("Item: "+item)
    if(item !== undefined){
      // console.log("Item defined")
      if(transactionEntryField == TransactionEntryField.DEPOSIT){
        // console.log("Item dep")
        res = item.deposit;
      }else if(transactionEntryField == TransactionEntryField.WITHDRAWAL){
        // console.log("Item withd")
        res = item.withdraw;
      }else if(transactionEntryField == TransactionEntryField.PROCESSED){
        res = item.processed;
        // console.log("Item proc")
      }else if(transactionEntryField == TransactionEntryField.POINTS){
        res = item.points;
      }
    }
  }
  return res;
}

export const DateUnits = {
  MONTH: 0,
  YEAR: 1,
  DAY: 2
}

export function GetMomentString(dateUnit){
  switch(dateUnit){
    case DateUnits.MONTH:
      return "MMM";
    case DateUnits.DAY:
      return "Do";
    case DateUnits.YEAR:
      return "y";
  }
  return "Do";
}

export function GetMomentKey(dateUnit){
  switch(dateUnit){
    case DateUnits.MONTH:
      return "months";
    case DateUnits.DAY:
      return "days";
    case DateUnits.YEAR:
      return "years";
  }
  return "days";
}

export function GetMomentCompareKey(dateUnit){
  switch(dateUnit){
    case DateUnits.MONTH:
      return "month";
    case DateUnits.DAY:
      return "day";
    case DateUnits.YEAR:
      return "year";
  }
  return "day";
}

export const sectionColors = 
  [
    colors.orange[700],
    colors.blue[700],
    colors.red[700],
    colors.green[700],
    colors.purple[700],
    colors.cyan[700],
    colors.pink[700]
  ]

export const sectionColorsLight = 
  [
    colors.orange[300],
    colors.blue[300],
    colors.red[300],
    colors.green[300],
    colors.purple[300],
    colors.cyan[300],
    colors.pink[300]
  ]

function CreateDataSetForCompany(company, entryField, prefixFunc, suffixFunc, currencyFunc){
  return {
    offerId: GetOfferItemId(null),
    companyId: GetCompanyHelperId(company),
    label: GetCompanyName(company),
    tEFValue: entryField,
    labelPrefix: prefixFunc(entryField),
    labelSuffix:suffixFunc(entryField),
    labelFormatCurrency:currencyFunc(entryField),
    backgroundColor: sectionColors[(GetCompanyHelperId(company)%sectionColors.length)],
    data: [],
    fill: false,
    borderWidth: 2,
    borderColor: sectionColorsLight[(GetCompanyHelperId(company)%sectionColorsLight.length)],
    lineTension: 0,   
    hoverOffset: 4
  }
}

function CreateDataSetForCompanySame(company, transactionEntryField){
  return {
    companyId: GetCompanyHelperId(company),
    tEFValue: transactionEntryField,
    label: GetTransactionEntryFieldString(transactionEntryField),
    labelPrefix: GetTransactionEntryFieldPrefixString(transactionEntryField),
    labelSuffix:GetTransactionEntryFieldSuffixString(transactionEntryField),
    labelFormatCurrency:GetTransactionEntryFieldIsCurrency(transactionEntryField),
    backgroundColor: sectionColors[((GetCompanyHelperId(company)+transactionEntryField)%sectionColors.length)],
    data: [],
    fill: false,
    borderWidth: 2,
    borderColor: sectionColorsLight[((GetCompanyHelperId(company)+transactionEntryField)%sectionColorsLight.length)],
    lineTension: 0,   
    hoverOffset: 4
  }
}

export function DateCompareFunction(currDate, boundaryDate, dateUnit){
  //var boundaryDate = moment().add(1-dateRangeLength, GetMomentKey(dateUnit))
  // console.log("From:" + boundaryDate)
  // console.log("To:" + moment())
  return moment(currDate).isBetween(boundaryDate, moment(), GetMomentKey(dateUnit), [])
}


//fullTransactionList - unfiltered list of transactionEntries
//dateRangeLength - number of dateUnits data should span
//dateUnit - unit of time used by chart (month, week, day)
export function GenerateDataForChart(showAllStats, fullTransactionList, companyList, dateRangeLength, dateUnit, transactionEntryField){
  // console.log(companyList.length)
  // console.log("dateRangeLength: "+dateRangeLength)
  // console.log("dateUnit: "+dateUnit)
  // console.log("dateUnitKEY: "+GetMomentKey(dateUnit))
  // console.log("GetMomentCompareKey: "+GetMomentCompareKey(dateUnit))
  // console.log("GetMomentString: "+GetMomentString(dateUnit))
  // console.log("GetAmntFromMap CHECKING: "+transactionEntryField)
  //(Starting out using today as starting time)
  var dateArray = Array(dateRangeLength); 
  var dataSets = [];
  if(showAllStats && companyList.length > 0){
    dataSets.push(CreateDataSetForCompanySame(companyList[0], TransactionEntryField.DEPOSIT))
    dataSets.push(CreateDataSetForCompanySame(companyList[0], TransactionEntryField.WITHDRAWAL))
    dataSets.push(CreateDataSetForCompanySame(companyList[0], TransactionEntryField.POINTS))
  }else if(companyList != null && Array.isArray(companyList)){
    dataSets = companyList.map(comp => 
      CreateDataSetForCompany(comp, transactionEntryField, GetTransactionEntryFieldPrefixString, GetTransactionEntryFieldSuffixString, GetTransactionEntryFieldIsCurrency)
    );
  }

  const res = Array.apply(0, dateArray).map(
    function(_,i){
      return moment().add(1-dateRangeLength+i, GetMomentKey(dateUnit))
    }
  )

  var compareFunc = (aDate, labelDate) => {
    return moment(aDate).isSame(labelDate, GetMomentCompareKey(dateUnit))
  }

  var index;
  for (index = 0; index < res.length; index++) {
   // console.log("GetAmntFromMap BEFORE FILTER: "+fullTransactionList.length)
    var partialTransactionList = fullTransactionList.filter(
      anEntry => compareFunc(GetPlaidDateString(anEntry), res[index])
    );
    //console.log("GetAmntFromMap LENGTH: "+partialTransactionList.length)
    var entryMap = CreateUserTransactionEntriesMap(partialTransactionList);
    //console.log("GetAmntFromMap MAP: "+entryMap.size)
    
    if(showAllStats && companyList.length > 0){
      dataSets[0].data.push(GetTEMField(dataSets[0].companyId, entryMap, TransactionEntryField.DEPOSIT))
      dataSets[1].data.push(GetTEMField(dataSets[1].companyId, entryMap, TransactionEntryField.WITHDRAWAL))
      dataSets[2].data.push(GetTEMField(dataSets[2].companyId, entryMap, TransactionEntryField.POINTS))
    }else{
      dataSets.map(companySet => 
        companySet.data.push(GetTEMField(companySet.companyId, entryMap, transactionEntryField)))
    }
  }

  const labelList = res.map(x => x.format(GetMomentString(dateUnit)))

 // console.log("Current Month: "+labelList)
 // console.log(res)
 // console.log(dataSets.length)

  var combined = {
    labels: labelList,
    datasets: dataSets
  }

  return combined;
  // var i;
  // for (i = 0; i < dateRangeLength; i++) {
  //   dateArray.push(moment().add(-i, 'months').month(i).format('MMM'))
  // }
  //Create labels

  //Map list separating by company

}


export const OfferEntryField = {
  POINTS: 0,
  COUNT: 1
}

export function GetOfferEntryFieldString(field){
  switch(field){
    case OfferEntryField.POINTS:
      return "Total Points";
    case OfferEntryField.COUNT:
      return "Offer Count";
  }
  return "n/a"
}

export function GetOfferEntryFieldShortString(field){
  switch(field){
    case OfferEntryField.POINTS:
      return "pts";
    case OfferEntryField.COUNT:
      return "ct";
  }
  return "n/a"
}

export function GetOfferEntryFieldPrefixString(field){
  switch(field){
    case OfferEntryField.COUNT:
      return "";
    case OfferEntryField.POINTS:
      return "";
  }
  return "";
}

export function GetOfferEntryFieldSuffixString(field){
  switch(field){
    case OfferEntryField.COUNT:
      return "ct";
    case OfferEntryField.POINTS:
      return "pts";
  }
  return "";
}

export function GetOfferEntryFieldIsCurrency(field){
  switch(field){
    case OfferEntryField.COUNT:
      return false;
    case OfferEntryField.POINTS:
      return false;
  }
  return false;
}


export function CreateUserOfferEntriesMap(offerList, companyId, compareCompany){
  //console.log("MAP CHECKING: "+offerList.length)
 // console.log("MAP CHECKING: "+companyId)
//  console.log("MAP CHECKING: "+compareCompany)
  if(compareCompany){
   // console.log("showASJdifnadsidfn ")
    let offerEntryMap = new Map();
    //offerList.map(x => console.log("CompanyId: "+ GetClient(x)))
    // Totals amounts of deposits, withdrawals and processed amount across all dates, grouped by company
    const map1 = offerList.map(entry => (offerEntryMap.has(GetClient(entry)))?
    offerEntryMap.set(GetClient(entry), {points: offerEntryMap.get(GetClient(entry)).points + GetPointsEarned(entry), 
      count: offerEntryMap.get(GetClient(entry)).count + 1})
    :offerEntryMap.set(GetClient(entry), {points: GetPointsEarned(entry), count: 1}));
////    console.log("Map created comparing companies")
  //  console.log(JSON.stringify(offerEntryMap))
    return offerEntryMap;
  }else{
  //  console.log("showAllStats CHECKING: "+offerList.length)
  //  console.log("showAllStats CHECKING: "+companyId)
    var filteredList = offerList.filter(entry => GetClient(entry) == companyId);
    return CreateUserOfferEntriesMapSameCompany(filteredList)
  }
}

function CreateUserOfferEntriesMapSameCompany(offerList){
  let offerEntryMap = new Map();
  // Totals amounts of deposits, withdrawals and processed amount across all dates, grouped by company
  const map1 = offerList.map(entry => (offerEntryMap.has(GetOfferItemId(entry)))?
  offerEntryMap.set(GetOfferItemId(entry), {points: offerEntryMap.get(GetOfferItemId(entry)).points + GetPointsEarned(entry), 
    count: offerEntryMap.get(GetOfferItemId(entry)).count + 1})
  :offerEntryMap.set(GetOfferItemId(entry), {points: GetPointsEarned(entry), count: 1}));
 // console.log("Map created not comparing companies")

  //  console.log(JSON.stringify(offerEntryMap))
 //   console.log(JSON.stringify(offerList))
  //  console.log(JSON.stringify(map1))
    return offerEntryMap;
}

export function CreateUserOfferEntriesMapDate(offerList, dateRangeLength, dateUnit, companyId, compareCompany){
  var boundaryDate = moment().add(1-dateRangeLength, GetMomentKey(dateUnit))
  // console.log("From:" + boundaryDate.format("MMM DD, YYYY"))
  // console.log("To:" + moment().format("MMM DD, YYYY"))
  
  // Totals amounts of deposits, withdrawals and processed amount across all dates, grouped by company
  //console.log("Called from CreateUserOfferEntriesMapDate")
  const filteredOfferList = offerList.filter(x => DateCompareFunction(GetOfferItemCreatedDate(x), boundaryDate, dateUnit))
  return CreateUserOfferEntriesMap(filteredOfferList, companyId, compareCompany)
}


function GetAmntFromOfferMap(offerEntryMap, companyId, offerEntryField){
  var amnt = 0;
  //console.log("GetAmntFromOfferMap: "+offerEntryMap)
 // const iterator1 = offerEntryMap.keys();
// console.log("Keys: "+iterator1.next().value);
// console.log("Keys: "+iterator1.next().value);
//   console.log("GetAmntFromOfferMap: "+companyId)
//   console.log("GetAmntFromOfferMap: "+offerEntryField)
  const item = offerEntryMap.get(companyId);
  //console.log("GetAmntFromOfferMap: "+item)
  if(item !== undefined){
    if(offerEntryField == OfferEntryField.POINTS){
      amnt = item.points;
    }else if(offerEntryField == OfferEntryField.COUNT){
      amnt = item.count;
    }
  }else{
  }
  return amnt;
}

function CreateOfferDataSetForOfferSameCompany(offerEntry, offerEntryField){
  return {
    offerId: GetOfferItemId(offerEntry),
    companyId: GetClient(offerEntry),
    label: "Offer "+GetOfferItemId(offerEntry),
    tEFValue: offerEntryField,
    labelPrefix: GetOfferEntryFieldPrefixString(offerEntryField),
    labelSuffix:GetOfferEntryFieldSuffixString(offerEntryField),
    labelFormatCurrency:GetOfferEntryFieldIsCurrency(offerEntryField),
    backgroundColor: sectionColors[((GetOfferItemId(offerEntry))%sectionColors.length)],
    data: [],
    fill: false,
    borderWidth: 2,
    borderColor: sectionColorsLight[((GetOfferItemId(offerEntry))%sectionColorsLight.length)],
    lineTension: 0,   
    hoverOffset: 4
  }
}

export function GenerateDataForOfferChart(showAllStats, fullOfferList, companyList, dateRangeLength, dateUnit, offerEntryField){
  //console.log(companyList.length)
  // console.log("SameCompany: "+showAllStats);
  // console.log("dateRangeLength: "+dateRangeLength)
  // console.log("dateUnit: "+dateUnit)
  // console.log("dateUnitKEY: "+GetMomentKey(dateUnit))
  // console.log("GetMomentCompareKey: "+GetMomentCompareKey(dateUnit))
  // console.log("GetMomentString: "+GetMomentString(dateUnit))
  // console.log("GetAmntFromMap CHECKING: "+offerEntryField)

  //(Starting out using today as starting time)
  var dateArray = Array(dateRangeLength); 
  var dataSets = [];
  
  var compareFunc = (aDate, labelDate) => {
    // console.log("Compare First: "+aDate);
    // console.log("Compare Second: "+labelDate)
    return moment(aDate,["MM-DD-YYYY", "YYYY-MM-DD"]).isSame(labelDate, GetMomentCompareKey(dateUnit))
  }
  
  const res = Array.apply(0, dateArray).map(
    function(_,i){
      return moment().add(1-dateRangeLength+i, GetMomentKey(dateUnit))
    }
  )

  
  if(showAllStats && companyList.length > 0){

     var compOfferList = fullOfferList.filter( anEntry => GetClient(anEntry) == GetCompanyHelperId(companyList[0]) && DateCompareFunction(GetOfferItemStartDate(anEntry), res[0], dateUnit))
     dataSets = compOfferList.map(anEntry => CreateOfferDataSetForOfferSameCompany(anEntry, offerEntryField));

    //dataSets.push(CreateOfferDataSetForCompanySame(companyList[0], OfferEntryField.POINTS))
    //dataSets.push(CreateOfferDataSetForCompanySame(companyList[0], OfferEntryField.COUNT))
  }else{
    dataSets = companyList.map(comp => 
      CreateDataSetForCompany(comp, offerEntryField, GetOfferEntryFieldPrefixString, GetOfferEntryFieldSuffixString, GetOfferEntryFieldIsCurrency)
    );
  }
  

  

  var index;
  for (index = 0; index < res.length; index++) {
  //  console.log("GetAmntFromMap BEFORE FILTER: "+fullOfferList.length)
    var partialOfferList = fullOfferList.filter(
      anEntry => compareFunc(GetOfferItemCreatedDate(anEntry), res[index])
    );
   // console.log("Called from CreateUserOfferEntriesMapDate")
    var entryMap = CreateUserOfferEntriesMap(partialOfferList, GetCompanyHelperId(companyList[0]), !showAllStats);
   
    // console.log("GetAmntFromOfferMap: "+entryMap)
    // console.log("GetAmntFromOfferMap: "+partialOfferList.length)
    //const iterator1 = entryMap.keys();
    // console.log("Keys: "+iterator1.next().value);
    // console.log("Keys: "+iterator1.next().value);
    
    if(showAllStats && companyList.length > 0){
      dataSets.map(offerEntry => offerEntry.data.push(GetAmntFromOfferMap(entryMap, offerEntry.offerId, OfferEntryField.POINTS)))
      //dataSets.map(offerEntry => offerEntry.data.push(GetAmntFromOfferMap(entryMap, offerEntry.offerId, OfferEntryField.COUNT)))
      // dataSets[0].data.push(GetAmntFromOfferMap(entryMap, dataSets[0].offerId, OfferEntryField.POINTS))
      // dataSets[1].data.push(GetAmntFromOfferMap(entryMap, dataSets[1].offerId, OfferEntryField.COUNT))
    }else{
      dataSets.map(companySet => 
        companySet.data.push(GetAmntFromOfferMap(entryMap, companySet.companyId, offerEntryField)))
    }
  }

  const labelList = res.map(x => x.format(GetMomentString(dateUnit)))

  // console.log("Current Month: "+labelList)
  // console.log(res)
  // console.log(dataSets.length)

  var combined = {
    labels: labelList,
    datasets: dataSets
  }

  return combined;
}

export function GetOEMFieldAll(companyId, offerEntryMap, offerEntryField){
  var res = 0;
 // console.log("All: "+offerEntryField)
 // console.log("All company: "+companyId)
 // console.log("All map: "+JSON.stringify(offerEntryMap))
  if(offerEntryMap != null){
    for (const [key, value] of offerEntryMap.entries()) {
      if(companyId != key){
        if(offerEntryField == OfferEntryField.POINTS){
          res += value.points;
        }else if(offerEntryField == OfferEntryField.COUNT){
          res += value.count;
        }
      }
    }
  }
  return res;
}

export function GetOEMField(companyId, offerEntryMap, offerEntryField){
  var res = 0;
  offerEntryField += 0;
  //console.log("Checking field oem:"+offerEntryMap)
  //console.log("Checking field cI:"+companyId)
  if(offerEntryMap != null){
    if(companyId > 0){
      const item = offerEntryMap.get(companyId);
      //console.log("Item: "+item)
      if(item !== undefined){
       
        if(offerEntryField == OfferEntryField.POINTS){
          res = item.points;
        }else if(offerEntryField == OfferEntryField.COUNT){
          res = item.count;
        }
      }
    }else if (companyId == -1){

    }
  }
  return res;
}

export function GetListOfOffers(userdetails) {
  if (userdetails == null || userdetails.ListOfOffers == null) {
    return [];
  } else {
    return userdetails.ListOfOffers;
  }
}
