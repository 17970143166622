import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { IconButton, Grid, Button, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import Spinner from "react-bootstrap/Spinner";

import { ProductsToolbar, ProductCard, OffersTable } from "./components";
import WebService from "../../api/WebServices.js";
import PropTypes from "prop-types";
import axios from "axios";
import mockData from "./data";
import { Tooltip } from "@mui/material";
import InfoRounded from "@mui/icons-material/InfoRounded";
import withAuthorization from "../../helpers/Authorization";

import {
  GetStatusInt,
} from "helpers/OfferHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const ProductList = (props) => {
  const { history, ...rest } = props;

  const classes = useStyles();

  const [Products, setProducts] = useState([]);
  const [userCriteriaList, setUserCriteria] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleSelectOffer = (product) => {
    history.push({
      pathname: "./offerdetails",
      state: { product: product, userCriteriaList: userCriteriaList },
    });
  };

  const filterDeletedOffers = (offer) =>{
      return GetStatusInt(offer) != 0;
  };

  const handleDupeOffer = (offer) => {
    history.push({
      pathname: "./createOffer",
      state: { dupeOffer: offer, userCriteriaList: userCriteriaList },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await WebService.GetMyOffers(history);
      if (result != null && result.WebOffers != null) {
        
        setProducts(result.WebOffers.filter(filterDeletedOffers));
        setUserCriteria(result.AllOfferCriteria);
      } else {
        history.push({ pathname: "./sign-in" });
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <h4>List of Offers</h4>
        <Tooltip title="Enter offer id to view offer's details">
          <IconButton aria-label="?">
            <InfoRounded />
          </IconButton>
        </Tooltip>
      </div>

      <ProductsToolbar userCriteriaList={userCriteriaList} />
      <div className={classes.content}>
        {(isLoading) && <Spinner animation="border" variant="primary" />}
        {(!isLoading) && <OffersTable dupeOffer={handleDupeOffer} offers={Products} userCriteriaList={userCriteriaList} />}
      </div>
    </div>
  );
};

ProductList.propTypes = {
  history: PropTypes.object,
};

export default withAuthorization(ProductList);
