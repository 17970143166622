import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import WebService from "../../api/WebServices.js";
import { UsersToolbar, UsersTable } from "./components";
import mockData from "./data";

import Spinner from "react-bootstrap/Spinner";

import SessionHelper from "helpers/SessionHelper";
import {
  GetSearchTerm, 
  SetSearchTerm, 
  GetUserStandingFilter, 
  SetUserStandingFilter, 
  GetOnlineFilter, 
  SetOnlineFilter, 
  GetPendingTransactionFilter,
  SetPendingTransactionFilter
} from "helpers/ConfigHelper";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import InfoRounded from "@mui/icons-material/InfoRounded";
import { IconButton } from "@mui/material";
import withAuthorization from "../../helpers/Authorization";



import {
  GetUserStandingStatusInt,
  GetRecentlyOnline,
  GetPendingTransactions,
  GetEmail,
  GetName
} from "helpers/UserHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const UserList = (props) => {
  const classes = useStyles();

  const pageConfig = SessionHelper.getUserListConfig();

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [inCache, setInCache] = useState(0);
  const [pendingApproval, setPendingApproval] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { history, ...rest } = props;

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const result = await WebService.GetUsers(history);
      if (result != null) {
        console.log(result);
        result.sort(
           (firstUser, secondUser) =>
              (firstUser.RegistrationDate !== null
                 ? firstUser.RegistrationDate
                 : 0)
             -
            
                 (secondUser.RegistrationDate !== null
                    ? secondUser.RegistrationDate
                    : 0)
        );
        setUsers(result);
        setInCache(getArraySum(result, GetRecentlyOnline))
        setPendingApproval(getArraySum(result, GetPendingTransactions))
      } else {
        history.push({ pathname: "./sign-in" });
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const [selectedUserStandingFilter, setSelectedUserStandingFilter] = useState(GetUserStandingFilter(pageConfig));
  const [onlineUserFilter, setOnlineUserFilter] = useState(GetOnlineFilter(pageConfig));
  const [pendingTransactionFilter, setPendingTransactionFilter] = useState(GetPendingTransactionFilter(pageConfig));
  const [searchTerm, setSearchTerm] = useState(GetSearchTerm(pageConfig));

  useEffect(() => {
    applyAllFilters();
  }, [selectedUserStandingFilter, onlineUserFilter, 
    pendingTransactionFilter, users, searchTerm]);

  const handleFilterSelected = (value) => {
    SetUserStandingFilter(value)
    setSelectedUserStandingFilter(value)
  };

  const handleOnlineFilterSelected = (value) => {
    SetOnlineFilter(value)
    setOnlineUserFilter(value)
  };

  const handlePendingFilterSelected = (value) => {
    SetPendingTransactionFilter(value)
    setPendingTransactionFilter(value)
  };

  const handleSearchTermSet = (value) => {
    SetSearchTerm(value)
    setSearchTerm(value)
  }

  const applyAllFilters = () => {
    var allUsersVar = users;
    console.log("All users Count "+allUsersVar.length)
    console.log(selectedUserStandingFilter)
    if(selectedUserStandingFilter != 0){
      console.log("user standing filter")
      allUsersVar = allUsersVar.filter(aUser => (GetUserStandingStatusInt(aUser)) == selectedUserStandingFilter)
    }else{
      console.log("No USER filter")
    }

    if(onlineUserFilter == 1){
      allUsersVar = allUsersVar.filter(aUser => (GetRecentlyOnline(aUser)) == true)
    }

    if(pendingTransactionFilter == 1){
      allUsersVar = allUsersVar.filter(aUser => (GetPendingTransactions(aUser)) == true)
    }

    if(searchTerm != undefined && searchTerm.length > 0 && typeof searchTerm === 'string'){
      allUsersVar = allUsersVar.filter(aUser => (GetEmail(aUser).toLowerCase().search(searchTerm.toLowerCase()) != -1
      || GetName(aUser).toLowerCase().search(searchTerm.toLowerCase()) != -1))
    }

    setFilteredUsers(allUsersVar)
  }
  function getArraySum(theArray, grabFunction){
    var total=0;
    for (let index = 0; index < theArray.length; ++index) {
      const element = theArray[index];
      if(grabFunction(element) == true)
          total++;
    }
    return total;
  }

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <h4>User List </h4>
        
        <Tooltip title="Enter UserId to search for it, Click on user to see user details.">
          <IconButton aria-label="?">
            <InfoRounded />
          </IconButton>
        </Tooltip>
      </div>
      <div>
      {/* <h2>In Cache: {inCache}</h2> */}
      </div>
      <p/>
      <h5>Users with Pending Transactions: {pendingApproval}</h5>
<div></div>
      <UsersToolbar 
      onFilterSelected={handleFilterSelected} 
      filterValue={selectedUserStandingFilter}
      onOnlineFilterSelected={handleOnlineFilterSelected}
      onlineFilterValue={onlineUserFilter}
      onSeachTermSet={handleSearchTermSet}
      searchTermValue={searchTerm}
      onPendingFilterSelected={handlePendingFilterSelected}
      pendingFilterValue={pendingTransactionFilter}/>
      <div className={classes.content}>
        {(isLoading) && <Spinner animation="border" variant="primary" />}
        {(!isLoading) && 
        <UsersTable pageConfig={pageConfig} users={filteredUsers} />
        }
      </div>
    </div>
  );
};

UserList.propTypes = {
  history: PropTypes.object,
};

export default withAuthorization(UserList);
