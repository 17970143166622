import React, { useState, useEffect } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WebService from "../../api/WebServices.js";
import ImagePreviewGrid from '../StoredImageList/Components/ImagePreviewGrid'
import SessionHelper from '../../helpers/SessionHelper'
import { MaxDepositValues, CashBackValues, MaxDaysValues, InEqualityValues, MaxTransactionValues, MinDepositValues, 
  OfferTransactionTypeValues, UserActionTypeValues, RewardTypeValues, GetActionTypeFromOfferType, GetRewardTypeFromOfferType } from "../../constants/constants.js";
import moment from "moment";
import { NumericFormat } from 'react-number-format';
import {
  GetStoredImageId,
  GetBackgroundImageId,
  GetSmallLogoId,
  GetCompanyIcon,
  GetCompanyId,
  GetOfferId,
  GetStatusInt,
  OfferConfigureSection,
  GetReplacedDate,
  GetPreviousVersions,
  GetReplacedId,
  GetOfferRequirementItem,
  GetOfferRequirementItemSet,
  GetSmallIcon,
  GetBackgroundImage,
  OfferSection
} from '../../helpers/OfferHelper'
import { Form } from "react-bootstrap";
import { 
  GetCompanyName,
  GetCompanyId as CHGetCompany
} from 'helpers/CompanyHelper'
import {
  GetProvinceId
} from '../../helpers/OfferLocationHelper'
import {
  ButtonGroup,
  Button,
  Card,
  CardBody,
  CardText,
  CardGroup,
  CardTitle,
} from "reactstrap";

import { ProductCardPreview } from "../ProductList/components";


import {
  Grid,
  IconButton,
  TextField,
  Link,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import withAuthorization from "../../helpers/Authorization";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%"
  },
  grid: {
    height: "100%"
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/auth.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px"
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "top",
    justifyContent: "center"
  },
  gridItem:{
    paddingLeft:theme.spacing(4),
    paddingRight:theme.spacing(4)
  },
  spinner:{
    justifyContent:"center", display:"flex"
  },
  // form: {
  //    paddingLeft: 100,
  //   // paddingRight: 100,
  //    paddingBottom: 125,
  //   // flexBasis: 700,
  //   // [theme.breakpoints.down("sm")]: {
  //   //   paddingLeft: theme.spacing(2),
  //   //   paddingRight: theme.spacing(2)
  //   // }
  // },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const EditOffer = props => {
  const { history } = props;
  const offer = props.location.state.offer;
  const companySelected = props.location.state.companySelected;

  const classes = useStyles();
  const isActiveOffer = GetStatusInt(offer) == 1;
  const [startDate, setStartDate] = useState(
    (offer.StartDate !== undefined)?
    moment(offer.StartDate).toDate():
    //moment(offer.StartDate).format("ddd"):
    "n/a");
  const [endDate, setEndDate] = useState(
    (offer.ExpirationDate !== undefined)?
    moment(offer.ExpirationDate).toDate():
    //moment(offer.ExpirationDate).format("ddd"):
    "n/a");
  const [cashBackValue, setCashBackValue] = useState(offer.CashbackPercentage*100);
  const [daysAvailable, setDaysAvailable] = useState(offer.AmountOfDaysValid);
  const [sendRequest, setSendRequest] = useState(false);
  const criteriaList = props.location.state.criteriaList;
  const provinceList = SessionHelper.getProvinceList();
  //const requirementList = offer.

  const [offerActionType, setOfferActionType] = useState((offer != null)?GetActionTypeFromOfferType(offer.OfferType):0);
  const [offerRewardType, setOfferRewardType] = useState((offer != null)?GetRewardTypeFromOfferType(offer.OfferType):0);
  const [minDepositValue, setMinDepositValue] = useState((offer != null)?offer.MinDeposit:0);

  const [showCustomActiveDays, setShowCustomActiveDays] = useState((offer != null && offer.AmountOfDaysValid > 0)?!MaxDaysValues.includes(offer.AmountOfDaysValid):false);
  const [showCustomMinDeposit, setShowCustomMinDeposit] = useState((offer != null && offer.MinDeposit > 0)?!MinDepositValues.includes(offer.MinDeposit):false);
  const [showCustomMaxDeposit, setShowCustomMaxDeposit] = useState((offer != null && offer.MaxDeposit > 0)?!MaxDepositValues.includes(offer.MaxDeposit):false);
  const [showCustomCashback, setShowCustomCashback] = useState((offer != null && offer.CashbackPercentage > 0)?!CashBackValues.includes(offer.CashbackPercentage*100):false);
  
  const [showCustomMaxTransactions, setShowCustomMaxTransactions] = useState((offer != null)?!MinDepositValues.includes(offer.MaxAmountOfTransactionsAllowed):false);

  const [criteriaValues, setCriteriaValues] = useState([
    ...criteriaList
  ])
  
  const GetExistingOfferLocationIdList = () => {
    var offerLocationList = [];
    if(offer.OfferLocations !== undefined && offer.OfferLocations != null){
      offer.OfferLocations.map(x => offerLocationList.push(GetProvinceId(x)));
    }
    return offerLocationList;
  }
  const [LocationsList, setLocationsList] = useState(GetExistingOfferLocationIdList());

  const [PreviousOfferList, setPreviousOfferList] = useState(GetPreviousVersions(offer))
  const [SelectedPreviousOffer, setSelectedPreviousOffer] = useState(offer)

  const [ActiveFilterList, setActiveFilterList] = useState([]);
  const [maxTransactionValue, setMaxTransactionValue] = useState(
    offer.MaxAmountOfTransactionsAllowed !== undefined?offer.MaxAmountOfTransactionsAllowed:0);
    const [maxDepositValue, setMaxDepositValue] = useState((offerRewardType == 0)?((offer != null)?offer.Reward:0):((offer != null)?offer.MaxDeposit:0));
  const [formState, setFormState] = useState({
    isValid: true,
    values: { 
      title: offer.Title, 
      title2: ((offerRewardType == 2)?offer.ShortDescription:offer.Title2), 
      description: offer.Description, 
      offerURL: offer.OfferURL, 
      termsURL: offer.TermsURL, 
      termsSummary: offer.TermsSummary,
      extraURL: offer.ExtraURL, 
      maxDeposit: (offerRewardType == 0)?((offer != null)?offer.Reward:0):((offer != null)?offer.MaxDeposit:0),
      minDeposit: (offer != null)?offer.MinDeposit:0,
      maxTransactionsAllowed: (offer !== undefined && offer.MaxAmountOfTransactionsAllowed !== undefined)?offer.MaxAmountOfTransactionsAllowed:0,
      cashbackPercent: (offer != null && offer.CashbackPercentage > 0)?offer.CashbackPercentage*100:CashBackValues[0],
      amountOfDaysValid: (offer != null && offer.AmountOfDaysValid > 0)?offer.AmountOfDaysValid:3,
      startDate: moment(offer.StartDate).toDate(),
      endDate: moment(offer.ExpirationDate).toDate(),
      offerTransactionType: (offer != null)?offer.OfferTransactionType:0,
      offerActionType: (offer != null)?GetActionTypeFromOfferType(offer.OfferType):0,
      offerRewardType: (offer != null)?GetRewardTypeFromOfferType(offer.OfferType):0,
      offerType:(offer != null)?offer.OfferType:0,
      storedImageId: (offer != null)?offer.StoredImageId:0,
      imgUrlInput: (offer != null && offer.ClientIcon != null)?offer.ClientIcon:SessionHelper.getIcon(),
      backgroundImageId: GetBackgroundImageId(offer), 
      smallLogoId: GetSmallLogoId(offer),
      backgroundImageUrl: GetBackgroundImage(offer), 
      smallLogoUrl: GetSmallIcon(offer),
      instructionSet1: GetOfferRequirementItemSet(offer, 0),
      instructionSet2: GetOfferRequirementItemSet(offer, 1),
      instructionSet3: GetOfferRequirementItemSet(offer, 2),
      instruction1: GetOfferRequirementItem(offer, 0),
      instruction2: GetOfferRequirementItem(offer, 1),
      instruction3: GetOfferRequirementItem(offer, 2),
      daysAvailableCustom: (offer != null && offer.AmountOfDaysValid > 0)?!MaxDaysValues.includes(offer.AmountOfDaysValid):false,
      minDepositCustom: (offer != null && offer.MinDeposit > 0)?!MinDepositValues.includes(offer.MinDeposit):false,
      maxDepositCustom: (offer != null && offer.MaxDeposit > 0)?!MaxDepositValues.includes(offer.MaxDeposit):false,
      cashBackCustom: (offer != null && offer.CashbackPercentage > 0)?!CashBackValues.includes(offer.CashbackPercentage*100):false,
      maxTransactionCustom: (offer != null)?!MinDepositValues.includes(offer.MaxAmountOfTransactionsAllowed):false,
    },
    touched: {},
    errors: {}
  });

  const [RequestObject, setRequestObject] = useState({
    Title: "",
    Title2: "",
    Description: "",
    OfferURL: "",
    TermsURL: "",
    TermsSummary: "",
    ExtraURL: "",
    MaxDeposit: 0,
    MinDeposit: 0,
    MaxTransactionsAllowed: 0,
    StartDate: moment(startDate).format("MM/DD/YYYY"),
    EndDate: moment(endDate).format("MM/DD/YYYY"),
    Reward: 0,
    CashbackPercent: 0,
    AmountOfDaysValid: 0,
    OffertransactionType: 0,
    OfferRewardType: 0,
    OfferActionType: 0,
    InstructionSet1: {
      Id: 0,
      Requirement: "",
      OfferItemId: 0,
      Order: 0
    },
    InstructionSet2: {
      Id: 0,
      Requirement: "",
      OfferItemId: 0,
      Order: 0
    },
    InstructionSet3: {
      Id: 0,
      Requirement: "",
      OfferItemId: 0,
      Order: 0
    },
    Instruction1: "",
    Instruction2: "",
    Instruction3: "",
    BackgroundImageId: 0,
    BackgroundImageUrl: "",
    SmallLogoUrl: "",
    SmallLogoId: 0,
    Add: ActiveFilterList
  });

  
  function FillInDefaults(criteriaItem){
    FillInValues(criteriaItem, 0, 0, 0, 0, 0, 0)
  }

  function FillInValues(criteriaItem, firstVal, firstEqu, secondVal, secondEqu, thirdVal, thirdEqu){
    criteriaItem.FirstValue = firstVal;
    criteriaItem.FirstEquality = firstEqu;
    criteriaItem.SecondValue = secondVal;
    criteriaItem.SecondEquality = secondEqu;
    criteriaItem.ThirdValue = thirdVal;
    criteriaItem.ThirdEquality = thirdEqu;
    criteriaItem.StoredProcedureName = criteriaItem.CriteriaDisplayName.slice();
    criteriaItem.StoredProcedureName = (criteriaItem.StoredProcedureName+"").split(' ').map((aValue) =>
                      
      (aValue == '{0}')?
        (criteriaItem.FirstValue + " ")
      :((aValue == '{1}')?
        (
  (criteriaItem.FirstEquality !== undefined && criteriaItem.FirstEquality > 0)?(InEqualityValues[(criteriaItem.FirstEquality-1)].Sym + " "):(InEqualityValues[0].Sym + " ")):
        ((aValue == '{C}')?
        SessionHelper.getCompany()+" "
        :
          aValue+" "
      )
      )
    )
    
   
  }

  const criteriaValuesUpdated = (criteriaId, firstValue, firstInequality, secondValue, secondInequality) => {
    const criteriaLength = criteriaValues.length;
    const isLargeNumber = (element) => element > 13;
    
    const valueIndexFun = (element) => element.Id == criteriaId;
    const valueIndex = criteriaValues.findIndex(valueIndexFun);
    console.log("C values: " + criteriaValues);
    console.log("C Index: " + valueIndex);
    console.log("C Length: " + criteriaLength);
    console.log("C Id: " + criteriaId);
    console.log("C firstValue: " + firstValue);
    console.log("C firstInequality: " + firstInequality);
    console.log("C secondValue: " + secondValue);
    console.log("C secondInequality: " + secondInequality);
    console.log("C whole: " + criteriaValues[valueIndex]);
    if(valueIndex !== undefined && valueIndex >= 0){
      console.log(criteriaValues[valueIndex]);
      var tempArray = [];
      if(valueIndex > 0){
        tempArray.push(...criteriaValues.slice(0,valueIndex))
      }
      tempArray.push({
        ...criteriaValues[valueIndex],
        FirstValue: (firstValue !== undefined)?firstValue:criteriaValues[valueIndex].FirstValue,
        FirstEquality: (firstInequality !== undefined)?firstInequality:criteriaValues[valueIndex].FirstEquality,
        SecondValue:  (secondValue !== undefined)?secondValue:criteriaValues[valueIndex].SecondValue,
        SecondEquality: (secondInequality !== undefined)?secondInequality:criteriaValues[valueIndex].SecondEquality,
        ThirdValue: 0,
        ThirdEquality: 0,
        StoredProcedureParameterCount: 2,
        StoredProcedureInequalityParameterCount: 2
      })
      if(valueIndex < criteriaLength-1){
        tempArray.push(...criteriaValues.slice(valueIndex+1, undefined))
      }
      var currCriteria = tempArray[valueIndex];
      FillInValues(currCriteria, currCriteria.FirstValue, currCriteria.FirstEquality,
        currCriteria.SecondValue, currCriteria.SecondEquality,
        currCriteria.ThirdValue, currCriteria.ThirdEquality);

      setCriteriaValues(tempArray);
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleManualChange = (varName, value) => {

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [varName]:
           value
      },
      touched: {
        ...formState.touched,
        [varName]: true
      }
    }));
  };

  const handleCreateOffer = event => {
    event.preventDefault();

    // setRequestObject(RequestObject => ({
    //   Title: formState.values.title,
    //   Title2: formState.values.title2,
    //   Description: formState.values.description,
    //   OfferURL: formState.values.offerURL,
    //   TermsSummary: formState.values.termsSummary,
    //   TermsURL: formState.values.termsURL,
    //   ExtraURL: formState.values.extraURL,
    //   CashbackPercent: cashBackValue,
    //   AmountOfDaysValid: daysAvailable,
    //   Add: ActiveFilterList
    // }));

    setRequestObject({
      Title: formState.values.title,
      Title2: formState.values.title2,
      Description: formState.values.description,
      OfferURL: formState.values.offerURL,
      TermsURL: formState.values.termsURL,
      TermsSummary: formState.values.termsSummary,
      ExtraURL: formState.values.extraURL,
      MaxDeposit: formState.values.maxDeposit,
      MinDeposit: formState.values.minDeposit,
      MaxTransactionsAllowed: formState.values.maxTransactionsAllowed,
      CashbackPercent: formState.values.cashbackPercent,
      AmountOfDaysValid: formState.values.amountOfDaysValid,
      StartDate: formState.values.startDate,
      EndDate: formState.values.endDate,
      OfferTransactionType: formState.values.offerTransactionType,
      OfferActionType: formState.values.offerActionType,
      OfferRewardType: formState.values.offerRewardType,
      Instruction1: formState.values.instruction1,
      Instruction2: formState.values.instruction2,
      Instruction3: formState.values.instruction3,
      InstructionSet1: {
        ...formState.values.instructionSet1,
        Requirement: formState.values.instruction1,
        Id: 0
      },
      InstructionSet2: {
        ...formState.values.instructionSet2,
        Requirement: formState.values.instruction2,
        Id: 0
      },
      InstructionSet3: {
        ...formState.values.instructionSet3,
        Requirement: formState.values.instruction3,
        Id: 0
      },
      StoredImageId: formState.values.storedImageId,
      BackgroundImageId: formState.values.backgroundImageId,
      SmallLogoId: formState.values.smallLogoId,
      Add: ActiveFilterList
    });

    setSendRequest(true);
  };

  const handleAddFilter = value => {
    setActiveFilterList(ActiveFilterList => [...ActiveFilterList, value]);
  };

  const handleRemoveFilter = value => {
    setActiveFilterList(ActiveFilterList.filter(item => item != value));
  };

  const handlePreviousSelected = (value) => {
    var selectedPrev = offer;
    if(value > 0){
      selectedPrev = PreviousOfferList.find(function (crita) {
        return crita.Id == value;
      });
    }
    setSelectedPreviousOffer(selectedPrev)
  };

  const handleCashBackSelect = (hideCustom, value) => {
    if(hideCustom)
      setShowCustomCashback(false)
    setCashBackValue(value);
  };

  const handleMaxTransactionSelect = (hideCustom, value) => {
    if(hideCustom)
      setShowCustomMaxTransactions(false)
    setMaxTransactionValue(value);
  };

  const handleMaxDepositSelect = (hideCustom, value) => {
    if(hideCustom)
      setShowCustomMaxDeposit(false)
    setMaxDepositValue(value);
  };

  function handleDaysSelect(hideCustom, value) {
    if(hideCustom)
      setShowCustomActiveDays(false)
    setDaysAvailable(value);
  }

  function ActiveFilter(value) {
    return ActiveFilterList.some(item => value == item);
  }

  const handleSetStartDate = (dateToSet) => {
    setStartDate(dateToSet);
  };

  const handleSetDate = (dateToSet) => {
    setEndDate(dateToSet);
  };

  function handleActionTypeSelect(value) {
    setOfferActionType(value);
  }

  function handleRewardTypeSelect(value) {
    setOfferRewardType(value);
  }

  const handleShowCustomMinDeposit = () => {
    setShowCustomMinDeposit(!showCustomMinDeposit)
  }

  const handleMinDepositSelect = (hideCustom, value) => {
    if(hideCustom)
      setShowCustomMinDeposit(false);
    setMinDepositValue(value);
  }

  const MAX_VAL = 100;
  const MIN_DAY_VAL = 1;
  const withValueLimit = ({ floatValue }) => floatValue <= MAX_VAL;
  const withMinDayValue = ({ floatValue }) => floatValue >= MIN_DAY_VAL;

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const fetchData = async () => {
    console.log(RequestObject);
    console.log("Offer ID: "+GetOfferId(offer))
    let res = await WebService.ModifyOffer(history, RequestObject, GetOfferId(offer), StoredImageId, 
      criteriaValues.filter((value) => ActiveFilter(value.FilterType)), LocationsList, ImgUrlInput, companySelected);

    if(res != null){
      // history.push("./Offers");
      if(SessionHelper.getCompanyId() == 0){
        history.push("./AdminOffersView");
      }else{
        history.push("./Offers");
      }
    }else{
      history.push("./sign-in");
    }
  };

  const editExtras = async () => {
    console.log(RequestObject);
    console.log("Offer ID: "+GetOfferId(offer))
    let res = await WebService.CreateOfferChangeRequest(history, RequestObject, GetOfferId(offer),  
      criteriaValues.filter((value) => ActiveFilter(value.FilterType)), StoredImageId, LocationsList, ImgUrlInput, companySelected);

    if(res != null){
      //history.push("./Offers");
      if(SessionHelper.getCompanyId() == 0){
        history.push("./AdminOffersView");
      }else{
        history.push("./Offers");
      }
    }else{
      history.push("./sign-in");
    }
  };

  const [ImgUrlInput, setImgUrlInput] = useState(GetCompanyIcon(offer));
  const [StoredImageId, setStoredImageId] = useState(GetStoredImageId(offer));

  const [showViewStoredImages, setShowViewStoredImages] = useState(false);
    const handleCloseViewStoredImages = () => setShowViewStoredImages(false);
    const handleShowViewStoredImages = () => {
        setShowViewStoredImages(true);
    }
    const onImageSelected = (imageId, imageUrl) => {
        console.log("Image selected Id: "+ imageId)
        setImgUrlInput(imageUrl);
        setStoredImageId(imageId);
        handleCloseViewStoredImages();
    }
    const [showBackViewStoredImages, setShowBackViewStoredImages] = useState(false);
    const handleCloseBackViewStoredImages = () => setShowBackViewStoredImages(false);
    const handleShowBackViewStoredImages = () => {
        setShowBackViewStoredImages(true);
    }
    const onBackgroundImageSelected = (imageId, imageUrl) => {
      console.log("Image selected Id: "+ imageId)
      handleManualChange("backgroundImageId", imageId)
      handleManualChange("backgroundImageUrl", imageUrl)
      //setImgUrlInput(imageUrl);
      //setStoredImageId(imageId);
      handleCloseBackViewStoredImages();
  }

  const [showSmallViewStoredImages, setShowSmallViewStoredImages] = useState(false);
    const handleCloseSmallViewStoredImages = () => setShowSmallViewStoredImages(false);
    const handleShowSmallViewStoredImages = () => {
        setShowSmallViewStoredImages(true);
    }
    const onSmallImageSelected = (imageId, imageUrl) => {
      console.log("Image selected Id: "+ imageId)
      handleManualChange("smallLogoId", imageId)
      handleManualChange("smallLogoUrl", imageUrl)
      //setImgUrlInput(imageUrl);
      //setStoredImageId(imageId);
      handleCloseSmallViewStoredImages();
  }

    const handleSelectAll = () => {
  
      if(LocationsList.length == provinceList.length)
        setLocationsList([])
      else{
        const provIdList = provinceList.map(x => x.Id)
        setLocationsList([...provIdList])
      }
    }

    const handleLocationChange = (event) => {
      if(!event.target.checked){
        setLocationsList(LocationsList.filter(x => x != +event.target.value))
      }else{
        setLocationsList([...LocationsList, +event.target.value])
      }
    };

  useEffect(() => {
    var i;
    for (i = 0; i < criteriaValues.length; i++) {
      console.log("C FillIn: " + i);
      FillInDefaults(criteriaValues[i])
    }
    setCriteriaValues([...criteriaValues])
    console.log("CriteriaSelected: " + offer.OfferCriteria)
    var tempArray = [];
    if(offer != undefined && offer.OfferCriteria != undefined){
      
      var j;
      for (j = 0; j < offer.OfferCriteria.length; j++) {
        var criteriaServ = offer.OfferCriteria[j];
        var criteriaInst = criteriaValues.find(function (crita) {
          return crita.FilterType == offer.OfferCriteria[j].FilterType;
        });
        if(criteriaInst != undefined && criteriaServ != undefined){
          FillInValues(criteriaInst, criteriaServ.FirstValue, criteriaServ.FirstEquality, 
            criteriaServ.SecondValue, criteriaServ.SecondEquality, 
            criteriaServ.ThirdValue, criteriaServ.ThirdEquality)
        }
        console.log("C OfferCriteria: " + offer.OfferCriteria[j].FirstValue);
        tempArray.push(offer.OfferCriteria[j].FilterType)
      }
    }
    setActiveFilterList(tempArray);

  }, []);

  useEffect(() => {
    if (sendRequest) {
      // if(isActiveOffer){
      //   editExtras();
      // }else{
      //   fetchData();
      // }
      fetchData();

    }
  }, [sendRequest]);

  return (
    <div className={classes.root}>
      <div>
        <Modal
              className={classes.content}
              show={sendRequest}
              // onHide={handleCloseOfferDetail}
              centered>
              <Modal.Header >
                <Modal.Title>{"Processing"}</Modal.Title>
              </Modal.Header>
              <Modal.Body className={classes.spinner}>
              <Spinner animation="border" variant="primary" />
              </Modal.Body>
              <Modal.Footer>
              {/* <Button variant="primary" onClick={handleOfferDetail}>
                  Delete
              </Button> */}
              {/* <Button variant="primary" onClick={handleCancelOfferDetail}>
                  Save Changes
              </Button>
              <Button variant="secondary" onClick={handleCancelOfferDetail}>
                  Cancel
              </Button> */}
              </Modal.Footer>
        </Modal>
        <ImagePreviewGrid 
            showViewStoredImages={showViewStoredImages} 
            handleCloseViewStoredImages={handleCloseViewStoredImages}
            onImageSelected={onImageSelected}
            companyId={GetCompanyId(offer)}
            imageType={1}/>
          <ImagePreviewGrid 
            showViewStoredImages={showBackViewStoredImages} 
            handleCloseViewStoredImages={handleCloseBackViewStoredImages}
            onImageSelected={onBackgroundImageSelected}
            companyId={GetCompanyId(offer)}
            imageType={1}/>
          <ImagePreviewGrid 
            showViewStoredImages={showSmallViewStoredImages} 
            handleCloseViewStoredImages={handleCloseSmallViewStoredImages}
            onImageSelected={onSmallImageSelected}
            companyId={GetCompanyId(offer)}
            imageType={1}/>
        <Grid className={classes.grid} container>
          <Grid className={classes.content} item lg={12} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton onClick={handleBack}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className={classes.contentBody}>
              <Grid container className={classes.contentBody} spacing={24} direction="row" >
              <Grid className={classes.gridItem} item md={6} xs={6}>
                <form className={classes.form} onSubmit={handleCreateOffer}>
                  <Typography className={classes.title} variant="h2">
                    Edit Offer
                  </Typography>
                  <Form inline className={"mt-4"}>
                  <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                      Company 
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className="my-1 mr-sm-2"
                      id="inlineFormCustomSelectPref"
                      custom
                      disabled
                      value={GetCompanyId(offer)}
                      
                    >
                      <option value={0}>None Selected</option>
                      {SessionHelper.getCompanyList() != null && SessionHelper.getCompanyList().map((aNum) => (
                        <option value={CHGetCompany(aNum)} key={CHGetCompany(aNum)}>{GetCompanyName(aNum)}</option>
                      ))}
                    </Form.Control>
                    </Form>
                  {/* <TextField
                    multiline
                    className={classes.textField}
                    error={hasError("title")}
                    fullWidth
                    helperText={
                      hasError("title") ? formState.errors.title[0] : null
                    }
                    label="Title"
                    name="title"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.title || ""}
                    variant="outlined"
                    maxRows={2}
                    rows={2}
                    label={"Title"+((formState.values.title !== undefined && formState.values.title.length > 0)?(" "+(40 - formState.values.title.length)+" characters remaining"):"")}
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                  {false && <TextField
                    className={classes.textField}
                    error={hasError("title2")}
                    fullWidth
                    helperText={
                      hasError("title2") ? formState.errors.title2[0] : null
                    }
                    name="title2"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.title2 || ""}
                    variant="outlined"
                    label={"Title2"+((formState.values.title2 !== undefined && formState.values.title2 != null && formState.values.title2.length > 0)?(" "+(20 - formState.values.title2.length)+" characters remaining"):"")}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />}
                  <TextField
                  multiline
                    className={classes.textField}
                    error={hasError("description")}
                    fullWidth
                    helperText={
                      hasError("description")
                        ? formState.errors.description[0]
                        : null
                    }
                    name="description"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.description || ""}
                    variant="outlined"
                    maxRows={4}
                    rows={4}
                    label={"Description"+((formState.values.description !== undefined && formState.values.description.length > 0)?(" "+(150 - formState.values.description.length)+" characters remaining"):"")}
                    inputProps={{
                      maxLength: 150,
                    }}
                  />
                  <TextField
                    className={classes.textField}
                    error={hasError("offerURL")}
                    fullWidth
                    helperText={
                      hasError("offerURL")
                        ? formState.errors.offerURL[0]
                        : null
                    }
                    label="Offer URL"
                    name="offerURL"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.offerURL || ""}
                    variant="outlined"
                  />
                  <TextField
                    multiline
                    className={classes.textField}
                    error={hasError("termsSummary")}
                    fullWidth
                    helperText={
                      hasError("termsSummary")
                        ? formState.errors.termsSummary[0]
                        : null
                    }
                    label="Terms Summary"
                    name="termsSummary"
                    onChange={handleChange}
                    minRows={4}
                    type="text"
                    label={"Terms Summary"}
                   
                    placeholder=
                    "• Term 1&#13;&#10;• Term 2&#13;&#10;• Term 3"
                    value={formState.values.termsSummary || ""}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    error={hasError("termsURL")}
                    fullWidth
                    helperText={
                      hasError("termsURL")
                        ? formState.errors.termsURL[0]
                        : null
                    }
                    label="Terms URL"
                    name="termsURL"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.termsURL || ""}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    error={hasError("extraURL")}
                    fullWidth
                    helperText={
                      hasError("extraURL")
                        ? formState.errors.extraURL[0]
                        : null
                    }
                    label="Offer Terms Hyperlink Text"
                    name="extraURL"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.extraURL || ""}
                    variant="outlined"
                  />
                  <Typography
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="h3"
                  >
                    Offer Valid Dates
                  </Typography>
                  <Typography
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1"
                  >
                    Offer Start Date
                  </Typography>
                  <DatePicker
                    selected={startDate}
                    
                     onChange={(date) => handleSetStartDate(date)}
                  />
                  <Typography
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1"
                  >
                    Offer End Date
                  </Typography>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => handleSetDate(date)}
                  />
                  <Typography
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1"
                  >
                    Days Claimed Offer is Valid
                  </Typography>
                  <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {showCustomActiveDays ? "custom" : daysAvailable + " days"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {MaxDaysValues.map((value) =>
                        typeof value == "string" ? (
                          <Dropdown.Item
                            onClick={(event) => setShowCustomActiveDays(!showCustomActiveDays)}
                            active={showCustomActiveDays}
                          >
                            {value + ""}
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            onClick={(event) => handleDaysSelect(true, value)}
                            active={!showCustomActiveDays && value == daysAvailable}
                          >
                            {value + " days"}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
              
                  {showCustomActiveDays && 
                    <NumericFormat value={daysAvailable} 
                    thousandSeparator={true} 
                    allowNegative={false}
                    isAllowed={withMinDayValue}
                    decimalScale={0}
                    suffix={(daysAvailable > 1)?' days':' day'} onValueChange={(values) => {
                      const {formattedValue, value} = values;
                      handleDaysSelect(false, parseInt(value, 10))
                    }}/>}
                  <br></br>
                        {maxDepositValue != 0 && cashBackValue != 0 &&
                    <div className={classes.row}>
                      <h3>
                        <div>
                            <NumericFormat decimalScale={2} fixedDecimalScale={true} value={((maxDepositValue * ((offerRewardType == 0)?1:cashBackValue)) / 100)} displayType={'text'} thousandSeparator={true} prefix={'Max Reward: $'} />
                            <NumericFormat decimalScale={0} fixedDecimalScale={true} value={(maxDepositValue * ((offerRewardType == 0)?1:cashBackValue))} displayType={'text'} thousandSeparator={true} suffix={" BetFully points)"} prefix={' ('} />
                          </div>
                          </h3>
                    </div>
                  }
                 {offer.OfferType != 9 && <Typography
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1"
                  >
                    User Action Type
                  </Typography>}
                  {offer.OfferType != 9 && <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {UserActionTypeValues[offerActionType]}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {UserActionTypeValues.map((value,index) =>
                        value === offerActionType ? (
                          <Dropdown.Item
                            onClick={(event) => handleActionTypeSelect(index)}
                            active
                          >
                            {value}
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            onClick={(event) => handleActionTypeSelect(index)}
                          >
                            {value}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>}
                  {offer.OfferType != 9 && <Typography
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1"
                  >
                    Reward Type
                  </Typography>}
                  {offer.OfferType != 9 && <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {RewardTypeValues[offerRewardType]}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {RewardTypeValues.map((value,index) =>
                        value === offerRewardType ? (
                          <Dropdown.Item
                            onClick={(event) => handleRewardTypeSelect(index)}
                            active
                          >
                            {value}
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            onClick={(event) => handleRewardTypeSelect(index)}
                          >
                            {value}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>}
                  {offer.OfferType != 9 && <Typography
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1"
                  >
                    {"Minimum Single "+((offerActionType == 1)?"Withdrawal":"Deposit")+" Amount ($)"}
                  </Typography>}
                  {(offerRewardType == 2) && <TextField
                    className={classes.textField}
                    error={hasError("title2")}
                    fullWidth
                    helperText={
                      hasError("title2") ? formState.errors.title2[0] : null
                    }
                    name="title2"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.title2 || ""}
                    variant="outlined"
                    label={"Tapjoy Placement"}
                  />}
                  {offer.OfferType != 9 && <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {showCustomMinDeposit?"custom":((minDepositValue == 0)?"none":"$" + minDepositValue)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {MinDepositValues.map((value) =>
                        typeof value == "string" ? (
                          <Dropdown.Item
                            onClick={(event) => handleShowCustomMinDeposit()}
                            active = {showCustomMinDeposit}
                          >
                            {value}
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            onClick={(event) => handleMinDepositSelect(true, value)}
                            active ={!showCustomMinDeposit && value == minDepositValue}
                          >
                            {(value == 0)?"none":"$" + value}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>}
                
                  {showCustomMinDeposit && offer.OfferType != 9 &&  
                  <NumericFormat value={minDepositValue} 
                  thousandSeparator={true} 
                  allowNegative={false}
                  decimalScale={0}
                  prefix={'$'} onValueChange={(values) => {
                    const {formattedValue, value} = values;
                    // formattedValue = $2,223
                    // value ie, 2223
                    //setPointAmount(parseInt(value, 10))
                    handleMinDepositSelect(false, parseInt(value, 10))
                  }}/>}

                  {(offerRewardType == 1) && offer.OfferType != 9 && <Typography
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1"
                  >
                    {"Max Total "+((offerActionType == 1)?"Withdrawal":"Deposit")+" Amount ($)"}
                  </Typography>}
                  {(offerRewardType == 1) && offer.OfferType != 9 && <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {showCustomMaxDeposit ? "custom" : ((maxDepositValue == 0)?"no limit":"$" + maxDepositValue)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {MaxDepositValues.map((value) =>
                        typeof value == "string" ? (
                          <Dropdown.Item
                            onClick={(event) => setShowCustomMaxDeposit(!showCustomMaxDeposit)}
                            active = {showCustomMaxDeposit}
                          >
                            {"" + value}
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            onClick={(event) => handleMaxDepositSelect(true, value)}
                            active = {!showCustomMaxDeposit && value == maxDepositValue}
                          >
                            {(value == 0)?"no limit":"$" + value}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>}
                  {(offerRewardType == 0 || offer.OfferType == 9 ) && <Typography
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1"
                  >
                    {"Reward Amount (Points)"}
                  </Typography>}
                  {((showCustomMaxDeposit && (offerRewardType == 1)) || offerRewardType == 0 || offer.OfferType != 9) && 
                  <NumericFormat value={maxDepositValue} 
                  thousandSeparator={true} 
                  allowNegative={false}
                  decimalScale={0}
                  prefix={(offerRewardType == 0)?'':'$'} onValueChange={(values) => {
                    const {formattedValue, value} = values;
                    handleMaxDepositSelect(false, parseInt(value, 10))
                  }}/>}
                 {(offerRewardType == 1) && offer.OfferType != 9 && <Typography
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1"
                  >
                    Cashback (%)
                  </Typography>}

                  {(offerRewardType == 1) && offer.OfferType != 9 && <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {showCustomCashback ? "custom" : cashBackValue + "%"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {CashBackValues.map((value) =>
                        typeof value == "string" ? (
                          <Dropdown.Item
                            onClick={(event) => setShowCustomCashback(!showCustomCashback)}
                            active = {showCustomCashback}
                          >
                            {value + ""}
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            onClick={(event) => handleCashBackSelect(true, value)}
                            active={!showCustomCashback && value == cashBackValue}
                          >
                            {value + "%"}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>}
                
                  {showCustomCashback && (offerRewardType == 1) && offer.OfferType != 9 && 
                  <NumericFormat value={cashBackValue} 
                  thousandSeparator={true} 
                  allowNegative={false}
                  isAllowed={withValueLimit}
                  decimalScale={2}
                  suffix={'%'} onValueChange={(values) => {
                    const {formattedValue, value} = values;
                    handleCashBackSelect(false, parseFloat(value))
                  }}/>}
              
                  {(offerRewardType == 1) && offer.OfferType != 9 && <Typography
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1"
                  >
                    Max Transactions
                  </Typography>}

                  {(offerRewardType == 1) && offer.OfferType != 9 && <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {showCustomMaxTransactions ? "custom" : ((maxTransactionValue == 0)?"no limit":maxTransactionValue)}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {MaxTransactionValues.map((value) =>
                      typeof value == "string" ? (
                          <Dropdown.Item
                            onClick={(event) => setShowCustomMaxTransactions(!showCustomMaxTransactions)}
                            active = {showCustomMaxTransactions}
                          >
                            {value}
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            onClick={(event) => handleMaxTransactionSelect(true, value)}
                            active={!showCustomMaxTransactions && value == maxTransactionValue}
                          >
                            {(value == 0)?"no limit":value}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu>
                  </Dropdown>}
                
                  {showCustomMaxTransactions && (offerRewardType == 1) && offer.OfferType != 9 && 
                  <NumericFormat value={maxTransactionValue} 
                  thousandSeparator={true} 
                  allowNegative={false}
                  decimalScale={0}
                  prefix={''} onValueChange={(values) => {
                    const {formattedValue, value} = values;
                    handleMaxTransactionSelect(false, parseInt(value, 10))
                  }}/>}
                 {false && <Typography
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1"
                  >
                    Customer Filters
                  </Typography>}

                  {false && <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {true ? "Select a Filter" : "Multiple Item Selected"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {criteriaValues.map((value) =>
                        ActiveFilter(value.FilterType) ? (
                          <div></div>
                        ) : (
                          <Dropdown.Item onClick={(event) => handleAddFilter(value.FilterType)}>
                            {(value.StoredProcedureName === undefined)?'Filter':value.StoredProcedureName}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu> */}
                    {/* <Dropdown.Menu>
                      {criteriaList.map(value =>
                        ActiveFilter(value.Id) ? (
                          <Dropdown.Item
                            onClick={event => handleRemoveFilter(value.Id)}
                            active
                          >
                            {(value.StoredProcedureName === undefined)?'filter':value.StoredProcedureName}
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            onClick={event => handleAddFilter(value.Id)}
                          >
                            {(value.StoredProcedureName === undefined)?'filter':value.StoredProcedureName}
                          </Dropdown.Item>
                        )
                      )}
                    </Dropdown.Menu> */}
                  {/* </Dropdown>}
                  <div>
              <br></br> */}
              {/* Filters Selected: */}
              {/* {criteriaValues.map((value) =>
                ActiveFilter(value.FilterType) ? (
                  
                  <Card className="mb-3 mt-3">
                    <CardBody>
                      <Button
                        close
                        onClick={(event) => handleRemoveFilter(value.FilterType)}
                      />

                      <CardText className="mr-4">
                        {(value.CriteriaDisplayName+"").split(' ').map((aValue) =>
                        
                          (aValue == '{0}')?
                            (<label>
                              <input
                                name="numberOfGuests"
                                type="number"
                                value={value.FirstValue}
                                onChange={(event) => criteriaValuesUpdated(value.Id,  event.target.value, undefined, undefined, undefined)} />
                            </label>)
                          :((aValue == '{1}')?
                            (<label>
                              <Dropdown>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                      {(value.FirstEquality !== undefined && value.FirstEquality > 0)?InEqualityValues[(value.FirstEquality-1)].Sym:InEqualityValues[0].Sym}
                    </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {InEqualityValues.map((inValue) =>
                                  inValue.Val == InEqualityValues[((value.FirstEquality !== undefined && value.FirstEquality > 0)?value.FirstEquality-1:0)].Val ? (
                                    <Dropdown.Item
                                      onClick={(event) => criteriaValuesUpdated(value.Id,  undefined, inValue.Val, undefined, undefined)}
                                      active
                                    >
                                      {inValue.Sym + ""}
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      onClick={(event) => criteriaValuesUpdated(value.Id,  undefined, inValue.Val, undefined, undefined)}
                                    >
                                      {inValue.Sym + ""}
                                    </Dropdown.Item>
                                  )
                                )}
                              </Dropdown.Menu>
                              </Dropdown>

                            </label>)
                            :
                            ((aValue == '{C}')?
                            <label>
                            {(SessionHelper.getCompany()+" ")}</label>
                            :
                              aValue+" "
                          )
                          )
                        )}
                      </CardText>
                    </CardBody>
                  </Card>
                ) : (
                  <div></div>
                )
              )}
            </div>
            {(offerRewardType != 2) && <Grid container columns={15} spacing={1} direction="row" >
                  {provinceList.map(x => (<Grid item md={3} xs={5}><FormControlLabel
                      control={<Checkbox 
                        onChange={handleLocationChange} 
                        checked={LocationsList.includes(x.Id)} 
                        value={x.Id}
                        name={x.Name} />}
                      label={x.Name}
                    /></Grid>))} */}
                    
                  {/* </FormGroup> */}
                  {/* </Grid>}
                  {(offerRewardType != 2) && <Button
                    className={classes.signInButton}
                    color="primary"
                    fullWidth
                    size="large"
                    variant="contained"
                    onClick={() => handleSelectAll()}
                  >
                    {(LocationsList.length == provinceList.length)?"Remove All":"Select All"}
                  </Button>} */}
                  {/* Offer Details Bullet Points */}
                  {/* {(offerRewardType != 2) && <Grid container columns={15} spacing={1} direction="row" >
                  {provinceList.map(x => (<Grid item md={3} xs={5}><FormControlLabel
                      control={<Checkbox 
                        onChange={handleLocationChange} 
                        checked={LocationsList.includes(x.Id)} 
                        value={x.Id}
                        name={x.Name} />}
                      label={x.Name}
                    /></Grid>))}
                    
                  {/* </FormGroup> 
                  </Grid>} */}
{/* <div>Offer Image</div>
                  <ProductCardPreview
              className={classes.cardPreview}
              product={formState.productObj}
              showImageSelect={handleShowViewStoredImages}
              displayImage={ImgUrlInput}
              // onClick={event => handleSelectOffer(product)}
            />
            <div>Offer Background Image</div>
<ProductCardPreview
              className={classes.cardPreview}
              product={formState.productObj}
              showImageSelect={handleShowBackViewStoredImages}
              displayImage={formState.values.backgroundImageUrl}
              // onClick={event => handleSelectOffer(product)}
            />
            <div>Offer Small Icon Image</div>
            <ProductCardPreview
              className={classes.cardPreview}
              product={formState.productObj}
              showImageSelect={handleShowSmallViewStoredImages}
              displayImage={formState.values.smallLogoUrl}
              // onClick={event => handleSelectOffer(product)}
            />
            <TextField
                    multiline
                    className={classes.textField}
                    error={hasError("instruction1")}
                    fullWidth
                    helperText={
                      hasError("instruction1") ? formState.errors.instruction1[0] : null
                    }
                    label="Instruction1"
                    name="instruction1"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.instruction1 || ""}
                    variant="outlined"
                    maxRows={2}
                    rows={2}
                    label={"Instruction1"+((formState.values.instruction1 !== undefined && 
                      formState.values.instruction1.length > 0)?
                      (" "+(40 - formState.values.instruction1.length)+" characters remaining"):"")}
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                  <TextField
                    multiline
                    className={classes.textField}
                    error={hasError("instruction2")}
                    fullWidth
                    helperText={
                      hasError("instruction2") ? formState.errors.instruction2[0] : null
                    }
                    label="Instruction2"
                    name="instruction2"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.instruction2 || ""}
                    variant="outlined"
                    maxRows={2}
                    rows={2}
                    label={"Instruction2"+((formState.values.instruction2 !== undefined && 
                      formState.values.instruction2.length > 0)?
                      (" "+(40 - formState.values.instruction2.length)+" characters remaining"):"")}
                    inputProps={{
                      maxLength: 40,
                    }}
                  />
                  <TextField
                    multiline
                    className={classes.textField}
                    error={hasError("instruction3")}
                    fullWidth
                    helperText={
                      hasError("instruction3") ? formState.errors.instruction3[0] : null
                    }
                    label="Instruction3"
                    name="instruction3"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.instruction3 || ""}
                    variant="outlined"
                    maxRows={2}
                    rows={2}
                    label={"Instruction3"+((formState.values.instruction3 !== undefined && 
                      formState.values.instruction3.length > 0)?
                      (" "+(40 - formState.values.instruction3.length)+" characters remaining"):"")}
                    inputProps={{
                      maxLength: 40,
                    }}
                  /> */}
                  <OfferSection 
                    editEnabled={true} 
                    useStyles={useStyles}
                    formStateOld = {
                      formState
                    }
                    dupeOffer={offer}
                    handleChange={handleChange}
                    handleManualChange={handleManualChange}
                    hasError={hasError} 
                    withMinDayValue={withMinDayValue}
                    withValueLimit={withValueLimit}
                    provinceList={provinceList}
                    handleLocationChange={handleLocationChange}
                    LocationsList={LocationsList}
                    handleSelectAll={handleSelectAll}
                    handleShowViewStoredImages={handleShowViewStoredImages}
                    handleShowBackViewStoredImages={handleShowBackViewStoredImages}
                    handleShowSmallViewStoredImages={handleShowSmallViewStoredImages}
                />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save Offer
                  </Button>
                  
                </form>
                </Grid>
                <Grid className={classes.gridItem} item md={6} xs={6}>
                  <Typography className={classes.title} variant="h2">
                      Previous
                  </Typography>
                  <Form inline className={"mt-4"}>
                  <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                      Version 
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className="my-1 mr-sm-2"
                      id="inlineFormCustomSelectPref"
                      custom
                      value={GetReplacedId(SelectedPreviousOffer)}
                      onChange = {(event) => handlePreviousSelected(+event.target.value)}
                      >
                      <option value={0}>Current</option>
                      {PreviousOfferList.map((aNum) => (
                        <option value={GetReplacedId(aNum)} key={GetReplacedId(aNum)}>{GetReplacedDate(aNum)}</option>
                      ))}
                    </Form.Control>
                    </Form>
                    {/* </Grid>
                <Grid className={classes.gridItem} item md={6} xs={6}> */}
                  <OfferSection 
                    editEnabled={false} 
                    useStyles={useStyles}
                    dupeOffer={SelectedPreviousOffer}
                    handleChange={handleChange}
                    hasError={hasError} 
                    withMinDayValue={withMinDayValue}
                    withValueLimit={withValueLimit}
                    provinceList={provinceList}
                    handleLocationChange={handleLocationChange}
                    LocationsList={LocationsList}
                    handleSelectAll={handleSelectAll}
                    handleShowViewStoredImages={handleShowViewStoredImages}
                    handleShowBackViewStoredImages={handleShowBackViewStoredImages}
                    handleShowSmallViewStoredImages={handleShowSmallViewStoredImages}
                />
                </Grid>
                {/* <Grid className={classes.gridItem} item md={6} xs={6}>
                <OfferConfigureSection 
                    editEnabled={false} 
                    useStyles={useStyles}
                    offer={SelectedPreviousOffer}
                    handleChange={handleChange}
                    formStateOld={formState}
                    hasError={hasError} 
                    handleSetStartDate={handleSetStartDate}
                    handleSetDate={handleSetDate}
                    startDateOld={startDate}
                    endDateOld={endDate}
                    showCustomActiveDays={showCustomActiveDays}
                    daysAvailableOld={daysAvailable}
                    setShowCustomActiveDays={setShowCustomActiveDays}
                    handleDaysSelect={handleDaysSelect}
                    withMinDayValue={withMinDayValue}
                    maxDepositValueOld={maxDepositValue}
                    cashBackValueOld={cashBackValue}
                    offerRewardTypeOld={offerRewardType}
                    offerActionTypeOld={offerActionType}
                    handleActionTypeSelect={handleActionTypeSelect}
                    handleRewardTypeSelect={handleRewardTypeSelect}
                    showCustomMinDeposit={showCustomMinDeposit}
                    minDepositValueOld={minDepositValue}
                    handleShowCustomMinDeposit={handleShowCustomMinDeposit}
                    handleMinDepositSelect={handleMinDepositSelect}
                    showCustomMaxDeposit={showCustomMaxDeposit}
                    setShowCustomMaxDeposit={setShowCustomMaxDeposit}
                    handleMaxDepositSelect={handleMaxDepositSelect}
                    showCustomCashback={showCustomCashback}
                    setShowCustomCashback={setShowCustomCashback}
                    handleCashBackSelect={handleCashBackSelect}
                    withValueLimit={withValueLimit}
                    showCustomMaxTransactions={showCustomMaxTransactions}
                    maxTransactionValueOld={maxTransactionValue}
                    setShowCustomMaxTransactions={setShowCustomMaxTransactions}
                    handleMaxTransactionSelect={handleMaxTransactionSelect}
                    provinceList={provinceList}
                    handleLocationChange={handleLocationChange}
                    LocationsList={LocationsList}
                    handleSelectAll={handleSelectAll}
                    handleShowViewStoredImages={handleShowViewStoredImages}
                    ImgUrlInputOld={ImgUrlInput}/>
                  </Grid> */}
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

EditOffer.propTypes = {
  history: PropTypes.object
};

export default withAuthorization(EditOffer);
