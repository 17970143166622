import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import WebService from "../../api/WebServices.js";
import { EXPIRED_TOKEN } from 'constants/constants'
import SessionHelper from "../../helpers/SessionHelper";
import { ToastProvider } from "react-toast-notifications";

import { Sidebar, Topbar, Footer } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    minHeight: "100vh",
    paddingTop: "64px",
    /* For the Footer */
    paddingBottom: "5rem",
  },
  shiftContent: {
    paddingLeft: 240,
  },
  contentWrapper: {},
}));

const Main = (props) => {
  const { history, children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"), {
    defaultMatches: true,
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  const [GotUserData, setGotUserData] = useState(0);

  // useEffect(() => {
  //   console.log("Icon is: " + SessionHelper.getIcon());
  //   if (!SessionHelper.dataRetrieved()) {
  //     const fetchData = async () => {
  //       const result = await WebService.GetMyInfo(history, undefined);
  //       if (result != null) {
  //         SessionHelper.setIcon(result.ProfilePictureURL);
  //         SessionHelper.setCompany(result.CompanyName);
  //         SessionHelper.setCompanyId(result.CompanyId);
  //         SessionHelper.setCompanyList(result.CompanyList);
  //         SessionHelper.setDataRetrieved(true);
  //         setGotUserData(1);
  //       } else {
  //       }
  //     };
  //     fetchData();
  //   }
  // }, []);

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} />
      <Sidebar
        onClose={handleSidebarClose}
        open={shouldOpenSidebar}
        variant={isDesktop ? "persistent" : "temporary"}
      />
      <ToastProvider placement="bottom-center">
        <main className={classes.contentWrapper}>{children}</main>
      </ToastProvider>
      <Footer />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

export default Main;
