import moment from "moment";

import {COLUMNS_SORT} from "../helpers/TableHelper"

import SessionHelper from "helpers/SessionHelper";

// export const Unknown = "Unknown";
// export const SignIn = "Sign In";
// export const ActivatedOffer = "Offer Activated";
// export const LinkedBankAccount = "Bank Account Linked";
// export const ProcessedTransaction = "Transaction Processed";
// export const CashOutRequest = "Cashout Requested";
// export const AdProcessed = "Ad Processed";
// export const TransactionAppliedToOffer = "Transaction Applied To Offer";
// export const FS_OfferMaxedOut = "Suspicious Average Offer Completion Rate";
// export const FS_PointsEarnedExceededThreshold = "Suspicious Amount of Points Earned";
// export const FS_DuplicateBankAccountLinked = "Linked Bank Account previously found in BetFully";
// export const FS_DuplicateCreditCardLinked = "Linked Credit Card previously found in BetFully";
// export const FS_WithdrawDepositDifference = "Suspiciously Similar Withdraw and Deposit amounts detected";
// export const FS_BankAccountCountThresholdExceeded = "Suspicious Number of Bank Accounts Linked To BetFully";
// export const FS_LoggedInOutsideUS = "Logged From IP outside US";

//int value
export function GetSelectedPage(config){
  if(config && config.selectedPage){
    return config.selectedPage;
  }else{
    return 0;
  }
}

export function SetSelectedPage(value){
  var configFile = SessionHelper.getUserListConfig();
  if(configFile){
    configFile.selectedPage = value;
    SessionHelper.setUserListConfig();
  }
}

//int value
export function GetRowsPerPage(config){
  if(config && config.rowsPerPage){
    return config.rowsPerPage;
  }else{
    return 0;
  }
}

export function SetRowsPerPage(value){
  var configFile = SessionHelper.getUserListConfig();
  if(configFile){
    configFile.rowsPerPage = value;
    SessionHelper.setUserListConfig();
  }
}

//int value
export function GetCurrentSort(config){
  if(config && config.currentSort){
    return config.currentSort;
  }else{
    return COLUMNS_SORT.DEFAULT;
  }
}

export function SetCurrentSort(value){
  var configFile = SessionHelper.getUserListConfig();
  if(configFile){
    configFile.currentSort = value;
    SessionHelper.setUserListConfig();
  }
}

//string value
export function GetSearchTerm(config){
  if(config && config.searchTerm){
    return config.searchTerm;
  }else{
    return "";
  }
}

export function SetSearchTerm(value){
  var configFile = SessionHelper.getUserListConfig();
  if(configFile){
    configFile.searchTerm = value;
    SessionHelper.setUserListConfig();
  }
}

//int value
export function GetUserStandingFilter(config){
  if(config && config.userStandingFilter){
    return config.userStandingFilter;
  }else{
    return 0;
  }
}

export function SetUserStandingFilter(value){
  var configFile = SessionHelper.getUserListConfig();
  if(configFile){
    configFile.userStandingFilter = value;
    SessionHelper.setUserListConfig();
  }
}


//int value
export function GetOnlineFilter(config){
  if(config && config.onlineFilter){
    return config.onlineFilter;
  }else{
    return 0;
  }
}

export function SetOnlineFilter(value){
  var configFile = SessionHelper.getUserListConfig();
  if(configFile){
    configFile.onlineFilter = value;
    SessionHelper.setUserListConfig();
  }
}

//int value
export function GetPendingTransactionFilter(config){
  if(config && config.pendingTransactionFilter){
    return config.pendingTransactionFilter;
  }else{
    return 0;
  }
}

export function SetPendingTransactionFilter(value){
  var configFile = SessionHelper.getUserListConfig();
  if(configFile){
    configFile.pendingTransactionFilter = value;
    SessionHelper.setUserListConfig();
  }
}

//int value
export function GetDefaultConfig(){
  return {
    selectedPage: 0,
    rowsPerPage: 100,
    currentSort: COLUMNS_SORT.DEFAULT,
    searchTerm: "",
    userStandingFilter: 0,
    onlineFilter: 0,
    pendingTransactionFilter: 0
  };
}