import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, IconButton } from "@mui/material";
import { Link as RouterLink, withRouter } from "react-router-dom";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Form, Row, Col, Spinner } from "react-bootstrap";
import {
  UserProfile,
  TotalDeposits,
  OfferActivty,
  ClaimRate,
  DepositsBySite,
  DepositHistory,
  LatestSales,
  CompetitorsDeposits,
  LatestOrders,
  TotalPoints,
} from "./components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";

import {
  GetCancelledCount,
  GetActiveCount,
  GetExpiredCount,
  GetInactiveCount,
  GetCompletedCount,
  CreateUserOfferEntriesMap,
  CreateUserOfferEntriesMapDate,
  GenerateDataForOfferChart,
  GetEntryClients,
  GetOfferEntryFieldString,
  OfferEntryField,
  DateUnits,
} from "helpers/UserDetailsHelper";
import {
  GetCompanyId,
  GetCompanyName,
  GetCompanyNameWithId
} from "helpers/CompanyHelper";
import SessionHelper from "helpers/SessionHelper";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  contactSection: {
    marginBottom: theme.spacing(1),
  },
}));


const OfferView = (props) => {
  const classes = useStyles();
  const { history, userDetails, isLoading, approveTransaction } = props;
  //const [userDetails, setUserDetails] = useState([]);

  const TIME_RANGE = {
    SAME_DAY: {Name:'Last Day', Val:0, DateUnits:DateUnits.DAY, Count:1},
    WEEK: {Name:'Last 7 Days', Val:5, DateUnits:DateUnits.DAY, Count:7},
    WEEK_2: {Name:'Last 14 Days', Val:6,DateUnits:DateUnits.DAY, Count:14},
    MONTH: {Name:'Last Month', Val:1, DateUnits:DateUnits.DAY, Count:30},
    MONTH_3: {Name:'Last 3 Months', Val:7, DateUnits: DateUnits.MONTH, Count:3},
    MONTH_6: {Name:'Last 6 Months', Val:2, DateUnits: DateUnits.MONTH, Count:6},
    YEAR: {Name:'Last Year', Val:3, DateUnits:DateUnits.MONTH, Count:12},
    ALL_TIME: {Name:'All Time', Val:4, DateUnits:DateUnits.YEAR, Count:5},
  }
  
  const [compareCompanies, setCompareCompanies] = useState(false);
  const [filteredCompanyIdA, setFilteredCompanyIdA] = useState(
    (SessionHelper.getCompanyList() != null && SessionHelper.getCompanyList().length > 0)?
    GetCompanyId((SessionHelper.getCompanyList())[0]):0);
  
  const [offerMap, setOfferMap] = useState(CreateUserOfferEntriesMap(GetEntryClients(userDetails), filteredCompanyIdA, compareCompanies));
  const [allOfferMap, setAllOfferMap] = useState(CreateUserOfferEntriesMap(GetEntryClients(userDetails), filteredCompanyIdA, true));
  const [offerEntryField, setOfferEntryField] = useState(OfferEntryField.POINTS);
  const [timeRangeBar, setTimeRangeBar] = useState(TIME_RANGE.ALL_TIME);
  const [filteredCompanyIdB, setFilteredCompanyIdB] = useState(0);
    
  const [barChartDataSet, setBarChartDataSet] = useState(
    GenerateDataForOfferChart(
      !compareCompanies,
      GetEntryClients(userDetails), 
      (filteredCompanyIdA == 0)?SessionHelper.getCompanyList():SessionHelper.getCompanyList().filter(x => GetCompanyId(x) ==  filteredCompanyIdA || GetCompanyId(x) == filteredCompanyIdB), 
      TIME_RANGE.ALL_TIME.Count,
      TIME_RANGE.ALL_TIME.DateUnits, offerEntryField));
  
  const handleCompareCheckChange = (event) => {
    if(!event.target.checked){
      setCompareCompanies(false)
      setOfferEntryField(OfferEntryField.POINTS)
      setFilteredCompanyIdB(0)
    }else{
      setCompareCompanies(true)
    }
  }
  
  const handleSetOfferEntryField = (event) => {
    setOfferEntryField(+event.target.value)
  }
  
  const handleChangeA = (event) => {
    setFilteredCompanyIdA(+event.target.value)
  };
  
  const handleChangeB = (event) => {
    setFilteredCompanyIdB(+event.target.value)
  };
  
  const handleDateChangeDate = (event) => {
    var amountTime = TIME_RANGE.ALL_TIME;
    switch(+event.target.value){
      case TIME_RANGE.SAME_DAY.Val:
        amountTime = TIME_RANGE.SAME_DAY;
        break;
      case TIME_RANGE.WEEK.Val:
        amountTime = TIME_RANGE.WEEK;
        break;
      case TIME_RANGE.WEEK_2.Val:
        amountTime = TIME_RANGE.WEEK_2;
        break;
      case TIME_RANGE.MONTH.Val:
        amountTime = TIME_RANGE.MONTH;
        break;
      case TIME_RANGE.MONTH_3.Val:
        amountTime = TIME_RANGE.MONTH_3;
        break;
      case TIME_RANGE.MONTH_6.Val:
        amountTime = TIME_RANGE.MONTH_6;
        break;
      case TIME_RANGE.ALL_TIME.Val:
        amountTime = TIME_RANGE.ALL_TIME;
        break;
      case TIME_RANGE.YEAR.Val:
        amountTime = TIME_RANGE.YEAR;
        break;
    }
    setTimeRangeBar(amountTime);
  };
  
  useEffect(() => {
    console.log("CHANGE")
    var filterList = (filteredCompanyIdA == 0)?SessionHelper.getCompanyList():SessionHelper.getCompanyList().filter(x => GetCompanyId(x) ==  filteredCompanyIdA || GetCompanyId(x) == filteredCompanyIdB);
    setOfferMap((timeRangeBar ==TIME_RANGE.ALL_TIME)?CreateUserOfferEntriesMap(GetEntryClients(userDetails), filteredCompanyIdA, compareCompanies)
      :CreateUserOfferEntriesMapDate(GetEntryClients(userDetails), timeRangeBar.Count,timeRangeBar.DateUnits, filteredCompanyIdA, compareCompanies))
    setAllOfferMap((timeRangeBar ==TIME_RANGE.ALL_TIME)?CreateUserOfferEntriesMap(GetEntryClients(userDetails), filteredCompanyIdA, true)
    :CreateUserOfferEntriesMapDate(GetEntryClients(userDetails), timeRangeBar.Count,timeRangeBar.DateUnits, filteredCompanyIdA, true))
    console.log("offerEntryField input:"+offerEntryField)

    setBarChartDataSet(GenerateDataForOfferChart(!compareCompanies,GetEntryClients(userDetails), filterList, timeRangeBar.Count,timeRangeBar.DateUnits, offerEntryField))
  }, [isLoading, timeRangeBar, filteredCompanyIdA, filteredCompanyIdB, offerEntryField, compareCompanies]);
  // 

  const user = props.location.state.user;

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.root}>
      <div className={classes.contactSection}>
      {/* <FormControl className="mr-sm-2 ml-sm-2 mb-sm-2">
          <InputLabel htmlFor="age-native-simple">Time Span</InputLabel>
          <Select
            native
            value={timeRangeBar.Val}
            onChange={handleDateChangeDate}
            inputProps={{
              name: 'name',
              id: 'name-native-simple',
            }}
          >
            <option value={TIME_RANGE.ALL_TIME.Val} key={TIME_RANGE.ALL_TIME.Val}>{TIME_RANGE.ALL_TIME.Name}</option>
            <option value={TIME_RANGE.MONTH_6.Val} key={TIME_RANGE.MONTH_6.Val}>{TIME_RANGE.MONTH_6.Name}</option>
            <option value={TIME_RANGE.MONTH.Val} key={TIME_RANGE.MONTH.Val}>{TIME_RANGE.MONTH.Name}</option>
            <option value={TIME_RANGE.YEAR.Val} key={TIME_RANGE.YEAR.Val}>{TIME_RANGE.YEAR.Name}</option>
          </Select>
        </FormControl> */}
        {false && compareCompanies &&
        <FormControl className="mr-sm-2 ml-sm-2">
          <InputLabel htmlFor="age-native-simple">Offer Type</InputLabel>
          <Select
            native
            value={offerEntryField}
            onChange={handleSetOfferEntryField}
            inputProps={{
              name: 'name',
              id: 'name-native-simple',
            }}
          >
            <option value={OfferEntryField.POINTS}>{GetOfferEntryFieldString(OfferEntryField.POINTS)}</option> 
            <option value={OfferEntryField.COUNT}>{GetOfferEntryFieldString(OfferEntryField.COUNT)}</option> 
          
        
          </Select>
        </FormControl>}
        {/* <FormControl className="mr-sm-2 ml-sm-2">
          <InputLabel htmlFor="age-native-simple">Company</InputLabel>
          <Select
            native
            value={filteredCompanyIdA}
            onChange={handleChangeA}
            inputProps={{
              name: 'name',
              id: 'name-native-simple',
            }}
          >
        {SessionHelper.getCompanyList().filter(x => GetCompanyId(x) != filteredCompanyIdB).map((comp)=> 
            <option key={GetCompanyId(comp)} value={GetCompanyId(comp)}>{GetCompanyName(comp)}</option> 
          
        )}
          </Select>
        </FormControl> */}

        {false && compareCompanies && <FormControl className="mr-sm-2 ml-sm-2">
          <InputLabel htmlFor="age-native-simple">Company 2</InputLabel>
          <Select
            native
            value={filteredCompanyIdB}
            onChange={handleChangeB}
            inputProps={{
              name: 'name',
              id: 'name-native-simple',
            }}
          >
            <option value={0}>{"Select"}</option> 
        {SessionHelper.getCompanyList().filter(x => GetCompanyId(x) != filteredCompanyIdA).map((comp)=> 
            <option key={GetCompanyId(comp)} value={GetCompanyId(comp)}>{GetCompanyName(comp)}</option> 
          
        )}
          </Select>
        </FormControl>
        }
        {/* <FormControlLabel
        className="mr-sm-2 ml-sm-2"
          control={<Checkbox
            checked={compareCompanies}
            onChange={handleCompareCheckChange}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />}
          label={"Compare Data"}
        /> */}
        </div>
        {isLoading &&<Spinner animation="border" variant="primary" />}
        {!isLoading && <Grid container spacing={4} >
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          <LatestOrders userdetails={userDetails} approveTransaction={approveTransaction} />
        </Grid>
        {/* <Grid item lg={12} sm={12} xl={12} xs={12}>
          <OfferActivty offerMap={allOfferMap} companyId={filteredCompanyIdA}/>
        </Grid> */}

        {/* <Grid item lg={6} sm={12} xl={6} xs={12}>
          <DepositsBySite showAllStats={!compareCompanies} offerMap={offerMap} barChartData={barChartDataSet} offerEntryField={offerEntryField} />
        </Grid>

        <Grid item lg={6} sm={12} xl={6} xs={12}>
          <LatestSales  showAllStats={!compareCompanies}
            barChartDataSet={barChartDataSet}
            offerEntryField={offerEntryField}/>
        </Grid> */}

        
      </Grid>
}
    </div>
  );
};

OfferView.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object
};
export default withRouter(OfferView);
