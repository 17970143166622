import React, { useState, useEffect } from "react";
import { Link , withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import WebService from "../../api/WebServices.js";
import { makeStyles } from "@mui/styles";
import {
    Grid,
    Button,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Facebook as FacebookIcon, Google as GoogleIcon } from "icons";

const schema = {
    email: {
        presence: { allowEmpty: false, message: "is required" },
        email: true,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: false, message: "is required" },
        length: {
            maximum: 128
        }
    }
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: "100%"
    },
    grid: {
        height: "100%"
    },
    quoteContainer: {
        [theme.breakpoints.down("md")]: {
            display: "none"
        }
    },
    quote: {
        backgroundColor: theme.palette.neutral,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: "url(/images/auth.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
    },
    quoteInner: {
        textAlign: "center",
        flexBasis: "600px"
    },
    quoteText: {
        color: theme.palette.white,
        fontWeight: 300
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    centerWrapper: {
        backgroundColor: theme.palette.neutral,
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundPosition: "center"
    },
    bio: {
        color: theme.palette.white
    },
    contentContainer: {},
    content: {
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    contentHeader: {
        display: "flex",
        alignItems: "center",
        paddingTop: theme.spacing(5),
        paddingBototm: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    logoImage: {
        marginLeft: theme.spacing(4)
    },
    contentBody: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            justifyContent: "center"
        }
    },
    form: {
        paddingLeft: 100,
        paddingRight: 100,
        paddingBottom: 125,
        flexBasis: 700,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    title: {
        marginTop: theme.spacing(3)
    },
    socialButtons: {
        marginTop: theme.spacing(3)
    },
    socialIcon: {
        marginRight: theme.spacing(1)
    },
    sugestion: {
        marginTop: theme.spacing(2)
    },
    textField: {
        marginTop: theme.spacing(2)
    },
    UnauthorizedAccessButton: {
        margin: theme.spacing(2, 0)
    }
}));

const UnauthorizedAccess = props => {
    const { history } = props;

    const classes = useStyles();

    const [formState, setFormState] = useState({
        isValid: true,
        values: {},
        touched: {},
        errors: {}
    });

    const handleBack = () => {
        history.goBack();
    };

    const [RequestObject, setRequestObject] = useState();

    useEffect(() => {
        
    }, []);


    //
    const fetchUnauthorizedAccessData = () => {
    };

    return (
        <div className={classes.root}>
            <Grid className={classes.grid} container>
                <Grid className={classes.content} item lg={12} xs={12}>
                    <div className={classes.content}>
                        <div className={classes.contentBody}>
                            <div className={classes.centerWrapper}>
                              You're not authorized to access this page 
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

UnauthorizedAccess.propTypes = {
    history: PropTypes.object
};

export default withRouter(UnauthorizedAccess);
