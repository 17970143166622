import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import WebService from "../../../../api/WebServices";
import { SearchInput } from "components";
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const KeywordsToolbar = (props) => {
  const { className, history, ...rest } = props;

  const classes = useStyles();
  const [sendRequest, setSendRequest] = useState(false);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {}, [sendRequest]);

  const handleChange = (event) => {
    event.persist();

    setKeyword(keyword + event.nativeEvent.data);
  };

  return <div {...rest} className={clsx(classes.root, className)}></div>;
};

KeywordsToolbar.propTypes = {
  className: PropTypes.string,
};

export default withMasterAuthorization(KeywordsToolbar);
