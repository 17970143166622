import React from "react";
import { Pie, Doughnut } from "react-chartjs-2";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography,
  colors
} from "@mui/material";

import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import RefreshIcon from "@mui/icons-material/Refresh";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import { NumericFormat } from 'react-number-format';
import {
  GetEntryClientsLabels,
  GetEntryClientsDeposits,
  GetDataObjects,
  GetTEMField,
  GetTransactionEntryFieldString,
  TransactionEntryField
} from "helpers/UserDetailsHelper";

import {
  GetCompanyNameWithId
} from "helpers/CompanyHelper";
import {
  GetClientName
} from "helpers/WebUserTransactionHelper";
import withAuthorization from "../../../../../../helpers/Authorization";

import {
  pieMoneyOptions,
  FormatDataForPie
} from "helpers/ChartHelper"

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  chartContainer: {
    position: "relative",
    height: "300px",
  },
  stats: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  device: {
    textAlign: "center",
    padding: theme.spacing(1),
  },
  deviceIcon: {
    color: theme.palette.icon,
  },
}));

const DepositsBySite = (props) => {
  const { className, transactionMap, barChartData, showAllStats, transactionEntryField, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <IconButton size="small">
            <RefreshIcon />
          </IconButton>
        }
        title={(showAllStats)?"Data For "+GetCompanyNameWithId(barChartData.datasets[0].companyId):GetTransactionEntryFieldString(transactionEntryField) + " by Site"}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut data={FormatDataForPie(transactionMap, barChartData, GetTEMField, colors, false)} options={pieMoneyOptions} />
        </div>
        <div className={classes.stats}>

          {
            barChartData.datasets != null && barChartData.datasets.map(x =>
              <div className={classes.device} key={x.companyId+x.tEFValue}>
              <Typography variant="body1">{x.label}</Typography>
              <Typography style={{ color: x.backgroundColor }} variant="h4">
                {x.labelPrefix+
                ((x.labelFormatCurrency)?new Intl.NumericFormat('en-US', { style: 'currency', currency: 'USD' }).format(GetTEMField(x.companyId, transactionMap, x.tEFValue)):new Intl.NumericFormat('en-US').format(GetTEMField(x.companyId, transactionMap, x.tEFValue)))
                + x.labelSuffix}
              </Typography>
            </div>
            )
          }
        </div>
      </CardContent>
    </Card>
  );
};

DepositsBySite.propTypes = {
  className: PropTypes.string,
  barChartData: PropTypes.any,
  transactionMap: PropTypes.any,
  transactionEntryField: PropTypes.any,
  showAllStats: PropTypes.bool
};

export default withAuthorization(DepositsBySite);
