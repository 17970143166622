import React, { Component } from "react";
import { Route } from "react-router";
import { Layout } from "./components/Layout";
import { Home } from "./components/Home";
import { FetchData } from "./components/FetchData";
import { Counter } from "./components/Counter";
import { UserDetails } from "./components/UserDetails/UserDetails";
import { Chart } from "react-chartjs-2";
import { Router } from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import { ThemeProvider } from "@mui/styles";
import theme from "./theme";
import Routes from "./Routes";


import { chartjs } from "./helpers";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <ThemeProvider theme={theme}>
       
        <>
          <link rel="apple-app-site-association file" href="/assets/apple-app-site-association"  />
        </>
        <Routes />
        {/* <FetchData /> */}
      </ThemeProvider>
    );
  }
}
