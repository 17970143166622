import React, { useState, useEffect } from "react";

import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";

import { SearchInput } from "components";
import DatePicker from "react-datepicker";
import WebService from "../../../../api/WebServices";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import { IconButton, Button } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@mui/material";

import {
  Unknown_INT,
  SignIn_INT,
  ActivatedOffer_INT,
  LinkedBankAccount_INT,
  ProcessedTransaction_INT,
  CashOutRequest_INT,
  AdProcessed_INT,
  TransactionAppliedToOffer_INT,
  FS_OfferMaxedOut_INT,
  FS_PointsEarnedExceededThreshold_INT,
  FS_DuplicateBankAccountLinked_INT,
  FS_DuplicateCreditCardLinked_INT,
  FS_WithdrawDepositDifference_INT,
  FS_BankAccountCountThresholdExceeded_INT,
  FS_LoggedInOutsideUS_INT,
  GetLogTypeName,
  GetDateString,
  GetEventName,
  GetDescription,
  GetType,
  GetId
} from "helpers/LogHelper";
import { GetUserId } from "helpers/UserHelper";
import withAuthorization from "../../../../helpers/Authorization";
const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

const ActivityTable = (props) => {
  const { className, history, user, ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [userId, setUserId] = useState("");
  const criteriaList = [
    SignIn_INT,
    ActivatedOffer_INT,
    LinkedBankAccount_INT,
    ProcessedTransaction_INT,
    CashOutRequest_INT,
    AdProcessed_INT,
    TransactionAppliedToOffer_INT,
    FS_OfferMaxedOut_INT, 
    FS_PointsEarnedExceededThreshold_INT, 
    FS_DuplicateBankAccountLinked_INT,
    FS_WithdrawDepositDifference_INT,
    FS_BankAccountCountThresholdExceeded_INT,
    FS_LoggedInOutsideUS_INT,
    FS_DuplicateCreditCardLinked_INT
  ];

  const handlePageChange = (event, page) => {
    setPage(page);
  };
  var RequestObject = {
    IntTag: GetUserId(user),
    StringTag: "",
  };

  const handleChange = (event) => {
    event.persist();

    setUserId(userId + event.nativeEvent.data);
  };

  const [selectedLogStandingFilter, setSelectedLogStandingFilter] = useState(0);

  useEffect(() => {
    applyAllFilters();
  }, [selectedLogStandingFilter, logs]);

  const handleFilterSelected = (value) => {
    setSelectedLogStandingFilter(value)
};


  useEffect(() => {
    const fetchData = async () => {
      const result = await WebService.GetUserLogs(history, RequestObject);
      if (result != null) {
        console.log(result);
        result.sort(
          (firstLog, secondLog) =>
            (firstLog.DateCreated !== null ? firstLog.DateCreated : 0) -
            (secondLog.DateCreated !== null ? secondLog.DateCreated : 0)
        );
        setLogs(result);
      } else {
        history.push({ pathname: "./sign-in" });
      }
    };
    fetchData();
  }, []);

  const applyAllFilters = () => {
    var allLogsVar = logs;
    console.log("All logs Count "+allLogsVar.length)
    console.log(selectedLogStandingFilter)
    if(selectedLogStandingFilter != 0){
      console.log("user standing filter")
      allLogsVar = allLogsVar.filter(aLog => (GetType(aLog)) == selectedLogStandingFilter)
    }else{
      console.log("No USER filter")
    }
    setFilteredLogs(allLogsVar)
  }

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div>
      <div className={classes.row}>
        <div className={classes.contentHeader}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <Dropdown>

          <Dropdown.Menu onChange = {(event) => handleFilterSelected(event.target.value)}>
          <Dropdown.Item value={0}>All</Dropdown.Item>
            {criteriaList.map((aNum) => (
              <Dropdown.Item value={aNum} key={aNum}>{GetLogTypeName(aNum)}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <DatePicker />
      </div>

      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Timesss Stamp</TableCell>
                    <TableCell>Activity Type</TableCell>
                    <TableCell>Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredLogs.slice(0, rowsPerPage).map((log) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={GetId(log)}
                      onClick={""}
                      selected={""}
                    >
                      <TableCell>{GetDateString(log)}</TableCell>
                      <TableCell>{GetEventName(log)}</TableCell>
                      <TableCell>{GetDescription(log)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={logs.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </CardActions>
      </Card>
    </div>
  );
};

ActivityTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
  history: PropTypes.object,
};

export default withAuthorization(ActivityTable);
