import React, { useEffect, useState } from 'react'
import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Avatar,
  Tooltip,
  TableSortLabel
} from "@mui/material";

import {
  GetId,
  GetCompanyId,
  GetCompanyName,
  GetWord,
  GetCount,
  GetDateLastScanned,
  GetStatus,
  ShowApproveButton,
  ShowDenyButton, ShowRevokeButton
} from '../../../../helpers/KeywordHelper'
import Checkbox from '@mui/material/Checkbox'
import { GetDateCreated } from 'helpers/AdminUserHelper';
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";

import {COLUMNS_TYPE, COLUMNS_SORT, toggleSortFunc, isSortActive} from "helpers/TableHelper"

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
  },
  nameContainer: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: "flex-end"
  },
  buttonsWrapper: {
    textAlign: "right !important"
  },
  deleteButton: {
    backgroundColor: "#B53F51",
    color: "#FFFFFF",
    margin:"5px"
  },
}));

const KeywordTable = props => {
  const { className, Keywords, 
          showSelectBox,
          history, ...rest } = props;
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setPage(0)
    setRowsPerPage(event.target.value);
  };

  const [selectedSort, setSelectedSort] = useState(COLUMNS_SORT.DEFAULT);

  const sortTypeList = [
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR,
    COLUMNS_TYPE.NUM,
    COLUMNS_TYPE.REV_NUM,
    COLUMNS_TYPE.DATE,
    COLUMNS_TYPE.REV_DATE,
    COLUMNS_TYPE.DATE,
    COLUMNS_TYPE.REV_DATE,
    COLUMNS_TYPE.STR,
    COLUMNS_TYPE.REV_STR
  ];

  const dataRetrieveFunctionList = [
    GetCompanyName, 
    GetCompanyName, 
    GetWord, 
    GetWord, 
    GetCount, 
    GetCount, 
    GetDateCreated,
    GetDateCreated,
    GetDateLastScanned,
    GetDateLastScanned,
    GetStatus,
    GetStatus
  ];

  const toggleSort = (columnType) => {
    toggleSortFunc(columnType, selectedSort, Keywords, sortTypeList, dataRetrieveFunctionList, GetId, undefined, setSelectedSort) 
  };

  const handleIsSortActive = (columnType) => {
    return isSortActive(columnType, selectedSort)
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                {Keywords !== undefined && 
                  <TableRow>
                    <TableCell onClick={()=>toggleSort(COLUMNS_SORT.COL1_DESC)}>
                          <Tooltip enterDelay={300} title="Sort">
                            <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL1_ASC)?"asc":"desc"} 
                            active={handleIsSortActive(COLUMNS_SORT.COL1_ASC) || handleIsSortActive(COLUMNS_SORT.COL1_DESC)}>
                            Company
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                        <TableCell  onClick={()=>toggleSort(COLUMNS_SORT.COL2_DESC)}>
                          <Tooltip enterDelay={300} title="Sort">
                            <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL2_ASC)?"asc":"desc"} 
                            active={handleIsSortActive(COLUMNS_SORT.COL2_ASC) || handleIsSortActive(COLUMNS_SORT.COL2_DESC)}>
                            Keyword
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                        <TableCell  onClick={()=>toggleSort(COLUMNS_SORT.COL3_DESC)}>
                          <Tooltip enterDelay={300} title="Sort">
                            <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL3_ASC)?"asc":"desc"} 
                            active={handleIsSortActive(COLUMNS_SORT.COL3_ASC) || handleIsSortActive(COLUMNS_SORT.COL3_DESC)}>
                            Transaction Count
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                        <TableCell  onClick={()=>toggleSort(COLUMNS_SORT.COL4_DESC)}>
                          <Tooltip enterDelay={300} title="Sort">
                            <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL4_ASC)?"asc":"desc"} 
                            active={handleIsSortActive(COLUMNS_SORT.COL4_ASC) || handleIsSortActive(COLUMNS_SORT.COL4_DESC)}>
                            Date Created
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                        <TableCell  onClick={()=>toggleSort(COLUMNS_SORT.COL5_DESC)}>
                          <Tooltip enterDelay={300} title="Sort">
                            <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL5_ASC)?"asc":"desc"} 
                            active={handleIsSortActive(COLUMNS_SORT.COL5_ASC) || handleIsSortActive(COLUMNS_SORT.COL5_DESC)}>
                            Last Scan
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                        <TableCell  onClick={()=>toggleSort(COLUMNS_SORT.COL6_DESC)}>
                          <Tooltip enterDelay={300} title="Sort">
                            <TableSortLabel direction={handleIsSortActive(COLUMNS_SORT.COL6_ASC)?"asc":"desc"} 
                            active={handleIsSortActive(COLUMNS_SORT.COL6_ASC) || handleIsSortActive(COLUMNS_SORT.COL6_DESC)}>
                            Status
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                }
              </TableHead>
              <TableBody>
                  { Keywords !== undefined &&
                    Keywords.slice((page)*rowsPerPage, (rowsPerPage)+(page)*rowsPerPage).map(Keyword => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={GetId(Keyword)}
                      >
                        <TableCell>{GetCompanyName(Keyword)}</TableCell>
                        <TableCell>{GetWord(Keyword)}</TableCell>
                        <TableCell>{GetCount(Keyword)}</TableCell>
                        <TableCell>{GetDateCreated(Keyword)}</TableCell>
                        <TableCell>{GetDateLastScanned(Keyword)}</TableCell>
                        <TableCell>{GetStatus(Keyword)}</TableCell>
                        <TableCell className={classes.buttonsWrapper}>
                          { ShowApproveButton(GetStatus(Keyword)) &&
                            <Button
                               variant="contained"
                               color="primary"
                               onClick={() => props.handleKeywordApproval(GetId(Keyword), true)}
                            >
                              Approve
                            </Button>
                          }
                          { ShowDenyButton(GetStatus(Keyword)) && 
                            <Button
                               className={classes.deleteButton}
                               variant="contained"
                               onClick={() =>
                                  props.openPopupForDenying(Keyword)} >
                              Deny
                            </Button>
                          }

                          { ShowRevokeButton(GetStatus(Keyword)) &&
                            <Button
                               className={classes.deleteButton}
                               variant="contained"
                               onClick={() =>
                                  props.openPopupForDenying(Keyword)} >
                              Revoke
                            </Button>
                          }
                        </TableCell>
                      </TableRow>
                    ))
                  }
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        { Keywords !== undefined &&
          <TablePagination
           component="div"
           count={Keywords.length}
           onChangePage={handlePageChange}
           onChangeRowsPerPage={handleRowsPerPageChange}
           page={page}
           rowsPerPage={rowsPerPage}
           rowsPerPageOptions={[10, 25, 50]}
         />
        }
      </CardActions>
    </Card>
  );
};

KeywordTable.propTypes = {
  className: PropTypes.string,
  Keywords: PropTypes.array.isRequired,
  history: PropTypes.object,
  Keyword: PropTypes.object
};

export default withMasterAuthorization(KeywordTable);
