import moment from "moment";
import { GetStoredImageIsDefault } from "./StoredImageHelper";

export const PresetStandingChangeSuspend = "Please Contact info@betfully.com for more info.";
export const PresetStandingChangeBan = "This account has been banned from BetFully. If you believe this was done in error please contact info@betfully.com to appeal.";

export const None = "Good";
export const Normal = "Good";
export const Suspended = "Suspended";
export const Banned = "Banned";
export const Excluded = "Self-Excluded";

export const None_INT = 0;
export const Normal_INT = 1;
export const Suspended_INT = 2;
export const Banned_INT = 3;
export const Excluded_INT = 4;

export function GetUserStandingName(standingTypeInt){
  switch(standingTypeInt){
    case Normal_INT:
      return Normal;
    case Suspended_INT:
      return Suspended;
    case Banned_INT:
      return Banned;
    case Excluded_INT:
      return Excluded;
  }
  return None;
}

export function GetUserStandingStatus(user){
  return GetUserStandingName(user.UserStanding);
}

export function GetUserStandingStatusInt(user){
  return user.UserStanding;
}

export function GetCurrentProvinceId(user){
  if(user.CurrentProvinceId)
    return user.CurrentProvinceId;
  else
    return 0;
}

export function GetAdminSetLocation(user){
  if(user.AdminSetLocation)
    return user.AdminSetLocation;
  else
    return false;
}

export function GetCashoutEmail(user) {
  if(user.CashoutEmail){
    return user.CashoutEmail;
  }else{
    return "n/a";
  }
}

export function GetName(user) {
  if(user.Name){
    return user.Name;
  }else{
    return "User "+GetUserId(user);
  }
}

export function GetCity(user) {
  if (user.Address && user.Address.City) {
    return user.Address.City;
  } else {
    return "-";
  }
}

export function GetState(user) {
  if (user.Address && user.Address.State) {
    return user.Address.State;
  } else {
    return "-";
  }
}

export function GetCountry(user) {
  if (user.Address && user.Address.Country) {
    return user.Address.Country;
  } else {
    return "-";
  }
}

export function GetEmail(user) {
  if (user.Email && user.Email) {
    return user.Email;
  } else {
    return "-";
  }
}

export function GetUserLocation(user) {
  if (user.UserLocation) {
    return user.UserLocation;
  } else {
    return "-";
  }
}

export function GetUserCity(user) {
  if (user.City) {
    return user.City;
  } else {
    return "-";
  }
}

export function GetUserState(user) {
  if (user.State) {
    return user.State;
  } else {
    return "-";
  }
}

export function GetUserPostalcode(user) {
  if (user.Postalcode) {
    return user.Postalcode;
  } else {
    return 0;
  }
}

export function GetUserStreetNumber(user) {
  if (user.StreetNumber) {
    return user.StreetNumber;
  } else {
    return 0;
  }
}

export function GetUserStreetName(user) {
  if (user.StreetName) {
    return user.StreetName;
  } else {
    return "-";
  }
}

export function GetUserPoints(user) {
  if (user != null && user.Points) {
    return user.Points;
  } else {
    return 0;
  }
}

export function GetTotalPointsEver(user) {
  if (user.TotalPointsEver) {
    return user.TotalPointsEver;
  } else {
    return 0;
  }
}

export function GetUserId(user) {
  if (user.BetfullyUserId && user.BetfullyUserId) {
    return user.BetfullyUserId;
  } else {
    return -1;
  }
}

export function GetSuspensionEndDate(user) {
  console.log("Suspension value: "+user.SuspensionEndDate)
  if (user.SuspensionEndDate) {
    return moment(user.SuspensionEndDate).format("MMM DD, YYYY");
  } else {
    return "-";
  }
}

export function GetCreatedDate(user) {
  if (user.RegistrationDate && user.RegistrationDate) {
    return moment(user.RegistrationDate).format("MMM DD, YYYY");
  } else {
    return "-";
  }
}

export function GetPhone(user) {
  if (user.PhoneNumber && user.PhoneNumber) {
    return user.PhoneNumber;
  } else {
    return "-";
  }
}

export function GetProfileURL(user) {
  if (user.ProfilePictureURL) {
    return user.ProfilePictureURL;
  } else {
    return "-";
  }
}

export function GetInitials(user) {
  if (user.Name) {
    var name = user.Name;
    return name
      .replace(/\s+/, " ")
      .split(" ")
      .slice(0, 2)
      .map((v) => v && v[0].toUpperCase())
      .join("");
  } else {
    return "-";
  }
}

export function GetRecentlyOnline(user) {
  if (user.UserInCache) {
    return user.UserInCache;
  } else {
    return false;
  }
}

export function GetPendingTransactions(user) {
  if (user.ContainsPendingTransaction) {
    return user.ContainsPendingTransaction;
  } else {
    return false;
  }
}
