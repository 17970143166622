import React, { useState } from "react";

import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import {
  Card,
  CardActions,
  IconButton,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableSortLabel,
  Collapse,
  Paper,
  Box,
  Grid,
  colors
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {
  GetOfferId,
  GetTitle,
  GetTitle2,
  GetDescription,
  GetActivationCount,
  GetReward,
  GetCashBackPercentage,
  GetStatus,
  GetDateCreated,
  GetStartDate,
  GetExpirationDate,
  GetOfferURL,
  GetTermsURL,
  GetExtraURL,
  GetCompanyIcon,
  GetTransactionType
} from "helpers/OfferHelper";
import withAuthorization from "../../../../helpers/Authorization";
import SessionHelper from '../../../../helpers/SessionHelper'
import StickyHeadTable from '../../../../helpers/OfferUserListHelper'

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  tableRowHeader: {
    height: 10,
    padding:0
  },
  tableRow: {
    height: 10,
    padding:0,
    backgroundColor: colors.grey[300]
  },
  tableCell: {
    height: 15,
   // width: 150,
    paddingLeft:3,
    paddingRight:3,
    paddingTop:1,
    paddingBottom:1,
  },
  tableCellSmallText: {
    height: 10,
    width: 10,
    textAlign: 'center',
    paddingLeft:1,
    paddingRight:1,
    paddingTop:1,
    paddingBottom:1,
    fontSize: '10pt'
  },
  imageContainer: {
    height: 35,
    width: 50,
    margin: "auto",
    marginBottom: "0px",
    borderRadius: "1px",
    overflow: "hidden",
    display: "flex",
    alignItems: "normal",
    justifyContent: "center",
  },
  imageStoredImage: {
    width: "100%",
    objectFit : "contain"
  },
}));

const OffersTable = (props) => {
  const { className, offers, userCriteriaList, history, dupeOffer, user, ...rest } = props;

  const classes = useStyles();

  const selectedUser = useState();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handleSelectOffer = (product) => {
    history.push({
      pathname: "./offerdetails",
      state: { product: product, userCriteriaList: userCriteriaList },
    });
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };
  const CreateLocationRow = (location) => {
    var provinceList = SessionHelper.getProvinceList();
    if(provinceList != null){
    const foundLocation = provinceList.find(x => x.Id == location.ProvinceId);
      if(foundLocation != null){
        return (
          <Grid container spacing={4}>
                      <Grid align="left" item xs={4}>
                          <div>{foundLocation.Name}</div>
                      </Grid>
                    </Grid>
        )
      }
    }
  }
  function OfferRow(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    return (
      <React.Fragment>
        <TableRow
            className={classes.tableRow}
            key={GetOfferId(row)}
          >
            <TableCell className={classes.tableCell}>
              <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={(event) => handleSelectOffer(row)}
                >
                  Edit
                </Button>
            </TableCell>
            <TableCell>
              {" "}
              <div className={classes.imageContainer}>
                <img
                  alt="Product"
                  className={classes.imageStoredImage}
                  src={GetCompanyIcon(row)}
                />
              </div>
            </TableCell>
            <TableCell className={classes.tableCell}>{GetStatus(row)}</TableCell>
            <TableCell className={classes.tableCell}>{GetTitle(row)+((GetTitle2(row).length > 0)?(" "+GetTitle2(row)):"")}</TableCell>
            <TableCell className={classes.tableCell}>{GetDescription(row)}</TableCell>
            <TableCell className={classes.tableCellSmallText}>{GetTransactionType(row)}</TableCell>
            <TableCell className={classes.tableCellSmallText}>{GetReward(row)}</TableCell>
            <TableCell className={classes.tableCellSmallText}>{GetStartDate(row)}</TableCell>
            <TableCell className={classes.tableCellSmallText}>{GetExpirationDate(row)}</TableCell>
            
            <TableCell className={classes.tableRow}>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container spacing={1}>
            
            <div className="mt-4">
            <Button
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={(event) => dupeOffer(row)}
                >
                  Duplicate Offer
                </Button>
            <Box margin={5}>
                <Typography variant="h4" gutterBottom component="div">
                  Date Created
                </Typography>
                <Paper className={classes.paper}>
                {GetDateCreated(row)}
                </Paper>
              </Box>
              
              <Box margin={5}>
                <Typography variant="h4" gutterBottom component="div">
                  Claimed
                </Typography>
                <Paper className={classes.paper}>
                  {GetActivationCount(row)}
                </Paper>
                <Typography variant="h4" gutterBottom component="div">
                  Completed
                </Typography>
                <Paper className={classes.paper}>
                  {GetActivationCount(row)}
                </Paper>
              </Box>
              

              {GetOfferURL(row) != null &&
              <Box margin={5}>
                <Typography variant="h4" gutterBottom component="div">
                  Offer URL
                </Typography>
                <Paper className={classes.paper}>
                {GetOfferURL(row)}
                
                </Paper>
              </Box>
              }
              {GetTermsURL(row) != null &&
              <Box margin={5}>
                <Typography variant="h4" gutterBottom component="div">
                  Terms URL
                </Typography>
                <Paper className={classes.paper}>
                {GetTermsURL(row)}
                
                </Paper>
              </Box>
              }
              {GetExtraURL(row) != null &&
              <Box margin={5}>
                <Typography variant="h4" gutterBottom component="div">
                  Extra URL
                </Typography>
                <Paper className={classes.paper}>
                {GetExtraURL(row)}
                
                </Paper>
              </Box>
              }
            </div>
              {/* <Box margin={5}>
                <Typography variant="h4" gutterBottom component="div">
                  Test
                </Typography>
                <Paper className={classes.paper}>
                {GetDateCreated(row)}
                </Paper>
              </Box> */}
              <Box margin={5}>
              <StickyHeadTable userTransactions={row.PendingUsers}/>

             
              </Box>
            </Grid>
              {row.OfferLocations != null &&
              <Box margin={5}>
                <Typography variant="h4" gutterBottom component="div">
                  Available Locations
                </Typography>
                <Paper className={classes.paper}>
                {row.OfferLocations.map( location => (
                  CreateLocationRow(location)
                  ))}
                
                </Paper>
              </Box>
              }
              {row.OfferItemExtraRequest != null &&
              <Box margin={5}>
                <Typography variant="h4" gutterBottom component="div">
                  Pending Edit
                </Typography>
                <Paper className={classes.paper}>
                <Grid container spacing={4}>
                  <Grid align="left" item xs={4}>
                      <div><b>Title:</b> {GetTitle(row.OfferItemExtraRequest)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                      <div><b>Title2:</b> {GetTitle2(row.OfferItemExtraRequest)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                    <div><b>Desc:</b>{GetDescription(row.OfferItemExtraRequest)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                    <div><b>Offer Offer URL:</b>{GetOfferURL(row.OfferItemExtraRequest)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                    <div><b>Offer Terms URL:</b>{GetTermsURL(row.OfferItemExtraRequest)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                    <div><b>Offer Terms Hyperlink Text:</b>{GetExtraURL(row.OfferItemExtraRequest)}</div>
                  </Grid>
                  <Grid align="left" item xs={4}>
                      <div><b>Date Requested:</b> {GetDateCreated(row.OfferItemExtraRequest)}</div>
                  </Grid>
                  {row.OfferLocations != null &&
                  <Box margin={5}>
                    <Typography variant="h4" gutterBottom component="div">
                      Locations
                    </Typography>
                    <Paper className={classes.paper}>
                    {row.OfferItemExtraRequest.OfferLocations.map( location => (
                      CreateLocationRow(location)
                      ))}
                    </Paper>
                  </Box>
              }
                </Grid>
                <Button
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={(event) => handleSelectOffer(row)}
                  // onClick={(event) => handleApproveOfferRequest(event, row.OfferItemExtraRequest.Id)}
                >
                  Go To Details
                </Button>

                </Paper>
              </Box>
              }
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow className={classes.tableRowHeader}>
                  <TableCell></TableCell>
                  <TableCell>Icon</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Trans Type</TableCell>
                  <TableCell>Reward</TableCell>
                  <TableCell>Starts</TableCell>
                  <TableCell>Expires</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {offers.slice(0, rowsPerPage).map((offer) => (
                  <OfferRow key={GetOfferId(offer)} row={offer} />
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={offers.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </CardActions>
    </Card>
  );
};

OffersTable.propTypes = {
  className: PropTypes.string,
  offers: PropTypes.array.isRequired,
  history: PropTypes.object,
  user: PropTypes.object,
};

export default withAuthorization(OffersTable);
