import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import WebService from "../../api/WebServices.js";
import { KeywordsToolbar, KeywordsTable } from "./components";
import mockData from "./data";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { SearchInput } from "components";
import { Tooltip } from "@mui/material";
import InfoRounded from "@mui/icons-material/InfoRounded";
import { IconButton } from "@mui/material";
import withMasterAuthorization from "../../helpers/MasterAuthorization";
import withAuthorization from "../../helpers/Authorization";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import CreateKeywordModal from "./components/CreateKeywordModal";
import EditKeywordModal from "./components/EditKeywordModal";
import {GetId, GetWord} from "../../helpers/KeywordHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  addKeyword: {
    marginLeft: "auto"
  }
}));

const CompanyKeywordList = (props) => {
  const classes = useStyles();
  const [myKeywords, setMyKeywords] = useState([]);
  const [showAddKeyword, setShowAddKeyword] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [keywordToUpdate, setKeywordToUpdate] = useState({});

  const { history, ...rest } = props;
  const handleCloseAddKeyword = () => setShowAddKeyword(false);
  const handleShowAddKeyword = () => setShowAddKeyword(true);
  const handleShowEdit = () => setShowEdit(true);
  const handleCloseEdit = () => setShowEdit(false);
  
  const handleCreateKeyword = (keyword) => {
    if(keyword != null) {
      WebService.CreateKeyword(history, keyword)
         .then(data => {
           if(data != null) {
             const allKeywords = myKeywords.slice();
             allKeywords.push(data);
             setMyKeywords(allKeywords)
             handleCloseAddKeyword();
           }else{
             handleCloseAddKeyword()
           }
         });
    }
  }
  
  const RemoveKeyword = (keywordId) => {
    if(keywordId > 0){
      console.log("Removing keyword");
      console.log(keywordId);
      WebService.RemoveKeyword(history, keywordId)
         .then(data => {
           if(data === true){
             console.log("Keyword was correctly removed");
             window.location.reload();
           }else{ 
             alert("There was an error while deleting!")
           }
         });
    }
  }
 
  const openEditPopupWithKeyword = (keyword) => {
    console.log("Keyword value sent is:")
    console.log(GetWord(keyword))
    console.log("and keyword Id is: ");
    console.log(GetId(keyword))
    setKeywordToUpdate(keyword);
    handleShowEdit()
    
    console.log("Keyword to update is: ")
    console.log(GetWord(keywordToUpdate))
  }
  
  const UpdateKeyword = (keywordName, KeywordId) => {
    console.log("Inside the UpdateKeyword function with: Name ");
    console.log(keywordName)
    console.log("and Id: ")
    console.log(KeywordId)
    
    if(KeywordId > 0){
      WebService.ModifyKeyword(history, keywordName, KeywordId)
         .then(data => {
           debugger;
           if (data !== null){
             setMyKeywords(data);
             handleCloseEdit();
           }
         });
    }
  }
  
  useEffect(() => {
    const fetchData = async () => {
      const result = await WebService.GetMyEvents(history, undefined);
      if (result != null) {
        setMyKeywords(result.AllKeywordModels);
      } else {
        history.push({pathname: "./sign-in"});
      }
    };
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      
      <CreateKeywordModal 
         showAddKeyword={showAddKeyword} 
         handleCreateKeyword={handleCreateKeyword}
         handleCloseAddKeyword={handleCloseAddKeyword}
      />
      
      <EditKeywordModal
         showEditKeyword={showEdit}
         updateKeyword={UpdateKeyword}
         handleCloseEdit={handleCloseEdit}
         currentKeywordId={GetId(keywordToUpdate)}
         currentKeyword={GetWord(keywordToUpdate)}
      />
      
      <div className={classes.row}>
        <h4>Transaction Keywords</h4>
        <Tooltip title="List of keywords used when scanning for transactions. Create new keywords. ">
          <IconButton aria-label="?">
            <InfoRounded />
          </IconButton>
        </Tooltip>
        
        <Button className={classes.addKeyword}
                color="primary" 
                size="small" 
                variant="outlined" 
                onClick={handleShowAddKeyword}>
          Add Keyword
        </Button>
      </div>
      <div className={classes.content}>
        <KeywordsTable keywords={myKeywords} 
                       openEditPopupForKeyword={openEditPopupWithKeyword}
                       RemoveKeyword={RemoveKeyword}/>
      </div>
    </div>
  );
};

CompanyKeywordList.propTypes = {
  history: PropTypes.object,
};

export default withAuthorization(CompanyKeywordList);
