import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Button, Grid, createTheme, responsiveFontSizes, 
  ThemeProvider, Typography } from '@mui/material'
import { Form } from "react-bootstrap";

import { SearchInput } from 'components';
import {  KeywordStatusToInt, KeywordStatusToString } from '../../../../helpers/KeywordHelper'
import { Filter } from '@mui/icons-material'
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";
import WebService from "../../../../api/WebServices.js";
import { 
  GetCompanyName,
  GetCompanyId
} from '../../../../helpers/CompanyHelper'
import {Col, Container, Row} from "reactstrap";

const useStyles = makeStyles(theme => ({
  root: {    
    padding: theme.spacing(3),
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  buttonText: {
    whiteSpace: 'nowrap',
    width: 'auto',
    textTransform: 'none'
  },
  nonClickedActiveButton: {
    whiteSpace: 'nowrap',
    width: 'auto',
    textTransform: 'none',
    border: '1px solid #51B53F',
    backgroundColor: '#FFFFFF',
    color: '#51B53F'
  },
  clickedActiveButton: {
    whiteSpace: 'nowrap',
    width: 'auto',
    textTransform: 'none',
    backgroundColor: "#51B53F",
    color: "#FFFFFF",
    border: "none"
  },
  nonClickedPendingButton: {
    whiteSpace: 'nowrap',
    width: 'auto',
    textTransform: 'none',
    border: '1px solid #B5A33F',
    backgroundColor: '#FFFFFF',
    color: '#B5A33F'
  },
  clickedPendingButton:{
    whiteSpace: 'nowrap',
    width: 'auto',
    textTransform: 'none',
    backgroundColor: "#B5A33F",
    color: "#FFFFFF",
    border: "none"
  },
  nonClickedFixButton: {
    whiteSpace: 'nowrap',
    width: 'auto',
    textTransform: 'none',
    border: '1px solid #B53F51',
    backgroundColor: '#FFFFFF',
    color: '#B53F51'
  }, 
  clickedFixButton: {
    whiteSpace: 'nowrap',
    width: 'auto',
    textTransform: 'none',
    backgroundColor: "#B53F51",
    color: "#FFFFFF",
    border: "none"
  },
  wrapperWithoutLeftPadding: {
    marginLeft: '0',
    paddingLeft: '0'
  }
}));



  

const KeywordToolbar = props => {
  const { className, filterUsersBy, onFilterSelected, currentFilter, history, ...rest } = props;
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
        const fetchData = async () => {
            const result = await WebService.GetAllCompanies(history, undefined);
            if (result != null) {
                // Temporary sorting solution:
                result.sort((firstCompany, secondCompany) =>
                GetCompanyName(firstCompany).localeCompare(GetCompanyName(secondCompany)));
                setCompanies(result)
            } else {
            }
        };
        fetchData();
}, []);

  const classes = useStyles();
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search Admin"
          role="button"
        />

        <Form inline>
          <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
            Company 
          </Form.Label>
          <Form.Control
            as="select"
            className="my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            onChange = {(event) => onFilterSelected(event.target.value)}
          >
            <option value={0}>All</option>
            {companies.map((aNum) => (
              <option value={GetCompanyId(aNum)} key={GetCompanyId(aNum)}>{GetCompanyName(aNum)}</option>
            ))}
          </Form.Control>
        </Form>
      </div>
      <br/>
      <Grid container spacing={4}>
        <Grid item lg={9} sm={9} xl={9} xs={9}>
          <Container className={classes.wrapperWithoutLeftPadding}>
            <Row>
              <Col lg="2" md="2" sm="2">
                <Button 
                  className={(currentFilter === KeywordStatusToInt.Active) ? 
                                classes.clickedActiveButton : classes.nonClickedActiveButton}
                  color="primary"
                  onClick={() => filterUsersBy(KeywordStatusToInt.Active)}
                >
                  {KeywordStatusToString.Active + " ("+props.activeCount+")"}
                </Button>
              </Col>
              
              <Col lg="2" md="2" sm="2">
                <Button
                  className={(currentFilter === KeywordStatusToInt.Pending) ? 
                                  classes.clickedPendingButton : classes.nonClickedPendingButton}
                  color="primary"
                  onClick={() => filterUsersBy(KeywordStatusToInt.Pending)}
                >
                 {KeywordStatusToString.Pending + " ("+props.pendingCount+")"} 
                </Button>
              </Col> 
             
              <Col lg="2" md="2" sm="2">
                <Button
                  className={classes.buttonText}
                  color="primary"
                  variant={(currentFilter === KeywordStatusToInt.Removed) ? "contained" : "outlined"}
                  onClick={() => filterUsersBy(KeywordStatusToInt.Removed)}
                >
                  {KeywordStatusToString.Removed + " ("+props.expiredCount+")"}
                </Button>
              </Col>
              
              <Col lg="2" md="2" sm="2">
                <Button
                  className={classes.buttonText}
                  color="primary"
                  variant={(currentFilter === KeywordStatusToInt.Suspended) ? "contained" : "outlined"}
                  onClick={() => filterUsersBy(KeywordStatusToInt.Suspended)}
                >
                  {"Revoked ("+props.suspendedCount+")"}
                </Button>
              </Col>
              
              <Col lg="2" md="2" sm="2">
                <Button
                  className={(currentFilter === KeywordStatusToInt.Fix) ? 
                                classes.clickedFixButton : classes.nonClickedFixButton}
                  variant={(currentFilter === KeywordStatusToInt.Fix) ? "contained" : "outlined"}
                  onClick={() => filterUsersBy(KeywordStatusToInt.Fix)}
                >
                  {KeywordStatusToString.Fix+ " ("+props.revisionCount+")"}
                </Button>
              </Col>
            </Row>
          </Container>
          
        </Grid>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          {" "}
        </Grid>
      </Grid>
    </div>
  );
};

KeywordToolbar.propTypes = {
  className: PropTypes.string
};

export default withMasterAuthorization(KeywordToolbar);
