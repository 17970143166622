import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { Form } from "react-bootstrap";

import { SearchInput } from 'components';
import {
  None_INT,
  Normal_INT,
  Suspended_INT,
  Banned_INT,
  GetName,
  GetEmail,
  GetUserStandingStatus,
  GetUserStandingName,
  GetInitials,
  GetPhone,
  GetCreatedDate,
  GetUserLocation,
  GetUserId,
  GetProfileURL,
} from "helpers/UserHelper";
import withAuthorization from "../../../../helpers/Authorization";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const UsersToolbar = props => {
  const { className,onFilterSelected, 
    filterValue,
    onlineFilterValue,
    onOnlineFilterSelected, 
    onSeachTermSet,
    searchTermValue,
    onPendingFilterSelected,
    pendingFilterValue, ...rest } = props;
  const classes = useStyles();

  const userStandingOptions = [Normal_INT, Suspended_INT, Banned_INT];

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        {/* <Button className={classes.importButton}>Import</Button>
        <Button className={classes.exportButton}>Export</Button>
        <Button
          color="primary"
          variant="contained"
        >
          Add user
        </Button> */}
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search user"
          value={searchTermValue}
          onChange= {(event) => onSeachTermSet(event.target.value)}
        />

        <Form inline>
          <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
            User Standing 
          </Form.Label>
          <Form.Control
            as="select"
            className="my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            value={filterValue}
            onChange = {(event) => onFilterSelected(+event.target.value)}
          >
            <option value={0}>All</option>
            {userStandingOptions.map((aNum) => (
              <option value={aNum} key={aNum}>{GetUserStandingName(aNum)}</option>
            ))}
          </Form.Control>
          <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
            Online Only 
          </Form.Label>
          <Form.Control
            as="select"
            className="my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            value={onlineFilterValue}
            onChange = {(event) => onOnlineFilterSelected(+event.target.value)}
          >
            <option value={0}>No</option>
            <option value={1}>Yes</option>
          </Form.Control>

          <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
            Pending Transactions Only
          </Form.Label>
          <Form.Control
            as="select"
            className="my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            value={pendingFilterValue}
            onChange = {(event) => onPendingFilterSelected(+event.target.value)}
          >
            <option value={0}>No</option>
            <option value={1}>Yes</option>
          </Form.Control>
        </Form>
      </div>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default withAuthorization(UsersToolbar);
