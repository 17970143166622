import moment from "moment";

import { GetName } from "helpers/UserHelper"

export const Unknown = "Unknown";
export const SignIn = "Sign In";
export const ActivatedOffer = "Offer Activated";
export const LinkedBankAccount = "Bank Account Linked";
export const ProcessedTransaction = "Transaction Processed";
export const CashOutRequest = "Cashout Requested";
export const AdProcessed = "Ad Processed";
export const TransactionAppliedToOffer = "Transaction Applied To Offer";
export const FS_OfferMaxedOut = "Suspicious Average Offer Completion Rate";
export const FS_PointsEarnedExceededThreshold = "Suspicious Amount of Points Earned";
export const FS_DuplicateBankAccountLinked = "Linked Bank Account previously found in BetFully";
export const FS_DuplicateCreditCardLinked = "Linked Credit Card previously found in BetFully";
export const FS_WithdrawDepositDifference = "Suspiciously Similar Withdraw and Deposit amounts detected";
export const FS_BankAccountCountThresholdExceeded = "Suspicious Number of Bank Accounts Linked To BetFully";
export const FS_LoggedInOutsideUS = "Logged From IP outside US";

export const Unknown_INT = 0;
export const SignIn_INT = 1;
export const ActivatedOffer_INT = 2;
export const LinkedBankAccount_INT = 3;
export const ProcessedTransaction_INT = 4;
export const CashOutRequest_INT = 5;
export const AdProcessed_INT = 6;
export const TransactionAppliedToOffer_INT = 7;
export const FS_OfferMaxedOut_INT = 8;
export const FS_PointsEarnedExceededThreshold_INT = 9;
export const FS_DuplicateBankAccountLinked_INT = 10;
export const FS_WithdrawDepositDifference_INT = 11;
export const FS_BankAccountCountThresholdExceeded_INT = 12;
export const FS_LoggedInOutsideUS_INT = 13;
export const FS_DuplicateCreditCardLinked_INT = 14;

export function GetLogTypeName(logTypeInt){
  switch(logTypeInt){
    case Unknown_INT:
      return Unknown;
    case SignIn_INT:
      return SignIn;
    case ActivatedOffer_INT:
      return ActivatedOffer;
    case LinkedBankAccount_INT:
      return LinkedBankAccount;
    case ProcessedTransaction_INT:
      return ProcessedTransaction;
    case CashOutRequest_INT:
      return CashOutRequest;
    case AdProcessed_INT:
      return AdProcessed;
    case TransactionAppliedToOffer_INT:
      return TransactionAppliedToOffer;
    case FS_OfferMaxedOut_INT:
      return FS_OfferMaxedOut;
    case FS_PointsEarnedExceededThreshold_INT:
      return FS_PointsEarnedExceededThreshold;
    case FS_DuplicateBankAccountLinked_INT:
      return FS_DuplicateBankAccountLinked;
    case FS_WithdrawDepositDifference_INT:
      return FS_WithdrawDepositDifference;
    case FS_BankAccountCountThresholdExceeded_INT:
      return FS_BankAccountCountThresholdExceeded;
    case FS_LoggedInOutsideUS_INT:
      return FS_LoggedInOutsideUS;
    case FS_DuplicateCreditCardLinked_INT:
      return FS_DuplicateCreditCardLinked;
  }
  return Unknown;
}

export function GetId(log) {
  if (log.Id) {
    return log.Id;
  } else {
    return 0;
  }
}

export function GetWebUser(log){
  if(log.WebUser){
    return log.WebUser;
  }else{
    return null;
  }
}

export function GetBetfullyUserId(log) {
  if (log.BetfullyUserId) {
    return log.BetfullyUserId;
  } else {
    return 0;
  }
}

export function GetType(log) {
  if (log.Event) {
    return log.Event;
  } else {
    return 0;
  }
}

export function GetEventName(log) {
  if (log.EventName) {
    return log.EventName;
  } else {
    return "n/a"
  }
}

export function GetDescription(log) {
  if (log.Description) {
    return log.Description;
  } else {
    return "n/a";
  }
}


export function GetDateString(log){
  if (log.DateCreated) {
    return moment(log.DateCreated).format("MMM DD, YYYY");
  } else {
    return "-";
  }
}

export function GetDate(log) {
  if (log.DateCreated) {
    return moment(log.DateCreated, "YYYY-MM-DD HH:mm:ss").format("MM/DD/YY  HH:mm:ss");
  } else {
    return "n/a";
  }
}

export function GetValue1(log) {
  if (log.Value1) {
    return log.Value1;
  } else {
    return 0;
  }
}

export function GetValue2(log) {
  if (log.Value2) {
    return log.Value2;
  } else {
    return 0;
  }
}

export function GetUserName(log){
  if (GetWebUser(log)) {
    return GetName(GetWebUser(log));
  } else {
    return "n/a";
  }
}
