import WebService from "../api/WebServices";
import {
  GetCompanyName
} from "helpers/CompanyHelper";

import {COLUMNS_SORT} from "helpers/TableHelper"

import {GetDefaultConfig} from "helpers/ConfigHelper"

var SessionHelper = (function() {
  var full_name = sessionStorage.getItem("userEmail");
  var user_role = sessionStorage.getItem("userRole");
  var user_icon = sessionStorage.getItem("userIcon");
  var user_company = sessionStorage.getItem("userCompany");
  var user_companyId = sessionStorage.getItem("userCompanyId");
  var company_list = sessionStorage.getItem("companyList");
  var parsed_company_list = undefined;
  var province_list = sessionStorage.getItem("provinceList");
  var parsed_province_list = undefined;
  var data_retrieved = sessionStorage.getItem("dataRetrieved");
  var config_userlist = sessionStorage.getItem("config_userlist");
  var parsed_config_userlist = undefined;

  var dataRetrieved = function() {
    return data_retrieved != null;
  };

  var setDataRetrieved = function(wasRetrieved) {
    if(wasRetrieved == false){
      sessionStorage.removeItem("dataRetrieved");  
      data_retrieved = undefined;
    }
    else
      sessionStorage.setItem("dataRetrieved", "yes");
  };

  var getName = function() {
    return full_name;    // Or pull this from cookie/localStorage
  };

  var setName = function(name) {
    full_name = name;     
    // Also set this in cookie/localStorage
    sessionStorage.setItem("userEmail", full_name);
  };

  var getRole = function() {
    return user_role;
  };

  var setRole = function(role) {
    user_role = role;     
    sessionStorage.setItem("userRole", user_role);
  };

  var getIcon = function() {

    const authLevel = WebService.getUserAuthorization();
    if(isMasterAdmin()){
      return "/assets/betfully-icon.png"
    }

    if(user_icon === undefined || user_icon == null)
      return null
    return user_icon;
  };

  var setIcon = function(icon) {
    user_icon = icon;     
    sessionStorage.setItem("userIcon", user_icon);
  };

  var getCompany = function() {
    const authLevel = WebService.getUserAuthorization();
    if(isMasterAdmin()){
      return ""
    }

    return user_company;    
  };

  var isMasterAdmin = function(){
    const authLevel = WebService.getUserAuthorization();
    return authLevel === "MasterAdmin" && user_companyId == 0
  }

  var setCompany = function(name) {
    user_company = name;     
    sessionStorage.setItem("userCompany", user_company);
  };

  var getCompanyId = function() {
    const authLevel = WebService.getUserAuthorization();
    if(isMasterAdmin()){
      return 0
    }

    return (user_companyId == null)?0:user_companyId;    
  };

  var setCompanyId = function(name) {
    user_companyId = name;     
    sessionStorage.setItem("userCompanyId", user_companyId);
  };

  var getCompanyList = function() {
    if(parsed_company_list === undefined || parsed_company_list == null)
      parsed_company_list = (company_list !== undefined)?JSON.parse(company_list):[];

    if(company_list === undefined){
      
    }

    if(parsed_company_list != null){
      parsed_company_list.sort(function(a, b) {
        return GetCompanyName(a).localeCompare(GetCompanyName(b));
     });
    }
    
    return parsed_company_list;
  };

  var setCompanyList = function(companyList) {
    parsed_company_list = companyList;
    company_list = JSON.stringify(companyList);     
    sessionStorage.setItem("companyList", company_list);
  };

  var getProvinceList = function() {

    if(parsed_province_list === undefined || parsed_province_list == null)
      parsed_province_list = (province_list !== undefined)?JSON.parse(province_list):[];

    if(province_list === undefined){
    }
    
    return parsed_province_list;
  };

  var setProvinceList = function(provinceList) {
    parsed_province_list = provinceList;
    province_list = JSON.stringify(provinceList);
    sessionStorage.setItem("provinceList", province_list);
  };

// var config_userlist = sessionStorage.getItem("config_userlist");
  // var parsed_config_userlist = undefined;

  var getUserListConfig = function(){
    if(parsed_config_userlist === undefined || parsed_config_userlist == null)
      parsed_config_userlist = (config_userlist !== undefined)?JSON.parse(config_userlist):null;

    if(parsed_config_userlist == null){
      //These are the default values for the configuration of the user list page
      parsed_config_userlist = GetDefaultConfig();
    }

    return parsed_config_userlist;
  }

  var setUserListConfig = function() {
    // parsed_config_userlist = configObj;
    config_userlist = JSON.stringify(parsed_config_userlist);
    sessionStorage.setItem("config_userlist", config_userlist);
  };

  return {
    dataRetrieved: dataRetrieved,
    setDataRetrieved : setDataRetrieved,
    getName: getName,
    setName: setName,
    getRole: getRole,
    setRole: setRole,
    getIcon: getIcon,
    setIcon: setIcon,
    getCompany: getCompany,
    setCompany: setCompany,
    getCompanyId: getCompanyId,
    setCompanyId: setCompanyId,
    setCompanyList: setCompanyList,
    getCompanyList: getCompanyList,
    setProvinceList: setProvinceList,
    getProvinceList: getProvinceList,
    getUserListConfig: getUserListConfig,
    setUserListConfig: setUserListConfig,
    isMasterAdmin: isMasterAdmin
  }

})();

export default SessionHelper;