import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, IconButton, Button } from "@mui/material";
/*
import WebService from "../../api/WebServices.js";
*/
import { Link as RouterLink, withRouter } from "react-router-dom";

import SessionHelper from "helpers/SessionHelper";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";
import withAuthorization from "../../helpers/Authorization";
import myiphone from "../../assets/apple-app-site-association.json"

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(4),
    },
    row: {
        height: "42px",
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(1),
    },
    spacer: {
        flexGrow: 1,
    },
    importButton: {
        marginRight: theme.spacing(),
    },
    exportButton: {
        marginRight: theme.spacing(1),
    },
}));

const MyRedirect = (props) => {
    const classes = useStyles();
    const { history } = props;
    

    const [Widgets, setWidgets] = useState([]);
    

    /*var RequestObject = {
        IntTag: offer.Id,
    };*/

    const theme = useTheme();

    const handleBack = () => {
        history.goBack();
    };
    /*const handleEditOffer = (offer) => {
        console.log("EDIT OFFER: "+offer.StartDate)
        history.push({
            pathname: "./EditOffer",
            state: { offer: offer, criteriaList: criteriaList },
        });
    };

    const handleDeleteOffer = (offer) => {
        setDeleteOffer(true);
    };

    const deleteOfferCall = async () => {
        const result = await WebService.RemoveOffer(history, RequestObject);
        if (result != null) {
            history.push({ pathname: "./Offers" });
        } else {
            history.push({ pathname: "./sign-in" });
        }
    };
*/
    /*const fetchData = async () => {
        const result = await WebService.GetMyWidgets(history, RequestObject);
        if (result != null) {
            setWidgets(result);
            setBarChartDataSet(GenerateDataForChart(SessionHelper.getCompanyId() != 0,result.UserTransactionEntries, SessionHelper.getCompanyList(), TIME_RANGE.YEAR.Count,TIME_RANGE.YEAR.DateUnits,  null))

        } else {
            history.push({ pathname: "./sign-in" });
        }
    };*/

    /*useEffect(() => {
        if (deleteOffer) {
            deleteOfferCall();
        }
    }, [deleteOffer]);

    useEffect(() => {
        fetchData();
    }, []);*/

    return (
        <div className={classes.root}>
            <pre> { JSON.stringify(myiphone, null, 4)}</pre>
                
        </div>
    );
};

MyRedirect.propTypes = {
    history: PropTypes.object,
};
export default withRouter(MyRedirect);
