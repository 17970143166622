import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import WebService from '../../../../api/WebServices';
import PropTypes from "prop-types";
import PaypalPayoutItemTable from './components';
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  }
}));

const PaypalPayoutItemList = props => {
  const { history, paypalPayoutBatchId ,...rest } = props;
  const classes = useStyles();
  const [paypalItems, setPaypalPayoutItems] = useState([]);
  
  
  useEffect(() => {
    const fetchData = async () => {
      const result = await WebService.GetPaypalBatchPayoutDetails(history, paypalPayoutBatchId);
      if(result != null){
        setPaypalPayoutItems(result);
      }else{
        history.push({ pathname: "./sign-in" });
      }
    };
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      
      <div className={classes.content}>
        <PaypalPayoutItemTable
          PaypalPayoutItems={paypalItems}
        />
      </div>
      
    </div>
  );
};

PaypalPayoutItemList.propTypes = {
  history: PropTypes.object
};

export default withMasterAuthorization(PaypalPayoutItemList);