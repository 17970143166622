import React, {useEffect, useState} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Divider, Drawer } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ViewListIcon from '@mui/icons-material/ViewList';
import PeopleIcon from "@mui/icons-material/People";
import ReceiptIcon from '@mui/icons-material/Receipt';
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import BusinessIcon from '@mui/icons-material/Business';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import SecurityIcon from '@mui/icons-material/Security';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import WebService from "../../../../api/WebServices";
import { Profile, SidebarNav, UpgradePlan } from "./components";
import SessionHelper from 'helpers/SessionHelper'
import { Fireplace } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();
   
  const [pages, SetPages] = useState([
    {
      title: "Dashboard",
      href: "/dashboard",
      icon: <DashboardIcon />,
    },
    
    {
      title: "Users",
      href: "/users",
      icon: <PeopleIcon />,
    },
    
    {
      title: "User Activity",
      href: "/useractivitysearch",
      icon: <DataUsageIcon />,
    },
    {
      title: "Settings",
      href: "/settings",
      icon: <SettingsIcon />,
    },
    
    {
      title: "Sign Out",
      href: "/sign-in",
      icon: <ExitToAppIcon />,
    },
  ]);

  const GetAdminPagesIfAuthorized = () => {
    const authLevel = WebService.getUserAuthorization();
    if(!SessionHelper.isMasterAdmin()){
      return [
        {
          title: "Keywords",
          href: "/Keywords",
          icon: <ViewListIcon/>
        },
        {
          title: "Offers",
          href: "/offers",
          icon: <LocalOfferIcon />,
        }
      ];
    }
    return undefined;
  }

  const GetMasterAdminPagesIfAuthorized = () => {
    const authLevel = WebService.getUserAuthorization();
    // console.log("AVFISDFMNSO Sidebarr" + authLevel);
    if(SessionHelper.isMasterAdmin()){
      // console.log("AVFISDFMNSO Sidebarr2" + authLevel);
      return [
        {
          title: "Wildfire",
          href: "/wildfire",
          icon: <LocalFireDepartmentIcon />,
        },
        {
          title: "FraudShield",
          href: "/FraudShieldView",
          icon: <SecurityIcon />,
        },
         {
          title: "Payouts",
          href: "/UserPayouts",
          icon: <AttachMoneyIcon />,
        },
        {
          title: "MasterKeywordList",
          href: "/MasterKeyword",
          icon: <ViewListIcon />,
        },
        {
          title: "Paypal Data",
          href: "/PaypalWebhooks",
          icon: <TrendingUpIcon />,
        },
        {
          title: "Admins",
          href: "/admins",
          icon: <SupervisedUserCircleIcon />,
        },
        {
          title: "Companies",
          href: "/companies",
          icon: <BusinessIcon />,
        },
        {
          title: "Banners",
          href: "/banners",
          icon: <ViewCarouselIcon />,
        },
        {
          title: "StoredImages",
          href: "/storedImages",
          icon: <PictureInPictureIcon />,
        },
        {
          title: "Offers: All",
          href: "/adminOffersView",
          icon: <LocalOfferIcon />,
        }
      ];
    }
    return undefined;
  }
  
  const GetFullList = () => {
   const pageList = [...pages];
   const masterAdminList = GetMasterAdminPagesIfAuthorized();
   if(masterAdminList !== undefined){
     const midIndex = pageList.length / 2
     pageList.splice(midIndex, 0, ...masterAdminList);
   }

   const regAdminList = GetAdminPagesIfAuthorized();
   if(regAdminList !== undefined){
    const midIndex = pageList.length / 2
    pageList.splice(midIndex, 0, ...regAdminList);
   }

   return pageList; 
  }
  
  useEffect(() => {
    SetPages(GetFullList());
    
  }, []);
  
  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
