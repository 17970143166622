import moment from "moment";
import { NumericFormat } from 'react-number-format';
import React from "react";

export function GetUserOfferItemId(entry){
  if(entry.UserOfferItemId){
    return entry.UserOfferItemId
  } else{
    return 0
  }
}

export function GetOfferItemId(entry){
  if(entry != null && entry.OfferItemId){
    return entry.OfferItemId
  } else{
    return 0
  }
}

export function GetAmountProcessed(entry){
  if(entry.AmountProcessed){
    return entry.AmountProcessed
  } else{
    return 0
  }
}

export function GetClient(entry){
  if(entry.Client){
    return entry.Client
  } else{
    return 0
  }
}

export function GetCashBackPercentage(entry){
  if(entry.CashBackPercentage){
    return entry.CashBackPercentage
  } else{
    return 0
  }
}

export function GetOfferItemCreatedDate(entry) {
  if (entry.OfferItemCreatedDate) {
    return moment(entry.OfferItemCreatedDate, "YYYY-MM-DD").format("MM/DD/YY");
  } else {
    return "";
  }
}

export function GetOfferItemStartDate(entry) {
  if (entry.StartDate) {
    return moment(entry.StartDate, "YYYY-MM-DD").format("MM/DD/YY");
  } else {
    return "";
  }
}

export function GetOfferItemExpireDate(entry) {
  if (entry.ExpirationDate) {
    return moment(entry.ExpirationDate, "YYYY-MM-DD").format("MM/DD/YY");
  } else {
    return "";
  }
}

export function GetPointsEarned(entry){
  if(entry.PointsEarned){
    return entry.PointsEarned
  } else{
    return 0
  }
}

export function GetPointsEarnedFormatted(entry){
  return <NumericFormat decimalScale={2} fixedDecimalScale={true} value={GetPointsEarned(entry)} displayType={'text'} thousandSeparator={true} prefix={''} />
}
