import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import WebService from "../../api/WebServices.js";
import PropTypes from "prop-types";
import WildfireTable from './Components/WildfireTable'
import CompaniesToolbar from './Components/CompaniesToolbar'
import WildFireTabs from './Components/WildfireTabs'
import withMasterAuthorization from "../../helpers/MasterAuthorization";
import ImageUploader from 'react-images-upload';
import { storage } from "../../firebase";
import ImagePreviewGrid from '../StoredImageList/Components/ImagePreviewGrid'
import { EXPIRED_TOKEN } from 'constants/constants'
import { 
    GetCompanyId,
    GetCompanyName,
    GetURL, 
    GetDateCreated,
    GetImageURL,
    GetCompanyStoredImageId
  } from '../../helpers/CompanyHelper'
import SessionHelper from "helpers/SessionHelper";
import Modal from 'react-bootstrap/Modal';
import Spinner from "react-bootstrap/Spinner";
import Form from 'react-bootstrap/Form';
import {
    Button
  } from "reactstrap";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    imageContainer: {
        height: 100,
        width: 300,
        margin: "0 auto",
        marginBottom: "20px",
        borderRadius: "1px",
        overflow: "hidden",
        display: "flex",
        alignItems: "normal",
        justifyContent: "center",
      },
      image: {
        width: "100%",
        objectFit : "contain"
      }
}));

const WildfireAdmin = props => {
    const { history, ...rest } = props;
    const classes = useStyles();
    const [companies, setCompanies] = useState([]);
    const [domains, setDomains] = useState([]);
    const [commissions, setCommissions] = useState([]);

    const [closeEditCompany, setCloseEditCompany] = useState(false)
    const [showEditCompany, setShowEditCompany] = useState(false);
    const handleCancelEditCompany = () => setShowEditCompany(false);
    const handleCloseEditCompany = () => {setCloseEditCompany(!closeEditCompany); setShowEditCompany(false);}
    const handleShowEditCompany = (theCompany) => {
        console.log("The Company: " + theCompany.Name);
        setCompanyIdInput(GetCompanyId(theCompany));
        setCompanyStoredImageId(GetCompanyStoredImageId(theCompany))
        setCompanyInput(GetCompanyName(theCompany));
        setCompanyUrlInput(GetURL(theCompany));
        setCompanyImgInput(GetImageURL(theCompany));
        setShowEditCompany(true);
    }

    const [showViewStoredImages, setShowViewStoredImages] = useState(false);
    const handleCloseViewStoredImages = () => setShowViewStoredImages(false);
    const handleShowViewStoredImages = () => {
        setShowViewStoredImages(true);
    }
    const onImageSelected = (imageId, imageUrl) => {
        console.log("Image selected Id: "+ imageId)
        setCompanyImgInput(imageUrl);
        setCompanyStoredImageId(imageId);
        handleCloseViewStoredImages();
    }

    const [CompanyInput, setCompanyInput] = useState("");
    const [CompanyIdInput, setCompanyIdInput] = useState(0);
    const [CompanyUrlInput, setCompanyUrlInput] = useState("");
    const [CompanyImgInput, setCompanyImgInput] = useState("");
    const [CompanyStoredImageId, setCompanyStoredImageId] = useState(0);
    const [CompanyCategory, setCompanyCategory] = useState(5)
    const [isLoading, setIsLoading] = useState(false);
    const [picturesInput, setPictures] = useState([]);

    const onDrop = (picture, something) => {
        console.log("Image: "+picture);
        var image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = something;
        //Validate the File Height and Width.
        image.onload = function () {
            var height = this.height;
            var width = this.width;



            if (height/width > 1.1 || height/width < 0.9) {
            alert("Recommended Ratio for images is 1:1");
            return false;
            }
            return true;
        };

        setPictures(
            picturesInput.concat(picture)
        );
    }

    const [showAddCompany, setShowAddCompany] = useState(false);
    const handleCloseAddCompany = () => setShowAddCompany(false);
    const handleShowAddCompany = () => {
        setShowAddCompany(true);
        setCompanyInput("");
        setCompanyIdInput(0);
        setCompanyStoredImageId(0);
        setCompanyUrlInput("");
        setCompanyImgInput("");
    }

    const handleCreateCompany = () => {
        console.log("Send Company")
        console.log("Company request:");
        console.log(CompanyInput);
        if(CompanyInput != null) {
            setIsLoading(true);
            WebService.CreateCompany(history, CompanyInput, CompanyUrlInput, null, CompanyStoredImageId, CompanyCategory)
                .then(data => {
                  console.log("ABC DONE");
                  console.log("Data: "+data);
                  if(data != null) {
                    SessionHelper.setCompanyList(data);
                    handleCloseAddCompany();
                    window.location.reload();
                  }else{
                    handleCloseAddCompany();
                    setIsLoading(false);
                  }
                });
        }
    }

    const handleEditCompany = () => {
        console.log("Edit Company")
        console.log(CompanyInput);
        if(CompanyInput != null) {
          WebService.EditCompany(history, CompanyIdInput, CompanyInput, CompanyUrlInput, 
                        CompanyImgInput, CompanyStoredImageId, CompanyCategory)
            .then(data => {
              console.log("ABC DONE");
              console.log("Data: "+data);
              if(data != null) {
                SessionHelper.setCompanyList(data);
                handleCloseEditCompany();
              }else{
                setShowEditCompany(false)
              }
            });
        }
    }
    
    const handleDeleteCompany = () => {
        if(CompanyIdInput  > 0) {
            setIsLoading(true);
            WebService.DeleteCompany(history, CompanyIdInput)
                .then(data => {
                  if(data != null) {
                    SessionHelper.setCompanyList(data);
                    handleCloseEditCompany();
                    window.location.reload();
                  }else{
                    handleCloseEditCompany();
                    setIsLoading(false);
                  }
            });
        }
    }

    const myChangeHandler = (event) => {
        console.log("Name:"+event.target.name);
        console.log("Value:"+event.target.value);
        let nam = event.target.name;
        let val = event.target.value;

        if(nam === 'companyNameInput'){
            setCompanyInput(val);
        }else if(nam === 'companyUrlInput'){
            setCompanyUrlInput(val);
        }else if(nam === 'companyImgInput'){
            setCompanyImgInput(val);
        }else if(nam === 'companyCategory'){
            setCompanyCategory(val);
        }
        
    }

    useEffect(() => {
        console.log("using use effect");
            const fetchData = async () => {
                const result = await WebService.GetAllCompanies(history, undefined);
                if(result != null && result == EXPIRED_TOKEN){
                    history.push({pathname: "./sign-in"});
                }
                if (result != null) {
                    // Temporary sorting solution:
                    result.sort((firstCompany, secondCompany) =>
                    GetCompanyName(firstCompany).localeCompare(GetCompanyName(secondCompany)));
                    setCompanies(result)
                } else {
                    history.push({pathname: "./sign-in"});
                }
            };
            fetchData();
    }, [closeEditCompany]);

    useEffect(() => {
        console.log("using use effect");
            const fetchData = async () => {
                const result = await WebService.GetAllDomains(history, undefined);
                if(result != null && result == EXPIRED_TOKEN){
                    history.push({pathname: "./sign-in"});
                }
                if (result != null) {
                    // Temporary sorting solution:
                    // result.sort((firstCompany, secondCompany) =>
                    // GetCompanyName(firstCompany).localeCompare(GetCompanyName(secondCompany)));
                    setDomains(result)
                } else {
                    history.push({pathname: "./sign-in"});
                }
            };
            fetchData();
    }, []);

    useEffect(() => {
        console.log("using use effect");
            const fetchData = async () => {
                const result = await WebService.GetAllCommissions(history, undefined);
                if(result != null && result == EXPIRED_TOKEN){
                    history.push({pathname: "./sign-in"});
                }
                if (result != null) {
                    // Temporary sorting solution:
                    // result.sort((firstCompany, secondCompany) =>
                    // GetCompanyName(firstCompany).localeCompare(GetCompanyName(secondCompany)));
                    setCommissions(result)
                } else {
                    history.push({pathname: "./sign-in"});
                }
            };
            fetchData();
    }, []);

    return (
      <div className={classes.root}>
          {(isLoading) && <Spinner animation="border" variant="primary" />}

          {(!isLoading) &&
              <div>
                  {/* <CompaniesToolbar history={history} openAddCompanyPopup={handleShowAddCompany}/> */}
                  <WildFireTabs Domains={domains} Commissions={commissions}/>
                  {/* <div className={classes.content}>
                      <WildfireTable Companies={companies} openEditCompanyPopup={handleShowEditCompany}/>
                  </div> */}
              </div>
          }
      </div> 
    );
};

WildfireAdmin.propTypes = {
    history: PropTypes.object
};

export default withMasterAuthorization(WildfireAdmin);
