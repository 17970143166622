import uuid from "uuid/v1";

export default [
  {
    id: uuid(),
    ref: "CDD1049",
    amount: 103,
    customer: {
      name: "5% Cash Back, New Users"
    },
    createdAt: 1555016400000,
    status: "Active"
  },
  {
    id: uuid(),
    ref: "CDD1048",
    amount: 509,
    customer: {
      name: "2% Cash Back, Existing Users"
    },
    createdAt: 1555016400000,
    status: "Active"
  },
  {
    id: uuid(),
    ref: "CDD1047",
    amount: 1332,
    customer: {
      name: "10% Cash Back, New users"
    },
    createdAt: 1554930000000,
    status: "Cancelled"
  },
  {
    id: uuid(),
    ref: "CDD1046",
    amount: 3065,
    customer: {
      name: "10% Cash Back, New users"
    },
    createdAt: 1554757200000,
    status: "Expired"
  },
  {
    id: uuid(),
    ref: "CDD1045",
    amount: 97,
    customer: {
      name: "10% Cash Back, New users"
    },
    createdAt: 1554670800000,
    status: "Expired"
  }
];
