import moment from "moment";
import { NumericFormat } from 'react-number-format';
import React from "react";
import { MaxDepositValues, CashBackValues, MaxDaysValues, InEqualityValues, MaxTransactionValues, MinDepositValues, 
  OfferTransactionTypeValues, UserActionTypeValues, RewardTypeValues, GetActionTypeFromOfferType, GetRewardTypeFromOfferType } from "../constants/constants.js";
import {
    Grid,
    IconButton,
    TextField,
    Link,
    Typography,
    FormGroup,
    FormControlLabel,
    Checkbox
  } from "@mui/material";
  import {
    ButtonGroup,
    Button,
    Card,
    CardBody,
    CardText,
    CardGroup,
    CardTitle,
  } from "reactstrap";
  import DatePicker from "react-datepicker";
  import { Dropdown, Modal, Spinner } from "react-bootstrap";
  import { ProductCardPreview } from "../views/ProductList/components";
  import SessionHelper from "../helpers/SessionHelper";
  export function GetOfferId(product) {
  if (product !== undefined && product.Id) {
    return product.Id;
  } else {
    return -1;
  }
}

export function GetActivationCount(product) {
  if (product !== undefined && product.ActivationCount) {
    return product.ActivationCount;
  } else {
    return "N/A";
  }
}

export function GetTitle(product) {
  if (product !== undefined && product.Title) {
    return product.Title;
  } else {
    return "";
  }
}

export function GetTitle2(product) {
  if (product !== undefined && product.Title2) {
    return "";
  } else {
    return "";
  }
}

export function GetDescription(product) {
  if (product !== undefined && product.Description) {
    return product.Description;
  } else {
    return "";
  }
}

export function GetClientName(product) {
  if (product !== undefined && product.ClientName) {
    return product.ClientName;
  } else {
    return "";
  }
}

export function GetOfferURL(product) {
  if (product !== undefined && product.OfferURL) {
    return product.OfferURL;
  } else {
    return "";
  }
}

export function GetOfferTermsSummary(product) {
  if (product !== undefined && product.TermsSummary) {
    return product.TermsSummary;
  } else {
    return "";
  }
}

export function GetTermsURL(product) {
  if (product !== undefined && product.TermsURL) {
    return product.TermsURL;
  } else {
    return "";
  }
}

export function GetExtraURL(product) {
  if (product !== undefined && product.ExtraURL) {
    return product.ExtraURL;
  } else {
    return "";
  }
}

export function GetDateCreated(product) {
  if (product !== undefined && product.DateCreated) {
    return moment(product.DateCreated).format("MMM DD, YYYY");
  } else {
    return "N/A";
  }
}

export function GetStartDate(product) {
  if (product !== undefined && product.StartDate) {
    return moment(product.StartDate).format("MMM DD, YYYY");
  } else {
    return moment('2016-03-12 13:00:00');
  }
}

export function GetPreviousVersions(product){
  if(product !== undefined && product.OfferItemHistories){
    return product.OfferItemHistories;
  }else{
    return [];
  }
}

export function GetFirstPreviousVersionDate(product){

  if(product !== undefined && product.OfferItemHistories && product.OfferItemHistories.length > 0){
    var latestDate = new Date(Math.max.apply(null, product.OfferItemHistories.map(function(e) {
      return new Date(e.DateReplaced);
    })));
    return moment(latestDate).format('MMMM Do YYYY, h:mm:ss a');
  }else{
    return 'n/a';
  }
}

export function GetReplacedDate(product){
  if(product !== undefined && product.DateReplaced){
    
    return moment(product.DateReplaced).format('MMMM Do YYYY, h:mm:ss a');
  }else{
    return "n/a"
  }
}

export function GetReplacedId(product) {
  if (product !== undefined && product.DateReplaced && product.Id) {
    return product.Id;
  } else {
    return 0;
  }
}

export function GetExpirationDate(product) {
  if (product !== undefined && product.ExpirationDate) {
    return moment(product.ExpirationDate).format("MMM DD, YYYY");
  } else {
    return "N/A";
  }
}

export function GetOfferCriteriaCount(product){
  if(product !== undefined && product.OfferCriteria){
    return product.OfferCriteria.length
  }else{
    return 0
  }
  
}

export function GetReward(product) {
  if (product !== undefined && product.Reward) {
    return <NumericFormat decimalScale={0} fixedDecimalScale={true} value={product.Reward} displayType={'text'} thousandSeparator={true} prefix={''} />;
  } else {
    return "N/A";
  }
}

export function GetCashBackPercentage(product) {
  if (product !== undefined && product.CashbackPercent) {
    return product.CashbackPercent*100;
  } else {
    return "N/A";
  }
}

export function GetCompanyIcon(product) {
  if (product !== undefined && product.ClientIcon) {
    return product.ClientIcon;
  } else {
    return "/assets/betfully-icon.png";
  }
}

export function GetSmallIcon(product) {
  if (product !== undefined && product.SmallLogoUrl) {
    return product.SmallLogoUrl;
  } else {
    return "/assets/betfully-icon.png";
  }
}

export function GetBackgroundImage(product) {
  if (product !== undefined && product.BackgroundImageUrl) {
    return product.BackgroundImageUrl;
  } else {
    return "/assets/betfully-icon.png";
  }
}

export function GetStoredImageId(product) {
  if (product !== undefined && product.StoredImageId) {
    return product.StoredImageId;
  } else {
    return 0;
  }
}

export function GetBackgroundImageId(product) {
  if (product !== undefined && product.BackgroundImageId) {
    return product.BackgroundImageId;
  } else {
    return 0;
  }
}

export function GetSmallLogoId(product) {
  if (product !== undefined && product.SmallLogoId) {
    return product.SmallLogoId;
  } else {
    return 0;
  }
}

export function GetOfferRequirementItems(product) {
  if (product !== undefined && product.OfferRequirementItems) {
    return product.OfferRequirementItems;
  } else {
    return [];
  }
}

export function GetOfferRequirementItemSet(product, index){
  if(product !== undefined && product.OfferRequirementItems !== undefined && product.OfferRequirementItems.length > index)
    return product.OfferRequirementItems[index]
  else
    return {
      Id: 0,
      Requirement: "",
      OfferItemId: 0,
      Order: index
    };
    
}

export function GetOfferRequirementItem(product, index){
  if(product !== undefined && product.OfferRequirementItems !== undefined && product.OfferRequirementItems.length > index)
    return product.OfferRequirementItems[index].Requirement
  else
    return ""
}

export function GetCompanyId(product){
  if (product !== undefined && product.Client) {
    return product.Client;
  } else {
    return 0;
  }
}

export function GetStatusInt(offer) {
  if(offer !== undefined && offer.Status)
    return offer.Status;
  else
    return 0;
}

export function GetStatus(offer) {
  if(offer === undefined || offer.Status === undefined)
    return "N/A"
  switch (offer.Status) {
    case 0:
      return "Inactive";
    case 1:
      return "Active";
    case 2:
      return "Pending";
    case 3:
      return "Rejected";
    case 4:
      return "Expired";
    case 5:
      return "Approved";
    default:
      return "N/A";
  }
}

export function GetTransactionType(offer) {
  if(offer === undefined || offer.OfferTransactionType === undefined)
    return "N/A"
  switch (offer.OfferTransactionType) {
   
    case 1:
      return "Deposit";
    case 2:
      return "Withdraw";
    case 3:
      return "Purchase";
    case 4:
      return "Subscription";
    case 5:
      return "Registration";
    default:
      return "N/A";
  }
}
export function OfferConfigureSection(props) {
    const { editEnabled, useStyles, row, dupeOffer, editOffer, offer,
      handleChange, formStateOld, hasError, handleSetStartDate, 
      handleSetDate, startDateOld, endDateOld, showCustomActiveDays,
      daysAvailableOld, setShowCustomActiveDays, handleDaysSelect, 
      withMinDayValue, maxDepositValueOld, cashBackValueOld, 
      offerRewardTypeOld, offerActionTypeOld, handleActionTypeSelect, 
      handleRewardTypeSelect, showCustomMinDeposit, minDepositValueOld, 
      handleShowCustomMinDeposit, handleMinDepositSelect, 
      showCustomMaxDeposit, setShowCustomMaxDeposit, handleMaxDepositSelect, 
      showCustomCashback, setShowCustomCashback, handleCashBackSelect, withValueLimit, 
      showCustomMaxTransactions, maxTransactionValueOld, setShowCustomMaxTransactions, 
      handleMaxTransactionSelect, provinceList, handleLocationChange, LocationsList,
      handleSelectAll, handleShowViewStoredImages, ImgUrlInputOld
    } = props;
    var formState = formStateOld;
    if(formStateOld === undefined || !editEnabled){
      formState = {
        isValid: true,
        values: { 
          title: offer.Title, 
          title2: offer.Title2, 
          description: offer.Description, 
          offerURL: offer.OfferURL, 
          termsSummary: offer.TermsSummary,
          termsURL: offer.TermsURL, 
          extraURL: offer.ExtraURL 
        },
        touched: {},
        errors: {}
      };
    }
    var startDate = (startDateOld === undefined || !editEnabled)?moment(offer.StartDate).toDate():startDateOld;
    var endDate = (endDateOld === undefined || !editEnabled)?moment(offer.ExpirationDate).toDate():endDateOld;
    var daysAvailable = (daysAvailableOld === undefined || !editEnabled)?offer.AmountOfDaysValid:daysAvailableOld
    var cashBackValue = (cashBackValueOld === undefined || !editEnabled)?offer.CashbackPercentage*100:cashBackValueOld
    var offerRewardType =(offerRewardTypeOld === undefined || !editEnabled)?((offer != null)?GetRewardTypeFromOfferType(offer.OfferType):0):offerRewardTypeOld
    var maxDepositValue =(maxDepositValueOld === undefined || !editEnabled)?(((offerRewardType == 0)?((offer != null)?offer.Reward:0):((offer != null)?offer.MaxDeposit:0))):maxDepositValueOld
    var offerActionType =(offerActionTypeOld === undefined || !editEnabled)?((offer != null)?GetActionTypeFromOfferType(offer.OfferType):0):offerActionTypeOld
    var minDepositValue =(minDepositValueOld === undefined || !editEnabled)?((offer != null)?offer.MinDeposit:0):minDepositValueOld
    var maxTransactionValue = (maxTransactionValueOld === undefined || !editEnabled)?(offer.MaxAmountOfTransactionsAllowed !== undefined?offer.MaxAmountOfTransactionsAllowed:0):maxTransactionValueOld
    var ImgUrlInput = (ImgUrlInputOld === undefined || !editEnabled)?GetCompanyIcon(offer):ImgUrlInputOld
    //editEnabled = (editEnabled === undefined)?false:editEnabled;
    const classes = useStyles();
    return (
      <React.Fragment>
        <TextField
          multiline
          className={classes.textField}
          error={hasError("title")}
          fullWidth
          helperText={
            hasError("title") ? formState.errors.title[0] : null
          }
          disabled={!editEnabled}
          label="Title"
          name="title"
          onChange={handleChange}
          type="text"
          value={formState.values.title || ""}
          variant="outlined"
          maxRows={2}
          rows={2}
          label={"Title"+((formState.values.title !== undefined && formState.values.title.length > 0)?(" "+(40 - formState.values.title.length)+" characters remaining"):"")}
          inputProps={{
            maxLength: 40,
          }}
        />
        <TextField
          multiline
          className={classes.textField}
          error={hasError("description")}
          fullWidth
          helperText={
            hasError("description")
              ? formState.errors.description[0]
              : null
          }
          disabled={!editEnabled}
          name="description"
          onChange={handleChange}
          type="text"
          value={formState.values.description || ""}
          variant="outlined"
          maxRows={4}
          rows={4}
          label={"Description"+((formState.values.description !== undefined && formState.values.description.length > 0)?(" "+(150 - formState.values.description.length)+" characters remaining"):"")}
          inputProps={{
            maxLength: 150,
          }}
        />
        <TextField
          className={classes.textField}
          error={hasError("offerURL")}
          disabled={!editEnabled}
          fullWidth
          helperText={
            hasError("offerURL")
              ? formState.errors.offerURL[0]
              : null
          }
          label="Offer URL"
          name="offerURL"
          onChange={handleChange}
          type="text"
          value={formState.values.offerURL || ""}
          variant="outlined"
        />
        <TextField
          multiline
          disabled={!editEnabled}
          className={classes.textField}
          error={hasError("termsSummary")}
          fullWidth
          helperText={
            hasError("termsSummary")
              ? formState.errors.termsSummary[0]
              : null
          }
          label="Terms Summary"
          name="termsSummary"
          onChange={handleChange}
          minRows={4}
          type="text"
          label={"Terms Summary"}
          
          placeholder=
          "• Term 1&#13;&#10;• Term 2&#13;&#10;• Term 3"
          value={formState.values.termsSummary || ""}
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          error={hasError("termsURL")}
          fullWidth
          disabled={!editEnabled}
          helperText={
            hasError("termsURL")
              ? formState.errors.termsURL[0]
              : null
          }
          label="Terms URL"
          name="termsURL"
          onChange={handleChange}
          type="text"
          value={formState.values.termsURL || ""}
          variant="outlined"
        />
        <TextField
          className={classes.textField}
          error={hasError("extraURL")}
          fullWidth
          disabled={!editEnabled}
          helperText={
            hasError("extraURL")
              ? formState.errors.extraURL[0]
              : null
          }
          label="Offer Terms Hyperlink Text"
          name="extraURL"
          onChange={handleChange}
          type="text"
          value={formState.values.extraURL || ""}
          variant="outlined"
        />
        <Typography
          className={classes.sugestion}
          color="textSecondary"
          variant="h3"
        >
          Offer Valid Dates
        </Typography>
        <Typography
          className={classes.sugestion}
          color="textSecondary"
          variant="body1"
        >
          Offer Start Date
        </Typography>
        <DatePicker
          selected={startDate}
          disabled={!editEnabled}
            onChange={(date) => handleSetStartDate(date)}
        />
        <Typography
          className={classes.sugestion}
          color="textSecondary"
          variant="body1"
        >
          Offer End Date
        </Typography>
        <DatePicker
          disabled={!editEnabled}
          selected={endDate}
          onChange={(date) => handleSetDate(date)}
        />
        <Typography
          className={classes.sugestion}
          color="textSecondary"
          variant="body1"
        >
          Days Claimed Offer is Valid
        </Typography>
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            {showCustomActiveDays ? "custom" : daysAvailable + " days"}
          </Dropdown.Toggle>

          {editEnabled && <Dropdown.Menu>
            {MaxDaysValues.map((value) =>
              typeof value == "string" ? (
                <Dropdown.Item
                  onClick={(event) => setShowCustomActiveDays(!showCustomActiveDays)}
                  active={showCustomActiveDays}
                >
                  {value + ""}
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={(event) => handleDaysSelect(true, value)}
                  active={!showCustomActiveDays && value == daysAvailable}
                >
                  {value + " days"}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Menu>}
        </Dropdown>
        {showCustomActiveDays && 
          <NumericFormat value={daysAvailable} 
          thousandSeparator={true} 
          allowNegative={false}
          isAllowed={withMinDayValue}
          decimalScale={0}
          suffix={(daysAvailable > 1)?' days':' day'} onValueChange={(values) => {
            const {formattedValue, value} = values;
            handleDaysSelect(false, parseInt(value, 10))
          }}/>}
          <br></br>
              {maxDepositValue != 0 && cashBackValue != 0 &&
          <div className={classes.row}>
            <h3>
              <div>
                  <NumericFormat decimalScale={2} fixedDecimalScale={true} value={((maxDepositValue * ((offerRewardType == 0)?1:cashBackValue)) / 100)} displayType={'text'} thousandSeparator={true} prefix={'Max Reward: $'} />
                  <NumericFormat decimalScale={0} fixedDecimalScale={true} value={(maxDepositValue * ((offerRewardType == 0)?1:cashBackValue))} displayType={'text'} thousandSeparator={true} suffix={" BetFully points)"} prefix={' ('} />
                </div>
                </h3>
          </div>
        }
        {offer.OfferType != 9 && <Typography
          className={classes.sugestion}
          color="textSecondary"
          variant="body1"
        >
          User Action Type
        </Typography>}
        {offer.OfferType != 9 && <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            {UserActionTypeValues[offerActionType]}
          </Dropdown.Toggle>

          {editEnabled && <Dropdown.Menu>
            {UserActionTypeValues.map((value,index) =>
              value === offerActionType ? (
                <Dropdown.Item
                  onClick={(event) => handleActionTypeSelect(index)}
                  active
                >
                  {value}
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={(event) => handleActionTypeSelect(index)}
                >
                  {value}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Menu>}
        </Dropdown>}
        {offer.OfferType != 9 && <Typography
          className={classes.sugestion}
          color="textSecondary"
          variant="body1"
        >
          Reward Type
        </Typography>}
        {offer.OfferType != 9 && <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            {RewardTypeValues[offerRewardType]}
          </Dropdown.Toggle>

          { editEnabled && <Dropdown.Menu>
            {RewardTypeValues.map((value,index) =>
              value === offerRewardType ? (
                <Dropdown.Item
                  onClick={(event) => handleRewardTypeSelect(index)}
                  active
                >
                  {value}
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={(event) => handleRewardTypeSelect(index)}
                >
                  {value}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Menu>}
        </Dropdown>}
        {offer.OfferType != 9 && <Typography
          className={classes.sugestion}
          color="textSecondary"
          variant="body1"
        >
          {"Minimum Single "+((offerActionType == 1)?"Withdrawal":"Deposit")+" Amount ($)"}
        </Typography>}
        {(offerRewardType == 2) && <TextField
                    className={classes.textField}
                    fullWidth
                    disabled={!editEnabled}
                    name="title2"
                    type="text"
                    value={offer.ShortDescription != undefined ? (offer.ShortDescription):""}
                    variant="outlined"
                    label={"Tapjoy Placement"}
                  />}
        {offer.OfferType != 9 && <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            {showCustomMinDeposit?"custom":((minDepositValue == 0)?"none":"$" + minDepositValue)}
          </Dropdown.Toggle>

          { editEnabled && <Dropdown.Menu>
            {MinDepositValues.map((value) =>
              typeof value == "string" ? (
                <Dropdown.Item
                  onClick={(event) => handleShowCustomMinDeposit()}
                  active = {showCustomMinDeposit}
                >
                  {value}
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={(event) => handleMinDepositSelect(true, value)}
                  active ={!showCustomMinDeposit && value == minDepositValue}
                >
                  {(value == 0)?"none":"$" + value}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Menu>}
        </Dropdown>}
                
        {showCustomMinDeposit && offer.OfferType != 9 &&  
        <NumericFormat value={minDepositValue} 
        thousandSeparator={true} 
        allowNegative={false}
        decimalScale={0}
        prefix={'$'} onValueChange={(values) => {
          const {formattedValue, value} = values;
          // formattedValue = $2,223
          // value ie, 2223
          //setPointAmount(parseInt(value, 10))
          handleMinDepositSelect(false, parseInt(value, 10))
        }}/>}

        {(offerRewardType == 1) && offer.OfferType != 9 && <Typography
          className={classes.sugestion}
          color="textSecondary"
          variant="body1"
        >
          {"Max Total "+((offerActionType == 1)?"Withdrawal":"Deposit")+" Amount ($)"}
        </Typography>}
        {(offerRewardType == 1) && offer.OfferType != 9 && <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            {showCustomMaxDeposit ? "custom" : ((maxDepositValue == 0)?"no limit":"$" + maxDepositValue)}
          </Dropdown.Toggle>

          { editEnabled && <Dropdown.Menu>
            {MaxDepositValues.map((value) =>
              typeof value == "string" ? (
                <Dropdown.Item
                  onClick={(event) => setShowCustomMaxDeposit(!showCustomMaxDeposit)}
                  active = {showCustomMaxDeposit}
                >
                  {"" + value}
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={(event) => handleMaxDepositSelect(true, value)}
                  active = {!showCustomMaxDeposit && value == maxDepositValue}
                >
                  {(value == 0)?"no limit":"$" + value}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Menu>}
        </Dropdown>}
        {(offerRewardType == 0 || offer.OfferType == 9 ) && <Typography
          className={classes.sugestion}
          color="textSecondary"
          variant="body1"
        >
          {"Reward Amount (Points)"}
        </Typography>}
        {((showCustomMaxDeposit && (offerRewardType == 1)) || offerRewardType == 0 || offer.OfferType != 9) && 
        <NumericFormat value={maxDepositValue} 
        thousandSeparator={true} 
        allowNegative={false}
        decimalScale={0}
        prefix={(offerRewardType == 0)?'':'$'} onValueChange={(values) => {
          const {formattedValue, value} = values;
          handleMaxDepositSelect(false, parseInt(value, 10))
        }}/>}
        {(offerRewardType == 1) && offer.OfferType != 9 && <Typography
          className={classes.sugestion}
          color="textSecondary"
          variant="body1"
        >
          Cashback (%)
        </Typography>}

        {(offerRewardType == 1) && offer.OfferType != 9 && <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            {showCustomCashback ? "custom" : cashBackValue + "%"}
          </Dropdown.Toggle>

          { editEnabled && <Dropdown.Menu>
            {CashBackValues.map((value) =>
              typeof value == "string" ? (
                <Dropdown.Item
                  onClick={(event) => setShowCustomCashback(!showCustomCashback)}
                  active = {showCustomCashback}
                >
                  {value + ""}
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={(event) => handleCashBackSelect(true, value)}
                  active={!showCustomCashback && value == cashBackValue}
                >
                  {value + "%"}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Menu>}
        </Dropdown>}
      
        {showCustomCashback && (offerRewardType == 1) && offer.OfferType != 9 && 
        <NumericFormat value={cashBackValue} 
        thousandSeparator={true} 
        allowNegative={false}
        isAllowed={withValueLimit}
        decimalScale={2}
        suffix={'%'} onValueChange={(values) => {
          const {formattedValue, value} = values;
          handleCashBackSelect(false, parseFloat(value))
        }}/>}
    
        {(offerRewardType == 1) && offer.OfferType != 9 && <Typography
          className={classes.sugestion}
          color="textSecondary"
          variant="body1"
        >
          Max Transactions
        </Typography>}

        {(offerRewardType == 1) && offer.OfferType != 9 && <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            {showCustomMaxTransactions ? "custom" : ((maxTransactionValue == 0)?"no limit":maxTransactionValue)}
          </Dropdown.Toggle>

          { editEnabled && <Dropdown.Menu>
            {MaxTransactionValues.map((value) =>
            typeof value == "string" ? (
                <Dropdown.Item
                  onClick={(event) => setShowCustomMaxTransactions(!showCustomMaxTransactions)}
                  active = {showCustomMaxTransactions}
                >
                  {value}
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={(event) => handleMaxTransactionSelect(true, value)}
                  active={!showCustomMaxTransactions && value == maxTransactionValue}
                >
                  {(value == 0)?"no limit":value}
                </Dropdown.Item>
              )
            )}
          </Dropdown.Menu>}
        </Dropdown>}
                
        {showCustomMaxTransactions && (offerRewardType == 1) && offer.OfferType != 9 && 
          <NumericFormat value={maxTransactionValue} 
          thousandSeparator={true} 
          disabled={!editEnabled}
          allowNegative={false}
          decimalScale={0}
          prefix={''} onValueChange={(values) => {
            const {formattedValue, value} = values;
            handleMaxTransactionSelect(false, parseInt(value, 10))
          }}/>}
                 
                 { editEnabled && <Grid container columns={15} spacing={1} direction="row" >
                {provinceList.map(x => (<Grid item md={3} xs={5}><FormControlLabel
                    control={<Checkbox 
                      onChange={handleLocationChange} 
                      checked={LocationsList.includes(x.Id)} 
                      value={x.Id}
                      disabled={!editEnabled}
                      name={x.Name} />}
                    label={x.Name}
                  /></Grid>))}
                  
                {/* </FormGroup> */}
                </Grid>}
                { editEnabled && <Button
                  className={classes.signInButton}
                  color="primary"
                  fullWidth
                  size="large"
                  variant="contained"
                  onClick={() => handleSelectAll()}
                >
                  {(LocationsList.length == provinceList.length)?"Remove All":"Select All"}
                </Button>}
                

                { editEnabled && <ProductCardPreview
            className={classes.cardPreview}
            product={formState.productObj}
            disabled={!editEnabled}
            showImageSelect={handleShowViewStoredImages}
            displayImage={ImgUrlInput}
            // onClick={event => handleSelectOffer(product)}
          />}
          { !editEnabled && <ProductCardPreview
            className={classes.cardPreview}
            product={formState.productObj}
            disabled={!editEnabled}
            showImageSelect={() => {}}
            displayImage={ImgUrlInput}
            // onClick={event => handleSelectOffer(product)}
          />}
      </React.Fragment>
    );
}

export function OfferSection(props) {
  const { editEnabled, useStyles, row, dupeOffer, editOffer, offer,
    handleChange, handleManualChange, formStateOld, hasError, 
    startDateOld, endDateOld, 
    handleShowBackViewStoredImages,
    handleShowSmallViewStoredImages,
    daysAvailableOld, withMinDayValue, 
    maxDepositValueOld, cashBackValueOld, 
    offerRewardTypeOld, offerActionTypeOld, 
    minDepositValueOld, 
    withValueLimit, maxTransactionValueOld, 
    provinceList, handleLocationChange, LocationsList,
    handleSelectAll, handleShowViewStoredImages, ImgUrlInputOld
  } = props;
  const classes = useStyles();

  const currentDate = new Date();
  const standardDate = currentDate.setDate(currentDate.getDate() + 1);

  const futureDate = currentDate.setDate(currentDate.getDate() + 7);

  var formState = formStateOld;
  if(formStateOld === undefined || !editEnabled){
    var offerRewardType = (dupeOffer != null)?GetRewardTypeFromOfferType(dupeOffer.OfferType):0
    formState = {
      isValid: true,
      values: { 
        title: (dupeOffer !== undefined)?GetTitle(dupeOffer):"", 
        title2: (dupeOffer !== undefined)?GetTitle2(dupeOffer):"", 
        description: (dupeOffer !== undefined)?GetDescription(dupeOffer):"", 
        offerURL: (dupeOffer !== undefined)?GetOfferURL(dupeOffer):"", 
        termsURL: (dupeOffer !== undefined)?GetTermsURL(dupeOffer):"", 
        termsSummary: (dupeOffer !== undefined)?GetOfferTermsSummary(dupeOffer):"",
        extraURL: (dupeOffer !== undefined)?GetExtraURL(dupeOffer):"", 
        maxDeposit: (offerRewardType == 0)?((dupeOffer != null)?dupeOffer.Reward:0):((dupeOffer != null)?dupeOffer.MaxDeposit:0),
        minDeposit: (dupeOffer != null)?dupeOffer.MinDeposit:0,
        maxTransactionsAllowed: (dupeOffer !== undefined && dupeOffer.MaxAmountOfTransactionsAllowed !== undefined)?dupeOffer.MaxAmountOfTransactionsAllowed:0,
        cashbackPercent: (dupeOffer != null && dupeOffer.CashbackPercentage > 0)?dupeOffer.CashbackPercentage*100:CashBackValues[0],
        amountOfDaysValid: (dupeOffer != null && dupeOffer.AmountOfDaysValid > 0)?dupeOffer.AmountOfDaysValid:3,
        startDate: (dupeOffer != null && dupeOffer.StartDate != null)?moment(dupeOffer.StartDate).toDate():
          standardDate
        ,
        endDate: (dupeOffer != null && dupeOffer.ExpirationDate != null)?moment(dupeOffer.ExpirationDate).toDate():
          futureDate
        ,
        offerTransactionType: (dupeOffer != null)?dupeOffer.OfferTransactionType:0,
        offerActionType: (dupeOffer != null)?GetActionTypeFromOfferType(dupeOffer.OfferType):0,
        offerRewardType: (dupeOffer != null)?GetRewardTypeFromOfferType(dupeOffer.OfferType):0,
        offerType:(dupeOffer != null)?dupeOffer.OfferType:0,
        storedImageId: (dupeOffer != null)?dupeOffer.StoredImageId:0,
        imgUrlInput: (dupeOffer != null && dupeOffer.ClientIcon != null)?dupeOffer.ClientIcon:SessionHelper.getIcon(),
        backgroundImageId: GetBackgroundImageId(dupeOffer), 
        smallLogoId: GetSmallLogoId(dupeOffer),
        backgroundImageUrl: GetBackgroundImage(dupeOffer), 
        smallLogoUrl: GetSmallIcon(dupeOffer),
        instructionSet1: GetOfferRequirementItemSet(dupeOffer, 0),
        instructionSet2: GetOfferRequirementItemSet(dupeOffer, 1),
        instructionSet3: GetOfferRequirementItemSet(dupeOffer, 2),
        instruction1: GetOfferRequirementItem(dupeOffer, 0),
        instruction2: GetOfferRequirementItem(dupeOffer, 1),
        instruction3: GetOfferRequirementItem(dupeOffer, 2),
        daysAvailableCustom: (dupeOffer != null && dupeOffer.AmountOfDaysValid > 0)?!MaxDaysValues.includes(dupeOffer.AmountOfDaysValid):false,
        minDepositCustom: (dupeOffer != null && dupeOffer.MinDeposit > 0)?!MinDepositValues.includes(dupeOffer.MinDeposit):false,
        maxDepositCustom: (dupeOffer != null && dupeOffer.MaxDeposit > 0)?!MaxDepositValues.includes(dupeOffer.MaxDeposit):false,
        cashBackCustom: (dupeOffer != null && dupeOffer.CashbackPercentage > 0)?!CashBackValues.includes(dupeOffer.CashbackPercentage*100):false,
        maxTransactionCustom: (dupeOffer != null)?!MinDepositValues.includes(dupeOffer.MaxAmountOfTransactionsAllowed):false,
      },
      touched: {},
      errors: {}
    };
  }

  function handleDropdownSelect(hideCustom, formName, value) {
    if(hideCustom)
      handleManualChange(formName+"Custom", false)
    handleManualChange(formName, value)
  }

  // var startDate = (startDateOld === undefined || !editEnabled)?moment(offer.StartDate).toDate():startDateOld;
  // var endDate = (endDateOld === undefined || !editEnabled)?moment(offer.ExpirationDate).toDate():endDateOld;
  // var daysAvailable = (daysAvailableOld === undefined || !editEnabled)?offer.AmountOfDaysValid:daysAvailableOld
  // var cashBackValue = (cashBackValueOld === undefined || !editEnabled)?offer.CashbackPercentage*100:cashBackValueOld
  // var offerRewardType =(offerRewardTypeOld === undefined || !editEnabled)?((offer != null)?GetRewardTypeFromOfferType(offer.OfferType):0):offerRewardTypeOld
  // var maxDepositValue =(maxDepositValueOld === undefined || !editEnabled)?(((offerRewardType == 0)?((offer != null)?offer.Reward:0):((offer != null)?offer.MaxDeposit:0))):maxDepositValueOld
  // var offerActionType =(offerActionTypeOld === undefined || !editEnabled)?((offer != null)?GetActionTypeFromOfferType(offer.OfferType):0):offerActionTypeOld
  // var minDepositValue =(minDepositValueOld === undefined || !editEnabled)?((offer != null)?offer.MinDeposit:0):minDepositValueOld
  // var maxTransactionValue = (maxTransactionValueOld === undefined || !editEnabled)?(offer.MaxAmountOfTransactionsAllowed !== undefined?offer.MaxAmountOfTransactionsAllowed:0):maxTransactionValueOld
  // var ImgUrlInput = (ImgUrlInputOld === undefined || !editEnabled)?GetCompanyIcon(offer):ImgUrlInputOld
  return (
    <React.Fragment>
      <Typography
        className={classes.sugestion}
        color="textSecondary"
        variant="body1"
      >
        User Action Type
      </Typography>
      <Dropdown>
        <Dropdown.Toggle disabled={!editEnabled} variant="secondary" id="dropdown-basic">
          {UserActionTypeValues[formState.values.offerActionType]}
        </Dropdown.Toggle>
        {editEnabled && <Dropdown.Menu>
          {UserActionTypeValues.map((value,index) =>
            value === formState.values.offerActionType ? (
              <Dropdown.Item
                onClick={(event) => handleManualChange("offerActionType", index)}
                active
              >
                {value}
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={(event) => handleManualChange("offerActionType", index)}
              >
                {value}
              </Dropdown.Item>
            )
          )}
        </Dropdown.Menu>}
      </Dropdown>
      <Typography
        className={classes.sugestion}
        color="textSecondary"
        variant="body1"
      >
        Reward Type
      </Typography>
      <Dropdown>
        <Dropdown.Toggle disabled={!editEnabled} variant="secondary" id="dropdown-basic">
          {RewardTypeValues[formState.values.offerRewardType]}
        </Dropdown.Toggle>

        {editEnabled && <Dropdown.Menu>
          {RewardTypeValues.map((value,index) =>
            value === formState.values.offerRewardType ? (
              <Dropdown.Item
                onClick={(event) => handleManualChange("offerRewardType", index)}
                active
              >
                {value}
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={(event) => handleManualChange("offerRewardType", index)}
              >
                {value}
              </Dropdown.Item>
            )
          )}
        </Dropdown.Menu>}
      </Dropdown>
      <TextField
        multiline
        className={classes.textField}
        error={hasError("title")}
        fullWidth
        helperText={
          hasError("title") ? formState.errors.title[0] : null
        }
        disabled={!editEnabled}
        label="Title"
        name="title"
        onChange={handleChange}
        type="text"
        value={formState.values.title || ""}
        variant="outlined"
        maxRows={2}
        rows={2}
        label={"Title"+((formState.values.title !== undefined && formState.values.title.length > 0)?(" "+(40 - formState.values.title.length)+" characters remaining"):"")}
        inputProps={{
          maxLength: 40,
        }}
      />
      {(formState.values.offerRewardType != 2) && <TextField
        multiline
        className={classes.textField}
        error={hasError("description")}
        fullWidth
        helperText={
          hasError("description")
            ? formState.errors.description[0]
            : null
        }
        disabled={!editEnabled}
        name="description"
        onChange={handleChange}
        type="text"
        value={formState.values.description || ""}
        variant="outlined"
        maxRows={4}
        rows={4}
        label={"Description"+((formState.values.description !== undefined && formState.values.description.length > 0)?(" "+(150 - formState.values.description.length)+" characters remaining"):"")}
        inputProps={{
          maxLength: 150,
        }}
      />}
      {editEnabled && formState.values.offerRewardType != 2 && <TextField
        multiline
        className={classes.textField}
        error={hasError("instruction1")}
        fullWidth
        helperText={
          hasError("instruction1") ? formState.errors.instruction1[0] : null
        }
        label="Instruction1"
        name="instruction1"
        onChange={handleChange}
        type="text"
        value={formState.values.instruction1 || ""}
        variant="outlined"
        maxRows={2}
        rows={2}
        label={"Instruction1"+((formState.values.instruction1 !== undefined && 
          formState.values.instruction1.length > 0)?
          (" "+(70 - formState.values.instruction1.length)+" characters remaining"):"")}
        inputProps={{
          maxLength: 70,
        }}
      />}
      {editEnabled && formState.values.offerRewardType != 2 && <TextField
        multiline
        className={classes.textField}
        error={hasError("instruction2")}
        fullWidth
        helperText={
          hasError("instruction2") ? formState.errors.instruction2[0] : null
        }
        label="Instruction2"
        name="instruction2"
        onChange={handleChange}
        type="text"
        value={formState.values.instruction2 || ""}
        variant="outlined"
        maxRows={2}
        rows={2}
        label={"Instruction2"+((formState.values.instruction2 !== undefined && 
          formState.values.instruction2.length > 0)?
          (" "+(70 - formState.values.instruction2.length)+" characters remaining"):"")}
        inputProps={{
          maxLength: 70,
        }}
      />}
      {editEnabled && (formState.values.offerRewardType != 2) && <TextField
        multiline
        className={classes.textField}
        error={hasError("instruction3")}
        fullWidth
        helperText={
          hasError("instruction3") ? formState.errors.instruction3[0] : null
        }
        label="Instruction3"
        name="instruction3"
        onChange={handleChange}
        type="text"
        value={formState.values.instruction3 || ""}
        variant="outlined"
        maxRows={2}
        rows={2}
        label={"Instruction3"+((formState.values.instruction3 !== undefined && 
          formState.values.instruction3.length > 0)?
          (" "+(70 - formState.values.instruction3.length)+" characters remaining"):"")}
        inputProps={{
          maxLength: 70,
        }}
      />}
      {(formState.values.offerRewardType != 2) && <TextField
        className={classes.textField}
        error={hasError("offerURL")}
        disabled={!editEnabled}
        fullWidth
        helperText={
          hasError("offerURL")
            ? formState.errors.offerURL[0]
            : null
        }
        label="Offer URL"
        name="offerURL"
        onChange={handleChange}
        type="text"
        value={formState.values.offerURL || ""}
        variant="outlined"
      />}
      {(formState.values.offerRewardType != 2) && <TextField
        multiline
        disabled={!editEnabled}
        className={classes.textField}
        error={hasError("termsSummary")}
        fullWidth
        helperText={
          hasError("termsSummary")
            ? formState.errors.termsSummary[0]
            : null
        }
        label="Terms Summary"
        name="termsSummary"
        onChange={handleChange}
        minRows={4}
        type="text"
        label={"Terms Summary"}
        
        placeholder=
        "• Term 1&#13;&#10;• Term 2&#13;&#10;• Term 3"
        value={formState.values.termsSummary || ""}
        variant="outlined"
      />}
      {(formState.values.offerRewardType != 2) && <TextField
        className={classes.textField}
        error={hasError("termsURL")}
        fullWidth
        disabled={!editEnabled}
        helperText={
          hasError("termsURL")
            ? formState.errors.termsURL[0]
            : null
        }
        label="Terms URL"
        name="termsURL"
        onChange={handleChange}
        type="text"
        value={formState.values.termsURL || ""}
        variant="outlined"
      />}
      {(formState.values.offerRewardType != 2) && <TextField
        className={classes.textField}
        error={hasError("extraURL")}
        fullWidth
        disabled={!editEnabled}
        helperText={
          hasError("extraURL")
            ? formState.errors.extraURL[0]
            : null
        }
        label="Offer Terms Hyperlink Text"
        name="extraURL"
        onChange={handleChange}
        type="text"
        value={formState.values.extraURL || ""}
        variant="outlined"
      />}
      <Typography
        className={classes.sugestion}
        color="textSecondary"
        variant="h3"
      >
        Offer Valid Dates
      </Typography>
      <Typography
        className={classes.sugestion}
        color="textSecondary"
        variant="body1"
      >
        Offer Start Date
      </Typography>
      <DatePicker
        selected={formState.values.startDate}
        disabled={!editEnabled}
          onChange={(date) => handleManualChange("startDate", date)}
      />
      <Typography
        className={classes.sugestion}
        color="textSecondary"
        variant="body1"
      >
        Offer End Date
      </Typography>
      <DatePicker
        disabled={!editEnabled}
        selected={formState.values.endDate}
        onChange={(date) => handleManualChange("endDate", date)}
      />
      {formState.values.offerRewardType != 2 && <Typography
        className={classes.sugestion}
        color="textSecondary"
        variant="body1"
      >
        Days Claimed Offer is Valid
      </Typography>}
      {formState.values.offerRewardType != 2 && <Dropdown>
        <Dropdown.Toggle disabled={!editEnabled} variant="secondary" id="dropdown-basic">
          {formState.values.daysAvailableCustom ? "custom" : formState.values.amountOfDaysValid + " days"}
        </Dropdown.Toggle>

        {editEnabled && <Dropdown.Menu>
          {MaxDaysValues.map((value) =>
            typeof value == "string" ? (
              <Dropdown.Item
                onClick={(event) => handleManualChange("daysAvailableCustom", !formState.values.daysAvailableCustom)}
                active={formState.values.daysAvailableCustom}
              >
                {value + ""}
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={(event) => handleDropdownSelect(true, "amountOfDaysValid", value)}
                active={!formState.values.daysAvailableCustom && value == formState.values.amountOfDaysValid}
              >
                {value + " days"}
              </Dropdown.Item>
            )
          )}
        </Dropdown.Menu>}
      </Dropdown>}
      {formState.values.daysAvailableCustom && 
        <NumericFormat value={formState.values.amountOfDaysValid} 
        thousandSeparator={true} 
        allowNegative={false}
        disabled={!editEnabled}
        isAllowed={withMinDayValue}
        decimalScale={0}
        suffix={(formState.values.amountOfDaysValid > 1)?' days':' day'} onValueChange={(values) => {
          const {formattedValue, value} = values;
          handleDropdownSelect(false, "amountOfDaysValid",parseInt(value, 10))
        }}/>}
        <br></br>
            {formState.values.maxDeposit != 0 && formState.values.cashbackPercent != 0 &&
        <div className={classes.row}>
          <h3>
            <div>
                <NumericFormat decimalScale={2} disabled={!editEnabled} fixedDecimalScale={true} value={((formState.values.maxDeposit * ((formState.values.offerRewardType == 0)?1:formState.values.cashbackPercent)) / 100)} displayType={'text'} thousandSeparator={true} prefix={'Max Reward: $'} />
                <NumericFormat decimalScale={0} disabled={!editEnabled} fixedDecimalScale={true} value={(formState.values.maxDeposit * ((formState.values.offerRewardType == 0)?1:formState.values.cashbackPercent))} displayType={'text'} thousandSeparator={true} suffix={" BetFully points)"} prefix={' ('} />
              </div>
              </h3>
        </div>
      }
      
      {formState.values.offerType != 9 && formState.values.offerRewardType != 2 && <Typography
        className={classes.sugestion}
        color="textSecondary"
        variant="body1"
      >
        {"Minimum Single "+((formState.values.offerActionType == 1)?"Withdrawal":"Deposit")+" Amount ($)"}
      </Typography>}
      {(formState.values.offerRewardType == 2) && <TextField
                  className={classes.textField}
                  fullWidth
                  disabled={!editEnabled}
                  name="title2"
                  type="text"
                  value={formState.values.shortDescription != undefined ? (formState.values.shortDescription):""}
                  variant="outlined"
                  label={"Tapjoy Placement"}
                />}
      {formState.values.offerType != 9 && formState.values.offerRewardType != 2 && <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic" disabled={!editEnabled}>
          {formState.values.minDepositCustom?"custom":((formState.values.minDeposit == 0)?"none":"$" + formState.values.minDeposit)}
        </Dropdown.Toggle>

        { editEnabled && <Dropdown.Menu>
          {MinDepositValues.map((value) =>
            typeof value == "string" ? (
              <Dropdown.Item
                onClick={(event) => handleManualChange("minDepositCustom",!formState.values.minDepositCustom)}
                active = {formState.values.minDepositCustom}
              >
                {value}
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={(event) => handleDropdownSelect(true, "minDeposit",value)}
                active ={!formState.values.minDepositCustom && value == formState.values.minDeposit}
              >
                {(value == 0)?"none":"$" + value}
              </Dropdown.Item>
            )
          )}
        </Dropdown.Menu>}
      </Dropdown>}
              
      {formState.values.minDepositCustom && formState.values.offerType != 9 &&  
      <NumericFormat value={formState.values.minDeposit} 
      thousandSeparator={true} 
      disabled={!editEnabled}
      allowNegative={false}
      decimalScale={0}
      prefix={'$'} onValueChange={(values) => {
        const {formattedValue, value} = values;
        // formattedValue = $2,223
        // value ie, 2223
        //setPointAmount(parseInt(value, 10))
        handleDropdownSelect(false, "minDeposit", parseInt(value, 10))
      }}/>}

      {(formState.values.offerRewardType == 1) && formState.values.offerType != 9 && <Typography
        className={classes.sugestion}
        color="textSecondary"
        variant="body1"
      >
        {"Max Total "+((formState.values.offerActionType == 1)?"Withdrawal":"Deposit")+" Amount ($)"}
      </Typography>}
      {(formState.values.offerRewardType == 1) && formState.values.offerType != 9 && <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          {formState.values.maxDepositCustom ? "custom" : ((formState.values.maxDeposit == 0)?"no limit":"$" + formState.values.maxDeposit)}
        </Dropdown.Toggle>

        { editEnabled && <Dropdown.Menu>
          {MaxDepositValues.map((value) =>
            typeof value == "string" ? (
              <Dropdown.Item
                onClick={(event) => handleManualChange("maxDepositCustom", !formState.values.maxDepositCustom)}
                active = {formState.values.maxDepositCustom}
              >
                {"" + value}
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={(event) => handleDropdownSelect(true, "maxDeposit", value)}
                active = {!formState.values.maxDepositCustom && value == formState.values.maxDeposit}
              >
                {(value == 0)?"no limit":"$" + value}
              </Dropdown.Item>
            )
          )}
        </Dropdown.Menu>}
      </Dropdown>}
      {(formState.values.offerRewardType == 0 || formState.values.offerType == 9 || formState.values.offerRewardType == 2) && <Typography
        className={classes.sugestion}
        color="textSecondary"
        variant="body1"
      >
        {"Reward Amount (Points)"}
      </Typography>}
      {((formState.values.maxDepositCustom && (formState.values.offerRewardType == 1)) || formState.values.offerRewardType == 0 || formState.values.offerRewardType == 2 || formState.values.offerType != 9) && 
      <NumericFormat value={formState.values.maxDeposit} 
      thousandSeparator={true} 
      allowNegative={false}
      disabled={!editEnabled}
      decimalScale={0}
      prefix={(formState.values.offerRewardType == 0 || formState.values.offerRewardType == 2)?'':'$'} onValueChange={(values) => {
        const {formattedValue, value} = values;
        handleDropdownSelect(false, "maxDeposit", parseInt(value, 10))
      }}/>}
      {(formState.values.offerRewardType == 1) && formState.values.offerType != 9 && <Typography
        className={classes.sugestion}
        color="textSecondary"
        variant="body1"
      >
        Cashback (%)
      </Typography>}

      {(formState.values.offerRewardType == 1) && formState.values.offerType != 9 && <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic" disabled={!editEnabled}>
          {formState.values.cashBackCustom ? "custom" : formState.values.cashbackPercent + "%"}
        </Dropdown.Toggle>

        { editEnabled && <Dropdown.Menu>
          {CashBackValues.map((value) =>
            typeof value == "string" ? (
              <Dropdown.Item
                onClick={(event) => handleManualChange("cashBackCustom",!formState.values.cashBackCustom)}
                active = {formState.values.cashBackCustom}
              >
                {value + ""}
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={(event) => handleDropdownSelect(true, "cashbackPercent", value)}
                active={!formState.values.cashBackCustom && value == formState.values.cashbackPercent}
              >
                {value + "%"}
              </Dropdown.Item>
            )
          )}
        </Dropdown.Menu>}
      </Dropdown>}
    
      {formState.values.cashBackCustom && (formState.values.offerRewardType == 1) && formState.values.offerType != 9 && 
      <NumericFormat value={formState.values.cashbackPercent} 
      thousandSeparator={true} 
      allowNegative={false}
      isAllowed={withValueLimit}
      disabled={!editEnabled}
      decimalScale={2}
      suffix={'%'} onValueChange={(values) => {
        const {formattedValue, value} = values;
        handleDropdownSelect(false, "cashbackPercent", parseFloat(value))
      }}/>}
  
      {(formState.values.offerRewardType == 1) && formState.values.offerType != 9 && <Typography
        className={classes.sugestion}
        color="textSecondary"
        variant="body1"
      >
        Max Transactions
      </Typography>}

      {(formState.values.offerRewardType == 1) && formState.values.offerType != 9 && <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic" disabled={!editEnabled}>
          {formState.values.maxTransactionCustom ? "custom" : ((formState.values.maxTransactionsAllowed == 0)?"no limit":formState.values.maxTransactionsAllowed)}
        </Dropdown.Toggle>

        { editEnabled && <Dropdown.Menu>
          {MaxTransactionValues.map((value) =>
          typeof value == "string" ? (
              <Dropdown.Item
                onClick={(event) => handleManualChange("maxTransactionCustom",!formState.values.maxTransactionCustom)}
                active = {formState.values.maxTransactionCustom}
              >
                {value}
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={(event) => handleDropdownSelect(true, "maxTransactionsAllowed", value)}
                active={!formState.values.maxTransactionCustom && value == formState.values.maxTransactionsAllowed}
              >
                {(value == 0)?"no limit":value}
              </Dropdown.Item>
            )
          )}
        </Dropdown.Menu>}
      </Dropdown>}
              
      {formState.values.maxTransactionCustom && (formState.values.offerRewardType == 1) && formState.values.offerType != 9 && 
        <NumericFormat value={formState.values.maxTransactionsAllowed} 
        thousandSeparator={true} 
        allowNegative={false}
        disabled={!editEnabled}
        decimalScale={0}
        prefix={''} onValueChange={(values) => {
          const {formattedValue, value} = values;
          handleDropdownSelect(false, "maxTransactionsAllowed", parseInt(value, 10))
        }}/>}
               
               { editEnabled && (formState.values.offerRewardType != 2) && <Grid container columns={15} spacing={1} direction="row" >
              {provinceList.map(x => (<Grid item md={3} xs={5}><FormControlLabel
                  control={<Checkbox 
                    onChange={handleLocationChange} 
                    checked={LocationsList.includes(x.Id)} 
                    value={x.Id}
                    disabled={!editEnabled}
                    name={x.Name} />}
                  label={x.Name}
                /></Grid>))}
                
              {/* </FormGroup> */}
              </Grid>}
              { editEnabled && (formState.values.offerRewardType != 2) &&<Button
                className={classes.signInButton}
                color="primary"
                fullWidth
                size="large"
                variant="contained"
                onClick={() => handleSelectAll()}
              >
                {(LocationsList.length == provinceList.length)?"Remove All":"Select All"}
              </Button>}
              
              <div>Offer Image</div>
        { editEnabled && <ProductCardPreview
          className={classes.cardPreview}
          product={formState.productObj}
          disabled={!editEnabled}
          showImageSelect={handleShowViewStoredImages}
          displayImage={formState.values.imgUrlInput}
          // onClick={event => handleSelectOffer(product)}
        />}
        { !editEnabled && <ProductCardPreview
          className={classes.cardPreview}
          product={formState.productObj}
          disabled={!editEnabled}
          showImageSelect={() => {}}
          displayImage={formState.values.imgUrlInput}
          // onClick={event => handleSelectOffer(product)}
        />}
        <div>Offer Background Image</div>
        { editEnabled && <ProductCardPreview
              className={classes.cardPreview}
              product={formState.productObj}
              showImageSelect={handleShowBackViewStoredImages}
              displayImage={formState.values.backgroundImageUrl}
              // onClick={event => handleSelectOffer(product)}
            />}
          { !editEnabled && <ProductCardPreview
              className={classes.cardPreview}
              product={formState.productObj}
              showImageSelect={() => {}}
              displayImage={formState.values.backgroundImageUrl}
              // onClick={event => handleSelectOffer(product)}
            />}
            <div>Offer Small Icon Image</div>
            { editEnabled && <ProductCardPreview
              className={classes.cardPreview}
              product={formState.productObj}
              showImageSelect={handleShowSmallViewStoredImages}
              displayImage={formState.values.smallLogoUrl}
              // onClick={event => handleSelectOffer(product)}
            />}
            { !editEnabled && <ProductCardPreview
              className={classes.cardPreview}
              product={formState.productObj}
              showImageSelect={() => {}}
              displayImage={formState.values.smallLogoUrl}
              // onClick={event => handleSelectOffer(product)}
            />}
            
    </React.Fragment>
  );
}
