import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Grid, Typography, Avatar } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MoneyIcon from "@mui/icons-material/Money";

import {
  GetCancelledCount,
  GetActiveCount,
  GetExpiredCount,
  GetInactiveCount,
  GetCompletedCount,
} from "helpers/UserDetailsHelper";
import withAuthorization from "../../../../../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
}));

const UserInfo = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              USER INFO
            </Typography>

            <Typography variant="h5">
              <b>First Deposit: </b> {"$500 (01/05/2020)"}
            </Typography>
            <Typography variant="h5">
              <b>Most Recent Deposit: </b> {"$100 (04/06/2020)"}
            </Typography>
            <Typography variant="h5">
              <b>Location: </b> {"San Diego, CA"}
            </Typography>
            <Typography variant="h5">
              <b>Email: </b> {"test@fantasysportsco.com"}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

UserInfo.propTypes = {
  className: PropTypes.string,
  userDetails: PropTypes.any,
};

export default withAuthorization(UserInfo);
