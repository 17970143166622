import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Avatar, Typography, Grid, Button } from "@mui/material";
import { Form } from "react-bootstrap";
import {
  None_INT,
  Normal_INT,
  Suspended_INT,
  Banned_INT,
  GetName,
  GetEmail,
  GetCashoutEmail,
  GetUserStandingStatus,
  GetUserStandingStatusInt,
  GetUserStandingName,
  GetInitials,
  GetPhone,
  GetCreatedDate,
  GetUserLocation,
  GetUserId,
  GetProfileURL,
  GetUserPoints,
  GetSuspensionEndDate,
  GetAdminSetLocation
} from "helpers/UserHelper";
import {
  GetPointsFromDollars,
  GetDollarsFromPoints
} from "constants/constants";
import withAuthorization from "../../../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 100,
    height: 100,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const UserProfile = (props) => {
  const { className, user, exportTransactions, openeditstandingpopup, openeditemailpopup, openeditprovincepopup, openeditpointspopup, currentlocation, ...rest } = props;

  
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      
       <Avatar className={classes.avatar} src={GetProfileURL(user)}>
        {GetInitials(user)}
    
      </Avatar>
     
      <Typography variant="h2">{GetName(user)}</Typography>
      <Typography variant="h5">BFUID: {GetUserId(user)}</Typography>

      <Typography variant="h5">Acquired through Placeholder</Typography>
      <Typography variant="h5">Joined: {GetCreatedDate(user)}</Typography>
      <Typography variant="h5">
        Standing: {GetUserStandingStatus(user)}
        <Button
            className={"ml-2"}
            color="primary"
            size="small"
            variant="text"
            onClick={() => openeditstandingpopup()}
          >
            update
          </Button>
      </Typography>
      <Typography variant="h5">
        Export Transactions
        <Button
            className={"ml-2"}
            color="primary"
            size="small"
            variant="text"
            onClick={() => exportTransactions()}
          >
            export
          </Button>
      </Typography>
      
      {GetUserStandingStatusInt(user) == Suspended_INT && 
      <Typography variant="h5">Suspension Ends: {GetSuspensionEndDate(user)}</Typography>}
      <Typography variant="h5">
        Current Points: {GetUserPoints(user) +" ($"+GetDollarsFromPoints(GetUserPoints(user))+")"}
        <Button
              className={"ml-2"}
              color="primary"
              size="small"
              variant="text"
              onClick={() => openeditpointspopup()}
            >
              update
            </Button>
      </Typography>
      <Typography variant="h5">
        Paypal Email: {GetCashoutEmail(user)}
        <Button
              className={"ml-2"}
              color="primary"
              size="small"
              variant="text"
              onClick={() => openeditemailpopup()}
            >
              update
            </Button>
      </Typography>
      <Typography variant="h5">
        Current Location: {(currentlocation !== undefined)?currentlocation.Name:"Not Set"}
        <Button
              className={"ml-2"}
              color="primary"
              size="small"
              variant="text"
              onClick={() => openeditprovincepopup()}
            >
              update
            </Button>
      </Typography>
      {GetAdminSetLocation(user) && <Typography variant="h6">
      Location Set By Admin
      </Typography>}
     
    </div>
  );
};

UserProfile.propTypes = {
  className: PropTypes.string,
};

export default withAuthorization(UserProfile);
