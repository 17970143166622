import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Avatar, Typography } from "@mui/material";
import SessionHelper from '../../../../../../helpers/SessionHelper'
import withAuthorization from "../../../../../../helpers/Authorization";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content"
  },
  avatar: {
    width: 60,
    height: 60,
    '& img': {
      objectFit : "contain"
    }
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const user = {
    name: SessionHelper.getName(),
    avatar: SessionHelper.getIcon(),
    bio: SessionHelper.getRole(),
    company: SessionHelper.getCompany()
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={user.avatar}
        to="/settings"
        variant="square"
      />
      <Typography className={classes.name} variant="body1">
        {user.name}
      </Typography>
      <Typography variant="body2">{user.bio}</Typography>
      <Typography variant="body2">{user.company}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default withAuthorization(Profile);
