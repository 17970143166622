import * as React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import FilterListIcon from '@mui/icons-material/FilterList';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { visuallyHidden } from '@mui/utils';
import { TableVirtuoso } from 'react-virtuoso';
import Checkbox from '@mui/material/Checkbox';

import withMasterAuthorization from "../../../../helpers/MasterAuthorization";
import WebService from "../../../../api/WebServices.js";

import moment from "moment";

const CommissionTable = props => {
  const { className, Commissions, history, ...rest } = props;
  const [dense, setDense] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('merchant');

  const [editMode, setEditMode] = React.useState('merchant');
  const [editTitle, setEditTitle] = React.useState('');

  const [overrideState, setOverrideState] = React.useState({
    editMerchantId: 0,
    editBool1: false,
    editBool2: false,
    editBool3: false,
    editString1: '',
    editString2: '',
    editString3: '',
    editDate1: '',
    editDate2: '',
    editDisabled1: false,
  });

  function resetOverrideState(){
    setOverrideState({
      editMerchantId: 0,
      editBool1: false,
      editBool2: false,
      editBool3: false,
      editString1: '',
      editString2: '',
      editString3: '',
      editDate1: '',
      editDate2: '',
      editDisabled1: false
    });
  };

  const handleOverrideChangeString = (event) => {
    setOverrideState({
      ...overrideState,
      [event.target.name]: event.target.value,
    });
  };

  const handleOverrideChangeBool = (event) => {
    setOverrideState({
      ...overrideState,
      [event.target.name]: event.target.checked,
    });
  };

  const [openEdit, setOpenEdit] = React.useState(false);

  const handleClickOpenEdit = (title, editMode, rowItem) => {
    resetOverrideState();

    setEditTitle(title);
    setEditMode(editMode);

    if(editMode === 'merchant'){
      setOverrideState({
        ...overrideState,
        editMerchantId: rowItem.Id,
        editBool1: !rowItem.InApp,
        editBool2: rowItem.IsPreferredMerchant,
        editString1: rowItem.MerchantName,
      });
    }

    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  function getConvertedDate(dateToDisplay) {
    if (dateToDisplay) {
      return moment(dateToDisplay).format("MMM DD, YYYY");
    } else {
      return "-";
    }
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = [
    {
      id: 'Id',
      numeric: true,
      disablePadding: false,
      label: 'Internal Id',
    },
    {
      id: 'WildFireDeviceId',
      numeric: true,
      disablePadding: false,
      label: 'Device Id',
    },
    {
      id: 'BetfullyUserId',
      numeric: true,
      disablePadding: false,
      label: 'BetFully User',
    },
    {
      id: 'DateCreated',
      numeric: false,
      disablePadding: false,
      label: 'Date Created (Internal)',
    },
    {
      id: 'WFCommissionID',
      numeric: true,
      disablePadding: false,
      label: 'WF Commission Id',
    },
    {
      id: 'WFApplicationId',
      numeric: true,
      disablePadding: false,
      label: 'WF App Id',
    },
    {
      id: 'WFMerchantID',
      numeric: true,
      disablePadding: false,
      label: 'Merchant Id',
    },
    {
      id: 'WFStatus',
      numeric: false,
      disablePadding: false,
      label: 'WF Status',
    },
    {
      id: 'WFEventDate',
      numeric: false,
      disablePadding: false,
      label: 'WF Event Date',
    },
    {
      id: 'WFLockingDate',
      numeric: false,
      disablePadding: false,
      label: 'WF Locking Date',
    },
    {
      id: 'WFCreatedDate',
      numeric: false,
      disablePadding: false,
      label: 'WF Created Date',
    },
    {
      id: 'WFWFModifiedDate',
      numeric: false,
      disablePadding: false,
      label: 'WF Modified Date',
    },
    {
      id: 'WFMerchantOrderID',
      numeric: false,
      disablePadding: false,
      label: 'Merchant Order Id',
    },
    {
      id: 'WFMerchantSKU',
      numeric: false,
      disablePadding: false,
      label: 'Merchant SKU',
    },
    {
      id: 'WFTrackingCode',
      numeric: false,
      disablePadding: false,
      label: 'Tracking Code',
    },
  ];

  function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
        <TableCell align="right" padding={'normal'}></TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const rows = Commissions;
 
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, Commissions],
  );
  
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Wildfire Commissions
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  }
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    

    return (
      <React.Fragment>
        <TableRow >
          <TableCell padding="checkbox">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {/* <TableCell component="th" scope="row">
            {row.Name}
          </TableCell> */}
          <TableCell onClick={() => {}} align={'center'} padding={'none'}>{row.Id}</TableCell>
          <TableCell onClick={() => {}} align={'center'} padding={'normal'}>{row.WildFireDeviceId}</TableCell>
          <TableCell onClick={() => {}} align={'center'} padding={'normal'}>{row.BetfullyUserId}</TableCell>
          <TableCell onClick={() => {}} align={'center'} padding={'normal'}>{getConvertedDate(row.DateCreated)}</TableCell>
          <TableCell onClick={() => {}} align={'center'} padding={'normal'}>{row.WFCommissionID}</TableCell>
          <TableCell onClick={() => {}} align={'center'} padding={'normal'} >{row.WFApplicationID}</TableCell>
          <TableCell onClick={() => {}} align={'center'} padding={'normal'}>{row.WFMerchantID}</TableCell>
          <TableCell onClick={() => {}} align={'center'} padding={'normal'}>{row.WFStatus}</TableCell>
          <TableCell onClick={() => {}} align={'center'} padding={'normal'}>{getConvertedDate(row.WFEventDate)}</TableCell>
          <TableCell onClick={() => {}} align={'center'} padding={'normal'}>{getConvertedDate(row.WFLockingDate)}</TableCell>
          <TableCell onClick={() => {}} align={'center'} padding={'normal'}>{getConvertedDate(row.WFCreatedDate)}</TableCell>
          <TableCell onClick={() => {}} align={'center'} padding={'normal'}>{getConvertedDate(row.WFWFModifiedDate)}</TableCell>
          <TableCell onClick={() => {}} align={'center'} padding={'normal'}>{row.WFMerchantOrderID}</TableCell>
          <TableCell onClick={() => {}} align={'center'} padding={'normal'}>{row.WFMerchantSKU}</TableCell>
          <TableCell onClick={() => {}} align={'center'} padding={'normal'}>{row.WFTrackingCode}</TableCell>
        </TableRow>
        <TableRow  style={{backgroundColor: 'gainsboro'  }}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0}} colSpan={15}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 0, paddingTop: 1}}>
                {/* <ImageList sx={{height: 150 }} cols={6}>
                  {row.DisplayImages.map((item) => (
                    <ImageListItem key={item.Id}  >
                      <img
                        style={{ objectFit: 'contain', height: 90 }}
                        srcSet={`${item.ImageUrl}?h=124&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item.ImageUrl}?h=124&fit=crop&auto=format`}
                        alt={item.ImageName}
                        loading="lazy"
                      />
                      <ImageListItemBar
                      align={'center'}
                        title={(item.IsActive&&item.ImageType==3)?"Active (Icon)":((item.IsActive&&item.ImageType==4)?"Active (BG)":"Inactive")}
                        subtitle={<span>w:{item.Width} h:{item.Height} ({item.ImageName} {item.Id})</span>}
                        position="below"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
                {row.Offers != null && row.Offers.length > 0 &&
                <Typography variant="h6" gutterBottom component="div">
                  Offers
                </Typography>}
                {row.Offers != null && row.Offers.length > 0 && 
                <Table size="small" aria-label="offers"  >
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell align="center">Valid For App</TableCell>
                      <TableCell align="center">In US</TableCell>
                      <TableCell align="center">Code</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Exclusions</TableCell>
                      <TableCell align="center">Start</TableCell>
                      <TableCell align="center">End</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { row.Offers !== null && row.Offers.map((historyRow) => (
                      <TableRow key={historyRow.Id}>
                        <TableCell component="th" scope="row">
                          {historyRow.Id}
                        </TableCell>
                        <TableCell align="center">{historyRow.ValidForInApp?"Yes":"No"}</TableCell>
                        <TableCell align="center">{historyRow.InUS?"Yes":"No"}</TableCell>
                        <TableCell align="center">{historyRow.Code}</TableCell>
                        <TableCell align="left">{historyRow.Description}</TableCell>
                        <TableCell align="left">{historyRow.Exclusions}</TableCell>
                        <TableCell align="center">{getConvertedDate(historyRow.StartDate)}</TableCell>
                        <TableCell align="center">{getConvertedDate(historyRow.EndDate)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>} */}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  Row.propTypes = {
    row: PropTypes.shape({
      history: PropTypes.arrayOf(
        PropTypes.shape({
          Id: PropTypes.number.isRequired,
          Description: PropTypes.string.isRequired,
          Code: PropTypes.string.isRequired,
          Exclusions: PropTypes.string.isRequired,
          StartDate: PropTypes.string.isRequired,
          EndDate: PropTypes.string.isRequired,
          DisplayImages: PropTypes.array.isRequired,
          DisplayImagesCount: PropTypes.number.isRequired
        }),
      ).isRequired,
      MerchantName: PropTypes.string.isRequired,
      // Url: PropTypes.string.isRequired,
      Id: PropTypes.number.isRequired,
      CommissionCount: PropTypes.number.isRequired,
      OfferCount: PropTypes.number.isRequired,
    }).isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={0} />
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            {/* <TableHead>
              <TableRow>
                <TableCell align="right" padding={'normal'}></TableCell>
                <TableCell align="left" padding="none">Merchant</TableCell>
                <TableCell align="center" padding={'normal'}>InApp</TableCell>
                <TableCell align="center" padding={'normal'}>Preferred</TableCell>
                <TableCell align="center" padding={'normal'}>US Based</TableCell>
                <TableCell align="center" padding="none">Images</TableCell>
                <TableCell align="right" padding={'normal'}>Wildfire ID</TableCell>
                <TableCell align="center" padding={'normal'}>Offers</TableCell>
                <TableCell align="center" padding={'normal'}>Commissions</TableCell>
              </TableRow>
            </TableHead> */}
            <EnhancedTableHead
              numSelected={0}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={()=>{}}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => (
                <Row key={row.name} row={row} />
              ))}
              {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={5} />
                    </TableRow>
                  )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      <Dialog
        // fullWidth={fullWidth}
        // maxWidth={maxWidth}
        open={openEdit}
        onClose={handleCloseEdit}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.merchantname;
            console.log(formData);
            console.log('Override State:');
            console.log(overrideState);
            WebService.WildfireItemOverride(history, overrideState)
                .then(data => {
                  console.log("ABC DONE");
                  console.log("Data: "+data);
                  if(data != null) {
                    window.location.reload();
                  }
                  //setIsLoading(false);

                  handleCloseEdit();

                });
          },
        }}
      >
        <DialogTitle>{editTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText>
         
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
           
              <InputLabel htmlFor="max-width">maxWidth</InputLabel>
              <Select
                autoFocus
                value={'aaa'}
                onChange={()=>{}}
                label="maxWidth"
                inputProps={{
                  name: 'max-width',
                  id: 'max-width',
                }}
              >
                <MenuItem value={false}>false</MenuItem>
                <MenuItem value="xs">xs</MenuItem>
                <MenuItem value="sm">sm</MenuItem>
                <MenuItem value="md">md</MenuItem>
                <MenuItem value="lg">lg</MenuItem>
                <MenuItem value="xl">xl</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch checked={true} onChange={()=>{}} />
              }
              label="Full width"
            />
            {editMode === 'merchant' && <TextField
            autoFocus
            required
            margin="dense"
            label="Merchant Name (As it should appear in app)"
            type="text"
            name="editString1"
            fullWidth
            variant="standard"
            value={overrideState.editString1}
            onChange={handleOverrideChangeString}
          />}
          {editMode === 'merchant' && <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch checked={overrideState.editBool1} onChange={handleOverrideChangeBool} name="editBool1" />
              }
              label="Don't show in App"
            />}
            {editMode === 'merchant' && <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch checked={overrideState.editBool2} onChange={handleOverrideChangeBool} name="editBool2" />
              }
              label="Preferred Merchant"
            />}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit}>Close</Button>
          <Button type="submit">Subscribe</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

CommissionTable.propTypes = {
  className: PropTypes.string,
  Commissions: PropTypes.array.isRequired,
  history: PropTypes.object
};

export default withMasterAuthorization(CommissionTable);
