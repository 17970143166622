import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {Button} from "@mui/material";
import React, {useState} from "react";
import PropTypes from "prop-types";
import withAuthorization from "../../../../helpers/Authorization";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
   content:{
      marginTop:theme.spacing(3)
   }
}));

const CreateKeywordModal = (props) => {
   const classes = useStyles();
   const [keyword, setKeyword] = useState("");

   const keywordChangeHandler = (event) => {
      event.persist();
      let word = event.target.value;
      setKeyword(word)
   }

   return (
      <Modal
         className={classes.content}
         show={props.showAddKeyword}
         onHide={props.handleCloseAddKeyword}
         centered>
         <Modal.Header closeButton>
            <Modal.Title>{"Create Keyword"}</Modal.Title>
         </Modal.Header>
            <Form onSubmit={() => props.handleCreateKeyword(keyword)}>
         <Modal.Body>
               <Form.Group controlId="formBasicEmail">
                  <Form.Label>Input the keyword you would like us to scan for</Form.Label>
                  <Form.Control required placeholder="Enter keyword" 
                                name='keywordInput' 
                                onChange={keywordChangeHandler}/>
                  <Form.Text className="text-muted">

                  </Form.Text>
               </Form.Group>
         </Modal.Body>
         <Modal.Footer>
            <Button
               variant="contained"
               color="primary"
               onClick={() => props.handleCreateKeyword(keyword)}
               >
               Submit
            </Button>
            <Button variant="contained" onClick={props.handleCloseAddKeyword}>
               Cancel
            </Button>
         </Modal.Footer>
                  </Form>
      </Modal>
   );
};

CreateKeywordModal.propTypes = {
   history: PropTypes.object
}

export default withAuthorization(CreateKeywordModal);