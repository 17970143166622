import React, {useState, useEffect} from "react";
import { makeStyles } from "@mui/styles";
import WebService from "../../api/WebServices.js";
import PropTypes from "prop-types";
import PaypalPayoutItemList from './components/PaypalPayoutItemList';
import PaypalWebhookDetailsHeader from './components/PaypalWebhookDetailsHeader';
import { GetPaypalPayoutItemId } from '../../helpers/PaypalPayoutItemHelper'
import { GetPaypalId, GetTimeCreated } from '../../helpers/PaypalWebhookResponseHelper'
import withMasterAuthorization from "../../helpers/MasterAuthorization";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}
));

const PaypalWebhookDetails = props => {
  const { history, ...rest} = props;
  const classes = useStyles();
  const paypalWebhook = props.location.state !== undefined ? props.location.state.ppalWebhook : [];
  
  return(
   <div className={classes.root}>
      <PaypalWebhookDetailsHeader
        payoutItemId={GetPaypalId(paypalWebhook)}
        dateCreated={GetTimeCreated(paypalWebhook)} 
      />
      <PaypalPayoutItemList
        history={history}
      />
   </div>
  );
  
}

PaypalWebhookDetails.propTypes = {
  history: PropTypes.object,
  ppalWebhook: PropTypes.object,
};

export default withMasterAuthorization(PaypalWebhookDetails);