import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Avatar, Typography } from "@mui/material";
import SessionHelper from "../../../../helpers/SessionHelper";
import {GetCompanyId} from "../../../../helpers/CompanyHelper";
import withAuthorization from "../../../../helpers/Authorization";
import Button from "@mui/material/Button";
import ImagePreviewGrid from '../../../StoredImageList/Components/ImagePreviewGrid'


const useStyles = makeStyles(theme => ({
   root: {
      display: "flex",
      alignItems: "center",
      minHeight: "fit-content"
   },
   avatar: {
      height: 60,
      width: 60,
      '& img': {
         objectFit : "contain"
      }
   },
   imageContainer: {
      height: 64,
      width: 60,
      margin: "auto",
      marginBottom: "20px",
      borderRadius: "1px",
      overflow: "hidden",
      display: "flex",
      alignItems: "normal",
      justifyContent: "center",
    },
    imageStoredImage: {
      width: "100%",
      objectFit : "contain"
    }
}));

const Profile = (props) => {
   const { className, ...rest } = props;

   const classes = useStyles();

   const user = {
      name: SessionHelper.getName(),
      avatar: SessionHelper.getIcon(),
      bio: SessionHelper.getRole(),
      company: SessionHelper.getCompany()
   };

   const [ImgUrlInput, setImgUrlInput] = useState(user.avatar);
   const [StoredImageId, setStoredImageId] = useState(0);

   const [showViewStoredImages, setShowViewStoredImages] = useState(false);
   const handleCloseViewStoredImages = () => setShowViewStoredImages(false);
   const handleShowViewStoredImages = () => {
      setShowViewStoredImages(true);
   }
   const onImageSelected = (imageId, imageUrl) => {
      console.log("Image selected Id: "+ imageId)
      setImgUrlInput(imageUrl);
      setStoredImageId(imageId);
      handleCloseViewStoredImages();
   }


   return (
      <div {...rest} className={clsx(classes.root, className)}>
         <ImagePreviewGrid 
          showViewStoredImages={showViewStoredImages} 
          handleCloseViewStoredImages={handleCloseViewStoredImages}
          onImageSelected={onImageSelected}
          companyId={GetCompanyId(user.company)}
          imageType={1}/>
         <div className={classes.imageContainer}>
            <img
               className={classes.imageStoredImage}
               component={RouterLink}
               src={ImgUrlInput}
            />
         </div>
         <Button 
            variant="contained"
            color="secondary"
            onClick={() => handleShowViewStoredImages()}
         >
            Upload New Image
         </Button>
         
      </div>
   );
};

Profile.propTypes = {
   className: PropTypes.string
};

export default withAuthorization(Profile);
