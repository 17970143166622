export const moneyOptions = {
  tooltips : {
    callbacks: {
      label: function(tooltipItems, data) {
          var label = ''//data.dataset.label || '';
          console.log(data)
          console.log(tooltipItems)
          var moneyFormat = false;
          var suffix = ""
          var prefix = ""
          if (data && data.datasets) {
              label += data.datasets[tooltipItems.datasetIndex].label+': ';
              moneyFormat = data.datasets[tooltipItems.datasetIndex].labelFormatCurrency;
              suffix = data.datasets[tooltipItems.datasetIndex].labelSuffix;
              prefix = data.datasets[tooltipItems.datasetIndex].labelPrefix;
          }
          if (tooltipItems.value !== null) {
            label += prefix;
            label += (moneyFormat)?new Intl.NumericFormat('en-US', { style: 'currency', currency: 'USD' }).format(tooltipItems.value):new Intl.NumericFormat('en-US').format(tooltipItems.value);
            label += suffix;
          }
          return label;
      }
  }
},
maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export const options = {
  tooltips : {
   
},
maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export const pieOptions = {
  legend: {
    display: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cutoutPercentage: 0,
  layout: { padding: 1 },
  tooltips: {
    enabled: true,
    mode: "index",
    intersect: false,
    borderWidth: 1,
    // borderColor: theme.palette.divider,
    // backgroundColor: theme.palette.white,
    // titleFontColor: theme.palette.text.primary,
    // bodyFontColor: theme.palette.text.secondary,
    // footerFontColor: theme.palette.text.secondary,
  },
};  

export const pieMoneyOptions = {
  legend: {
    display: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  cutoutPercentage: 80,
  layout: { padding: 1 },
  // arc: {
  //   borderWidth: 100
  // },
  tooltips: {
    enabled: true,
    mode: "index",
    intersect: false,
    borderWidth: 1,
    // borderColor: theme.palette.divider,
    // backgroundColor: theme.palette.white,
    // titleFontColor: theme.palette.text.primary,
    // bodyFontColor: theme.palette.text.secondary,
    // footerFontColor: theme.palette.text.secondary,
    callbacks: {
      label: function(tooltipItems, data) {
          var label = ''//data.dataset.label || '';
          console.log(data)
          console.log(tooltipItems)
          var moneyFormat = (tooltipItems.index == 2)?false:false;
          var prefix = (tooltipItems.index == 2)?"":"";
          var suffix = (tooltipItems.index == 2)?"":"";
          
          if (data && data.datasets) {
              label += data.labels[tooltipItems.index]+': ';
              moneyFormat = data.datasets[0].labelFormatCurrency[tooltipItems.index]
              prefix = data.datasets[0].labelPrefix[tooltipItems.index]
              suffix = data.datasets[0].labelSuffix[tooltipItems.index]
          }
          if (tooltipItems.value !== null) {
              label += prefix;
              label += (moneyFormat)?new Intl.NumericFormat('en-US', { style: 'currency', currency: 'USD' }).format(data.datasets[0].data[tooltipItems.index]):new Intl.NumericFormat('en-US').format(data.datasets[0].data[tooltipItems.index]);
              label += suffix;
          }
          return label;
      }
  }
  },
};  


export function FormatDataForPie(dataMap, barChartData, RetrieveFieldFunc, colors, useOfferId){
  var dataSet = {
    datasets: [{
      data: [],
      backgroundColor: [],
      borderWidth: 2,
      borderColor: [],
      hoverBorderColor: [],
      labelPrefix:[],
      labelSuffix:[],
      labelFormatCurrency:[]
    }],
    labels: []
  }
  if(barChartData != null && barChartData.datasets != null && barChartData.datasets.length > 0){
      barChartData.datasets.map(x =>
        dataSet.datasets[0].data.push(
          RetrieveFieldFunc((useOfferId)?x.offerId:x.companyId, dataMap, x.tEFValue))
      )
    }
    
    barChartData.datasets.map(x => 
      dataSet.datasets[0].backgroundColor.push(
        x.backgroundColor)
    )
    barChartData.datasets.map(x => 
      dataSet.datasets[0].borderColor.push(
        colors.common.white)
    )
    barChartData.datasets.map(x => 
      dataSet.datasets[0].hoverBorderColor.push(
        x.borderColor)
    )
    barChartData.datasets.map(x => 
      dataSet.labels.push(x.label)
    )
    barChartData.datasets.map(x => 
      dataSet.datasets[0].labelPrefix.push(
        x.labelPrefix)
    )
    barChartData.datasets.map(x => 
      dataSet.datasets[0].labelSuffix.push(
        x.labelSuffix)
    )
    barChartData.datasets.map(x => 
      dataSet.datasets[0].labelFormatCurrency.push(
        x.labelFormatCurrency)
    )
  
  return dataSet;
}

export const COLUMNS_TYPE = {
  DEFAULT: -1,
  NUM: 1,
  REV_NUM: 2,
  STR:3,
  REV_STR:4,
  DATE:5,
  REV_DATE:6
}

export const COLUMNS_SORT = {
  DEFAULT: -1,
  COL1_DESC: 0,
  COL1_ASC: 1,
  COL2_DESC: 2,
  COL2_ASC: 3,
  COL3_DESC: 4,
  COL3_ASC: 5,
  COL4_DESC: 6,
  COL4_ASC: 7,
  COL5_DESC: 8,
  COL5_ASC: 9,
  COL6_DESC: 10,
  COL6_ASC: 11,
  COL7_DESC: 12,
  COL7_ASC: 13
}

export const toggleSortFunc = (selectedColumn, currentColumn, currentList, sortTypeList, dataRetrieveFunctionList, dataRetrieveId, completeFunction, setSortFunction) => {
  var nextColumn = selectedColumn;
  // console.log("SelectedColumn: "+selectedColumn)
  //If current column is even, and the next column is 1 above, it is a toggle
  if(currentColumn % 2 == 0 && selectedColumn == currentColumn){
    nextColumn = selectedColumn+1;
  }else if(currentColumn % 2 == 1 && selectedColumn == currentColumn){
    nextColumn = selectedColumn-1;
  }
  // console.log("NextColumn: "+nextColumn)
  // console.log("OldColumn: "+currentColumn)
  sortColumnLists(nextColumn, currentList, sortTypeList, dataRetrieveFunctionList, dataRetrieveId, completeFunction, setSortFunction)
};

export const isSortActive = (columnType, currentActiveSort) => {
  // console.log("Current Sort: "+currentActiveSort)
  // console.log("Check Sort: "+columnType)
  // console.log("Check Sort: "+columnType)
  return currentActiveSort == columnType;
};

const sortColumnLists = (currentColumn, currentList, sortTypeList, dataRetrieveFunctionList, dataRetrieveId, completeFunction, setSortFunction) =>{
  var sortType = sortTypeList[currentColumn];
  const dataRetrieveFunction = dataRetrieveFunctionList[currentColumn];
  if(sortType === undefined)
    sortType = COLUMNS_SORT.DEFAULT;
  // console.log("Sort type: "+sortType)
  // console.log("Column index: "+currentColumn)
  currentList.sort(function(userA, userB) {
    var compareResult = 0;
    switch(sortType){
      case COLUMNS_TYPE.NUM:
        if (dataRetrieveFunction(userA)<dataRetrieveFunction(userB)){
          compareResult = -1;
        }
        if (dataRetrieveFunction(userA)>dataRetrieveFunction(userB)) {
          compareResult = 1;
        }
        break;
      case COLUMNS_TYPE.REV_NUM:
        if (dataRetrieveFunction(userA)<dataRetrieveFunction(userB)){
          compareResult = 1;
        }
        if (dataRetrieveFunction(userA)>dataRetrieveFunction(userB)) {
          compareResult = -1;
        }
        break;
      case COLUMNS_TYPE.STR:
        compareResult = dataRetrieveFunction(userB).localeCompare(dataRetrieveFunction(userA));
        break;
      case COLUMNS_TYPE.REV_STR:
        compareResult = dataRetrieveFunction(userA).localeCompare(dataRetrieveFunction(userB));
        break;
      case COLUMNS_TYPE.DATE:
        compareResult = new Date(dataRetrieveFunction(userA)) - new Date(dataRetrieveFunction(userB));
        break;
        case COLUMNS_TYPE.REV_DATE:
        compareResult = new Date(dataRetrieveFunction(userB)) - new Date(dataRetrieveFunction(userA));
        break;
    }
    //Compare unique ids in case of ties
    if(compareResult == 0){
      if (dataRetrieveId(userA)<dataRetrieveId(userB)){
        compareResult = -1;
      }
      if (dataRetrieveId(userA)>dataRetrieveId(userB)) {
        compareResult = 1;
      }
    }
    return compareResult;
  });
  //applyAllFilters();
  setSortFunction(currentColumn)
  if(completeFunction !== undefined)
    completeFunction();
};