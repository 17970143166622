import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Typography, Link } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
     position: 'fixed',
     bottom: 0,
     width: '100%',
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body1">
        &copy;{" "}
        <Link component="a" href="http://fantasysportsco.com/" target="_blank">
          Fantasy Sports Co {new Date().getFullYear()} 
        </Link>
      </Typography>
      <Typography variant="caption">
        Made with ❤️in Sunny ☀️San Diego 🌯
      </Typography>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
