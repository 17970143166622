import uuid from "uuid/v1";

export default [
  {
    id: uuid(),
    date: "01/04/2019",
    type: 1,
    message: "User Logged in",
    status: "True",
  },
  {
    id: uuid(),
    date: "01/04/2019",
    type: 1,
    message: "User Logged in",
    status: "True",
  },
  {
    id: uuid(),
    date: "01/04/2019",
    type: 1,
    message: "User Logged in",
    status: "True",
  },
  {
    id: uuid(),
    date: "01/04/2019",
    type: 1,
    message: "User Logged in",
    status: "True",
  },
  {
    id: uuid(),
    date: "01/04/2019",
    type: 1,
    message: "User Logged in",
    status: "True",
  },
];
