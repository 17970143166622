import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import WebService from "../../api/WebServices.js";
import PropTypes from "prop-types";
import KeywordTable from './Components/KeywordTable'
import KeywordToolbar from './Components/KeywordToolbar'
import UnmatchedSection from './Components/UnmatchedSection'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import KeywordFooterButtons from './Components/KeywordFooterButtons'
import {         KeywordStatusToInt, 
        GetPopupTitle, 
        GetPopupPos,
        GetPopupNeg,
        ShowPopupPosAdmin,
        ShowPopupNegAdmin,
        GetPopupBody } from '../../helpers/KeywordHelper'
import Form from 'react-bootstrap/Form';
import withMasterAuthorization from "../../helpers/MasterAuthorization";
import DenyingKeywordModal from "./Components/DenyingKeywordModal";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  }
}));

const MasterKeywordList = props => {
  const { history, ...rest } = props;
  const classes = useStyles();
  const [AllKeywords, setAllKeywords] = useState([]);
  const [typeOfConfirmation, setTypeOfConfirmation] = useState();
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [activeCount, setActiveCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [expiredCount, setExpiredCount] = useState(0);
  const [suspendedCount, setSuspendingCount] = useState(0);
  const [revisionCount, setRevisionCount] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState(KeywordStatusToInt.Pending);
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  const [showDenyingPopup, setShowDenyingPopup] = useState(false);
  const [selectedCompanyFilter, setSelectedCompanyFilter] = useState(0);

  useEffect(() => {
    applyAllFilters();
  }, [selectedCompanyFilter, selectedFilter, AllKeywords]);

  const handleFilterSelected = (value) => {
      setSelectedCompanyFilter(value)
    };

    const handleStatusFilterSelected = (value) => {
      setSelectedFilter(value)
    };

  const filterCompanies = () =>{
      console.log(selectedCompanyFilter)
      if(selectedCompanyFilter !== 0){
          console.log("company filter")
          const filteredRes = filteredKeywords.filter(aUser => (aUser.CompanyId) === selectedFilter)
          setFilteredKeywords(filteredRes)
      }else{
          console.log("No company filter")
      }
  }

  const applyAllFilters = () => {
    var allKeywordsVar = AllKeywords;
    console.log("All keyword Count "+allKeywordsVar.length)
    console.log(selectedCompanyFilter)
      if(selectedCompanyFilter != 0){
          console.log("company filter")
          allKeywordsVar = allKeywordsVar.filter(aUser => (aUser.CompanyId) == selectedCompanyFilter)
      }else{
          console.log("No company filter")
      }

      if(allKeywordsVar!= null){
        
        var activeCountA=0;
        var pendingCountA=0;
        var expiredCountA=0;
        var suspendedCountA=0;
        var revisionCountA=0;
        for (let index = 0; index < allKeywordsVar.length; index++) {
          switch(allKeywordsVar[index].Status){
            case KeywordStatusToInt.Active:
              activeCountA++;
              break;
            case KeywordStatusToInt.Pending:
              pendingCountA++;
              break;
            case KeywordStatusToInt.Removed:
              expiredCountA++;
              break;
            case KeywordStatusToInt.Suspended:
              suspendedCountA++
              break;
            case KeywordStatusToInt.Fix:
              revisionCountA++;
              break;
          }
        }
        setActiveCount(activeCountA);
        setPendingCount(pendingCountA);
        setExpiredCount(expiredCountA);
        setSuspendingCount(suspendedCountA);
        setRevisionCount(revisionCountA);
      }
      var statusIntValue = selectedFilter;
  
    console.log(statusIntValue)
    if(statusIntValue != 0 && allKeywordsVar != null){
        console.log("Keywords count: " + allKeywordsVar.length)
        allKeywordsVar = allKeywordsVar.filter(aUser => (aUser.Status) == statusIntValue)
        
    }else{
        console.log("No status filter")
    }
    setFilteredKeywords(allKeywordsVar)
  }
  
  const handleClosingDenyingPopup = () => setShowDenyingPopup(false);
  const handleOpeningDenyingPopup = () => setShowDenyingPopup(true);
  
  const handleSendKeywordApproval = (keywordId, approved, noteInput) => {
    console.log("Send Approval")
    console.log("Id: " + keywordId);
    console.log((approved) ? "Approve":"Reject");
    console.log("Feedback: " + noteInput);
    var RequestObject = {
      IntTag: keywordId,
      IntTag2: (approved) ? 1 : 0,
      StringTag:noteInput 
    };
    WebService.ProcessKeyword(history, RequestObject)
      .then(data => {
        if(data != null){
          console.log("Keyword Processed: Success");
          console.log(data);
          setAllKeywords(data);
        }else{
          console.log("Keyword Processed: Failed");
        }
        handleClosingDenyingPopup();
      });
  }


  const GetCounts = (keywords) => {
    if(keywords != null){
      var activeCountA=0;
      var pendingCountA=0;
      var expiredCountA=0;
      var suspendedCountA=0;
      var revisionCountA=0;
      for (let index = 0; index < keywords.length; index++) {
        switch(keywords[index].Status){
          case KeywordStatusToInt.Active:
            activeCountA++;
            break;
          case KeywordStatusToInt.Pending:
            pendingCountA++;
            break;
          case KeywordStatusToInt.Removed:
            expiredCountA++;
            break;
          case KeywordStatusToInt.Suspended:
            suspendedCountA++
            break;
          case KeywordStatusToInt.Fix:
            revisionCountA++;
            break;
        }
      }
      setActiveCount(activeCountA);
      setPendingCount(pendingCountA);
      setExpiredCount(expiredCountA);
      setSuspendingCount(suspendedCountA);
      setRevisionCount(revisionCountA);
    }
  }
  
  const GetUserPayoutsFilteredBy = (filter) => {
    var statusIntValue = filter;
    console.log(statusIntValue)
    if(statusIntValue !== 0){
        console.log("status filter: " + filteredKeywords.length)
        const filteredRes = filteredKeywords.filter(aUser => (aUser.Status) === statusIntValue)
        setFilteredKeywords(filteredRes)
    }else{
        console.log("No status filter")
    }
  }

  const handleShowingKeywordDenyingPopup = (keywordModel) => {
    console.log("Denying keyword: "+ keywordModel.Word);
    setSelectedKeyword(keywordModel);
    handleOpeningDenyingPopup();
  }
  
  useEffect(() => {
    const fetchData = async () => {
      const result = await WebService.GetAllKeywords(history, undefined);
      if(result != null){
        console.log("GetAllKeywords results:");
        setAllKeywords(result);
      }else{
        history.push({ pathname: "./sign-in" });
      }
    };
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      <DenyingKeywordModal
         showDenyingPopup={showDenyingPopup}
         handleCloseDenyingPopup={handleClosingDenyingPopup}
         handleUpdatingKeywordStatus={handleSendKeywordApproval}
         Keyword={selectedKeyword}
      />
      
      <KeywordToolbar
        filterUsersBy={handleStatusFilterSelected} 
        currentFilter={selectedFilter} 
        history={history}
        activeCount={activeCount}
        pendingCount={pendingCount}
        expiredCount={expiredCount}
        suspendedCount={suspendedCount}
        revisionCount={revisionCount}
        onFilterSelected={handleFilterSelected}
      />

      <div className={classes.content}>
        <KeywordTable
          handleKeywordApproval= {handleSendKeywordApproval}
          openPopupForDenying = {handleShowingKeywordDenyingPopup} 
          Keywords={filteredKeywords}
          history={history}
        />
      </div>
      <UnmatchedSection  />;
    </div>
  );
};

MasterKeywordList.propTypes = {
  history: PropTypes.object
};

export default withMasterAuthorization(MasterKeywordList);