import moment from "moment";
import SessionHelper from "helpers/SessionHelper";

export function GetCompanyId(company){ 
  if(company !== undefined && company !== null) {
    return company.Id !== null ? company.Id : 0;
  }
  return 0;
}

export function GetCompanyStoredImageId(company){ 
  if(company !== undefined && company !== null) {
    return company.StoredImageId !== null ? company.StoredImageId : 0;
  }
  return 0;
}

/**
 * @return {string}
 */
export function GetURL(company) {
  if (company.Url) {
    return company.Url;
  } else {
    return "N/A";
  }
}
export function GetCategory(company){
  if(company.Category){
    console.log("Category value is: ");
    console.log(company.Category)
    switch(company.Category){
      case 0: 
        return "Online Casinos";
      case 1:
        return "Sportsbooks";
      case 2:
        return "Fantasy Sports";
      case 3: 
        return "Horse Racing";
      case 4: 
        return "Food and Restaurant";
      case 5:
        return "Free Money";
        
    }
  }else{
    return "N/A";
  }
}

/**
 * @return {string}
 */
export function GetCompanyName(company){
  if(company.Name){
    return company.Name;
  }else{ 
    return "N/A";
  }
}

export function GetImageURL(company){ 
  return company.ImageUrl !== null ? company.ImageUrl : "/assets/betfully-icon.png";
}

/**
 * @return {string}
 */
export function GetDateCreated(company) {
  return company.DateCreated !== null ? moment(company.DateCreated).format("MMM DD, YYYY"): "N/A"; 
}

export function GetCompanyNameWithId(companyId){
  const compList = SessionHelper.getCompanyList();
  var foundName = "n/a"
  if(compList !== undefined && compList != null){
    const comp = compList.find(x => GetCompanyId(x) == companyId);
    if(comp !== undefined && comp != null){
      foundName = GetCompanyName(comp);
    }
  }
  return foundName;
}

