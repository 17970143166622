import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, IconButton, Button } from "@mui/material";
// import { GetUserDetails } from "../../api/WebServices.js";
import WebService from "../../api/WebServices";
import SessionHelper from "../../helpers/SessionHelper";
import { Form, Modal } from "react-bootstrap";
import { Link as RouterLink, withRouter } from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { NumericFormat } from 'react-number-format';
import {
  PresetStandingChangeSuspend,
  PresetStandingChangeBan,
  None_INT,
  Normal_INT,
  Suspended_INT,
  Excluded_INT,
  Banned_INT,
  GetUserStandingStatus,
  GetUserStandingStatusInt,
  GetCashoutEmail,
  GetUserStandingName,
  GetCreatedDate,
  GetSuspensionEndDate,
  GetUserPoints,
  GetCurrentProvinceId,
  GetAdminSetLocation,
  GetUserLocation
} from "helpers/UserHelper";

import {
  UserProfile,
  OfferView,
  ClaimRate,
  UserInfo,
  UserActivityView,
  TransactionsView,
  UserBanks,
  UserBanksDetail
} from "./components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";
import { isConstructorDeclaration } from "typescript";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import withAuthorization from "../../helpers/Authorization";
import {GetDollarsFromPoints} from "constants/constants"

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const options = {
  INFO: "info",
  OFFERS: "offers",
  TRANSACTIONS: "transactions",
  STATS: "stats",
  ACTIVITY: "activity",
  BANKS: "banks",
  BANKSDETAIL: "banksdetails"
};

const UserDetails = (props) => {
  const classes = useStyles();
  const { history } = props;
  const [userDetails, setUserDetails] = useState([]);
  const [selectedBankAccountId, setBankAccountId] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [tabSelected, setTabSelected] = useState(options.INFO);
  const { addToast } = useToasts();
  const [user, setUser] = useState(props.location.state.user);
  const userStandingOptions = [Normal_INT, Suspended_INT, Banned_INT];
  const provinceList = SessionHelper.getProvinceList();
  const userCurrentLocation = provinceList.find(x => x.Id == GetCurrentProvinceId(user));
  const [selectedUserStandingFilter, setSelectedUserStandingFilter] = useState((GetUserStandingStatusInt(user) == 0)?1:GetUserStandingStatusInt(user));
  const [selectedProvinceId, setSelectedProvinceId] = useState(GetCurrentProvinceId(user));
  const [suspendedDayCount, setSuspendedDayCount] = useState(1);
  const [standingReason, setStandingReason] = useState('');
  const [emailPayout, setEmailPayout] = useState('');
  const [pointAmount, setPointAmount] = useState(0);
  const [pointReason, setPointReason] = useState('');
  const numDaysString = 'numDaysIntInput';
  const numPointsString = 'numPointsIntInput';
  const standingChangeString = 'standingChangeStringInput'
  const emailChangeString = 'emailChangeStringInput'
  const pointChangeString = 'pointChangeStringInput'
  
  const handleSetUserStandingFilter = (val) => {
    setSelectedUserStandingFilter(val)
    console.log("Standing Selected: "+ val);
    if(val == Suspended_INT){
      setStandingReason(PresetStandingChangeSuspend)
    }else if(val == Banned_INT){
      setStandingReason(PresetStandingChangeBan)
    }else if(val == Normal_INT){
      setStandingReason("")
    }
  }

  const handleSetProvinceId = (val) => {
    setSelectedProvinceId(val)
  }

  const handleResetProvinceId = () => {
    // setSelectedProvinceId(0)
    handleUserLocationChange(0)
  }


  const [showEditStanding, setShowEditStanding] = useState(false);
  const handleCloseEditStanding = () => setShowEditStanding(false);
  const handleShowEditStanding = () => {      
    setShowEditStanding(true);
  }

  const [showEditProvince, setShowEditProvince] = useState(false);
  const handleCloseEditProvince = () => setShowEditProvince(false);
  const handleShowEditProvince = () => {      
    setShowEditProvince(true);
  }


  const [showEditEmail, setShowEditEmail] = useState(false);
  const handleCloseEditEmail = () => setShowEditEmail(false);
  const handleShowEditEmail = () => {      
    setShowEditEmail(true);
  }

  const [showEditPoints, setShowEditPoints] = useState(false);
  const handleCloseEditPoints = () => setShowEditPoints(false);
  const handleShowEditPoints = () => {      
    setShowEditPoints(true);
  }

  const [showUnlink, setShowUnlink] = useState(false);
  const handleCloseUnlink = () => setShowUnlink(false);
  const handleShowUnlink = () => {
    setShowUnlink(true)
  }

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => {
    setShowDelete(true)
  }

  const [showSummary, setShowSummary] = useState(false);
  const handleCloseSummary = () => setShowSummary(false);
  const handleShowSummary = () => {
    setShowSummary(true)
  }

  const [userSummary, setUserSummary] = useState("Select 'Refresh' to begin duplicate bank account check");
  const handleClearSummary = () => setShowSummary("");
  const handleSetUserSummary = (summText) => {
    setUserSummary(summText)
  }

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await WebService.GetUserDetails(history, user);
      if (result != null) {
        console.log("GetUserDetails")
        console.log(result)
        setUserDetails(result);
        setIsLoading(false)
      } else {
        history.push({ pathname: "./sign-in" });
      }
    };
    fetchData();
  }, []);

  function renderSwitch() {
    switch (tabSelected) {
      case options.INFO:
        return <UserInfo user={user} userDetails={userDetails} openUnlinkPopup={handleShowUnlink} openDeletePopup={handleShowDelete} openSummaryCheckPopup={handleShowSummary}/>;
      case options.OFFERS:
        return <OfferView isLoading={isLoading} userDetails={userDetails} approveTransaction={handleApproveTransaction} />;
      case options.TRANSACTIONS:
        return (
          <div>
            <TransactionsView isLoading={isLoading} userDetails={userDetails} />
          </div>
        );
      case options.STATS:
        return <ClaimRate user={user} userDetails={userDetails} />;
      case options.ACTIVITY:
        return <UserActivityView user={user} showFraudShieldOnly={false} />;
      case options.BANKS:
        
        return <UserBanks onBankSelected={handleClickBank} onRefreshBank={handleBankRefresh} onRefreshTransactions={handleBankRefreshTransactions} bankAccounts={userDetails.WebUserBankAccount} />;
      case options.BANKSDETAIL:
        return <UserBanksDetail user_id={user.BetfullyUserId} bank_account_id={selectedBankAccountId} bank_accounts={userDetails.WebUserBankAccount} />;
      default:
        return;
    }
  }

  const handleTabSelect = (event, selection) => {
    setTabSelected(selection);
  };

  const handleClickBank = (bankAccountId) => {
    console.log("Account selected: "+bankAccountId)
    setBankAccountId(bankAccountId);
    setTabSelected(options.BANKSDETAIL);
  };

  const handleBankRefresh = (bankAccountId) => {
    console.log("Account selected: "+bankAccountId);
    console.log("User selected: "+user.BetfullyUserId);
    
    
    //const result = WebService.FireTestWebhook(history, user.BetfullyUserId, bankAccountId);
    const result = WebService.ResetBankLogin(history, user.BetfullyUserId, bankAccountId);
    //const result = WebService.ForceUpdateSingleBankPlaidTransactions(history, user.BetfullyUserId, bankAccountId);
      if (result != null) {
        
        //setUserDetails(result);
      } else {
        //history.push({ pathname: "./sign-in" });
      }
  };

  const handleBankRefreshTransactions = (bankAccountId) => {
    console.log("Account selected: "+bankAccountId);
    console.log("User selected: "+user.BetfullyUserId);
    
    
    //const result = WebService.FireTestWebhook(history, user.BetfullyUserId, bankAccountId);
    //const result = WebService.ResetBankLogin(history, user.BetfullyUserId, bankAccountId);
    const result = WebService.ForceUpdateSingleBankPlaidTransactions(history, user.BetfullyUserId, bankAccountId);
      if (result != null) {
        
        //setUserDetails(result);
      } else {
        //history.push({ pathname: "./sign-in" });
      }
  };

  const handleApproveTransaction = async (offerTransactionId) => {
    const result = await WebService.ApproveSingleOfferTransaction(history, user.BetfullyUserId, offerTransactionId);
    if (result != null) {
      setUserDetails(result)
      } else {
      }
      addToast((result != null)?"Transaction Approved":"Transaction Approval Failed", {
        appearance: (result != null)?"success":"error",
        autoDismiss: true,
      });
  };

   const handleUserStandingChange = async () => {
    console.log("Reason: "+selectedUserStandingFilter)
    const result = await WebService.ModifyUserStanding(history, user.FirebaseId, selectedUserStandingFilter, suspendedDayCount, standingReason);
    console.log(result)
    if (result != null) {
      props.location.state.user = result;
      setUser(result)
        //setUserDetails(result);
      } else {
        //history.push({ pathname: "./sign-in" });
      }
      handleCloseEditStanding();
  };

  const handleUserLocationChange = async (overrideId) => {
    const result = await WebService.UpdateUserLocation(history, user.FirebaseId, overrideId);
    if (result != null) {
      props.location.state.user = result;
      setUser(result)
      } else {
        //history.push({ pathname: "./sign-in" });
      }
      handleCloseEditProvince();
  };

  const handleEmailChange = async () => {
    const result = await WebService.ModifyUserPayoutEmail(history, user.FirebaseId, emailPayout);
    if (result != null) {
      props.location.state.user = result;
      setUser(result)
        //setUserDetails(result);
      } else {
        //history.push({ pathname: "./sign-in" });
      }
      handleCloseEditEmail();
  };

  const handleUserPointChange = async () => {
    
    console.log("Reason: "+pointReason)
    console.log("Amount: "+pointAmount)
    const result = await WebService.AwardReclaimUserPoints(history, user.BetfullyUserId, pointAmount, pointReason);
    console.log(result)
    if (result != null) {
      props.location.state.user = result;
      setUser(result)
        //setUserDetails(result);
      } else {
        //history.push({ pathname: "./sign-in" });
      }
      handleCloseEditPoints();
      setPointAmount(0)
      setPointReason("")
  };
  
  const handleDeleteUser = async () => {
    console.log("Deleting user account")
    const result = await WebService.DeleteUserPersonalInfoFromBetfully(history, user.BetfullyUserId);
    addToast((result != null && result == true)?"User Info Deletion Success":"User Info Deletion Failed", {
      appearance: (result != null && result == true)?"success":"error",
      autoDismiss: true,
    });
    handleCloseDelete();
  };

  const handleSummaryUser = async () => {
    console.log("Retrieving user summary")
    handleSetUserSummary("Retrieving user summary...")
    const result = await WebService.RetrieveUserSummary(history, user.BetfullyUserId);
    addToast((result != null)?"User Info Retrieval Success":"User Info Retrieval Failed", {
      appearance: (result != null && result == true)?"success":"error",
      autoDismiss: true,
    });
    if(result == null || result == ""){
      handleSetUserSummary("No results found.")
    }else{
      handleSetUserSummary("Retrieved the following summary:\n"+(result != null?result:"error"))
    }
    //handleCloseSummary();
  };

  const handleUnlinkUser = async () => {
    console.log("Unlinking user account")
    const result = await WebService.UnlinkUserAccountFromFirebase(history, user.BetfullyUserId);
    console.log(result)
    addToast((result != null && result == true)?"Unlinking Success":"Unlinking Failed", {
      appearance: (result != null && result == true)?"success":"error",
      autoDismiss: true,
    });
    handleCloseUnlink();
  };

  const handleMyExportAction = async () => {
    console.log("Gathering report")
    const result = await WebService.MyExportAction(history, user.FirebaseId, user.BetfullyUserId);
    console.log(result)
    addToast((result != null)?"Export Success":"Export Failed", {
      appearance: (result != null)?"success":"error",
      autoDismiss: true,
    });
  };

  const myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;

    console.log("Name: " + nam)
    console.log("Val: " + val)
    if(nam === numDaysString){
      setSuspendedDayCount(val);
    }else if (nam === standingChangeString){
      setStandingReason(val);
    }else if (nam === numPointsString){
      setPointAmount(val)
    }else if (nam === pointChangeString){
      setPointReason(val)
    }else if (nam === emailChangeString){
      setEmailPayout(val)
    }
  };
    


  const handleViewMore = (event) => {
    switch (tabSelected) {
      case options.INFO:
        return <UserInfo />;
      case options.OFFERS:
        history.push({ pathname: "./offerhistory", state: { user: user } });
      case options.TRANSACTIONS:
        history.push({
          pathname: "./depositwithhistory",
          state: { user: user },
        });
      case options.STATS:
        return <ClaimRate userDetails={userDetails} />;
      case options.ACTIVITY:
        history.push({ pathname: "./useractivity", state: { user: user } });
      case options.BANKS:
        

        return <UserBanks bankAccounts={userDetails.WebUserBankAccount} />;
      default:
        return;
    }
  };

  return (
    <div className={classes.root}>
      
      <Modal
          className={classes.content}
          show={showUnlink}
          onHide={handleCloseUnlink}
          centered>
          <Modal.Header closeButton>
          <Modal.Title>{"Unlink User's Account?"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            When the user signs in again, it will create a new account.
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={handleUnlinkUser}>
              Unlink
          </Button>
          <Button variant="secondary" onClick={handleCloseUnlink}>
              Cancel
          </Button>
          </Modal.Footer>
      </Modal>
      <Modal
          className={classes.content}
          show={showDelete}
          onHide={handleCloseDelete}
          centered>
          <Modal.Header closeButton>
          <Modal.Title>{"Delete User's Account?"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            All user personal info will be deleted (Cannot be undone)
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={handleDeleteUser}>
              Delete
          </Button>
          <Button variant="secondary" onClick={handleCloseDelete}>
              Cancel
          </Button>
          </Modal.Footer>
      </Modal>
      <Modal
          className={classes.content}
          show={showSummary}
          onHide={handleCloseSummary}
          centered>
          <Modal.Header closeButton>
          <Modal.Title>{"User Summary"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {userSummary}
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={handleSummaryUser}>
              Refresh
          </Button>
          <Button variant="secondary" onClick={handleCloseSummary}>
              Close
          </Button>
          </Modal.Footer>
      </Modal>
      <Modal
          className={classes.content}
          show={showEditEmail}
          onHide={handleCloseEditEmail}
          centered>
          <Modal.Header closeButton>
          <Modal.Title>{"User's Paypal Email"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <h4>Paypal Email: {GetCashoutEmail(user)}</h4>
          <Form>
          
           
            <div class="form-group-vertical" >
              <label for={emailChangeString}>New Paypal Email:</label>
              <textarea class="form-control" value={emailPayout} name={emailChangeString} rows="1" onChange={myChangeHandler}></textarea>
            </div>
          </Form>
              
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={handleEmailChange}>
              Save
          </Button>
          <Button variant="secondary" onClick={handleCloseEditEmail}>
              Cancel
          </Button>
          </Modal.Footer>
      </Modal>
      <Modal
          className={classes.content}
          show={showEditProvince}
          onHide={handleCloseEditProvince}
          centered>
          <Modal.Header closeButton>
          <Modal.Title>{"User Location"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          
          <Form>
          <h4>Current Location: {(userCurrentLocation !== undefined)?userCurrentLocation.Name:"Not Set"}</h4>GetAdminSetLocation
          {GetAdminSetLocation(user) && <h6>Location Set By Admin
          <Button
              className={"ml-2"}
              color="primary"
              size="small"
              variant="text"
              onClick={() => handleResetProvinceId()}
            >
              reset
            </Button>
            </h6>}
          <div>
          Location:
          <Form.Control
            as="select"
            className="my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            defaultValue={selectedProvinceId}
            onChange = {(event) => handleSetProvinceId(event.target.value)}
          >
            {provinceList.map((aNum) => (
              <option value={aNum.Id} key={aNum.Id}>{aNum.Name}</option>
            ))}
          </Form.Control>
          
          </div>
          
        </Form>
              
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={() => handleUserLocationChange(selectedProvinceId)}>
              Save
          </Button>
          <Button variant="secondary" onClick={handleCloseEditProvince}>
              Cancel
          </Button>
          </Modal.Footer>
      </Modal>
      <Modal
          className={classes.content}
          show={showEditStanding}
          onHide={handleCloseEditStanding}
          centered>
          <Modal.Header closeButton>
          <Modal.Title>{"User Standing"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {GetUserStandingStatusInt(user) == Suspended_INT && 
          <h4>Current Suspension Ends: {GetSuspensionEndDate(user)}</h4>}
          <Form>
          
          <div>
          Standing:
          <Form.Control
            as="select"
            className="my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            defaultValue={selectedUserStandingFilter}
            onChange = {(event) => handleSetUserStandingFilter(event.target.value)}
          >
            {userStandingOptions.map((aNum) => (
              <option value={aNum} key={aNum}>{GetUserStandingName(aNum)}</option>
            ))}
          </Form.Control>
          {selectedUserStandingFilter == Suspended_INT && 
          <label for={numDaysString}>Length of Suspension In Days (Starting today):</label>}
          
          {selectedUserStandingFilter == Suspended_INT && 
          <Form.Control required placeholder="Enter Number of Days" type="number" name={numDaysString}  onChange={myChangeHandler}/>}
          </div>
          <div class="form-group-vertical" >
            <label for={standingChangeString}>Message to User:</label>
            <textarea class="form-control" value={standingReason} name={standingChangeString} rows="3" onChange={myChangeHandler}></textarea>
          </div>
        </Form>
              
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={handleUserStandingChange}>
              Save
          </Button>
          <Button variant="secondary" onClick={handleCloseEditStanding}>
              Cancel
          </Button>
          </Modal.Footer>
      </Modal>
      <Modal
          className={classes.content}
          show={showEditPoints}
          onHide={handleCloseEditPoints}
          centered>
          <Modal.Header closeButton>
          <Modal.Title>{"Change User's Points"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {/* {GetUserStandingStatusInt(user) == Suspended_INT && 
          <h4>Current Suspension Ends: {GetSuspensionEndDate(user)}</h4>} */}
          <Form>
          <div>
          <NumericFormat fixedDecimalScale={true} value={GetUserPoints(user)} displayType={'text'} thousandSeparator={true} prefix={' Current Balance: '} suffix={""}/>
          <NumericFormat decimalScale={2} fixedDecimalScale={true} value={GetDollarsFromPoints(GetUserPoints(user))} displayType={'text'} thousandSeparator={true} prefix={' ($'} suffix={")"}/>
          </div>
          <div>
          <NumericFormat fixedDecimalScale={true} value={GetUserPoints(user)+pointAmount} displayType={'text'} thousandSeparator={true} prefix={' New Balance: '} suffix={""}/>
          <NumericFormat decimalScale={2} fixedDecimalScale={true} value={GetDollarsFromPoints(GetUserPoints(user)+pointAmount)} displayType={'text'} thousandSeparator={true} prefix={' ($'} suffix={")"}/>
          </div>
          <div>
          Points:
          
          {/* <Form.Control required placeholder="Enter Amount of Points" type="number" name={numPointsString}  onChange={myChangeHandler}/> */}
          <NumericFormat value={pointAmount} thousandSeparator={true} prefix={''} name={numPointsString} onValueChange={(values) => {
            const {formattedValue, value} = values;
            // formattedValue = $2,223
            // value ie, 2223
            setPointAmount(parseInt(value, 10))

          }}/>
          <NumericFormat decimalScale={2} fixedDecimalScale={true} value={GetDollarsFromPoints(pointAmount)} displayType={'text'} thousandSeparator={true} prefix={' ($'} suffix={")"}/>
          </div>
          <div class="form-group-vertical" >
            <label for={standingChangeString}>Message to User:</label>
            <textarea class="form-control" value={pointReason} name={pointChangeString} rows="3" onChange={myChangeHandler}></textarea>
          </div>
        </Form>
              
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" onClick={handleUserPointChange}>
              Save
          </Button>
          <Button variant="secondary" onClick={handleCloseEditPoints}>
              Cancel
          </Button>
          </Modal.Footer>
      </Modal>
      <div className={classes.contentHeader}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
      </div>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={3} xl={3} xs={3}>
          <UserProfile exportTransactions={handleMyExportAction} user={user} openeditemailpopup={handleShowEditEmail} openeditstandingpopup={handleShowEditStanding} openeditprovincepopup={handleShowEditProvince}  openeditpointspopup={handleShowEditPoints} currentlocation={userCurrentLocation}/>
        </Grid>
        <Grid item lg={9} sm={9} xl={9} xs={9}>
          <Button
            color="primary"
            size="large"
            variant="text"
            onClick={(event) => handleTabSelect(event, options.INFO)}
          >
            Info
          </Button>
          <Button
            color="primary"
            size="large"
            variant="text"
            onClick={(event) => handleTabSelect(event, options.OFFERS)}
          >
            Offers
          </Button>
          <Button
            color="primary"
            size="large"
            variant="text"
            onClick={(event) => handleTabSelect(event, options.TRANSACTIONS)}
          >
            Points
          </Button>
          {/* <Button
            color="primary"
            size="large"
            variant="text"
            onClick={(event) => handleTabSelect(event, options.STATS)}
          >
            Stats
          </Button> */}
          <Button
            color="primary"
            size="large"
            variant="text"
            onClick={(event) => handleTabSelect(event, options.ACTIVITY)}
          >
            Activity
          </Button>
          <Button
            color="primary"
            size="large"
            variant="text"
            onClick={(event) => handleTabSelect(event, options.BANKS)}
          >
            Bank Accounts
          </Button>
        </Grid>
        <Grid item lg={12} sm={12} xl={12} xs={12}>
          {" "}
          {renderSwitch()}
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={(event) => handleViewMore(event)}
          >
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

UserDetails.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
};
export default withAuthorization(UserDetails);
