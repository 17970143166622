import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

import { GetClaimRate } from "helpers/UserDetailsHelper";

import {
  GetPointsFromDollars,
  GetDollarsFromPoints
} from "constants/constants";

import {
  GetUserPoints,
  GetTotalPointsEver
} from "helpers/UserHelper";

import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  LinearProgress,
} from "@mui/material";
import InsertChartIcon from "@mui/icons-material/InsertChartOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: 56,
    width: 56,
  },
  contactSection: {
    marginBottom: theme.spacing(1),
  },
  icon: {
    height: 32,
    width: 32,
  },
  progress: {
    marginTop: theme.spacing(3),
  },
}));

const ClaimRate = (props) => {
  const { className, userDetails, user, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="h4"
            >
              User Stats
            </Typography>
            {/* <Typography variant="h5">
              <b>First Deposit: </b> {"$500 (MM/dd/YYYY)"}
            </Typography>
            <Typography variant="h5">
              <b>Most Recent Deposit: </b> {"$100 (MM/dd/YYYY)"}
            </Typography> */}
            <Typography variant="h5" className={classes.contactSection}>
              <b>Current Points: </b>
              {GetUserPoints(user) +" ($"+GetDollarsFromPoints(GetUserPoints(user))+")"}
            </Typography>
            <Typography variant="h5" className={classes.contactSection}>
              <b>Lifetime Points: </b>
              {GetTotalPointsEver(user) +" ($"+GetDollarsFromPoints(GetTotalPointsEver(user))+")"}
            </Typography>
            <Typography variant="h5" className={classes.contactSection}>
              <b>Claim Rate: </b>
              {GetClaimRate(userDetails)+"%"}
            </Typography>
            {/* <Typography variant="h5">
              <b>Completion Rate: </b>
              {"10%"}
            </Typography> */}
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <InsertChartIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ClaimRate.propTypes = {
  className: PropTypes.string,
  userDetails: PropTypes.any,
  user: PropTypes.any
};

export default ClaimRate;
