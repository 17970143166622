import React from "react";
import { Doughnut, Pie } from "react-chartjs-2";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography,
  colors,
  Grid
} from "@mui/material";

import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import RefreshIcon from "@mui/icons-material/Refresh";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import { NumericFormat } from 'react-number-format';
import {
  GetEntryClientsLabels,
  GetEntryClientsDeposits,
  GetDataObjects,
  GetOEMField,
  GetOfferEntryFieldString,
  OfferEntryField,
  GetOfferEntryFieldShortString
} from "helpers/UserDetailsHelper";

import {
  pieOptions,
  pieMoneyOptions,
  FormatDataForPie
} from "helpers/ChartHelper"

import {
  GetCompanyNameWithId
} from "helpers/CompanyHelper";

import withAuthorization from "../../../../../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  chartContainer: {
    position: "relative",
    height: "300px",
  },
  stats: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  device: {
    textAlign: "center",
    padding: theme.spacing(1),
  },
  deviceIcon: {
    color: theme.palette.icon,
  },
}));

const DepositsBySite = (props) => {
  const { className, offerMap, barChartData, showAllStats, offerEntryField, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  const getData = () => {
    var dataSet = {
      datasets: [{
        data: [],
        backgroundColor: [
        ],
        borderWidth: 8,
        borderColor: theme.palette.white,
        hoverBorderColor: theme.palette.white,
      }],
      labels: []
    }
    if(barChartData != null && barChartData.datasets != null && barChartData.datasets.length > 0){
      if(showAllStats){
        barChartData.datasets.map(x => 
          dataSet.datasets[0].data.push(
            GetOEMField(x.offerId, offerMap, OfferEntryField.POINTS))
        )
          // dataSet.datasets[0].data.push(
          //   GetOEMField(barChartData.datasets[0].companyId, offerMap, OfferEntryField.POINTS))
          // dataSet.datasets[0].data.push(
          //   GetOEMField(barChartData.datasets[0].companyId, offerMap, OfferEntryField.COUNT))
      }else{
        barChartData.datasets.map(x => 
          dataSet.datasets[0].data.push(
            GetOEMField(x.companyId, offerMap, offerEntryField))
        )
      }
      
      barChartData.datasets.map(x => 
        dataSet.datasets[0].backgroundColor.push(
          x.backgroundColor)
      )
      barChartData.datasets.map(x => 
        dataSet.labels.push(x.label)
      )
    }
    return dataSet;
  };

  

  

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <IconButton size="small">
            <RefreshIcon />
          </IconButton>
        }
        title={(showAllStats)?("Data For "+((barChartData.datasets != null && barChartData.datasets[0] != null)?GetCompanyNameWithId(barChartData.datasets[0].companyId):"")):GetOfferEntryFieldString(offerEntryField) + " by Site"}
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut data={FormatDataForPie(offerMap, barChartData, GetOEMField, colors, showAllStats)} options={pieMoneyOptions} />
        </div>
        <div className={classes.stats}>
        <Grid container spacing={1} justify={"center"}>
        

        

          {
            barChartData.datasets != null && barChartData.datasets.map(x => 
              <Grid item >
              <div key={x.label} className={classes.device}> 
              <Typography variant="body1">{x.label}</Typography>
              <Typography style={{ color: x.backgroundColor }} variant="h4">
              {x.labelPrefix+
                ((x.labelFormatCurrency)?new Intl.NumericFormat('en-US', { style: 'currency', currency: 'USD' }).format(GetOEMField((showAllStats)?x.offerId:x.companyId, offerMap, x.tEFValue)):new Intl.NumericFormat('en-US').format(GetOEMField((showAllStats)?x.offerId:x.companyId, offerMap, x.tEFValue)))
                + x.labelSuffix}
              </Typography>
             </div>
            </Grid>

            //  key={((showAllStats)?(x.offerId):(x.companyId))}
            )
          }
       
          </Grid>
       
        </div>
      </CardContent>
    </Card>
  );
};

DepositsBySite.propTypes = {
  className: PropTypes.string,
  barChartData: PropTypes.any,
  offerMap: PropTypes.any,
  offerEntryField: PropTypes.any,
  showAllStats: PropTypes.bool
};

export default withAuthorization(DepositsBySite);
