import uuid from "uuid/v1";

export default [
  {
    id: uuid(),
    title: "5% Cash Back",
    description: "5% Cash Back to All New Users.",
    imageUrl: "/assets/Fanduel-Icon.png",
    totalDownloads: "594",
    updatedAt: "27/03/2019"
  },
  {
    id: uuid(),
    title: "10% Cash Back",
    description: "10% Cash Back to All New Users.",
    imageUrl: "/assets/Fanduel-Icon.png",
    totalDownloads: "625",
    createdAt: "31/03/2019"
  },
  {
    id: uuid(),
    title: "$5 Free Contest",
    description: "$5 Free Contest Link",
    imageUrl: "/assets/Fanduel-Icon.png",
    totalDownloads: "857",
    createdAt: "03/04/2019"
  },
  {
    id: uuid(),
    title: "$1 Free Contest",
    description: "$1 Free Contest Link",
    imageUrl: "/assets/Fanduel-Icon.png",
    totalDownloads: "406",
    createdAt: "04/04/2019"
  },
  {
    id: uuid(),
    title: "2% Cash Back",
    description: "2% Cash Back to existing Users.",
    imageUrl: "/assets/Fanduel-Icon.png",
    totalDownloads: "835",
    createdAt: "04/04/2019"
  }
];
