import React, { useState } from "react";

import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";

import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  colors
} from "@mui/material";

import { GetMyOfferDetails } from "api/WebServices";
import { 
  GetStoredImageId,
  GetImageURL,
  GetStoredImageIsActiveText,
  GetStoredImageIsDefaultText,
  GetStoredImageClient,
  GetStoredImageIsDefault
} from '../../../../helpers/StoredImageHelper'
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  tableRow: {
    // height: 10,
    // padding:0,
    backgroundColor: colors.grey[50]
  },
  nameContainer: {
    display: "flex",
    alignItems: "center"
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  imageContainer: {
    height: 120,
    width: 100,
    margin: "auto",
    marginBottom: "20px",
    borderRadius: "1px",
    overflow: "hidden",
    display: "flex",
    alignItems: "normal",
    justifyContent: "center",
  },
  imageStoredImage: {
    width: "100%",
    objectFit : "contain"
  },
  actions: {
    justifyContent: "flex-end"
  }
}));

const StoredImagesTable = props => {
  const { className, StoredImages, GetCompanyName, history, StoredImage, ...rest } = props;

  const classes = useStyles();

  const [selectedStoredImages, setSelectedStoredImages] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };



  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Preview</TableCell>
                  <TableCell>Company</TableCell>
                  <TableCell></TableCell>
                  {/* <TableCell>Active</TableCell>  */}
                </TableRow>
              </TableHead>
              <TableBody>
                {StoredImages.slice(0, rowsPerPage).map(StoredImage => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={GetStoredImageId(StoredImage)}
                    onClick={() => props.openEditStoredImagePopup(StoredImage)}
                    selected={selectedStoredImages.indexOf(GetStoredImageId(StoredImage)) !== -1}
                  >
                    <TableCell>
                        {" "}
                        <div className={classes.imageContainer}>
                          <img
                            alt="Product"
                            className={classes.imageStoredImage}
                            
                            src={GetImageURL(StoredImage)}
                          />
                        </div>
                      </TableCell>
                    <TableCell>{GetCompanyName(GetStoredImageClient(StoredImage))}</TableCell>
                    <TableCell>{GetStoredImageIsDefaultText(StoredImage)}</TableCell>
                    {/* <TableCell>{GetStoredImageIsActiveText(StoredImage)}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={StoredImages.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </CardActions>
    </Card>
  );
};

StoredImagesTable.propTypes = {
  className: PropTypes.string,
  StoredImages: PropTypes.array.isRequired,
  history: PropTypes.object,
  StoredImage: PropTypes.object
};

export default withMasterAuthorization(StoredImagesTable);
