import React, { useState, useEffect } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import { FormGroup, Tooltip } from "@mui/material";
import InfoRounded from "@mui/icons-material/InfoRounded";
import {
  ButtonGroup,
  Button,
  Card,
  CardBody,
  CardText,
  CardGroup,
  CardTitle,
} from "reactstrap";
import { Form } from "react-bootstrap";
import moment from "moment";
import { 
  MaxDepositValues, MinDepositValues, CashBackValues, 
  MaxDaysValues, InEqualityValues, MaxTransactionValues, 
  OfferTransactionTypeValues, UserActionTypeValues, RewardTypeValues, GetActionTypeFromOfferType, GetRewardTypeFromOfferType } from "../../constants/constants.js";
  import {
    GetProvinceId
  } from '../../helpers/OfferLocationHelper'
import { 
  GetCompanyName,
  GetCompanyId
} from 'helpers/CompanyHelper'
import { 
  GetStoredImageId,
  GetImageURL,
  GetStoredImageName,
  GetStoredImageIsActive,
  GetStoredImageIsDefault,
  GetStoredImageClient,
  GetImageTypeName,
  BANNER,
  PROFILE,
  OFFER,
  OTHER,
  BANNER_INT,
  PROFILE_INT,
  OFFER_INT,
  OTHER_INT
} from 'helpers/StoredImageHelper'
import { ProductCardPreview } from "../ProductList/components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import WebService from "../../api/WebServices";
import { ToastProvider, useToasts } from "react-toast-notifications";
import ImagePreviewGrid from "../StoredImageList/Components/ImagePreviewGrid";
import { NumericFormat } from 'react-number-format';
import {
  Grid,
  IconButton,
  TextField,
  Link,
  Typography,
} from "@mui/material";
import { InputGroup } from "reactstrap";
import withAuthorization from "../../helpers/Authorization";

import {
  GetOfferId,
  GetTitle,
  GetTitle2,
  GetDescription,
  GetActivationCount,
  GetReward,
  GetCashBackPercentage,
  GetStatus,
  GetDateCreated,
  GetOfferTermsSummary,
  GetStartDate,
  GetExpirationDate,
  GetOfferURL,
  GetTermsURL,
  GetExtraURL,
  GetCompanyIcon,
  GetTransactionType,
  OfferSection,
  GetBackgroundImageId,
  GetBackgroundImage,
  GetSmallLogoId,
  GetSmallIcon,
  GetOfferRequirementItemSet,
  GetOfferRequirementItem
} from "helpers/OfferHelper";

import SessionHelper from "../../helpers/SessionHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.white,
    height: "100%",
  },
  cardPreview: {
    width: "25%",
  },
  grid: {
    height: "100%",
    width: "50%",
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/auth.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px",
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    display: "flex",
    flexDirection: "column",
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(3),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  spinner:{
    justifyContent:"center", display:"flex"
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    maxWidth: "640px",
    alignItems: "center",
    justifyContent: "center"
    // [theme.breakpoints.down("md")]: {
    //   justifyContent: "center",
    // },
  },
  form: {
    paddingLeft: 0,
    paddingRight: 100,
    // paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
    paddingBototm: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const CreateOffer = (props) => {
  const { history } = props;
  const { addToast } = useToasts();
  const classes = useStyles();
  const dupeOffer = props.location.state.dupeOffer;


  const [isLoading, setIsLoading] = useState(false);

  const [endDate, setEndDate] = useState(
    (dupeOffer != null && dupeOffer.ExpirationDate != null)?moment(dupeOffer.ExpirationDate).toDate():
    () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 7);
    return currentDate;
  });
  const [startDate, setStartDate] = useState((dupeOffer != null && dupeOffer.StartDate != null)?moment(dupeOffer.StartDate).toDate():
    () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate;
  });

  const [CompanySelected, setCompanySelected] = useState((SessionHelper.getCompanyId() == 0)?(dupeOffer != null?dupeOffer.Client:0):undefined)
  const handleCompanySelected = (value) => {
    setCompanySelected(value)
  };
  const [DaysValid, setDaysValid] = useState(0);
  const [cashBackValue, setCashBackValue] = useState((dupeOffer != null && dupeOffer.CashbackPercentage > 0)?dupeOffer.CashbackPercentage*100:CashBackValues[0]);
  const [maxTransactionValue, setMaxTransactionValue] = useState((dupeOffer != null)?dupeOffer.MaxAmountOfTransactionsAllowed:0);
  const [minDepositValue, setMinDepositValue] = useState((dupeOffer != null)?dupeOffer.MinDeposit:0);
  const [daysAvailable, setDaysAvailable] = useState((dupeOffer != null && dupeOffer.AmountOfDaysValid > 0)?dupeOffer.AmountOfDaysValid:3);
  const [offerTransactionType, setOfferTransactionType] = useState((dupeOffer != null)?dupeOffer.OfferTransactionType:0);
  const [offerActionType, setOfferActionType] = useState((dupeOffer != null)?GetActionTypeFromOfferType(dupeOffer.OfferType):0);
  const [offerRewardType, setOfferRewardType] = useState((dupeOffer != null)?GetRewardTypeFromOfferType(dupeOffer.OfferType):0);
  const [maxDepositValue, setMaxDepositValue] = useState((offerRewardType == 0)?((dupeOffer != null)?dupeOffer.Reward:0):((dupeOffer != null)?dupeOffer.MaxDeposit:0));
  const [sendRequest, setSendRequest] = useState(false);
  
  const [showCustomMinDeposit, setShowCustomMinDeposit] = useState((dupeOffer != null && dupeOffer.MinDeposit > 0)?!MinDepositValues.includes(dupeOffer.MinDeposit):false);
  const [showCustomMaxDeposit, setShowCustomMaxDeposit] = useState((dupeOffer != null && dupeOffer.MaxDeposit > 0)?!MaxDepositValues.includes(dupeOffer.MaxDeposit):false);
  const [showCustomCashback, setShowCustomCashback] = useState((dupeOffer != null && dupeOffer.CashbackPercentage > 0)?!CashBackValues.includes(dupeOffer.CashbackPercentage*100):false);
  const [showCustomActiveDays, setShowCustomActiveDays] = useState((dupeOffer != null && dupeOffer.AmountOfDaysValid > 0)?!MaxDaysValues.includes(dupeOffer.AmountOfDaysValid):false);

  
  const [showCustomMaxTransactions, setShowCustomMaxTransactions] = useState((dupeOffer != null)?!MinDepositValues.includes(dupeOffer.MaxAmountOfTransactionsAllowed):false);
  
  const criteriaList = props.location.state.userCriteriaList;
  const provinceList = SessionHelper.getProvinceList();

  const [criteriaValues, setCriteriaValues] = useState([
    ...criteriaList
  ])

  const [storedImages, setStoredImages] = useState([])

  const currentDate = new Date();
  const standardDate = currentDate.setDate(currentDate.getDate() + 1);

  const futureDate = currentDate.setDate(currentDate.getDate() + 7);

  useEffect(() => {
    if(dupeOffer !== undefined && dupeOffer.Client == CompanySelected){

    }else{
      onImagesLoaded(storedImages)
    }
  }, [storedImages, CompanySelected]);

  useEffect(() => {
        const fetchData = async () => {
            const result = await WebService.GetAllStoredImages(history, 0, false, 1);
            if (result != null) {
                // Temporary sorting solution:
                result.sort((firstStoredImage, secondStoredImage) =>
                   (GetStoredImageId(firstStoredImage) != null ? GetStoredImageId(firstStoredImage) : 0) -
                   (GetStoredImageId(secondStoredImage) != null ? GetStoredImageId(secondStoredImage) : 0));
                setStoredImages(result.filter(img => GetStoredImageIsActive(img)))
            } else {
                history.push({pathname: "./sign-in"});
            }
        };
        fetchData();
  }, []);

  useEffect(() => {
    var i;
    for (i = 0; i < criteriaValues.length; i++) {
      console.log("C FillIn: " + i);
      FillInDefaults(criteriaValues[i])
    }
    setCriteriaValues([...criteriaValues])
  }, []);

  const [ActiveFilterList, setActiveFilterList] = useState([]);
  const GetExistingOfferLocationIdList = () => {
    var offerLocationList = [];
    if(dupeOffer.OfferLocations !== undefined && dupeOffer.OfferLocations != null){
      dupeOffer.OfferLocations.map(x => offerLocationList.push(GetProvinceId(x)));
    }
    return offerLocationList;
  }
  const [LocationsList, setLocationsList] = useState((dupeOffer != null)?GetExistingOfferLocationIdList():[]);
  const [formState, setFormState] = useState({
    isValid: true,
    values: {
      title: (dupeOffer !== undefined)?GetTitle(dupeOffer):"", 
        title2: (dupeOffer !== undefined)?((offerRewardType == 2)?dupeOffer.ShortDescription:GetTitle2(dupeOffer)):"", 
        description: (dupeOffer !== undefined)?GetDescription(dupeOffer):"", 
        offerURL: (dupeOffer !== undefined)?GetOfferURL(dupeOffer):"", 
        termsURL: (dupeOffer !== undefined)?GetTermsURL(dupeOffer):"", 
        termsSummary: (dupeOffer !== undefined)?GetOfferTermsSummary(dupeOffer):"",
        extraURL: (dupeOffer !== undefined)?GetExtraURL(dupeOffer):"", 
        maxDeposit: (offerRewardType == 0)?((dupeOffer != null)?dupeOffer.Reward:0):((dupeOffer != null)?dupeOffer.MaxDeposit:0),
        minDeposit: (dupeOffer != null)?dupeOffer.MinDeposit:0,
        maxTransactionsAllowed: (dupeOffer !== undefined && dupeOffer.MaxAmountOfTransactionsAllowed !== undefined)?dupeOffer.MaxAmountOfTransactionsAllowed:0,
        cashbackPercent: (dupeOffer != null && dupeOffer.CashbackPercentage > 0)?dupeOffer.CashbackPercentage*100:CashBackValues[0],
        amountOfDaysValid: (dupeOffer != null && dupeOffer.AmountOfDaysValid > 0)?dupeOffer.AmountOfDaysValid:3,
        startDate: (dupeOffer != null && dupeOffer.StartDate != null)?moment(dupeOffer.StartDate).toDate():
          standardDate,
        endDate: (dupeOffer != null && dupeOffer.ExpirationDate != null)?moment(dupeOffer.ExpirationDate).toDate():
          futureDate,
        offerTransactionType: (dupeOffer != null)?dupeOffer.OfferTransactionType:0,
        offerActionType: (dupeOffer != null)?GetActionTypeFromOfferType(dupeOffer.OfferType):0,
        offerRewardType: (dupeOffer != null)?GetRewardTypeFromOfferType(dupeOffer.OfferType):0,
        offerType:(dupeOffer != null)?dupeOffer.OfferType:0,
        storedImageId: (dupeOffer != null)?dupeOffer.StoredImageId:0,
        imgUrlInput: (dupeOffer != null && dupeOffer.ClientIcon != null)?dupeOffer.ClientIcon:SessionHelper.getIcon(),
        backgroundImageId: GetBackgroundImageId(dupeOffer), 
        smallLogoId: GetSmallLogoId(dupeOffer),
        backgroundImageUrl: GetBackgroundImage(dupeOffer), 
        smallLogoUrl: GetSmallIcon(dupeOffer),
        instructionSet1: GetOfferRequirementItemSet(dupeOffer, 0),
        instructionSet2: GetOfferRequirementItemSet(dupeOffer, 1),
        instructionSet3: GetOfferRequirementItemSet(dupeOffer, 2),
        instruction1: GetOfferRequirementItem(dupeOffer, 0),
        instruction2: GetOfferRequirementItem(dupeOffer, 1),
        instruction3: GetOfferRequirementItem(dupeOffer, 2),
        daysAvailableCustom: (dupeOffer != null && dupeOffer.AmountOfDaysValid > 0)?!MaxDaysValues.includes(dupeOffer.AmountOfDaysValid):false,
        minDepositCustom: (dupeOffer != null && dupeOffer.MinDeposit > 0)?!MinDepositValues.includes(dupeOffer.MinDeposit):false,
        maxDepositCustom: (dupeOffer != null && dupeOffer.MaxDeposit > 0)?!MaxDepositValues.includes(dupeOffer.MaxDeposit):false,
        cashBackCustom: (dupeOffer != null && dupeOffer.CashbackPercentage > 0)?!CashBackValues.includes(dupeOffer.CashbackPercentage*100):false,
        maxTransactionCustom: (dupeOffer != null)?!MinDepositValues.includes(dupeOffer.MaxAmountOfTransactionsAllowed):false,
    },
    touched: {},
    errors: {},
    productObj: {
      Title: (dupeOffer !== undefined)?GetTitle(dupeOffer):"Title",
      Title2: (dupeOffer !== undefined)?GetTitle2(dupeOffer):"Title2",
      Description: "Subtitle",
      OfferURL: "Redirect URL",
      TermsURL: "Terms URL",
      TermsSummary: "Terms Summary",
      ExtraURL: "Extra URL"
    },
  });

  const [OfferCriteriaList, setOfferCriteriaList] = useState([]);

  const [RequestObject, setRequestObject] = useState({
    Title: "",
    Title2: "",
    Description: "",
    OfferURL: "",
    TermsURL: "",
    TermsSummary: "",
    ExtraURL: "",
    MaxDeposit: 0,
    MinDeposit: 0,
    MaxTransactionsAllowed: 0,
    StartDate: moment(startDate).format("MM/DD/YYYY"),
    EndDate: moment(endDate).format("MM/DD/YYYY"),
    CashbackPercent: 0,
    AmountOfDaysValid: 0,
    OffertransactionType: 0,
    OfferRewardType: 0,
    OfferActionType: 0,
    Add: [],
  });

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
      productObj: {
        Title: formState.values.title,
        Title2: formState.values.title2,
        Description: formState.values.description,
        OfferURL: formState.values.offerURL,
        TermsURL: formState.values.termsURL,
        TermsSummary: formState.values.termsSummary,
        ExtraURL: formState.values.extraURL
      },
    }));
  };

  const handleManualChange = (varName, value) => {

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [varName]:
           value
      },
      touched: {
        ...formState.touched,
        [varName]: true
      }
    }));
  };

  const handleCreateOffer = (event) => {
    event.preventDefault();
    if(SessionHelper.getCompanyId() != 0 || CompanySelected > 0){
      setRequestObject({
        Title: formState.values.title,
        Title2: formState.values.title2,
        Description: formState.values.description,
        OfferURL: formState.values.offerURL,
        TermsURL: formState.values.termsURL,
        TermsSummary: formState.values.termsSummary,
        ExtraURL: formState.values.extraURL,
        MaxDeposit: formState.values.maxDeposit,
        MinDeposit: formState.values.minDeposit,
        MaxTransactionsAllowed: formState.values.maxTransactionsAllowed,
        CashbackPercent: formState.values.cashbackPercent,
        AmountOfDaysValid: formState.values.amountOfDaysValid,
        StartDate: moment(formState.values.startDate).format("MM/DD/YYYY"),
        EndDate: moment(formState.values.endDate).format("MM/DD/YYYY"),
        // StartDate: formState.values.startDate,
        // EndDate: formState.values.endDate,
        OfferTransactionType: formState.values.offerTransactionType,
        OfferActionType: formState.values.offerActionType,
        OfferRewardType: formState.values.offerRewardType,
        Instruction1: formState.values.instruction1,
        Instruction2: formState.values.instruction2,
        Instruction3: formState.values.instruction3,
        InstructionSet1: {
          ...formState.values.instructionSet1,
          Requirement: formState.values.instruction1,
          Id: 0
        },
        InstructionSet2: {
          ...formState.values.instructionSet2,
          Requirement: formState.values.instruction2,
          Id: 0
        },
        InstructionSet3: {
          ...formState.values.instructionSet3,
          Requirement: formState.values.instruction3,
          Id: 0
        },
        StoredImageId: formState.values.storedImageId,
        BackgroundImageId: formState.values.backgroundImageId,
        SmallLogoId: formState.values.smallLogoId,
        Add: ActiveFilterList,
      });
      setSendRequest(true);
    }else{
      if(SessionHelper.getCompanyId() == 0 && CompanySelected == 0){
        addToast("Please Select A Company", {
          appearance: "error",
          autoDismiss: true,
        });
      }
    }


  };
  
  const criteriaValuesUpdated = (criteriaId, firstValue, firstInequality, secondValue, secondInequality) => {
    const criteriaLength = criteriaValues.length;
    const isLargeNumber = (element) => element > 13;
    
    const valueIndexFun = (element) => element.Id == criteriaId;
    const valueIndex = criteriaValues.findIndex(valueIndexFun);
    console.log("C values: " + criteriaValues);
    console.log("C Index: " + valueIndex);
    console.log("C Length: " + criteriaLength);
    console.log("C Id: " + criteriaId);
    console.log("C firstValue: " + firstValue);
    console.log("C firstInequality: " + firstInequality);
    console.log("C secondValue: " + secondValue);
    console.log("C secondInequality: " + secondInequality);
    console.log("C whole: " + criteriaValues[valueIndex]);
    if(valueIndex !== undefined && valueIndex >= 0){
      console.log(criteriaValues[valueIndex]);
      var tempArray = [];
      if(valueIndex > 0){
        tempArray.push(...criteriaValues.slice(0,valueIndex))
      }
      tempArray.push({
        ...criteriaValues[valueIndex],
        FirstValue: (firstValue !== undefined)?firstValue:criteriaValues[valueIndex].FirstValue,
        FirstEquality: (firstInequality !== undefined)?firstInequality:criteriaValues[valueIndex].FirstEquality,
        SecondValue:  (secondValue !== undefined)?secondValue:criteriaValues[valueIndex].SecondValue,
        SecondEquality: (secondInequality !== undefined)?secondInequality:criteriaValues[valueIndex].SecondEquality,
        ThirdValue: 0,
        ThirdEquality: 0,
        StoredProcedureParameterCount: 2,
        StoredProcedureInequalityParameterCount: 2
      })
      if(valueIndex < criteriaLength-1){
        tempArray.push(...criteriaValues.slice(valueIndex+1, undefined))
      }
      FillInDefaults(tempArray[valueIndex]);

      setCriteriaValues(tempArray);
    }
  };

  const handleSetStartDate = (dateToSet) => {
    setStartDate(dateToSet);
  };

  const handleSetDate = (dateToSet) => {
    setEndDate(dateToSet);
  };

  const handleAddFilter = (value) => {
    setActiveFilterList((ActiveFilterList) => [...ActiveFilterList, value]);
    handleAddOfferCriteriaList(value);
  };

  const handleRemoveFilter = (value) => {
    setActiveFilterList(ActiveFilterList.filter((item) => item !== value));
    handleRemoveOfferCriteriaList(value);
  };

  const handleAddOfferCriteriaList = (value) => {
    setOfferCriteriaList((OfferCriteriaList) => [...OfferCriteriaList, 
      {
        FilterType: (OfferCriteriaList.length+1),
        FirstValue: 1,
        FirstEquality: 2,
        SecondValue: 5,
        SecondEquality: 3,
        ThirdValue: value,
        ThirdEquality: 1,
        StoredProcedureParameterCount: 3,
        StoredProcedureInequalityParameterCount: 3
      }]);
  };

  const handleRemoveOfferCriteriaList = (value) => {
    setOfferCriteriaList(OfferCriteriaList.filter((item) => item.ThirdValue !== value));
  };

  const handleCashBackSelect = (hideCustom, value) => {
    if(hideCustom)
      setShowCustomCashback(false)
    setCashBackValue(value);
  };

  const handleMaxTransactionSelect = (hideCustom, value) => {
    if(hideCustom)
      setShowCustomMaxTransactions(false)
    setMaxTransactionValue(value);
  };

  const handleMaxDepositSelect = (hideCustom, value) => {
    if(hideCustom)
      setShowCustomMaxDeposit(false)
    setMaxDepositValue(value);
  };

  const handleMinDepositSelect = (hideCustom, value) => {
    if(hideCustom)
      setShowCustomMinDeposit(false);
    setMinDepositValue(value);
  }

  const handleShowCustomMinDeposit = () => {
    setShowCustomMinDeposit(!showCustomMinDeposit)
  }

  function handleDaysSelect(hideCustom, value) {
    if(hideCustom)
      setShowCustomActiveDays(false)
    setDaysAvailable(value);
  }

  const MAX_VAL = 100;
  const MIN_DAY_VAL = 1;
  const withValueLimit = ({ floatValue }) => floatValue <= MAX_VAL;
  const withMinDayValue = ({ floatValue }) => floatValue >= MIN_DAY_VAL;


  function handleTransactionTypeSelect(value) {
    setOfferTransactionType(value);
  }

  function handleActionTypeSelect(value) {
    setOfferActionType(value);
  }

  function handleRewardTypeSelect(value) {
    setOfferRewardType(value);
  }

  function ActiveFilter(value) {
    return ActiveFilterList.some((item) => value === item);
  }

  function FillInDefaults(criteriaItem){
    if(criteriaItem.FirstValue === undefined)
      criteriaItem.FirstValue = 0;
    if(criteriaItem.FirstEquality === undefined)
      criteriaItem.FirstEquality = 0;
    if(criteriaItem.SecondValue === undefined)
      criteriaItem.SecondValue = 0;
    if(criteriaItem.SecondEquality === undefined)
      criteriaItem.SecondEquality = 0;
    if(criteriaItem.ThirdValue === undefined)
      criteriaItem.ThirdValue = 0;
    if(criteriaItem.ThirdEquality === undefined)
      criteriaItem.ThirdEquality = 0;
    criteriaItem.StoredProcedureName = criteriaItem.CriteriaDisplayName.slice();
    criteriaItem.StoredProcedureName = (criteriaItem.StoredProcedureName+"").split(' ').map((aValue) =>
                      
      (aValue == '{0}')?
        (criteriaItem.FirstValue + " ")
      :((aValue == '{1}')?
        (
  (criteriaItem.FirstEquality !== undefined && criteriaItem.FirstEquality > 0)?(InEqualityValues[(criteriaItem.FirstEquality-1)].Sym + " "):(InEqualityValues[0].Sym + " ")
)
        :
        ((aValue == '{C}')?
        SessionHelper.getCompany()+" "
        :
          aValue+" "
      )
      )
    )
    
   
  }

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const [ImgUrlInput, setImgUrlInput] = useState((dupeOffer != null && dupeOffer.ClientIcon != null)?dupeOffer.ClientIcon:SessionHelper.getIcon());
  const [StoredImageId, setStoredImageId] = useState((dupeOffer != null)?dupeOffer.StoredImageId:0);

  const [showViewStoredImages, setShowViewStoredImages] = useState(false);
  const handleCloseViewStoredImages = () => setShowViewStoredImages(false);
  const handleShowViewStoredImages = () => {
    setShowViewStoredImages(true);
  };

  const onImagesLoaded = (imagesArr) => {
    if(imagesArr !== undefined && imagesArr != null){
      var image = imagesArr.find(img => GetStoredImageIsActive(img) && GetStoredImageIsDefault(img) && (GetStoredImageClient(img) == CompanySelected))
      if(image !== undefined && image != null){
        handleManualChange("imgUrlInput",GetImageURL(image))
        handleManualChange("storedImageId",GetStoredImageId(image))
        setImgUrlInput(GetImageURL(image));
        setStoredImageId(GetStoredImageId(image));
      }else{
        setImgUrlInput(SessionHelper.getIcon());
        setStoredImageId(0);
        handleManualChange("imgUrlInput",SessionHelper.getIcon())
        handleManualChange("storedImageId",0)
      }
    }
  };

  const onImageSelected = (imageId, imageUrl) => {
    console.log("Image selected Id: " + imageId);
    handleManualChange("imgUrlInput",imageUrl)
    handleManualChange("storedImageId",imageId)
    setImgUrlInput(imageUrl);
    setStoredImageId(imageId);
    handleCloseViewStoredImages();
  };

  const [showBackViewStoredImages, setShowBackViewStoredImages] = useState(false);
  const handleCloseBackViewStoredImages = () => setShowBackViewStoredImages(false);
  const handleShowBackViewStoredImages = () => {
      setShowBackViewStoredImages(true);
  }
  const onBackgroundImageSelected = (imageId, imageUrl) => {
    console.log("Image selected Id: "+ imageId)
    handleManualChange("backgroundImageId", imageId)
    handleManualChange("backgroundImageUrl", imageUrl)
    //setImgUrlInput(imageUrl);
    //setStoredImageId(imageId);
    handleCloseBackViewStoredImages();
}

const [showSmallViewStoredImages, setShowSmallViewStoredImages] = useState(false);
  const handleCloseSmallViewStoredImages = () => setShowSmallViewStoredImages(false);
  const handleShowSmallViewStoredImages = () => {
      setShowSmallViewStoredImages(true);
  }
  const onSmallImageSelected = (imageId, imageUrl) => {
    console.log("Image selected Id: "+ imageId)
    handleManualChange("smallLogoId", imageId)
    handleManualChange("smallLogoUrl", imageUrl)
    //setImgUrlInput(imageUrl);
    //setStoredImageId(imageId);
    handleCloseSmallViewStoredImages();
}

  useEffect(() => {
    if (sendRequest ) {
      WebService.CreateOffer(history, RequestObject, criteriaValues.filter((value) =>
      ActiveFilter(value.Id)), StoredImageId, LocationsList, ImgUrlInput, CompanySelected).then((data) => {
        if (data != null) {
          console.log(data);
          if (data.Status == 200) {
            addToast(data.Message, {
              appearance: "success",
              autoDismiss: true,
            });
            if(SessionHelper.getCompanyId() == 0){
              history.push("./AdminOffersView");
            }else{
              history.push("./Offers");
            }
          } else {
            addToast(data.Message, {
              appearance: "error",
              autoDismiss: true,
            });
            //history.push("./Offers");
          }
        } else {
          addToast("Unknown Error", {
            appearance: "error",
            autoDismiss: true,
          });
        }
        setSendRequest(false);
      });
    }
  }, [sendRequest]);

  const handleSelectAll = () => {

    if(LocationsList.length == provinceList.length)
      setLocationsList([])
    else{
      const provIdList = provinceList.map(x => x.Id)
      setLocationsList([...provIdList])
    }
  }

  const handleLocationChange = (event) => {
    if(!event.target.checked){
      setLocationsList(LocationsList.filter(x => x != +event.target.value))
    }else{
      setLocationsList([...LocationsList, +event.target.value])
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <Modal
              className={classes.content}
              show={sendRequest}
              // onHide={handleCloseOfferDetail}
              centered>
              <Modal.Header >
                <Modal.Title>{"Processing"}</Modal.Title>
              </Modal.Header>
              <Modal.Body className={classes.spinner}>
              <Spinner animation="border" variant="primary" />
              </Modal.Body>
              <Modal.Footer>
              {/* <Button variant="primary" onClick={handleOfferDetail}>
                  Delete
              </Button> */}
              {/* <Button variant="primary" onClick={handleCancelOfferDetail}>
                  Save Changes
              </Button>
              <Button variant="secondary" onClick={handleCancelOfferDetail}>
                  Cancel
              </Button> */}
              </Modal.Footer>
        </Modal>
        <ImagePreviewGrid
          showViewStoredImages={showViewStoredImages}
          handleCloseViewStoredImages={handleCloseViewStoredImages}
          onImageSelected={onImageSelected}
          companyId={(SessionHelper.getCompanyId() > 0)?SessionHelper.getCompanyId():CompanySelected}
          imageType={1}
        />
       <ImagePreviewGrid 
            showViewStoredImages={showBackViewStoredImages} 
            handleCloseViewStoredImages={handleCloseBackViewStoredImages}
            onImageSelected={onBackgroundImageSelected}
            companyId={(SessionHelper.getCompanyId() > 0)?SessionHelper.getCompanyId():CompanySelected}
            imageType={1}/>
          <ImagePreviewGrid 
            showViewStoredImages={showSmallViewStoredImages} 
            handleCloseViewStoredImages={handleCloseSmallViewStoredImages}
            onImageSelected={onSmallImageSelected}
            companyId={(SessionHelper.getCompanyId() > 0)?SessionHelper.getCompanyId():CompanySelected}
            imageType={1}/>
        <div className={classes.row}>
          <h4>Create Offer</h4>
          <Tooltip title="Enter offer detail in the space provided below">
            <IconButton aria-label="delete">
              <InfoRounded />
            </IconButton>
          </Tooltip>
        </div>
        
        <ul class="list-group list-group-horizontal ">
          <li>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
          </li>
          <li class="list-group-item border-0 justify-content-center">
            <div className={classes.contentBody}>
              <div >
                <form className={classes.form} onSubmit={handleCreateOffer}>
                  <Typography className={classes.title} variant="h2">
                    Create Offer
                  </Typography>
                  {SessionHelper.getCompanyId() == 0 && <Form inline className={"mt-4"}>
                    <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
                      Company 
                    </Form.Label>
                    <Form.Control
                      as="select"
                      className="my-1 mr-sm-2"
                      id="inlineFormCustomSelectPref"
                      custom
                      value={CompanySelected}
                      onChange = {(event) => handleCompanySelected(+event.target.value)}
                    >
                      <option value={0}>None Selected</option>
                      {SessionHelper.getCompanyList() != null && SessionHelper.getCompanyList().map((aNum) => (
                        <option value={GetCompanyId(aNum)} key={GetCompanyId(aNum)}>{GetCompanyName(aNum)}</option>
                      ))}
                    </Form.Control>
                  </Form>}
                 
                  <OfferSection 
                    editEnabled={true} 
                    useStyles={useStyles}
                    offer={null}
                    handleChange={handleChange}
                    handleManualChange={handleManualChange}
                    formStateOld={formState}
                    hasError={hasError} 
                    startDateOld={startDate}
                    endDateOld={endDate}
                    daysAvailableOld={daysAvailable}
                    withMinDayValue={withMinDayValue}
                    maxDepositValueOld={maxDepositValue}
                    cashBackValueOld={cashBackValue}
                    offerRewardTypeOld={offerRewardType}
                    offerActionTypeOld={offerActionType}
                    minDepositValueOld={minDepositValue}
                    withValueLimit={withValueLimit}
                    maxTransactionValueOld={maxTransactionValue}
                    provinceList={provinceList}
                    handleLocationChange={handleLocationChange}
                    LocationsList={LocationsList}
                    handleSelectAll={handleSelectAll}
                    handleShowViewStoredImages={handleShowViewStoredImages}
                    handleShowBackViewStoredImages={handleShowBackViewStoredImages}
                    handleShowSmallViewStoredImages={handleShowSmallViewStoredImages}
                    ImgUrlInputOld={ImgUrlInput}/>
            <Button
              className={classes.signInButton}
              color="primary"
              disabled={!formState.isValid}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              CreateOffer
            </Button>
            </form>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

CreateOffer.propTypes = {
  history: PropTypes.object,
};

export default withAuthorization(CreateOffer);
