import moment from "moment";

export function GetPaypalId(paypalWebhook){
  console.log(paypalWebhook);
  return paypalWebhook !== undefined ? paypalWebhook.id : "0";
}

export function GetResourceType(paypalWebhook){
  return paypalWebhook !== undefined ? paypalWebhook.resource_type : "N/A"; 
}

export function GetEventType(paypalWebhook){
  if (paypalWebhook !== undefined){
    switch(paypalWebhook.event_type){
      case "PAYMENT.PAYOUTSBATCH.SUCCESS":
        return "Successful Batch Payout";
      default: 
        return paypalWebhook.event_type; 
    } 
  }
  return "N/A";
}

export function GetSummary(paypalWebhook){
  if(paypalWebhook !== undefined){
    const amount = GetBatchPayoutsWithAmount(paypalWebhook); 
    console.log("Amount value: ");
    return `${paypalWebhook.summary} ${amount}`
  }else{
    return "N/A";
  }
  
}

export function GetTimeCreated(paypalWebhook){
  return paypalWebhook !== undefined ? moment(paypalWebhook.create_time).format('MMMM Do YYYY, h:mm:ss a') : "N/A";
}

export function GetUserPayouts (paypalWebhook) {
  if(paypalWebhook !== undefined){
    if(paypalWebhook.PayoutRequests.length > 0) {
      return paypalWebhook.PayoutRequests;
    }
  }
  return null; 
}

const GetBatchPayoutsWithAmount = (paypalWebhook) => {
  if(paypalWebhook !== undefined){
    if(paypalWebhook.Resource !== undefined){
      return `\n${paypalWebhook.Resource.PayoutBatchHeader.Payments} payouts (${GetAmount()})`;
    }
  }
  return "";
}

const GetAmount = (paypalWebhook) => {
  if(paypalWebhook !== undefined){
    if(paypalWebhook.Resource !== undefined){
      return `${paypalWebhook.resource.batch_header.amount.currency} ${paypalWebhook.resource.batch_header.amount.value}`;
    }
  }
  return "";
}