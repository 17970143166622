import Modal from "react-bootstrap/Modal";
import {
   GetCompanyName,
   GetPopupBody,
   GetPopupTitle, 
   KeywordStatusToInt,
   ShowPopupNegAdmin,
   ShowPopupPosAdmin,
   GetCreatedDate,
   GetStatus,
   GetWord, GetId
} from "../../../../helpers/KeywordHelper";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";

const useStyles = makeStyles(theme => ({
   content: {
      marginTop: theme.spacing(2)
   }
}));

const DenyingKeywordModal = props => {
   const classes = useStyles();
   const [NoteInput, setNoteInput] = useState("");
   const [popupData, setPopupdata] = useState({
      title: "Approve Keyword?",
      body: "Approve or reject the proposed keyword. If rejected, the operator will have a chance to revise their request." +
         " You may leave a note to provide feedback on what they must correct."
   });

   const textInputHandler = (event) => {
      console.log("Name:"+event.target.name);
      console.log("Value:"+event.target.value);
      let nam = event.target.name;
      let val = event.target.value;
      setNoteInput(val)
   }
 
   return(
      <Modal
         className={classes.content}
         show={props.showDenyingPopup}
         onHide={props.handleCloseDenyingPopup}
         centered>
         <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to deny the Keyword?</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <div>{GetPopupBody(GetStatus(props.Keyword))}</div>
            <br />
            <div>Operating Company: {GetCompanyName(props.Keyword)}</div>
            <div>Proposed Keyword: '{GetWord(props.Keyword)}'</div>
            <div>Date Created: {GetCreatedDate(props.Keyword)}</div>
            <br />
            <Form >
               <Form.Group controlId="formBasicEmail" >
                  <Form.Label>{"Leave a Note for Operator (Optional):"}</Form.Label>
                  <Form.Control required placeholder="Enter Here" name='keywordInput' onChange={textInputHandler}/>
                  <Form.Text className="text-muted">

                  </Form.Text>
               </Form.Group>
            </Form>
         </Modal.Body>

         <Modal.Footer>
            <Button variant="primary" onClick={() => props.handleUpdatingKeywordStatus(GetId(props.Keyword), false, NoteInput)}>
               Confirm
            </Button>
            <Button variant="secondary" onClick={() => props.handleCloseDenyingPopup}>
               Cancel
            </Button>
         </Modal.Footer>
      </Modal>
   );
};

DenyingKeywordModal.propTypes = {
   history: PropTypes.object
}

export default withMasterAuthorization(DenyingKeywordModal)