import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, IconButton, Button } from "@mui/material";
import WebService from "../../api/WebServices.js";
import { Link as RouterLink, withRouter } from "react-router-dom";

import { 
  DateUnits,
 } from "helpers/UserDetailsHelper";

import SessionHelper from "helpers/SessionHelper";
import {
  GenerateDataForChart
} from "helpers/ChartHelper"

import {
  UserProfile,
  TotalDeposits,
  OfferActivty,
  ClaimRate,
  DepositsBySite,
  DepositHistory,
  ProductCard,
  OfferInfo,
} from "./components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PropTypes from "prop-types";
import withAuthorization from "../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
}));

const OfferDetails = (props) => {
  const classes = useStyles();
  const { history } = props;
  const offer = props.location.state.product;
  const criteriaList = props.location.state.userCriteriaList;

  const TIME_RANGE = {
    SAME_DAY: {Name:'Last Day', Val:0, DateUnits:DateUnits.DAY, Count:1},
    WEEK: {Name:'Last 7 Days', Val:5, DateUnits:DateUnits.DAY, Count:7},
    WEEK_2: {Name:'Last 14 Days', Val:6,DateUnits:DateUnits.DAY, Count:14},
    MONTH: {Name:'Last Month', Val:1, DateUnits:DateUnits.DAY, Count:30},
    MONTH_3: {Name:'Last 3 Months', Val:7, DateUnits: DateUnits.MONTH, Count:3},
    MONTH_6: {Name:'Last 6 Months', Val:2, DateUnits: DateUnits.MONTH, Count:6},
    YEAR: {Name:'Last Year', Val:3, DateUnits:DateUnits.MONTH, Count:12},
    ALL_TIME: {Name:'All Time', Val:4, DateUnits:DateUnits.YEAR, Count:5},
  }

  const [offerDetails, setOfferDetails] = useState([]);
  const [deleteOffer, setDeleteOffer] = useState(false);
  const [barChartDataSet, setBarChartDataSet] = useState(null);

  var RequestObject = {
    IntTag: offer.Id,
  };

  const theme = useTheme();

  const handleBack = () => {
    history.goBack();
  };
  const handleEditOffer = (offer) => {
    console.log("EDIT OFFER: "+offer.StartDate)
    history.push({
      pathname: "./EditOffer",
      state: { offer: offer, criteriaList: criteriaList },
    });
  };

  const handleDeleteOffer = (offer) => {
    setDeleteOffer(true);
  };

  const deleteOfferCall = async () => {
    const result = await WebService.RemoveOffer(history, RequestObject);
    if (result != null) {
      history.push({ pathname: "./Offers" });
    } else {
      history.push({ pathname: "./sign-in" });
    }
  };

  const fetchData = async () => {
    const result = await WebService.GetMyOfferDetails(history, RequestObject);
    if (result != null) {
      setOfferDetails(result);
      setBarChartDataSet(GenerateDataForChart(SessionHelper.getCompanyId() != 0,result.UserTransactionEntries, SessionHelper.getCompanyList(), TIME_RANGE.YEAR.Count,TIME_RANGE.YEAR.DateUnits,  null))

    } else {
      history.push({ pathname: "./sign-in" });
    }
  };

  useEffect(() => {
    if (deleteOffer) {
      deleteOfferCall();
    }
  }, [deleteOffer]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.contentHeader}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={(event) => handleEditOffer(offer)}
        >
          Edit Offer
        </Button>
        <span className={classes.spacer} /> <span className={classes.spacer} />{" "}
        <span className={classes.spacer} /> <span className={classes.spacer} />{" "}
        <span className={classes.spacer} /> <span className={classes.spacer} />{" "}
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={(event) => handleDeleteOffer(offer)}
        >
          Delete Offer
        </Button>
      </div>

      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <OfferInfo userDetails={offerDetails} />
        </Grid>

        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <OfferActivty userDetails={offerDetails} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ClaimRate userDetails={offerDetails} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalDeposits userDetails={offerDetails} />
        </Grid>

        <Grid item lg={12} md={12} xl={12} xs={12}>
          <DepositHistory userDetails={offerDetails} />
          <DepositHistory  
            showAllStats={true}
            barChartDataSet={barChartDataSet}
            //transactionEntryField={transactionEntryField}
           />
        </Grid>
      </Grid>
    </div>
  );
};

OfferDetails.propTypes = {
  history: PropTypes.object,
};
export default withAuthorization(OfferDetails);
