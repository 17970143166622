import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WebService from "../../api/WebServices.js";
import queryString from 'query-string';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Typography
} from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%"
  },
  grid: {
    height: "100%"
  },
  centerWrapper: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundPosition: "center"
  },
  quoteContainer: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(/images/auth.jpg)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center"
  },
  quoteInner: {
    textAlign: "center",
    flexBasis: "600px"
  },
  quoteText: {
    color: theme.palette.white,
    fontWeight: 300
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  contentContainer: {},
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  contentHeader: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center"
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  confirmButton: {
    margin: theme.spacing(2, 0)
  }
}));


const EmailConfirmed = props => { 
  const{ history } = props;
  const classes = useStyles();
  

  const [formState, setFormState] = useState({
    isValid: true,
    values: {},
    touched: {},
    errors: {}
  });
  
  const handleBack = () => {
    history.goBack();
  };
 
  const handlePasswordConfirmClicked = event => {
    event.preventDefault();
    const params = queryString.parse(props.location.search);
    
    if(validParamValues(params.ConfirmationToken, params.Email) && 
      validPasswordEntries(formState.values.Password, formState.values.ConfirmPassword)) {
      WebService.ConfirmAccount(history, {
        Email: decodeURI(params.Email),
        Password: formState.values.Password,
        Token: decodeURI(params.ConfirmationToken)
      }).then(data => {
        if (data !== null) {
          console.log("Password has been set");
          history.push("./sign-in");
        }
      });
    }
  }
  
  const validPasswordEntries = (password, confirmedPassword) => { 
    return (password !== undefined || confirmedPassword !== undefined) &&
            (password === confirmedPassword)  
  }
  
  const validParamValues = (token, email) => { 
    
    return (token !== undefined || email !== undefined) && 
           (token.length > 0 && email.length > 0);
  }
  

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  }; 
  
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  
  return(
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.content} item lg={12} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentHeader}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className={classes.contentBody}>

              <div className={classes.centerWrapper}>
                <form className={classes.form} onSubmit={handlePasswordConfirmClicked}>
                  <Typography className={classes.title} variant="h2">
                    Email Confirmed
                  </Typography>
                  
                  <Typography variant="h4">
                    Please set your password below:
                  </Typography>

                  <TextField
                    className={classes.textField}
                    error={hasError("Password")}
                    fullWidth
                    helperText={
                      hasError("Password") ? formState.errors.Password[0] : null
                    }
                    label="Password"
                    name="Password"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.Password || ""}
                    variant="outlined"
                  />

                  <TextField
                    className={classes.textField}
                    error={hasError("ConfirmPassword")}
                    fullWidth
                    helperText={
                      hasError("ConfirmPassword") ? formState.errors.title[0] : null
                    }
                    label="Confirm Password"
                    name="ConfirmPassword"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.ConfirmPassword || ""}
                    variant="outlined"
                  />

                  <Button
                    className={classes.ConfirmButton}
                    color="primary"
                    disabled={!formState.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Create password
                  </Button>
                  
                </form>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}


EmailConfirmed.propTypes = { 
  history: PropTypes.object
};
export default withRouter(EmailConfirmed);