import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Grid, Typography, Avatar, Button } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MoneyIcon from "@mui/icons-material/Money";
import { Tooltip } from "@mui/material";
import InfoRounded from "@mui/icons-material/InfoRounded";
import { IconButton } from "@mui/material";

import {
  GetName,
  GetEmail,
  GetInitials,
  GetPhone,
  GetCreatedDate,
  GetUserLocation,
  GetUserId,
  GetProfileURL,
  GetUserStandingStatus,
  GetUserPoints,
  GetUserState,
  GetUserCity,
  GetUserStreetNumber,
  GetUserStreetName,
  GetUserPostalcode,
  GetTotalPointsEver
} from "helpers/UserHelper";

import {
  UserActivityView
} from "../../components";

import {
  GetPointsFromDollars,
  GetDollarsFromPoints
} from "constants/constants";


import {
  GetCancelledCount,
  GetActiveCount,
  GetExpiredCount,
  GetInactiveCount,
  GetClaimRate,
  GetCompletedCount,
} from "helpers/UserDetailsHelper";
import withAuthorization from "../../../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  titleSecond: {
    fontWeight: 700,
    marginTop: theme.spacing(3)
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  contactSection: {
    marginBottom: theme.spacing(1),
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const UserInfo = (props) => {
  const { className, user, userDetails, openUnlinkPopup, openDeletePopup, openSummaryCheckPopup, ...rest } = props;

  const classes = useStyles();

  return (
    <div>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
            <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="h4"
              >
                User Stats
              </Typography>
              <Typography variant="h5" className={classes.contactSection}>
                <b>Current Points: </b>
                {GetUserPoints(user) +" ($"+GetDollarsFromPoints(GetUserPoints(user))+")"}
              </Typography>
              <Typography variant="h5" className={classes.contactSection}>
                <b>Lifetime Points: </b>
                {GetTotalPointsEver(user) +" ($"+GetDollarsFromPoints(GetTotalPointsEver(user))+")"}
              </Typography>
              {/* <Typography variant="h5" className={classes.contactSection}>
                <b>Claim Rate: </b>
                {GetClaimRate(userDetails)+"%"}
              </Typography> */}
              
              
              {/* <Typography
                className={classes.titleSecond}
                color="textSecondary"
                gutterBottom
                variant="h4"
              >
                Contact
              </Typography>

              
              <Typography variant="h5" className={classes.contactSection}>
                <b>Street : </b> {GetUserStreetNumber(user)+" "+ GetUserStreetName(user)}
              </Typography>
              <Typography variant="h5" className={classes.contactSection}>
                <b>City: </b> {GetUserCity(user)}
              </Typography>
              <Typography variant="h5" className={classes.contactSection}>
                <b>Zip: </b> {GetUserPostalcode(user)}
              </Typography>
              <Typography variant="h5" className={classes.contactSection}>
                <b>State: </b> {GetUserState(user)}
              </Typography>
              <Typography variant="h5" className={classes.contactSection}>
                <b>Phone: </b> {GetPhone(user)}
              </Typography> */}
              <Button
              color="primary"
              size="small"
              variant="text"
              onClick={() => openUnlinkPopup()}
              >
                Unlink Account
              </Button>
              <Button
              color="primary"
              size="small"
              variant="text"
              onClick={() => openDeletePopup()}
              >
                DELETE Account
              </Button>
              <Button
              color="primary"
              size="small"
              variant="text"
              onClick={() => openSummaryCheckPopup()}
              >
                User Summary
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div className={classes.row}>
        <h4>FraudShield Auto-Detected Events</h4>
        <Tooltip title="Logged Suspicious Events">
          <IconButton aria-label="?">
            <InfoRounded />
          </IconButton>
        </Tooltip>
      </div>

      <UserActivityView user={user} showFraudShieldOnly={true} />
    </div>
  );
};

UserInfo.propTypes = {
  className: PropTypes.string,
  userDetails: PropTypes.any,
};

export default withAuthorization(UserInfo);