import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Avatar, Typography } from "@mui/material";
import {
  GetName,
  GetEmail,
  GetInitials,
  GetPhone,
  GetCreatedDate,
  GetUserLocation,
  GetUserId,
  GetProfileURL,
} from "helpers/UserHelper";
import withAuthorization from "../../../../../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
  },
  avatar: {
    width: 100,
    height: 100,
  },
  name: {
    marginTop: theme.spacing(1),
  },
}));

const UserProfile = (props) => {
  const { className, user, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar className={classes.avatar} src={GetProfileURL(user)}>
        {GetInitials(user)}
      </Avatar>
      <Typography variant="h2">{GetName(user)}</Typography>
      <Typography variant="h5">Acquired through BetFully</Typography>
      <Typography variant="h5">Joined: {"01/01/2020"}</Typography>
    </div>
  );
};

UserProfile.propTypes = {
  className: PropTypes.string,
};

export default withAuthorization(UserProfile);
