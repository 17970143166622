import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Bar, Line } from "react-chartjs-2";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  FormLabel
} from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import {
  options,
  moneyOptions
} from "helpers/ChartHelper"

import {
  GetTransactionEntryFieldString
} from "helpers/UserDetailsHelper";

import {
  GetCompanyNameWithId
} from "helpers/CompanyHelper";

import withAuthorization from "../../../../../../helpers/Authorization";
import SessionHelper from "helpers/SessionHelper";

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: "relative"
  },
  actions: {
    justifyContent: "flex-end"
  },
  formControlWidth: {
    width: "162px",

  },
}));

const DepositHistory = props => {
  const { className, barChartDataSet, transactionEntryField, showAllStats, ...rest } = props;
  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
     {barChartDataSet != null &&  <CardHeader
        
        title={(showAllStats && barChartDataSet != null && barChartDataSet.datasets != null && 
          barChartDataSet.datasets.length > 0)?
          (
            (SessionHelper.getCompanyId() == 0)?"Recent "+GetTransactionEntryFieldString(transactionEntryField)+" Across All Partners":(
              (showAllStats)?("Recent Data for "+GetCompanyNameWithId(barChartDataSet.datasets[0].companyId))
              :"Recent "+GetTransactionEntryFieldString(transactionEntryField) )
          )
          :"No data available"}
      />}
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
        {barChartDataSet != null && <Line data={barChartDataSet} options={moneyOptions} />}
        </div>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button color="primary" size="small" variant="text">
          Overview <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

DepositHistory.propTypes = {
  className: PropTypes.string,
  transactionEntryField: PropTypes.any,
  showAllStats: PropTypes.bool
};

export default withAuthorization(DepositHistory);
