import React, { useEffect, useState } from 'react'
import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import Paper from '@mui/material/Paper'
import {
  GetEventType,
  GetPaypalId,
  GetResourceType,
  GetTimeCreated, 
  GetUserPayouts
} from '../../../../helpers/PaypalWebhookResponseHelper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  GetDateCreated, 
  GetDateStatusWasUpdated,
  GetFirebaseUserId,
  GetPayoutAmount,
  GetReceiverEmailAddress,
  GetStatus
} from '../../../../helpers/UserPayoutHelper'
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";

const useStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

const InnerCollapsiblePayoutTable = props => {
  const { className, PaypalWebhookResponse,
    history, ...rest } = props;
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  const handleClickedWebhookResponse = (event, paypalWebhookResponse) => {
    event.stopPropagation();
    history.push({ pathname: "./PaypalWebhookDetails", state: { ppalWebhook: paypalWebhookResponse} });

  }
  
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          { GetUserPayouts(PaypalWebhookResponse) !== null &&
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          }
        </TableCell>
        <TableCell 
          onClick={event => handleClickedWebhookResponse(event, PaypalWebhookResponse)}
        >
          {GetPaypalId(PaypalWebhookResponse)}
        </TableCell>
        <TableCell
          onClick={event => handleClickedWebhookResponse(event, PaypalWebhookResponse)}
        >
          {GetResourceType(PaypalWebhookResponse)}
        </TableCell>
        <TableCell
          onClick={event => handleClickedWebhookResponse(event, PaypalWebhookResponse)}
        >
          {GetEventType(PaypalWebhookResponse)}
        </TableCell>
        <TableCell
          onClick={event => handleClickedWebhookResponse(event, PaypalWebhookResponse)}
        >
          {GetEventType(PaypalWebhookResponse)}
        </TableCell>
        <TableCell
          onClick={event => handleClickedWebhookResponse(event, PaypalWebhookResponse)}
        >
          {GetTimeCreated(PaypalWebhookResponse)}
        </TableCell>
      </TableRow>
      
      <TableRow>
        <TableCell colSpan={6}>
          <Collapse in={open} timeauto="auto" unmountOnExit>
            <Box margin={1}>
              {
                GetUserPayouts(PaypalWebhookResponse) !== null && 
              <Typography variant="h6" gutterBottom component="div">
                <b>Paypal Payouts</b>
              </Typography>
              }
              <Table size="small">
                <TableHead>
                  {
                    GetUserPayouts(PaypalWebhookResponse) !== null &&
                    <TableRow>
                      <TableCell>Email to receive payment</TableCell>
                      <TableCell>Payout amount</TableCell>
                      <TableCell>Firebase User Id</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Date Status Changed</TableCell>
                    </TableRow>
                  }
                </TableHead>
                <TableBody>
                  { 
                    GetUserPayouts(PaypalWebhookResponse) !== null &&
                    GetUserPayouts(PaypalWebhookResponse).map( (UserPayout) => (
                      <TableRow>
                        <TableCell>{GetReceiverEmailAddress(UserPayout)}</TableCell>
                        <TableCell>{GetPayoutAmount(UserPayout)}</TableCell>
                        <TableCell>{GetFirebaseUserId(UserPayout)}</TableCell>
                        <TableCell>{GetStatus(UserPayout)}</TableCell>
                        <TableCell>{GetDateStatusWasUpdated(UserPayout)}</TableCell> 
                      </TableRow>
                    )) 
                  }
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

InnerCollapsiblePayoutTable.propTypes = {
  className: PropTypes.string,
  PaypalWebhookResponses: PropTypes.array.isRequired,
  history: PropTypes.object
};

export default withMasterAuthorization(InnerCollapsiblePayoutTable);
