import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {Button} from "@mui/material";
import React, {useState} from "react";
import PropTypes from "prop-types";
import withAuthorization from "../../../../helpers/Authorization";
import {makeStyles} from "@mui/styles";
import {GetId} from "../../../../helpers/KeywordHelper";

const useStyles = makeStyles((theme) => ({
   content:{
      marginTop:theme.spacing(3)
   }
}));

const EditKeywordModal = (props) => {
   const classes = useStyles();
   const [keywordToUpdate, setKeywordToUpdate] = useState({});
   
   
   const updateKeywordChangeHandler = (event) => {
      let word = event.target.value;
      console.log("UpdateKeywordChangeHandler")
      console.log(word)
      setKeywordToUpdate(word)
   }

   return (
      <Modal
         className={classes.content}
         show={props.showEditKeyword}
         onHide={props.handleCloseEdit}
         centered>
         <Modal.Header closeButton>
            <Modal.Title>{"Edit Keyword"}</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            <Form>
               <Form.Group controlId="formBasicEmail">
                  <Form.Label>{"Update the keyword you would like us to scan for" +
                              "*(Please note the keyword will be sent for Re-approval)"}</Form.Label>
                  <Form.Control required 
                                defaultValue={props.currentKeyword}
                                name='keywordInput'
                                onChange={updateKeywordChangeHandler}/>
                  <Form.Text className="text-muted">

                  </Form.Text>
               </Form.Group>
            </Form>
         </Modal.Body>
         <Modal.Footer>
            <Button
               variant="contained"
               color="primary"
               onClick={(keywordName, keywordId) => props.updateKeyword(keywordToUpdate, props.currentKeywordId)}>
               Submit
            </Button>
            <Button variant="contained" onClick={props.handleCloseEdit}>
               Cancel
            </Button>
         </Modal.Footer>
      </Modal>
   );
};

EditKeywordModal.propTypes = {
   history: PropTypes.object
}

export default withAuthorization(EditKeywordModal);