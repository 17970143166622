import React, { useState } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@mui/styles";
import {Button, TableSortLabel, Tooltip} from "@mui/material";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from "@mui/material";

import {
  GetWord,
  GetStatus,
  GetCount,
  GetCreatedDate,
  GetId,
  GetDateLastScanned,
  GetCompanyName,
  KeywordStatusEnum,
  GetAdminNote,
  ShowRevisionNote, KeywordStatusToString, ShowRemoveButton
} from "helpers/KeywordHelper";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";
import withAuthorization from "../../../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  deleteButton: {
    backgroundColor: "#B53F51",
    color: "#FFFFFF",
    margin:"5px"
  },
  buttonsWrapper: {
    textAlign: "right !important"
  },
  statusContainer: {
    textAlign: "center",
  },
  approvedStatus: {
    textAlign: "center",
    border: "2px",
    maxWidth: "90%",
    borderRadius: "25px",
    backgroundColor: "#51B53F",
    color: "#FFFFFF"
    
  },
  deniedStatus: {
    textAlign: "center",
    border: "2px",
    maxWidth: "90%",
    borderRadius: "25px",
    backgroundColor: "#B53F51",
    color: "#FFFFFF"
  },
  pendingStatus: {
    textAlign: "center",
    border: "2px",
    maxWidth: "90%",
    borderRadius: "25px",
    backgroundColor: "#B5A33F",
    color: "#FFFFFF"
  }
}));

const KeywordsTable = (props) => {
  const { className, users, history, user, ...rest } = props;

  const classes = useStyles();
  const keywords = props.keywords;

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);

  const handleSelectedKeyword = (event, usera) => {
    history.push({ pathname: "./keyworddetails", state: { user: usera } });
  };

  const handlePageChange = (event, page) => {

    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };
  
  const getStatusClassName = (approvalStatus) => {
    switch(approvalStatus){
      case KeywordStatusToString.Active:
        return classes.approvedStatus;
      case KeywordStatusToString.Fix:
        return classes.deniedStatus;
      case KeywordStatusToString.Pending:
        return classes.pendingStatus;
      default:
        return classes.statusContainer;
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>

      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Word</TableCell>
                  <TableCell>Approval Notes</TableCell>
                  <TableCell>Transaction Count</TableCell>
                  <TableCell sortDirection="desc">
                    <Tooltip enterDelay={300} title="Sort">
                      <TableSortLabel active direction="desc">
                        Date Created
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>

                  <TableCell>Approval Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {keywords.map((keyword) => (
                   <TableRow hover key={GetId(keyword)}>
                     <TableCell>{GetWord(keyword)}</TableCell>
                     { ShowRevisionNote(GetStatus(keyword)) &&
                        <TableCell>{GetAdminNote(keyword)}</TableCell>
                     }
                     { !ShowRevisionNote(GetStatus(keyword)) &&
                        <TableCell>N/A</TableCell>
                     }
                     <TableCell>{GetCount(keyword)}</TableCell>
                     <TableCell>
                       {GetCreatedDate(keyword)}
                     </TableCell>

                     <TableCell>
                       <div 
                          className={getStatusClassName(GetStatus(keyword))}>
                         {GetStatus(keyword)}
                       </div>
                     </TableCell>
                     <TableCell
                        className={classes.buttonsWrapper}
                     >
                       {ShowRevisionNote(GetStatus(keyword)) && <Button
                          variant="contained"
                          color="primary"
                          onClick={() => props.openEditPopupForKeyword(keyword)}
                          >
                         Edit
                       </Button> }
                       {ShowRemoveButton(GetStatus(keyword)) && <Button
                          className={classes.deleteButton}
                          variant="contained"
                          onClick={() =>
                             props.RemoveKeyword(keyword.Id)} >
                          Delete
                       </Button>
                       }
                     </TableCell>
                   </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={keywords.length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
        />
      </CardActions>
    </Card>
  );
};

KeywordsTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired,
  history: PropTypes.object,
  user: PropTypes.object,
};

export default withAuthorization(KeywordsTable);
