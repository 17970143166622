export function GetPaypalPayoutItemId(paypalPayoutItem){ 
  return paypalPayoutItem !== undefined ? paypalPayoutItem.payout_item_id : "N/A";
}

export function GetPaypalPayoutItemTransactionId(paypalPayoutItem) {
  return paypalPayoutItem !== undefined ? paypalPayoutItem.transaction_id : "N/A";
}

export function GetPaypalPayoutItemStatus(paypalPayoutItem){
  if(paypalPayoutItem !== undefined){
    switch(paypalPayoutItem.transaction_status){
      case 0:
        return "Success";
      case 1: 
        return "Failed";
      case 2:
        return "Pending";
      case 3: 
        return "Unclaimed";
      case 4: 
        return "Returned";
      case 5: 
        return "OnHold";
      case 6: 
        return "Blocked";
      case 7: 
        return "Refunded";
      case 8: 
        return "Reversed";
      default: 
        return "N/A";
    }
  }
}

export function GetPaypalPayoutItemFee(paypalPayoutItem) {
  if(paypalPayoutItem !== undefined){
    return `${GetCurrencySymbol(paypalPayoutItem.payout_item_fee.currency)} ${paypalPayoutItem.payout_item_fee.value}`
  }  
  return "N/A";
}

export function GetReceiverEmail(paypalPayoutItem){ 
  return paypalPayoutItem !== undefined ? paypalPayoutItem.payout_item.receiver : "N/A";
}

export function GetTimeProcessed(paypalPayoutItem) {
  return paypalPayoutItem !== undefined ? paypalPayoutItem.time_processed : "N/A";
}

const GetCurrencySymbol = (currency) => {
  switch(currency){
    case "USD":
      return "$";
    default: 
      return "";
  }
}