import React, { useState } from "react";
import clsx from "clsx";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  TablePagination
} from "@mui/material";

import { GetClientNameFrom, GetOfferStatus, GetListOfOffers } from "helpers/UserDetailsHelper";
import { 
  GetUserOfferId,
  GetOfferModelId,
  GetBetfullyUserId,
  GetStartDate,
  GetExpirationDate,
  GetDateClaimed,
  GetStatusString,
  GetAmountProcessed,
  GetOfferModel,
  OfferRow
   } from "helpers/UserOfferHelper"

import { 
  GetTitle,
  GetTitle2,
  GetDescription,
  GetOfferId,
  GetCompanyId } from "helpers/OfferHelper"

import {GetCompanyNameWithId} from "helpers/CompanyHelper"

import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 800,
  },
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const LatestOrders = (props) => {
  const { className, userdetails, approveTransaction, ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [offers, setOffers] = useState(GetListOfOffers(userdetails));
  const [filteredOffers, setFilteredOffers] = useState(offers);
  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0)
    setRowsPerPage(event.target.value);
  };

  const COLUMNS_SORT = {
    DEFAULT: -1,
    DATE_CREATED_DESC: 0,
    DATE_CREATED_ASC: 1,
    TITLE_DESC: 2,
    TITLE_ASC: 3,
    DESCRIPTION_DESC: 4,
    DESCRIPTION_ASC: 5,
    DEP_AMOUNT_DESC: 6,
    DEP_AMOUNT_ASC: 7,
    CLIENT_DESC: 8,
    CLIENT_ASC: 9,
    STATUS_DESC: 10,
    STATUS_ASC: 11
  }

  const toggleSort = (columnType) => {
    var nextColumn = columnType;
    switch(columnType){
      case COLUMNS_SORT.DATE_CREATED_ASC:
      case COLUMNS_SORT.DATE_CREATED_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.DATE_CREATED_DESC)?COLUMNS_SORT.DATE_CREATED_ASC:COLUMNS_SORT.DATE_CREATED_DESC;
        break;
      case COLUMNS_SORT.TITLE_ASC:
      case COLUMNS_SORT.TITLE_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.TITLE_DESC)?COLUMNS_SORT.TITLE_ASC:COLUMNS_SORT.TITLE_DESC;
        break;
      case COLUMNS_SORT.DESCRIPTION_ASC:
      case COLUMNS_SORT.DESCRIPTION_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.DESCRIPTION_DESC)?COLUMNS_SORT.DESCRIPTION_ASC:COLUMNS_SORT.DESCRIPTION_DESC;
        break;
      case COLUMNS_SORT.DEP_AMOUNT_ASC:
      case COLUMNS_SORT.DEP_AMOUNT_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.DEP_AMOUNT_DESC)?COLUMNS_SORT.DEP_AMOUNT_ASC:COLUMNS_SORT.DEP_AMOUNT_DESC;
        break;
      case COLUMNS_SORT.CLIENT_ASC:
      case COLUMNS_SORT.CLIENT_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.CLIENT_DESC)?COLUMNS_SORT.CLIENT_ASC:COLUMNS_SORT.CLIENT_DESC;
        break;
      case COLUMNS_SORT.STATUS_ASC:
      case COLUMNS_SORT.STATUS_DESC:
        nextColumn = (selectedSort == COLUMNS_SORT.STATUS_DESC)?COLUMNS_SORT.STATUS_ASC:COLUMNS_SORT.STATUS_DESC;
        break;
      default:
        nextColumn = COLUMNS_SORT.DEFAULT;
        break;
    }
    sortOffers(nextColumn);
    setSelectedSort(nextColumn);
  };

  const sortOffers = (columnType) =>{
    filteredOffers.sort(function(userA, userB) {
      var compareResult = 0;
      switch(columnType){
        case COLUMNS_SORT.DATE_CREATED_ASC:
          compareResult = new Date(GetDateClaimed(userA)) - new Date(GetDateClaimed(userB));
          break;
        case COLUMNS_SORT.DATE_CREATED_DESC:
          compareResult = new Date(GetDateClaimed(userB)) - new Date(GetDateClaimed(userA))
          break;
        case COLUMNS_SORT.TITLE_ASC:
          compareResult = GetTitle(GetOfferModel(userB)).localeCompare(GetTitle(GetOfferModel(userA)));
          break;
        case COLUMNS_SORT.TITLE_DESC:
          compareResult = GetTitle(GetOfferModel(userA)).localeCompare(GetTitle(GetOfferModel(userB)));
          break;
        case COLUMNS_SORT.DESCRIPTION_ASC:
          compareResult = GetDescription(GetOfferModel(userB)).localeCompare(GetDescription(GetOfferModel(userA)));
          break;
        case COLUMNS_SORT.DESCRIPTION_DESC:
          compareResult = GetDescription(GetOfferModel(userA)).localeCompare(GetDescription(GetOfferModel(userB)));
          break;
        case COLUMNS_SORT.DEP_AMOUNT_ASC:
          if (GetAmountProcessed(userA)<GetAmountProcessed(userB)){
            compareResult = -1;
          }
          if (GetAmountProcessed(userA)>GetAmountProcessed(userB)) {
            compareResult = 1;
          }
          break;
        case COLUMNS_SORT.DEP_AMOUNT_DESC:
          if (GetAmountProcessed(userA)>GetAmountProcessed(userB)){
            compareResult = -1;
          }
          if (GetAmountProcessed(userA)<GetAmountProcessed(userB)) {
            compareResult = 1;
          }
          break;
        case COLUMNS_SORT.CLIENT_ASC:
          compareResult = GetCompanyNameWithId(GetCompanyId(GetOfferModel(userB))).localeCompare(GetCompanyNameWithId(GetCompanyId(GetOfferModel(userA))));
          break;
        case COLUMNS_SORT.CLIENT_DESC:
          compareResult = GetCompanyNameWithId(GetCompanyId(GetOfferModel(userA))).localeCompare(GetCompanyNameWithId(GetCompanyId(GetOfferModel(userB))));
          break;
        case COLUMNS_SORT.STATUS_ASC:
          
          compareResult = GetStatusString(userB).localeCompare(GetStatusString(userA));
          break;
        case COLUMNS_SORT.STATUS_DESC:
          compareResult = GetStatusString(userA).localeCompare(GetStatusString(userB));
          break;
          
        default:
          compareResult = 0;
      }
      //Compare unique ids in case of ties
      if(compareResult == 0){
        if (GetUserOfferId(userA)<GetUserOfferId(userB)){
          compareResult = -1;
        }
        if (GetUserOfferId(userA)>GetUserOfferId(userB)) {
          compareResult = 1;
        }
      }
      return compareResult;
    });
    //applyAllFilters();
  };
  
  const isSortActive = (columnType) => {
    return selectedSort == columnType;
  };

  const [selectedSort, setSelectedSort] = useState(COLUMNS_SORT.DEFAULT);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        // action={
        //   <Button color="primary" size="small" variant="outlined">
        //     New entry
        //   </Button>
        // }
        title="Offer History"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                <TableCell 
                    className="w-1 p-1"
                    onClick={()=>toggleSort(COLUMNS_SORT.TITLE_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.TITLE_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.TITLE_ASC) || isSortActive(COLUMNS_SORT.TITLE_DESC)}>
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.DATE_CREATED_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.DATE_CREATED_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.DATE_CREATED_ASC) || isSortActive(COLUMNS_SORT.DATE_CREATED_DESC)}>
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    >
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel 
                        >
                        OfferId
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    >
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel>
                        Company
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.TITLE_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.TITLE_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.TITLE_ASC) || isSortActive(COLUMNS_SORT.TITLE_DESC)}>
                        Title
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-10 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.TITLE_DESC)}>
                    Title2
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.DESCRIPTION_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.DESCRIPTION_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.DESCRIPTION_ASC) || isSortActive(COLUMNS_SORT.DESCRIPTION_DESC)}>
                        Descrip
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.DESCRIPTION_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.DESCRIPTION_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.DESCRIPTION_ASC) || isSortActive(COLUMNS_SORT.DESCRIPTION_DESC)}>
                        Offer Type
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.STATUS_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.STATUS_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.STATUS_ASC) || isSortActive(COLUMNS_SORT.STATUS_DESC)}>
                        Reward
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.STATUS_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.STATUS_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.STATUS_ASC) || isSortActive(COLUMNS_SORT.STATUS_DESC)}>
                        Points Awarded
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.DEP_AMOUNT_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.DEP_AMOUNT_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.DEP_AMOUNT_ASC) || isSortActive(COLUMNS_SORT.DEP_AMOUNT_DESC)}>
                        Amnt Processed
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.STATUS_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.STATUS_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.STATUS_ASC) || isSortActive(COLUMNS_SORT.STATUS_DESC)}>
                        Started
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.STATUS_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.STATUS_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.STATUS_ASC) || isSortActive(COLUMNS_SORT.STATUS_DESC)}>
                        Expiration
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.STATUS_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.STATUS_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.STATUS_ASC) || isSortActive(COLUMNS_SORT.STATUS_DESC)}>
                        Date Claimed
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.CLIENT_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.CLIENT_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.CLIENT_ASC) || isSortActive(COLUMNS_SORT.CLIENT_DESC)}>
                        Status
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                  
                  
                  <TableCell 
                    className="w-25 p-3"
                    onClick={()=>toggleSort(COLUMNS_SORT.STATUS_DESC)}>
                      <Tooltip enterDelay={300} title="Sort">
                        <TableSortLabel direction={isSortActive(COLUMNS_SORT.STATUS_ASC)?"asc":"desc"} 
                        active={isSortActive(COLUMNS_SORT.STATUS_ASC) || isSortActive(COLUMNS_SORT.STATUS_DESC)}>
                        </TableSortLabel>
                      </Tooltip>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredOffers.slice((page)*rowsPerPage, (rowsPerPage)+(page)*rowsPerPage).map((order) => (
                  <OfferRow key={GetOfferId(order)} row={order} approveTransaction={approveTransaction} />
                  // <TableRow hover key={GetUserOfferId(order)}>
                  //   <TableCell>{GetStartDate(order)}</TableCell>
                  //   <TableCell>{GetTitle(GetOfferModel(order))}</TableCell>
                  //   <TableCell>{GetDescription(GetOfferModel(order))}</TableCell>
                  //   <TableCell>{"$"+GetAmountProcessed(order)}</TableCell>
                  //   <TableCell>
                  //     {GetCompanyNameWithId(GetCompanyId(GetOfferModel(order)))}
                  //   </TableCell>
                  //   <TableCell>{GetStatusString(order)}</TableCell>
                  // </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={filteredOffers.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50]}
          />
        </CardActions>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string,
  userdetails: PropTypes.any,
};

export default LatestOrders;
