import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";

import { SearchInput } from "components";
import withAuthorization from "../../../../helpers/Authorization";

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1),
  },
}));

const ProductsToolbar = (props) => {
  const { className, history, userCriteriaList, ...rest } = props;

  const classes = useStyles();

  const handleCreateOffer = () => {
    history.push({
      pathname: "./createOffer",
      state: { userCriteriaList: userCriteriaList },
    });
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        <Button
          color="primary"
          variant="contained"
          onClick={(event) => handleCreateOffer()}
          role="button"
        >
          Create Offer
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search Offer"
        />
      </div>
    </div>
  );
};

ProductsToolbar.propTypes = {
  className: PropTypes.string,
  userCriteriaList: PropTypes.object,
};

export default withAuthorization(ProductsToolbar);
