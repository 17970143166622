import {Typography} from "@mui/material";
import {Button} from "reactstrap";
import React, {useState} from "react";
import {makeStyles} from "@mui/styles";
import withAuthorization from "../../../../helpers/Authorization";
import PropTypes from "prop-types";
import SessionHelper from "../../../../helpers/SessionHelper";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.white,
      height: "100%",
   },
   cardPreview: {
      width: "25%",
   },
   grid: {
      height: "100%",
      width: "50%",
   },
   quoteContainer: {
      [theme.breakpoints.down("md")]: {
         display: "none",
      },
   },
   quote: {
      backgroundColor: theme.palette.neutral,
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: "url(/images/auth.jpg)",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
   },
   quoteInner: {
      textAlign: "center",
      flexBasis: "600px",
   },
   quoteText: {
      color: theme.palette.white,
      fontWeight: 300,
   },
   name: {
      marginTop: theme.spacing(3),
      color: theme.palette.white,
   },
   bio: {
      color: theme.palette.white,
   },
   contentContainer: {},
   content: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
   },
   contentHeader: {
      display: "flex",
      alignItems: "center",
      paddingTop: theme.spacing(3),
      paddingBototm: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
   },
   logoImage: {
      marginLeft: theme.spacing(4),
   },
   contentBody: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
         justifyContent: "center",
      },
   },
   form: {
      flexBasis: 700,
      [theme.breakpoints.down("sm")]: {
         paddingLeft: theme.spacing(2),
         paddingRight: theme.spacing(2),
      },
   },
   title: {
      marginTop: theme.spacing(3),
   },
   socialButtons: {
      marginTop: theme.spacing(3),
   },
   socialIcon: {
      marginRight: theme.spacing(1),
   },
   suggestion: {
      marginTop: theme.spacing(2),
      paddingBototm: theme.spacing(2),
   },
   textField: {
      marginTop: theme.spacing(2),
   },
   email: {
      margin: theme.spacing(2, 0),
      width: '100%'
   },
   row: {
      height: "42px",
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(1),
   },
}));


const ProfileDetails = (props) => {
   const { className, history, ...rest } = props;
   const classes = useStyles();
   const [formState, setFormState] = useState({
      isValid: true,
      values: {},
      touched: {},
      errors: {},
      productObj: {
         Title: "Title",
         Description: "Subtitle",
      },
   });

   const user = {
      name: SessionHelper.getName(),
      avatar: SessionHelper.getIcon(),
      role: SessionHelper.getRole(),
      company: SessionHelper.getCompany()
   };
   
   return (<div className={classes.content}>
      <div className={classes.contentBody}>
         <form className={classes.form}>
            <Typography
               className={classes.suggestion}
               color="textSecondary"
               variant="h4"
            >
               Email
            </Typography>
            <TextField className={classes.email} 
                       defaultValue={user.name} 
                       variant="outlined"
                       disabled
            />

            <Typography
               className={classes.suggestion}
               color="textSecondary"
               variant="h4"
            >
              Role 
            </Typography>
            <TextField className={classes.email}
                       defaultValue={user.role}
                       variant="outlined"
                       disabled
            />
            {/*<Button
               className={classes.suggestion}
               color="primary"
            >
               Save Changes
            </Button>*/}
         </form>
      </div>
   </div>)
}

ProfileDetails.propTypes = {
   className: PropTypes.string,
};

export default withAuthorization(ProfileDetails)