import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import WebService from "../../api/WebServices.js";
import PropTypes from "prop-types";
import PaypalWebhookResponseToolbar from './components/PaypalWebhookResponseToolbar';
import PaypalWebhookResponseTable from './components/PaypalWebhookResponseTable';
import withMasterAuthorization from "../../helpers/MasterAuthorization";
import SessionHelper from 'helpers/SessionHelper'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  pagination: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  }
}));

const PaypalWebhookResponseList = props => {
  const { history, ...rest } = props;
  const classes = useStyles();
  const [paypalWebhookResponse, setPaypalWebhookResponse] = useState([])
  
  useEffect(() => {
    if(SessionHelper.isMasterAdmin()) {
      const fetchData = async () => {
        const result = await WebService.GetPaypalWebhookResponse(history);
        if (result != null) {
          setPaypalWebhookResponse(result)
        } else {
          history.push({pathname: "./sign-in"});
        }
      };
      fetchData();
    }
    
  }, []);

  return (
    <div className={classes.root}>
      <PaypalWebhookResponseToolbar
        history={history}/>
      <div className={classes.content}>
        <PaypalWebhookResponseTable
          PaypalWebhookResponses={paypalWebhookResponse}
        />
      </div>
    </div>
  );
};

PaypalWebhookResponseList.propTypes = {
  history: PropTypes.object
};

export default withMasterAuthorization(PaypalWebhookResponseList);