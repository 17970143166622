import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { Form } from "react-bootstrap";

import { SearchInput } from 'components';
import withMasterAuthorization from "../../../../helpers/MasterAuthorization";
import { 
  GetCompanyName,
  GetCompanyId
} from '../../../../helpers/CompanyHelper'

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const StoredImagesToolbar = props => {
  const { className, history, allCompanies, sortCompanies, ...rest } = props;

  const classes = useStyles();
  
  const handleCreateStoredImage = () => { 
    
    history.push({
      pathname: "./CreateAdminUser"
    });
  }
  
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={props.openAddStoredImagePopup}
        >
          Create StoredImage 
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={props.openShowStoredImagesPopup}
        >
          Show StoredImages
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search StoredImage"
          role="button"
        />

        <Form inline>
          <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">
            Company 
          </Form.Label>
          <Form.Control
            as="select"
            className="my-1 mr-sm-2"
            id="inlineFormCustomSelectPref"
            custom
            onChange = {(event) => props.sortCompanies(event.target.value)}
          >
            <option value={0}>All</option>
            {props.allCompanies !== undefined && props.allCompanies.map((aNum) => (
              <option value={GetCompanyId(aNum)} key={GetCompanyId(aNum)}>{GetCompanyName(aNum)}</option>
            ))}
          </Form.Control>
        </Form> 
      </div>
    </div>
  );
};

StoredImagesToolbar.propTypes = {
  className: PropTypes.string
};

export default withMasterAuthorization(StoredImagesToolbar);
