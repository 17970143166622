import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from 'recompose';
import webService from "../api/WebServices.js";


const withAuthorization =  (Component) => {
    class WithAuthorization extends React.Component {
        componentDidMount() {
            console.log("Authorization componentDidMount");
            // Not authorized:
            if(!this.isAuthenticated()){
                console.log("The user is not authenticated");
                this.props.history.push({ pathname: "./sign-in" });
            }
        }


        render() {
            return <Component {...this.props} />;
        }


        isAuthenticated = () => {
           var authorizationRole = webService.getUserAuthorization();
           console.log("Authorization Role is:")
           console.log(authorizationRole)
           return authorizationRole === "Admin" || 
              authorizationRole === "Operator" || 
              authorizationRole === "Accounting" || 
              authorizationRole === "Director" || 
              authorizationRole === "Customer Service" || 
              authorizationRole === "MasterAdmin";
        }
        
    }

    return compose(
       withRouter
    )(WithAuthorization);
};

export default withAuthorization;