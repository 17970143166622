import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Card, CardContent, Grid, Typography, Avatar } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import withAuthorization from "../../../../../../helpers/Authorization";
import { NumericFormat } from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
}));

const CompetitorsDeposits = (props) => {
  const { className, companyName, amount, ...rest } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="inherit"
              gutterBottom
              variant="body2"
            >
              Deposits w/{companyName}
            </Typography>
            <Typography color="inherit" variant="h3">
              <NumericFormat decimalScale={2} fixedDecimalScale={true} value={amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

CompetitorsDeposits.propTypes = {
  className: PropTypes.string,
  companyName: PropTypes.string,
  amount: PropTypes.number,
};

export default withAuthorization(CompetitorsDeposits);
