import uuid from "uuid/v1";

export default [
  {
    id: uuid(),
    ref: "BF1049",
    amount: 103,
    customer: {
      name: "test1@test.com",
    },
    createdAt: 1555016400000,
    status: "Active",
  },
  {
    id: uuid(),
    ref: "BF1048",
    amount: 509,
    customer: {
      name: "test2@test.com",
    },
    createdAt: 1555016400000,
    status: "Active",
  },
  {
    id: uuid(),
    ref: "BF1047",
    amount: 1332,
    customer: {
      name: "test3@test.com",
    },
    createdAt: 1554930000000,
    status: "Active",
  },
  {
    id: uuid(),
    ref: "BF1046",
    amount: 3065,
    customer: {
      name: "test4@test.com",
    },
    createdAt: 1554757200000,
    status: "Disabled",
  },
  {
    id: uuid(),
    ref: "BF1045",
    amount: 97,
    customer: {
      name: "test5@test.com",
    },
    createdAt: 1554670800000,
    status: "Disabled",
  },
];
