import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { Form, Row, Col, Spinner } from "react-bootstrap";
import {EXPIRED_TOKEN} from "constants/constants"

import { Link as RouterLink, withRouter, useHistory } from "react-router-dom";

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import {
  GetOfferId,
  GetStartDate,
} from "helpers/OfferHelper";
import SessionHelper from "helpers/SessionHelper";

import {
  GetCompanyId,
  GetCompanyName,
  GetCompanyNameWithId
} from "helpers/CompanyHelper";
import {
  DepositHistory
} from "../UserDetails/components/TransactionsView/components";

import { 
  CreateUserTransactionEntriesMap,
  CreateUserTransactionEntriesMapDate,
  GetUserTransactionEntries,
  GetTotalDeposits,
  GetDepositAmountSum,
  GenerateDataForChart,
  DateUnits,
  TransactionEntryField,
  GetTEMField,
  GetTransactionEntryFieldString
 } from "helpers/UserDetailsHelper";

import {
  Budget,
  TotalUsers,
  TasksProgress,
  TotalProfit,
  LatestSales,
  UsersByDevice,
  LatestProducts,
  LatestOrders
} from "./components";
import WebService from "../../api/WebServices.js";
import withAuthorization from "../../helpers/Authorization";
import { data } from "./components/LatestSales/chart";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));


const Dashboard =  props => {
  const classes = useStyles();
  const {history, ...rest } = props;
  const TIME_RANGE = {
    SAME_DAY: {Name:'Last Day', Val:0, DateUnits:DateUnits.DAY, Count:1},
    WEEK: {Name:'Last 7 Days', Val:5, DateUnits:DateUnits.DAY, Count:7},
    WEEK_2: {Name:'Last 14 Days', Val:6,DateUnits:DateUnits.DAY, Count:14},
    MONTH: {Name:'Last Month', Val:1, DateUnits:DateUnits.DAY, Count:30},
    MONTH_3: {Name:'Last 3 Months', Val:7, DateUnits: DateUnits.MONTH, Count:3},
    MONTH_6: {Name:'Last 6 Months', Val:2, DateUnits: DateUnits.MONTH, Count:6},
    YEAR: {Name:'Last Year', Val:3, DateUnits:DateUnits.MONTH, Count:12},
    ALL_TIME: {Name:'All Time', Val:4, DateUnits:DateUnits.YEAR, Count:5},
  }


  const [countNewCustomers, setCountNewCustomers] = useState(0);
  const [dataAnalytics, setDataAnalytics] = useState(
    {
      UserAnalyticsData: null, RegisteredToday: 0,  
      RegisteredLast7Days: 0, RegisteredLast30Days: 0,
      TotalRegistered: 0
    });
  const [companyIdOverride, setCompanyIdOverride] = useState(SessionHelper.getCompanyId());
  const [countReactivatedCustomers, setCountReactivatedCustomers] = useState(0);
  const [cashRebatedMonth, setCashRebatedMonth] = useState(0);
  const [cashRebated, setCashRebated] = useState(0);
  const [recentOffers, setRecentOffers] = useState([]);
  const [transactionEntryField, setTransactionEntryField] = useState(TransactionEntryField.DEPOSIT);
  const [barChartDataSet, setBarChartDataSet] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUserLoading, setIsUserLoading] = useState(true);

  const handleChangeA = (event) => {
    console.log("Company Switch")
    SessionHelper.setDataRetrieved(false);
    setCompanyIdOverride(+event.target.value)
    setIsLoading(true)
    setIsUserLoading(true)
  };

  const sortOffers = (offers) =>{
    if(offers == null)
      offers = [];
    offers.sort(function(userA, userB) {
      var compareResult = 0;
      compareResult = new Date(GetStartDate(userB)) - new Date(GetStartDate(userA));
      //Compare unique ids in case of ties
      if(compareResult == 0){
        if (GetOfferId(userA)<GetOfferId(userB)){
          compareResult = -1;
        }
        if (GetOfferId(userA)>GetOfferId(userB)) {
          compareResult = 1;
        }
      }
      return compareResult;
    });
    if(offers.length > 5){
      setRecentOffers(offers.slice(0, 5));
    }else{
      setRecentOffers(offers);
    }
      
  };

  useEffect(() => {
    console.log("Icon is: " + SessionHelper.getIcon());
    if (!SessionHelper.dataRetrieved()) {
      const fetchData = async () => {
        const result = await WebService.GetMyInfo(history, companyIdOverride);
        if (result != null) {
          SessionHelper.setIcon(result.ProfilePictureURL);
          SessionHelper.setCompany(result.CompanyName);
          SessionHelper.setCompanyId(result.CompanyId);
          SessionHelper.setCompanyList(result.CompanyList);
          SessionHelper.setProvinceList(result.ProvinceList);
          SessionHelper.setDataRetrieved(true);
          console.log("Should refresh")
          history.go(0)
   //       window.location.reload();
        } else {
        }
        setIsUserLoading(false)
      };
      fetchData();
    }else{
      if(isUserLoading){
        setIsUserLoading(false)
      }
    }
  }, [companyIdOverride]);

  useEffect(() => {
    if(!isUserLoading){
    const fetchData = async () => {
      const result = await WebService.GetMyDashboard(history);
      if (result != null) {
        console.log(result)
        console.log("Number of UTEs: "+result.UserTransactionEntries.length)
        setCountNewCustomers(result.CustomersAddedCount)
        setCountReactivatedCustomers(result.CustomersReactivatedCount)
        setCashRebatedMonth(result.CashRebatedToday)
        setCashRebated(result.CashRebatedMonth)
        sortOffers(result.RecentOffers)
     //   setDataAnalytics(result.UserAnalyticsData)
     
        setBarChartDataSet(GenerateDataForChart(SessionHelper.getCompanyId() != 0,result.UserTransactionEntries, SessionHelper.getCompanyList(), TIME_RANGE.YEAR.Count,TIME_RANGE.YEAR.DateUnits, transactionEntryField))
        //setProducts(result.WebOffers);
        //setUserCriteria(result.AllOfferCriteria);
      } else {
       // history.push({ pathname: "./sign-in" });
      }
       setIsLoading(false)
    };
    fetchData();
    }
  }, [isUserLoading]);

  

  return (
    <div className={classes.root}>
      {(isLoading || isUserLoading) &&<Spinner animation="border" variant="primary" />}
      {WebService.getUserAuthorization() === "MasterAdmin" && <FormControl className="mr-sm-2 ml-sm-2">
        <InputLabel htmlFor="age-native-simple">Company Override</InputLabel>
        <Select
          native
          value={companyIdOverride}
          onChange={handleChangeA}
          inputProps={{
            name: 'name',
            id: 'name-native-simple',
          }}
        >
          <option key={0} value={0}>{"Default"}</option> 
      {SessionHelper.getCompanyList() != null && SessionHelper.getCompanyList().filter(x => GetCompanyId(x)).map((comp)=> 
          <option key={GetCompanyId(comp)} value={GetCompanyId(comp)}>{GetCompanyName(comp)}</option> 
         
       )}
        </Select>
      </FormControl>}
      {(!isLoading && !isUserLoading) && <Grid container spacing={3}>
     
      
      <Grid item lg={8} sm={8} xl={8} xs={8}>
          <TotalUsers count={countReactivatedCustomers} data={[dataAnalytics.RegisteredToday, dataAnalytics.RegisteredLast7Days, dataAnalytics.RegisteredLast30Days, dataAnalytics.TotalRegistered ]} titles={["Registered Today", "Last 7 Days", "Last 30 Days", "Total"]}/>
      </Grid>
      <Grid item lg={8} sm={8} xl={8} xs={8}>
          <TotalUsers count={countReactivatedCustomers} data={[dataAnalytics.TotalNewPlaidConnectionsToday, dataAnalytics.TotalNewPlaidConnectionsLast7, dataAnalytics.TotalNewPlaidConnectionsLast30, dataAnalytics.TotalNewPlaidConnections]} titles={["Plaid Connections Today", "Last 7 Days", "Last 30 Days", "Total"]}/>
      </Grid>
      <Grid item lg={8} sm={8} xl={8} xs={8}>
          <TotalUsers count={countReactivatedCustomers} data={[dataAnalytics.TotalClaimedOffers, dataAnalytics.TotalCompletedOffers, dataAnalytics.TotalNonSignUpOffersCompleted, dataAnalytics.UsersThatCompletedOffers]} titles={["Total Claimed Offers", "Total Completed Offers", "Total Non Signup Offers", "Total Users Completed"]}/>
      </Grid>
      <Grid item lg={8} sm={8} xl={8} xs={8}>
          <TotalUsers count={countReactivatedCustomers} data={[dataAnalytics.PointsAwardedToday, dataAnalytics.PointsAwardedLast7, dataAnalytics.PointsAwardedLast30, dataAnalytics.PointsAwarded]} titles={["Points Awarded Today", "Last 7 Days", "Last 30 Days", "Total"]}/>
      </Grid>
      <Grid item lg={8} sm={8} xl={8} xs={8}>
          <TotalUsers count={countReactivatedCustomers} data={[dataAnalytics.PointsByAdsToday, dataAnalytics.PointsByAdsLast7, dataAnalytics.PointsByAdsLast30, dataAnalytics.PointsByAdsTotal]} titles={["Points Awarded Today (Ads)", "Last 7 Days", "Last 30 Days", "Total"]}/>
      </Grid>
     

       
      </Grid>}
    </div>
  );
};

export default withAuthorization(Dashboard);
