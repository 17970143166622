import moment from "moment";

export function GetWord(keyword) {
  if (keyword !== null && keyword.Word) {
    return keyword.Word;
  } else {
    return "N/A";
  }
}
export function GetCount(keyword) {
  if (keyword !== null && keyword.Count) {
    return keyword.Count;
  } else {
    return "N/A";
  }
}

export function GetId(keyword) {
  if (keyword !== null && keyword.Id) {
    return keyword.Id;
  } else {
    return 0;
  }
}

export function GetCompanyId(keyword) {
  if (keyword !== null && keyword.CompanyId) {
    return keyword.CompanyId;
  } else {
    return "N/A";
  }
}

export function GetCompanyName(keyword) {
  if (keyword !== null && keyword.CompanyName) {
    return keyword.CompanyName;
  } else {
    return "N/A";
  }
}

export function GetAdminNote(keyword) {
  console.log("Keyword in Admin note: ");
  console.log(keyword);
  
  if (keyword !== null && keyword.AdminNote) {
    return keyword.AdminNote;
  } else {
    return "N/A";
  }
}

export function GetDateLastScanned(keyword) {
  if (keyword !== null && keyword.LastTransactionCheck) {
    return keyword.LastTransactionCheck !== null ? moment(keyword.LastTransactionCheck).format("MM/DD/YYYY"): "N/A"; 
  } else {
    return "N/A";
  }
}

export function GetCreatedDate(keyword) {
  if (keyword !== null && keyword.DateCreated) {
    return moment(keyword.DateCreated).format("MMM/DD/YYYY");
  } else {
    return "N/A";
  }
}

export function GetStatus(keywordModel){
  if(keywordModel !== null ) {
    switch (keywordModel.Status) {
      case 0:
        return KeywordStatusToString.None;
      case 1:
        return KeywordStatusToString.Active;
      case 2:
        return KeywordStatusToString.Pending;
      case 4:
        return KeywordStatusToString.Removed;
      case 5:
        return KeywordStatusToString.Fix;
      case 6:
        return KeywordStatusToString.Suspended;
    }
  }
}

export const KeywordStatusToString = {
  None: "None",
  Active: "Approved",
  Pending: "Pending",
  Removed: "Expired",
  Fix : "Revisions Required",
  Suspended: "Suspended"
}

export const KeywordStatusToInt = {
  None: 0,
  Active: 1,
  Pending: 2,
  Removed: 4,
  Fix : 5,
  Suspended: 6
}

export function KeywordStatusEnum(keywordModel){
  switch(keywordModel.Status){
    case 0:
      return KeywordStatusToInt.None;
    case 1:
      return KeywordStatusToInt.Active;
    case 2: 
      return KeywordStatusToInt.Pending;
    case 4: 
      return KeywordStatusToInt.Removed;
    case 5:
      return KeywordStatusToInt.Fix;
    case 6: 
      return KeywordStatusToInt.Suspended;
  }
  return 0;
}

export function GetPopupTitle(status){
  switch(status){
    case KeywordStatusToInt.None:
      return "None"
    case KeywordStatusToInt.Active:
      return "Keyword is Active"
    case KeywordStatusToInt.Pending: 
      return "Keyword is Pending";
    case KeywordStatusToInt.Removed:
      return "Removed";
    case KeywordStatusToInt.Fix:
      return "Keyword Requires Revision";
    case KeywordStatusToInt.Suspended:
      return "Keyword Is Suspended"
  }
  return "";
}

export function GetPopupBody(status){
  switch(status){
    case KeywordStatusToInt.None:
      return "None"
    case KeywordStatusToInt.Active:
      return "The keyword will be scanned for and applied towards offers."
    case KeywordStatusToInt.Pending: 
      return "Approve or reject the proposed keyword. If rejected, the operator will have a chance to revise their request. You may leave a note to provide feedback on what they must correct.";
    case KeywordStatusToInt.Removed:
      return "The keyword is removed, it will not be applied towards offers.";
    case KeywordStatusToInt.Fix:
      return "The keyword must be revised and approved by an admin before it can be applied towards offers. This keyword will be moved to pending once an operator makes changes.";
    case KeywordStatusToInt.Suspended:
      return "The keyword is suspended, an admin must be contacted to reactivate it."
  }
  return "";
}

export function GetPopupBodyOperator(status){
  switch(status){
    case KeywordStatusToInt.None:
      return "None"
    case KeywordStatusToInt.Active:
      return "This keyword will be scanned for and applied towards your offers."
    case KeywordStatusToInt.Pending: 
      return "Awaiting admin approval of keyword. If rejected, Admin will leave a note to provide feedback and you could revise your request.";
    case KeywordStatusToInt.Removed:
      return "This keyword is removed, it will not be applied towards offers.";
    case KeywordStatusToInt.Fix:
      return "This keyword must be revised and approved by an admin before it can be applied towards offers.";
    case KeywordStatusToInt.Suspended:
      return "This keyword is suspended, an admin must be contacted to reactivate it."
  }
  return "";
}

export function ShowApproveButton(status){
  return status === KeywordStatusToString.Pending;
}

export function ShowDenyButton(status) {
  return status === KeywordStatusToString.Pending;
}

export function ShowRemoveButton(status) {
  return status !== KeywordStatusToString.Suspended;
}

export function ShowRevokeButton(status) {
  return status === KeywordStatusToString.Active;
}

export function ShowRevisionNote(status) {
  return status === KeywordStatusToString.Fix;
}
export function ShowPopupPosAdmin(status){
  switch(status){
    case KeywordStatusToInt.None:
      return false;
    case KeywordStatusToInt.Active:
      return false;
    case KeywordStatusToInt.Pending: 
      return true;
    case KeywordStatusToInt.Removed:
      return false;
    case KeywordStatusToInt.Fix:
      return false;
    case KeywordStatusToInt.Suspended:
      return true;
  }
  return "";
}

export function ShowPopupNegAdmin(status){
  switch(status){
    case KeywordStatusToInt.None:
      return false;
    case KeywordStatusToInt.Active:
      return true;
    case KeywordStatusToInt.Pending: 
      return true;
    case KeywordStatusToInt.Removed:
      return false;
    case KeywordStatusToInt.Fix:
      return false;
    case KeywordStatusToInt.Suspended:
      return false;
  }
  return "";
}


